import React, { useState } from 'react'
import SocialDistancing from 'components/SocialDistancing'
import { TextButton } from 'components/Button'
import { LayoutDirection } from 'types/enums'
import Color from 'types/color'
import { SVG } from 'components/SVG'

const TileButton: React.FC<{
  label: string
  Icon: SVG
  onClick: () => void
}> = ({ label, Icon, onClick, children }) => {
  const defaultColor = Color.DarkBlue
  const [color, setColor] = useState<string>(defaultColor)
  const textStyle = { color, fontSize: 18 }
  const iconStyle = { color, scale: 1.2, style: { marginTop: '2px' } }

  return (
    <TextButton
      key={label}
      style={textStyle}
      onClick={() => {
        setColor(defaultColor)
        onClick()
      }}
      onMouseOver={() => setColor(Color.LightBlue)}
      onMouseLeave={() => setColor(defaultColor)}
    >
      <SocialDistancing spacing="16px" direction={LayoutDirection.Horizontal}>
        <div>{label}</div>
        <Icon {...iconStyle} />
      </SocialDistancing>
      {children}
    </TextButton>
  )
}

export default TileButton
