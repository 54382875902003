import React, { FunctionComponent } from 'react'
import { useFormikContext } from 'formik'
import { capitalize, get, includes, map } from 'lodash'
import { intlMessageForId } from 'localization'
import SocialDistancing from 'components/SocialDistancing'
import { FormCheckbox, FormRadioButtons } from 'components/FormElements'

export enum LocationAccessType {
  All = 'ALL',
  Restricted = 'RESTRICTED',
}

export type LocationFilterOption = {
  label: string
  value: string
}

const getElementWidth: (numberOfElements: number) => string = (
  numberOfElements
) => {
  const percentLength = numberOfElements >= 3 ? 33.3 : 100 / numberOfElements
  return `${percentLength}%`
}

const LocationFilter: FunctionComponent<{
  directions: string
  formNames: {
    accessType: string
    locations: string
  }
  locations: Array<LocationFilterOption>
}> = ({ directions, formNames, locations }) => {
  const { values } = useFormikContext()

  const renderLocations: boolean =
    get(values, formNames.accessType) === LocationAccessType.Restricted

  return (
    <SocialDistancing spacing="32px" style={{ flexGrow: 1 }}>
      <SocialDistancing spacing="16px">
        <p>{directions}</p>
        <FormRadioButtons
          name={formNames.accessType}
          options={[
            {
              label: intlMessageForId(
                `Admin.AddUser.LocationAccess.AccessType.${capitalize(
                  LocationAccessType.All
                )}`
              ),
              value: LocationAccessType.All,
            },
            {
              label: intlMessageForId(
                `Admin.AddUser.LocationAccess.AccessType.${capitalize(
                  LocationAccessType.Restricted
                )}`
              ),
              value: LocationAccessType.Restricted,
            },
          ]}
          displayError={false}
        />
      </SocialDistancing>
      {renderLocations && (
        <div
          role="group"
          aria-labelledby="checkbox-group"
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          {map(locations, (location: LocationFilterOption) => {
            const { label, value } = location

            const isChecked = includes(
              get(values, formNames.locations, []),
              value
            )

            return (
              <FormCheckbox
                id={label}
                checked={isChecked}
                name={formNames.locations}
                value={value}
                style={{
                  flexBasis: getElementWidth(locations.length),
                  maxWidth: '400px',
                }}
                key={label}
              >
                {location.label}
              </FormCheckbox>
            )
          })}
        </div>
      )}
    </SocialDistancing>
  )
}

export default LocationFilter
