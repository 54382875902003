import React, { FunctionComponent } from 'react'
import { gql } from '@apollo/client'
import { map, property } from 'lodash/fp'
import { Path } from 'types/enums'
import { intlMessageForId } from 'localization'
import Page from 'components/Page'
import { SegmentType, useSegmentedControl } from 'components/SegmentedControl'
import FacetsProvider, {
  appendAllOption,
  Facets,
  FacetsConfig,
  FacetValue,
} from 'components/charts/FacetsProvider'
import Divider from 'components/Divider'
import LoadAcceptanceChart from './LoadAcceptance'
import ConcernConcFactory from './ConcernConcFactory'

const GMO_TITLE_ID = 'Charts.GMOConcentration'
const MYCOTOXIN_TITLE_ID = 'Charts.MycotoxinConcentration'
const ALLERGEN_TITLE_ID = 'Charts.AllergenConcentration'

enum SegmentKey {
  LoadAcceptance = 'LOAD_ACCEPTANCE',
  GMO = 'GMO',
  Mycotoxin = 'MYCOTOXIN',
  Allergen = 'ALLERGEN',
}

const segments: Array<SegmentType> = [
  {
    key: SegmentKey.LoadAcceptance,
    title: intlMessageForId('Dashboard.Segments.LoadAcceptance'),
    path: Path.QuickScan_LoadAcceptance,
  },
  {
    key: SegmentKey.GMO,
    title: intlMessageForId('Dashboard.Segments.Gmo'),
    path: Path.QuickScan_Gmo,
  },
  {
    key: SegmentKey.Mycotoxin,
    title: intlMessageForId('Dashboard.Segments.Mycotoxin'),
    path: Path.QuickScan_Mycotoxin,
  },
  {
    key: SegmentKey.Allergen,
    title: intlMessageForId('Dashboard.Segments.Allergen'),
    path: Path.QuickScan_Allergen,
  },
]

export const dashboardPaths: string[] = map(property(['path']))(segments)

type FacetsResponseData = {
  commodity: FacetValue[]
  gmo: {
    analyte: FacetValue[]
  }
  location: FacetValue[]
  mycotoxin: {
    analyte: FacetValue[]
  }
  allergen: {
    analyte: FacetValue[]
  }
  supplier: FacetValue[]
}

export const QUICKSCAN_TRANSLATED_FACETS_QUERY = gql`
  query {
    quickscanTranslatedFacets {
      commodity {
        key
        label
      }
      gmo {
        analyte {
          key
          label
        }
      }
      mycotoxin {
        analyte {
          key
          label
        }
      }
      allergen {
        analyte {
          key
          label
        }
      }
      location {
        key
        label
      }
      supplier {
        key
        label
      }
    }
  }
`

const commonChartFacetsConfig: FacetsConfig = {
  location: {
    optional: true,
  },
  supplier: {
    optional: true,
  },
}

export const chartFacetsConfig: {
  loadAcceptance: FacetsConfig
  concernConc: FacetsConfig
} = {
  loadAcceptance: {
    ...commonChartFacetsConfig,
    commodity: {
      optional: true,
    },
  },
  concernConc: {
    ...commonChartFacetsConfig,
    commodity: {
      optional: false,
    },
    analyte: {
      optional: false,
    },
  },
}

const Dashboard: FunctionComponent = () => {
  const { SegmentedControl, selectedSegment } = useSegmentedControl(segments)

  return (
    <FacetsProvider
      query={QUICKSCAN_TRANSLATED_FACETS_QUERY}
      facetsKey="quickscanTranslatedFacets"
      render={(facets) => {
        const {
          commodity,
          location,
          supplier,
          gmo,
          mycotoxin,
          allergen,
        } = facets as FacetsResponseData

        // display order of filters is set here
        const chartFacets: {
          loadAcceptance: Facets
          gmo: Facets
          mycotoxin: Facets
          allergen: Facets
        } = {
          loadAcceptance: {
            commodity,
            location,
            supplier,
          },
          gmo: {
            commodity,
            analyte: gmo.analyte,
            location,
            supplier,
          },
          mycotoxin: {
            commodity,
            analyte: mycotoxin.analyte,
            location,
            supplier,
          },
          allergen: {
            commodity,
            analyte: allergen.analyte,
            location,
            supplier,
          },
        }

        const cases = {
          [SegmentKey.LoadAcceptance]: (
            <LoadAcceptanceChart
              testId="LoadAcceptanceChart"
              facets={appendAllOption(
                chartFacets.loadAcceptance,
                chartFacetsConfig.loadAcceptance
              )}
            />
          ),
          [SegmentKey.GMO]: (
            <ConcernConcFactory
              facets={appendAllOption(
                chartFacets.gmo,
                chartFacetsConfig.concernConc
              )}
              titleId={GMO_TITLE_ID}
              testId="GMOConcentrationChart"
              key={GMO_TITLE_ID}
            />
          ),
          [SegmentKey.Mycotoxin]: (
            <ConcernConcFactory
              facets={appendAllOption(
                chartFacets.mycotoxin,
                chartFacetsConfig.concernConc
              )}
              titleId={MYCOTOXIN_TITLE_ID}
              testId="MycotoxinConcentrationChart"
              key={MYCOTOXIN_TITLE_ID}
            />
          ),
          [SegmentKey.Allergen]: (
            <ConcernConcFactory
              facets={appendAllOption(
                chartFacets.allergen,
                chartFacetsConfig.concernConc
              )}
              titleId={ALLERGEN_TITLE_ID}
              testId="AllergenConcentrationChart"
              key={ALLERGEN_TITLE_ID}
            />
          ),
        }

        return (
          <Page title={intlMessageForId('Dashboard.Header')} testId="Dashboard">
            <SegmentedControl />
            <Divider />
            <div style={{ paddingTop: '48px' }}>
              {cases[selectedSegment.key as SegmentKey]}
            </div>
          </Page>
        )
      }}
    />
  )
}

export default Dashboard
