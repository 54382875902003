import React, { FunctionComponent, ReactElement, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { capitalize, join, map } from 'lodash'
import { getOr } from 'lodash/fp'
import { intlMessageForId } from 'localization'
import { FormattedMessageId, ID } from 'types'
import { openUrl } from 'utils'
import { Concern, Path } from 'types/enums'
import { EMPTY_STATE_STRING } from 'constants/index'
import { SecondaryButton } from 'components/Button'
import Page, { NoPrint, OnlyPrint } from 'components/Page'
import SocialDistancing from 'components/SocialDistancing'
import Section from 'components/Section'
import { Loading, Error } from 'components/Placeholders'
import Table, { getColumns, TableColumnType } from 'components/Table'
import Callout from 'components/Callout'
import {
  LabsDivider as divider,
  LabeledValue,
  LargeLabel,
  HeaderSection,
  AboutOrdering,
  SampleIdTableCell,
} from './components'
import { LabOrderStatus } from './LabOrders'
import { EnvirologixLocations, Location } from './utils'

export type LabAssay = {
  id: ID
  analyte: string
  commodity: string
  category: string
  concern: Concern
  portalOrderForm: string | null
  type: 'QUANTITATIVE' | 'QUALITATIVE'
}

export type LabTest = { assay: LabAssay }

export type Sample = {
  id: string
  assays: Array<LabAssay>
  description: string
  sampleCommodity: string
  status: string
  sampleType: string
  rush: boolean
  tests: Array<LabTest>
  sampleComments?: string
  noGmoSum: boolean
}

type TableDatum = Partial<{
  sampleId: ReactElement
  commodity: string
  formattedSampleType: string
  formattedAssays: string
  formattedOvernightRush: string
  formattedStatus: string
}>

export const LAB_ORDER_QUERY = gql`
  query($id: ID!) {
    labOrder(id: $id) {
      customer {
        customerName
        address
        billTo
        phone
      }
      state
      createdAt
      receivedAt
      completedAt
      reportContact
      numSamples
      samples {
        id
        sampleType
        rush
        status
        sampleCommodity
        description
        tests {
          assay {
            title
          }
        }
      }
      location
    }
  }
`

const DateSection: FunctionComponent<{
  location?: string
  createdAt?: string
  receivedAt?: string
  completedAt?: string
}> = ({ location, createdAt, receivedAt, completedAt }) => (
  <Section>
    {map(
      [
        {
          id: 'Labs.FieldLabels.location',
          value: location,
        },
        {
          id: 'Labs.FieldLabels.createdAt',
          value: createdAt,
        },
        {
          id: 'Labs.FieldLabels.receivedAt',
          value: receivedAt,
        },
        {
          id: 'Labs.FieldLabels.completedAt',
          value: completedAt,
        },
      ],
      (content: { id: FormattedMessageId; value?: string }) => {
        const { id, value } = content
        return <LabeledValue key={id} labelId={id} value={value} />
      }
    )}
  </Section>
)

const AddressSection: FunctionComponent<{
  reportContact: string
  shippingAddress?: string
  billingAddress?: string
}> = ({ reportContact, shippingAddress, billingAddress }) => (
  <Section style={{ display: 'flex', flexWrap: 'wrap', gap: '24px' }}>
    {map(
      [
        {
          id: 'Labs.FieldLabels.sendReportTo',
          value: reportContact,
          boldValueLine1: false,
        },
        {
          id: 'Labs.FieldLabels.yourInformation',
          value: shippingAddress,
          boldValueLine1: true,
        },
        {
          id: 'Labs.FieldLabels.billing',
          value: billingAddress,
          boldValueLine1: true,
        },
      ],
      (content: {
        id: FormattedMessageId
        value?: string
        boldValueLine1?: boolean
      }) => {
        const { id, value, boldValueLine1 } = content

        return (
          <LabeledValue
            key={id}
            labelId={id}
            value={value}
            boldValueLine1={boldValueLine1}
            StyledLabel={LargeLabel}
          />
        )
      }
    )}
  </Section>
)

const tableColumns: TableColumnType[] = [
  'sampleId',
  'commodity',
  'formattedSampleType',
  'formattedAssays',
  'formattedOvernightRush',
  'formattedStatus',
].map((field) => {
  return {
    title: intlMessageForId(`Labs.FieldLabels.${field}`),
    field,
    emptyValue: EMPTY_STATE_STRING,
  }
})

const tableData: (samples: Sample[]) => TableDatum[] = (samples) => {
  return map(samples, (sample: Sample) => {
    const {
      id,
      description,
      sampleCommodity,
      sampleType,
      rush,
      tests,
      status,
    } = sample

    const sampleId = (
      <SampleIdTableCell customerSampleId={description} qbenchSampleId={id} />
    )
    const commodity = sampleCommodity
    const formattedSampleType = capitalize(sampleType)
    const formattedOvernightRush = intlMessageForId(
      `Common.${rush ? 'Yes' : 'No'}`
    )
    const formattedAssays: string = join(
      map(tests, (test) => {
        return getOr('', 'assay.title', test)
      }),
      ', '
    )
    const formattedStatus = status ? capitalize(status) : undefined

    return {
      id,
      sampleId,
      commodity,
      formattedSampleType,
      formattedOvernightRush,
      formattedAssays,
      formattedStatus,
    }
  })
}

const TableSection: FunctionComponent<{
  numSamples: number
  samples: Sample[]
  orderId: ID
}> = ({ numSamples, samples, orderId }) => {
  const sectionHeader = `${intlMessageForId(
    'Labs.OrderDetails.SectionHeaders.Samples'
  )}: ${numSamples}`

  // sort without mutating the original array
  const sortedSamples = [...samples].sort((a: Sample, b: Sample) => {
    return a.id.localeCompare(b.id)
  })

  return (
    <Section header={sectionHeader}>
      <Table
        columns={getColumns(tableColumns, ['id'])}
        data={tableData(sortedSamples)}
        onRowClick={(
          _e: MouseEvent,
          rowData: {
            id?: ID
          }
        ) => {
          const sampleId = rowData.id
          openUrl(`${Path.Labs_Orders}/${orderId}/samples/${sampleId}`)
        }}
      />
    </Section>
  )
}

const LabAddressCallout: FunctionComponent<{ location: Location }> = ({
  location,
}) => {
  const { name, address, city, state, zip } = location
  return (
    <Callout>
      <SocialDistancing spacing="8px">
        <header>{intlMessageForId('Labs.OrderDetails.Callout.Header')}:</header>
        <div>{name}</div>
        <div>{address}</div>
        <div>
          {city}, {state} {zip}
        </div>
      </SocialDistancing>
    </Callout>
  )
}

const AboutOrderingSection: FunctionComponent<{ location: Location }> = ({
  location,
}) => {
  const callout = <LabAddressCallout location={location} />
  return (
    <>
      <NoPrint>
        <Section
          header={intlMessageForId(
            'Labs.OrderDetails.SectionHeaders.AboutOrdering'
          )}
        >
          <AboutOrdering />

          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div>{callout}</div>
          </div>
        </Section>
      </NoPrint>
      <OnlyPrint>{callout}</OnlyPrint>
    </>
  )
}

const LabOrderDetails: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>()
  const { loading, error, data } = useQuery(LAB_ORDER_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  })

  useEffect(() => {
    // scroll to top on load to make error banner visible
    window?.scrollTo(0, 0)
  }, [])

  if (loading) return <Loading />
  if (error) return <Error />

  const {
    customer,
    state: status,
    createdAt,
    receivedAt,
    completedAt,
    reportContact,
    numSamples,
    samples,
    location,
  } = data.labOrder

  const { customerName, address, phone, billTo } = customer
  const shippingAddress = `${address}\n${phone}`

  const pageTitle = `${intlMessageForId('Labs.OrderDetails.Header')}: ${id}`

  const editButton = () => (
    <SecondaryButton to={`${Path.Labs_Orders}/${id}/edit`} as={Link}>
      Edit
    </SecondaryButton>
  )

  return (
    <Page testId="LabOrderDetails">
      <HeaderSection
        pageTitle={pageTitle}
        status={intlMessageForId(`Types.LabOrderStatus.${status}`)}
        statusDetails={
          status === LabOrderStatus.Pending ? 'Est: yyyy-mm-dd' : undefined
        }
        printButtonLabel={intlMessageForId(
          'Labs.OrderDetails.Header.Buttons.Print'
        )}
        additionalElement={receivedAt ? undefined : editButton()}
      />
      {divider}
      <AboutOrderingSection location={EnvirologixLocations[location]} />
      {divider}
      <DateSection
        location={customerName}
        createdAt={createdAt}
        receivedAt={receivedAt}
        completedAt={completedAt}
      />
      {divider}
      <AddressSection
        reportContact={reportContact}
        shippingAddress={shippingAddress}
        billingAddress={billTo}
      />
      {divider}
      <TableSection
        numSamples={numSamples}
        samples={samples}
        orderId={id as ID}
      />
    </Page>
  )
}

export default LabOrderDetails
