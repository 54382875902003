import { gql, useQuery } from '@apollo/client'
import { get } from 'utils'
import { LocationFacetValue } from 'components/pages/labs/hooks/useLabsCustomerLocationFacet'

export const QUICK_SCAN_LOCATIONS_QUERY = gql`
  query {
    quickscanFacets {
      location
    }
  }
`

const parseData: (data: string[]) => Array<{ key: string; label: string }> = (
  data
) =>
  get(data, 'quickscanFacets.location', []).map((location: string) => {
    return { key: location, label: location }
  })

const useQuickScanLocationFacet: () => {
  location: Array<LocationFacetValue>
} = () => {
  const { data } = useQuery(QUICK_SCAN_LOCATIONS_QUERY)
  return { location: parseData(data) }
}

export default useQuickScanLocationFacet
