import React, { Dispatch, ReactElement } from 'react'
import { DocumentNode, useMutation } from '@apollo/client'
import { SecondaryButton } from 'components/Button'
import { Error } from 'components/Placeholders'
import { get } from 'utils'
import { BuildCreatePayload, TemplateBase } from './types'

type Props<Template, FormValueType> = {
  createMutation: DocumentNode
  formValue: FormValueType
  refetch: () => void
  setSelectedTemplate: Dispatch<React.SetStateAction<Template | undefined>>
  createMutationKey: string
  buildCreatePayload: BuildCreatePayload<FormValueType>
}

function SaveTemplate<Template extends TemplateBase, FormValueType>(
  props: Props<Template, FormValueType>
): ReactElement {
  const {
    createMutation,
    formValue,
    refetch,
    setSelectedTemplate,
    createMutationKey,
    buildCreatePayload,
  } = props

  const [createTemplate, { error: mutationError }] = useMutation(
    createMutation,
    {
      onCompleted: (mutationData) => {
        const response = get(mutationData, createMutationKey)

        if (response?.successful) {
          refetch()
          setSelectedTemplate(response?.result)
        }
      },
    }
  )

  const promptForName = () => {
    // using alert by design
    // eslint-disable-next-line no-alert
    const title = window.prompt(
      'Use the current values as a template to save time.',
      'Name your template'
    )

    if (title) {
      createTemplate({
        variables: buildCreatePayload(title, formValue),
      })
    }
  }

  if (mutationError) return <Error />

  return (
    <SecondaryButton
      style={{ marginLeft: 0, marginRight: 0, width: '100%' }}
      onClick={promptForName}
    >
      Save Template
    </SecondaryButton>
  )
}

export default SaveTemplate
