import React, {
  forwardRef,
  FunctionComponent,
  ReactElement,
  ReactNode,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import MaterialTable, { Icons, MTableBodyRow } from '@material-table/core'
import { TableContainer, makeStyles } from '@material-ui/core'
import { map } from 'lodash/fp'
import { ReactComponent as Reset } from 'assets/svg/reset.svg'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import { intlMessageForId } from 'localization'
import { isSet, parseIntOr } from 'utils'
import { AnyObject, StyleObject } from 'types'
import Color from 'types/color'
import { LayoutDirection } from 'types/enums'
import SocialDistancing from 'components/SocialDistancing'
import { ImgButton, TextButton } from 'components/Button'
import useQueryParams from 'hooks/useQueryParams'
import { SearchInput } from './FormElements/FormInput'

const PAGE_QUERY_PARAM = 'page'

export const PAGINATED_ROWS_PER_PAGE = 10

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
} as Icons

export const usePageQueryParam: () => number = () => {
  const { [PAGE_QUERY_PARAM]: page } = useQueryParams()

  // page number to use if it isn't in the url's query string or if it's invalid.
  // for example: '/labs/results' or '/labs/orders?page=foo' or '/labs/orders?page=-1'
  const unspecifiedPage = 1

  const result = parseIntOr(page, unspecifiedPage)

  return result > 0 ? result : unspecifiedPage
}

export const getOffset: (page: number, limit: number) => number = (
  page,
  limit
) => {
  return (page - 1) * limit
}

export const Pagination: FunctionComponent<{
  limit: number
  fetched: number
  refetch: (obj: AnyObject) => void
  page: number
  path: string
}> = ({ limit, fetched, page, path }) => {
  const navigate = useNavigate()

  // if the response is full but consists of all the remaining records then there will be a next-page button to an empty table.
  // this is a consequence of the api not returning the total number of pages for a given limit.
  const showNextButton = fetched === limit
  const showPage = page > 1
  const showPreviousButton = page > 1

  const getButtonPath: (increment: number) => string = (increment) => {
    return `${path}?${PAGE_QUERY_PARAM}=${page + increment}`
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <SocialDistancing
        spacing="24px"
        direction={LayoutDirection.Horizontal}
        style={{ alignItems: 'center' }}
      >
        {showPreviousButton && (
          <TextButton
            onClick={() => {
              navigate(getButtonPath(-1))
            }}
          >
            {intlMessageForId('Table.Previous')}
          </TextButton>
        )}
        {showPage && (
          <div style={{ color: Color.DarkBlue, fontWeight: 'bold' }}>
            {page}
          </div>
        )}
        {showNextButton && (
          <TextButton
            onClick={() => {
              navigate(getButtonPath(1))
            }}
          >
            {intlMessageForId('Table.Next')}
          </TextButton>
        )}
      </SocialDistancing>
    </div>
  )
}

export type TableColumnType = {
  title: string | ReactElement
  field: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cellStyle?: StyleObject | ((props: any) => StyleObject)
  emptyValue?: string | ReactElement | (() => ReactElement)
  hidden?: boolean
  width?: number | string
  customFilterAndSearch?: (filter: string, rowData: AnyObject) => boolean
}

const borderBottom = `${Color.Border} solid 1px`

// this border-bottom style override is necessary because MaterialTable's `rowStyle` object
// doesn't correctly implement the border rule
// (revisit later after lib update) -- c.eldridge:15dec2020
const useStyles = makeStyles({
  tableRow: {
    '& td': {
      borderBottom,
    },
  },
})

export const getColumns: (
  visibleColumns: TableColumnType[],
  hiddenColumnFields?: string[],
  options?: { includeDelete: boolean }
) => TableColumnType[] = (
  visibleColumns,
  hiddenColumnFields = [],
  options = { includeDelete: false }
) => {
  // hidden columns are used to build urls for table-row click events
  const hiddenColumns = map((field: string) => {
    return { field, title: field, hidden: true }
  })(hiddenColumnFields)

  const columns = [...hiddenColumns, ...visibleColumns]

  if (options.includeDelete) {
    const deleteColumn = {
      title: intlMessageForId('Table.FieldLabels.Delete'),
      field: 'delete',
      sorting: false,
      disableClick: true,
    }

    return [...columns, deleteColumn]
  }

  return columns
}

export const DetailedHeader: FunctionComponent<{
  text: string
  detail: string
}> = ({ text, detail }) => (
  <div>
    <div>{text}</div>
    <div style={{ color: '#A6A6A6', opacity: 0.6 }}>{detail}</div>
  </div>
)

export type DetailedCellProps = {
  text: ReactNode
  detailText?: ReactNode
  bold?: boolean
  highlight?: boolean
}

// DetailedCell component has an unused prop that passes the `highlight` boolean
export const cellStyle: (props: {
  props: DetailedCellProps
}) => StyleObject = ({ props: { highlight } }) => {
  return {
    backgroundColor: highlight ? Color.Highlight : 'transparent',
  }
}

export const DetailedCell: FunctionComponent<DetailedCellProps> = ({
  text,
  detailText,
  bold = false,
  // `highlight` prop only used when rendering DetailedCell within a Table
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  highlight = false,
}) => (
  <SocialDistancing
    spacing="4px"
    style={{ fontWeight: bold ? 'bold' : 'normal' }}
  >
    <div>{text}</div>
    {detailText && (
      <div
        style={{
          fontSize: '14px',
          lineHeight: '21px',
          color: Color.Black60,
        }}
      >
        {detailText}
      </div>
    )}
  </SocialDistancing>
)

export const DeleteCell: FunctionComponent<{
  confirmationMessage: string
  onClick: () => void
}> = ({ confirmationMessage, onClick }) => (
  <TextButton
    type="button"
    onClick={() => {
      const confirmation = window.confirm(confirmationMessage)
      if (confirmation) {
        onClick()
      }
    }}
  >
    {intlMessageForId('Buttons.Delete')}
  </TextButton>
)

type LineItem = { key: string; value: string }

export const MultilineCell: FunctionComponent<{
  lineItems: Array<LineItem>
}> = ({ lineItems }) => (
  <SocialDistancing spacing="4px">
    {map((lineItem: LineItem) => {
      const { key, value } = lineItem
      return <div key={key}>{value}</div>
    })(lineItems)}
  </SocialDistancing>
)

const Table: React.FunctionComponent<{
  columns: Array<TableColumnType>
  data: Array<AnyObject>
  search?: boolean
  sorting?: boolean
  onRowClick?: (e: MouseEvent, rowData: AnyObject) => void
  testId?: string
  rowStyle?: StyleObject
  conditionalRowStyle?: {
    predicate: (data: AnyObject) => boolean
    rowStyle: StyleObject
  }
  CallToAction?: ReactNode
}> = ({
  testId,
  columns,
  data,
  search = false,
  sorting = false,
  onRowClick,
  rowStyle = {},
  conditionalRowStyle,
  CallToAction,
}) => {
  const classes = useStyles()

  return (
    <div style={{ width: '100%' }}>
      {!search && CallToAction}
      <TableContainer data-testid={testId}>
        <MaterialTable
          columns={columns}
          data={data}
          icons={tableIcons}
          options={{
            sorting,
            toolbar: search,
            paging: false,
            headerStyle: {
              color: Color.Black60,
              fontWeight: 'bold',
              fontSize: '10px',
              letterSpacing: '0.05em',
              lineHeight: '12px',
              textTransform: 'uppercase',
              verticalAlign: 'top',
              borderBottom,
            },
            rowStyle: (d: AnyObject) => {
              const predicateFunc = conditionalRowStyle?.predicate

              const style =
                predicateFunc && predicateFunc(d)
                  ? conditionalRowStyle.rowStyle
                  : {}

              return {
                color: Color.Black,
                fontSize: '16px',
                height: '75px',
                verticalAlign: 'top',
                ...rowStyle,
                ...style,
              }
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: (
                <p
                  style={{
                    fontFamily: 'Kumbh Sans, sans-serif',
                    color: Color.Black60,
                  }}
                >
                  {intlMessageForId('Table.EmptyState')}
                </p>
              ),
            },
          }}
          components={{
            Toolbar: (props) => {
              const [urlSearchString, setUrlSearchString] = useState<string>('')

              // manually implementing the change handlers to render a custom input element instead of the default
              // allows for the reuse of the global input styles
              // reference: https://github.com/mbrn/material-table/blob/b26e2f715abb2217f0d14e1efb15c6367fe12de1/src/components/m-table-toolbar.js#L32
              const onSearchChange = (text: string) => {
                props.dataManager.changeSearchText(text)
                props.onSearchChanged(text)
                setUrlSearchString(text)
              }

              return (
                <div
                  style={{
                    minHeight: 60,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ display: 'flex', flexGrow: 1 }}>
                    <SearchInput
                      autoFocus
                      value={urlSearchString}
                      style={{ width: '100%', minWidth: 400 }}
                      placeholder="Search"
                      aria-label="Search"
                      onChange={(e) => {
                        onSearchChange(e.target.value)
                      }}
                    />
                    <ImgButton
                      style={{
                        cursor: 'pointer',
                        position: 'relative',
                        top: 3,
                        right: 60,
                        padding: '0 30px', // increases clickable area
                        visibility: isSet(urlSearchString)
                          ? 'visible'
                          : 'hidden',
                      }}
                      type="button"
                      onClick={() => {
                        onSearchChange('')
                      }}
                    >
                      <Reset fill={Color.Black60} />
                    </ImgButton>
                  </div>
                  {CallToAction && (
                    <div
                      style={{
                        display: 'flex',
                        flexGrow: 1,
                        justifyContent: 'end',
                        paddingRight: 4, // right padding provides space for the button's active state borders
                      }}
                    >
                      {CallToAction}
                    </div>
                  )}
                </div>
              )
            },
            Row: (props) => (
              <MTableBodyRow
                {...props}
                className={classes.tableRow}
                onRowClick={onRowClick}
              />
            ),
          }}
        />
      </TableContainer>
    </div>
  )
}

export default Table
