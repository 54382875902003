import React from 'react'
import { map, snakeCase, toUpper } from 'lodash/fp'
import { FormName } from 'types/forms'
import { traverseObject } from 'utils'
import { ToggledSelect } from 'components/FormElements'
import { FormSelectOption } from 'components/MultiSelect'
import { fieldLabel } from '../utils'

export const getSelectOptionsMap: (
  facets: Record<string, Array<string>>
) => Record<string, Array<FormSelectOption>> = (facets) => {
  const keyFunc = (key: string) => {
    return toUpper(snakeCase(key))
  }
  const valueFunc = (key: string, values: string[]) => {
    return map((value: string) => {
      return { label: value, value }
    })(values)
  }
  const omitted = '__typename'

  return traverseObject(facets, keyFunc, valueFunc, omitted)
}

const ReportsMultiSelect: React.FC<{
  formName: FormName
  selectOptions: Array<FormSelectOption>
  defaultChecked?: boolean
  tooltip?: string
}> = ({ formName, selectOptions, defaultChecked, tooltip }) => (
  <ToggledSelect
    name={formName}
    label={fieldLabel(formName)}
    selectOptions={selectOptions}
    defaultChecked={defaultChecked}
    tooltip={tooltip}
    multiple
  />
)

export default ReportsMultiSelect
