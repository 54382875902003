import React from 'react'
import { intlMessageForId } from 'localization'
import Page from 'components/Page'
import Color from 'types/color'

const NotFound: React.FunctionComponent = () => (
  <Page testId="NotFound">
    <div style={{ padding: '24px', color: Color.LightBlue }}>
      {intlMessageForId('ErrorMessages.404')}
    </div>
  </Page>
)

export default NotFound
