import React, { FC } from 'react'
import { getIntlMessageForId } from 'localization'
import Color from 'types/color'
import { LayoutDirection } from 'types/enums'
import { openUrl } from 'utils'
import { EventAction, EventCategory, trackEvent } from 'utils/analytics'
import useCurrentUser from 'hooks/useCurrentUser'
import { PDF } from 'components/SVG'
import SocialDistancing from 'components/SocialDistancing'
import { Loading } from 'components/Placeholders'
import VideoModal from './VideoModal'
import TileButton from './TileButton'
import ContactTechSupport from './ContactTechSupport'

type TileType = {
  id: string
  pdfs: Array<string>
  videos: Array<string>
  adminOnly: boolean
  labsOnly: boolean
}

const TILES: Array<TileType> = [
  {
    id: 'ActivatingExportInQuickScan',
    pdfs: ['ConnectCardQuickGuide', 'DataEntryBestPractices'],
    videos: ['HowToVideo'],
    adminOnly: false,
    labsOnly: false,
  },
  {
    id: 'GeneratingDataReports',
    pdfs: [
      'QuickScanReportingQuickGuide',
      'QuickCheckReportingQuickGuide',
      'GeneratingWeeklyReports',
    ],
    videos: ['HowToVideo'],
    adminOnly: false,
    labsOnly: false,
  },
  {
    id: 'LabOrdering&Reporting',
    pdfs: ['OrderingQuickGuide', 'ResultsReportQuickGuide'],
    videos: ['HowToVideo'],
    adminOnly: false,
    labsOnly: true,
  },
  {
    id: 'OrganizationAdminSettings',
    pdfs: ['QuickGuide'],
    videos: ['HowToVideo'],
    adminOnly: true,
    labsOnly: false,
  },
]

const trackClick: (section: string, element: string) => void = (
  section,
  element
) => {
  trackEvent(
    EventCategory.TotalHubSupport,
    EventAction.Click,
    `${section}__${element}`
  )
}

const Tile: FC<{
  id: string
  pdfs?: Array<string>
  videos?: Array<string>
  hide?: boolean
}> = ({ id, pdfs = [], videos = [], hide = false }) => {
  if (hide) {
    return null
  }

  const toIntl = getIntlMessageForId('HelpCenter.TotalHubSupport.Tiles')
  const title = toIntl(`${id}.title`)

  return (
    <SocialDistancing
      spacing="8px"
      direction={LayoutDirection.Vertical}
      style={{ color: Color.DarkBlue, fontWeight: 'bold' }}
    >
      <div style={{ fontSize: 18 }}>{title}</div>
      <SocialDistancing spacing="8px" direction={LayoutDirection.Vertical}>
        {pdfs.map((pdf: string) => {
          const label = toIntl(`${id}.${pdf}.label`)
          const src = toIntl(`${id}.${pdf}.src`)

          return (
            <TileButton
              key={label}
              label={label}
              Icon={PDF}
              onClick={() => {
                trackClick(title, label)
                openUrl(src)
              }}
            />
          )
        })}
        {videos.map((video: string) => {
          const label = toIntl(`${id}.${video}.label`)
          const src = toIntl(`${id}.${video}.src`)

          return (
            <VideoModal
              key={label}
              label={label}
              src={src}
              onClick={() => trackClick(title, label)}
            />
          )
        })}
      </SocialDistancing>
    </SocialDistancing>
  )
}

const TotalHubSupport: FC = () => {
  const { loading, orgAdmin, isLabsCustomer } = useCurrentUser()

  if (loading) return <Loading />

  return (
    <SocialDistancing spacing="48px">
      <div
        data-testid="TotalHubSupport"
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '48px',
        }}
      >
        {TILES.map((tile: TileType) => {
          const { id, pdfs, videos, adminOnly, labsOnly } = tile
          return (
            <Tile
              key={id}
              id={id}
              pdfs={pdfs}
              videos={videos}
              hide={(adminOnly && !orgAdmin) || (labsOnly && !isLabsCustomer)}
            />
          )
        })}
      </div>
      <ContactTechSupport />
    </SocialDistancing>
  )
}

export default TotalHubSupport
