import React, { ChangeEvent, FunctionComponent, useState } from 'react'
import { intlMessageForId } from 'localization'
import { isSet } from 'utils'
import {
  EventAction,
  EventCategory,
  EventLabel,
  trackEvent,
} from 'utils/analytics'
import Color from 'types/color'
import { LayoutDirection } from 'types/enums'
import RadioButtons from 'components/RadioButtons'
import SocialDistancing from 'components/SocialDistancing'
import LabeledValue from 'components/charts/LabeledValue'
import { useLocalStorage } from './hooks/useLocalStorage'

enum AverageType {
  Weighted = 'Weighted',
  Unweighted = 'Unweighted',
}

const AverageTypeSelector: FunctionComponent<{
  eventCategory: EventCategory
  id: string
  average?: string
  weightedAverage?: string
}> = ({ eventCategory, id, average, weightedAverage }) => {
  const [getSavedSelection, setSavedSelection] = useLocalStorage(
    `${id}-AverageType`
  )

  const [selectedAverageType, setSelectedAverageType] = useState<AverageType>(
    (getSavedSelection() as AverageType) || AverageType.Unweighted
  )

  const handleChange: (e: ChangeEvent<HTMLInputElement>) => void = (e) => {
    const selection = e.target.value
    trackEvent(
      eventCategory,
      EventAction.Select,
      `${EventLabel.LoadAcceptance}_${selection}`
    )
    setSavedSelection(selection)
    setSelectedAverageType(selection as AverageType)
  }

  const options = [AverageType.Weighted, AverageType.Unweighted].map(
    (element: AverageType) => {
      return {
        label: intlMessageForId(`Charts.Overview.${element}`),
        value: AverageType[element],
      }
    }
  )

  return (
    <SocialDistancing
      style={{ marginTop: '16px' }}
      spacing="30px"
      direction={LayoutDirection.Horizontal}
    >
      {selectedAverageType === AverageType.Unweighted && average && (
        <LabeledValue labelId="Charts.Overview.DataAverage" value={average} />
      )}

      {selectedAverageType === AverageType.Weighted && weightedAverage && (
        <LabeledValue
          labelId="Charts.Overview.DataAverage"
          value={weightedAverage as string}
        />
      )}

      {isSet(average) && isSet(weightedAverage) && (
        <RadioButtons
          name={`AVERAGE-${id}`}
          options={options}
          selectedValue={selectedAverageType as string}
          labelStyle={{
            color: Color.White60,
          }}
          groupLabelStyle={{
            color: Color.White60,
          }}
          handleChange={handleChange}
          verticalLayout
        />
      )}
    </SocialDistancing>
  )
}

export default AverageTypeSelector
