import React, { ReactElement } from 'react'
import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { pick } from 'lodash'
import { map } from 'lodash/fp'
import { intlMessageForId } from 'localization'
import { AnyObject } from 'types'
import { get, isSet } from 'utils'
import { EMPTY_STATE_STRING } from 'constants/index'
import { SectionHeader } from 'components/Section'
import SocialDistancing from 'components/SocialDistancing'
import Table, { DetailedCell, TableColumnType } from 'components/Table'
import { Loading } from 'components/Placeholders'
import Color from 'types/color'

export const QUICKSCAN_QUALITY_TESTS_QUERY = gql`
  query {
    quickscanQualityTests {
      calibration {
        id
        pass
        recordedAt
      }
      checkcomb {
        id
        pass
        recordedAt
      }
      cleantest {
        id
        pass
        recordedAt
      }
      location
      quickscanSerialId
    }
  }
`

type QualityTestResult = {
  id: string
  pass: boolean
  recordedAt: Date
}

const getContent: (qtr: QualityTestResult) => ReactElement = (qtr) => {
  if (!isSet(qtr)) {
    return <b>{intlMessageForId('Common.NoData')}</b>
  }

  const passed = qtr.pass

  return (
    <DetailedCell
      text={(passed
        ? intlMessageForId('Common.Pass')
        : intlMessageForId('Common.Fail')
      ).toUpperCase()}
      detailText={dayjs(qtr.recordedAt).format('YYYY-MM-DD')}
      bold={!passed}
    />
  )
}

type TableDatum = {
  quickscanSerialId: string
  location: string
  checkComb: ReactElement
  cleanTest: ReactElement
  calibration: ReactElement
}

const getContentFilter: (
  field: string
) => (value: string, rowData: AnyObject) => boolean = (field) => {
  return (value: string, rowData: AnyObject) => {
    const { children, detailText, text } = get(rowData, `${field}.props`, {})

    if (children) {
      return children.toLowerCase().indexOf(value.toLowerCase()) > -1
    }

    const searchableText = detailText ? `${text} ${detailText}` : text
    return searchableText.toLowerCase().indexOf(value.toLowerCase()) > -1
  }
}

const tableColumns: Array<TableColumnType> = [
  {
    title: intlMessageForId('Quality.FieldLabels.location'),
    field: 'location',
    emptyValue: EMPTY_STATE_STRING,
  },
  {
    title: intlMessageForId('Quality.FieldLabels.quickscanSerialId'),
    field: 'quickscanSerialId',
    emptyValue: EMPTY_STATE_STRING,
  },
  {
    title: intlMessageForId('Quality.FieldLabels.checkComb'),
    field: 'checkComb',
    emptyValue: EMPTY_STATE_STRING,
    customFilterAndSearch: getContentFilter('checkComb'),
  },
  {
    title: intlMessageForId('Quality.FieldLabels.cleanTest'),
    field: 'cleanTest',
    emptyValue: EMPTY_STATE_STRING,
    customFilterAndSearch: getContentFilter('cleanTest'),
  },
  {
    title: intlMessageForId('Quality.FieldLabels.calibration'),
    field: 'calibration',
    emptyValue: EMPTY_STATE_STRING,
    customFilterAndSearch: getContentFilter('calibration'),
  },
]

const CalibrationTable: React.FC = () => {
  const { loading, data: queryData } = useQuery(QUICKSCAN_QUALITY_TESTS_QUERY, {
    fetchPolicy: 'no-cache',
  })

  if (loading) {
    return <Loading />
  }

  const results = queryData?.quickscanQualityTests

  const tableData: Array<TableDatum> = map(
    (element: {
      quickscanSerialId: string
      location: string
      checkcomb: QualityTestResult
      cleantest: QualityTestResult
      calibration: QualityTestResult
    }) => {
      const picked = pick(element, ['location', 'quickscanSerialId'])

      const { checkcomb, cleantest, calibration } = element

      return {
        ...picked,
        checkComb: getContent(checkcomb),
        cleanTest: getContent(cleantest),
        calibration: getContent(calibration),
      } as TableDatum
    }
  )(results) as Array<TableDatum>

  return (
    <SocialDistancing spacing="24px">
      <SectionHeader>
        {intlMessageForId('Quality.SectionHeaders.CurrentCalibrationStatus')}
      </SectionHeader>
      <Table
        testId="QualityCurrentCalibrationStatus"
        columns={tableColumns}
        data={tableData}
        conditionalRowStyle={{
          // conditionally styles row background color if not all tests have passed
          predicate: (data: AnyObject) => {
            const allPassed = ['checkComb', 'cleanTest', 'calibration']
              .map((element) => {
                return (
                  get(data, `${element}.props.text`) ===
                  intlMessageForId('Common.Pass').toUpperCase()
                )
              })
              .every(Boolean)

            return !allPassed
          },
          rowStyle: { background: Color.Highlight },
        }}
        search
      />
    </SocialDistancing>
  )
}

export default CalibrationTable
