import React, { FC } from 'react'
import { intlMessageForId } from 'localization'
import { LayoutDirection } from 'types/enums'
import { MaterialCheckbox } from 'components/FormElements/FormCheckbox'
import SocialDistancing from 'components/SocialDistancing'
import { Setting, SettingType } from './types'

type UpdateSettingType = (arg: { variables: Setting }) => void

const EnabledCell: FC<{
  setting: Setting
  updateSetting: UpdateSettingType
  type: SettingType
}> = ({ setting, type, updateSetting }) => {
  const { id, enabled } = setting

  const updatePayload = () => {
    return {
      ...setting,
      ...(type === 'EMAIL' ? { email: setting.email } : { email: undefined }),
      ...(type === 'SAMPLE_TYPE'
        ? { sampleType: setting.sampleType }
        : { sampleType: undefined }),
      enabled: !enabled,
    }
  }

  return (
    <SocialDistancing
      direction={LayoutDirection.Horizontal}
      spacing="4px"
      style={{ alignItems: 'center' }}
    >
      <MaterialCheckbox
        id={id.toString()}
        checked={enabled}
        onChange={() => {
          updateSetting({
            variables: updatePayload(),
          })
        }}
      />
      <label htmlFor={setting.id.toString()} style={{ cursor: 'pointer' }}>
        {intlMessageForId('Alerts.Status.ControlLabel')}
      </label>
    </SocialDistancing>
  )
}

export default EnabledCell
