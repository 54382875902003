import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { locale, messagesByLocale } from 'localization'
import { AppRoutes } from 'AppRouter'
import Provider from 'components/Provider'
import './App.css'

const App: React.FunctionComponent = () => {
  return (
    <Provider>
      <IntlProvider locale={locale} messages={messagesByLocale[locale]}>
        <div className="App">
          <Router>
            <AppRoutes />
          </Router>
        </div>
      </IntlProvider>
    </Provider>
  )
}

export default App
