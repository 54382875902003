import React, { FunctionComponent } from 'react'
import SocialDistancing from 'components/SocialDistancing'
import Divider from 'components/Divider'
import { Concern } from 'types/enums'
import { AnalysisTypeQuant } from '../utils'
import TestResultsChart from './TestResultsChart'
import RecentSampleResults from './RecentSampleResults'

const LabsConcernDashboard: FunctionComponent<{
  analysisType: AnalysisTypeQuant
  concern: Concern
  testId: string
}> = ({ analysisType, concern, testId }) => (
  <SocialDistancing testId={testId} spacing="48px">
    <TestResultsChart analysisType={analysisType} concern={concern} />
    <Divider height="8px" />
    <RecentSampleResults concern={concern} />
  </SocialDistancing>
)

export default LabsConcernDashboard
