import React, { FunctionComponent } from 'react'
import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { intlMessageForId } from 'localization'
import { get, getFloat, isSet } from 'utils'
import { AnyObject } from 'types'
import { FormName } from 'types/forms'
import { Loading, Error } from 'components/Placeholders'
import Group from 'components/FormElements/Group'
import { ToggledInput, ToggledRange } from 'components/FormElements'
import {
  INITIAL_VALUES_MAP,
  groupTitle,
  fieldLabel,
  getValue,
  getColumns,
} from '../utils'
import { CreateReportType, RangeValueType } from '../types'
import ReportsMultiSelect, {
  getSelectOptionsMap,
} from '../components/ReportsMultiSelect'
import ReportBuilderPage from '../components/ReportBuilderPage'

export const QUICKCHECK_FACETS_QUERY = gql`
  query GetQuickcheckFacets {
    quickcheckFacets {
      acceptable
      analyte
      commodity
      location
      operator
    }
  }
`

export const CREATE_QUICKCHECK_REPORT_MUTATION = gql`
  mutation(
    $columns: [QuickcheckReportColumn]!
    $filters: QuickcheckReportFiltersInput!
  ) {
    createQuickcheckReport(columns: $columns, filters: $filters)
  }
`

const initialValues = {
  [FormName.SampleId]: INITIAL_VALUES_MAP.input,
  [FormName.Operator]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.Commodity]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.CassetteName]: INITIAL_VALUES_MAP.input,
  [FormName.Acceptable]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.ResultNumeric]: INITIAL_VALUES_MAP.range,
}

const onSubmit = (
  publishPayload?: (payload: Record<string, Array<string> | AnyObject>) => void
) => (formikValues: AnyObject, createReport: CreateReportType) => {
  const columns = getColumns(formikValues)

  const {
    ANALYTE,
    ACCEPTABLE,
    CASSETTE_NAME,
    COMMODITY,
    LOCATION,
    OPERATOR,
    RESULT_NUMERIC,
    SAMPLE_ID,
    WHEN,
  } = formikValues

  const filters: AnyObject = {
    analyte: getValue(ANALYTE),
    acceptable: getValue(ACCEPTABLE),
    cassetteName: getValue(CASSETTE_NAME),
    commodity: getValue(COMMODITY),
    location: getValue(LOCATION),
    operator: getValue(OPERATOR),
    resultNumericMax: getFloat(
      getValue(RESULT_NUMERIC) as RangeValueType,
      'max'
    ),
    resultNumericMin: getFloat(
      getValue(RESULT_NUMERIC) as RangeValueType,
      'min'
    ),
    sampleId: getValue(SAMPLE_ID),
    whenMax: dayjs(get(WHEN, 'max')).endOf('day').toISOString(),
    whenMin: dayjs(get(WHEN, 'min')).startOf('day').toISOString(),
  }

  const payload = {
    columns,
    filters,
  }

  if (isSet(publishPayload)) {
    publishPayload(payload)
  }

  createReport({
    variables: payload,
  })
}

const Quickcheck: FunctionComponent<{
  publishPayload?: (payload: Record<string, string[] | AnyObject>) => void
}> = ({ publishPayload }) => {
  const { data, loading, error } = useQuery(QUICKCHECK_FACETS_QUERY)

  if (loading) return <Loading />
  if (error) return <Error />

  const quickcheckFacets = get(data, 'quickcheckFacets', {})
  const selectOptionsMap = getSelectOptionsMap(quickcheckFacets)

  return (
    <ReportBuilderPage
      testId="QuickcheckReport"
      initialValues={initialValues}
      createReportMutation={CREATE_QUICKCHECK_REPORT_MUTATION}
      mutationResponseKey="createQuickcheckReport"
      onSubmit={onSubmit(publishPayload)}
    >
      <Group title={groupTitle('SampleInformation')}>
        <ReportsMultiSelect
          formName={FormName.Location}
          selectOptions={selectOptionsMap[FormName.Location]}
          defaultChecked
        />
        <ReportsMultiSelect
          formName={FormName.Operator}
          selectOptions={selectOptionsMap[FormName.Operator]}
          defaultChecked
        />
        <ToggledInput
          name={FormName.SampleId}
          label={fieldLabel(FormName.SampleId)}
          defaultChecked
        />
        <ReportsMultiSelect
          formName={FormName.Commodity}
          selectOptions={selectOptionsMap[FormName.Commodity]}
          defaultChecked
        />
        <ReportsMultiSelect
          formName={FormName.Analyte}
          selectOptions={selectOptionsMap[FormName.Analyte]}
          defaultChecked
        />
        <ToggledInput
          name={FormName.CassetteName}
          label={fieldLabel(FormName.CassetteName)}
          defaultChecked
        />
      </Group>
      <Group title={groupTitle('Results')}>
        <ReportsMultiSelect
          formName={FormName.Acceptable}
          selectOptions={selectOptionsMap[FormName.Acceptable]}
          defaultChecked
        />
        <ToggledRange
          name={FormName.ResultNumeric}
          label={fieldLabel(FormName.ResultNumeric)}
          defaultChecked
          tooltip={intlMessageForId(
            `Reports.Tooltips.${FormName.ResultNumeric}`
          )}
        />
      </Group>
    </ReportBuilderPage>
  )
}

export default Quickcheck
