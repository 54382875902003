import React, { FunctionComponent, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { find, map } from 'lodash'
import Color from 'types/color'
import { EventAction, EventCategory, trackEvent } from 'utils/analytics'
import { StyleObject } from 'types'

export type SegmentType = { key: string; title: string; path: string }

export const getSegmentForPath: (
  path: string,
  segments: Array<SegmentType>
) => SegmentType = (path, segments) => {
  return (
    find(segments, (segment: SegmentType) => {
      return segment.path === path
    }) || segments[0]
  )
}

const Segment: FunctionComponent<{
  segment: SegmentType
  isSelected: boolean
  styles?: StyleObject
}> = ({ segment, isSelected, styles = {} }) => {
  const { title, path } = segment

  return (
    <Link
      style={{
        fontSize: '16px',
        padding: '20px',
        paddingTop: 0,
        paddingBottom: '12px',
        borderBottom: isSelected ? `${Color.SelectedText} solid 4px` : 'none',
        color: Color.Black,
        textDecoration: 'none',
        ...styles,
      }}
      to={path}
    >
      {title}
    </Link>
  )
}

const SegmentedControl: FunctionComponent<{
  segments: Array<SegmentType>
  selectedSegment: SegmentType
}> = ({ segments, selectedSegment }) => (
  <div style={{ display: 'flex' }}>
    {map(segments, (segment: SegmentType) => {
      const { key, path } = segment

      return (
        <Segment
          key={key}
          segment={segment}
          isSelected={path === selectedSegment.path}
        />
      )
    })}
  </div>
)

export const useSegmentedControl: (
  segments: Array<SegmentType>
) => {
  SegmentedControl: FunctionComponent
  selectedSegment: SegmentType
} = (segments) => {
  const { pathname } = useLocation()

  const [selectedSegment, setSelectedSegment] = useState<SegmentType>(
    getSegmentForPath(pathname, segments)
  )

  useEffect(() => {
    setSelectedSegment(getSegmentForPath(pathname, segments))
    trackEvent(EventCategory.Segment, EventAction.Select, pathname)
  }, [pathname, segments])

  return {
    SegmentedControl: () => (
      <SegmentedControl segments={segments} selectedSegment={selectedSegment} />
    ),
    selectedSegment,
  }
}
export default SegmentedControl
