import React, { ReactNode } from 'react'
import { Form, Formik } from 'formik'
import { DocumentNode, useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import { isString } from 'lodash'
import { intlMessageForId } from 'localization'
import { AnyObject } from 'types'
import { FormName } from 'types/forms'
import { get, isSet, openUrl } from 'utils'
import Section, { SectionHeader } from 'components/Section'
import SocialDistancing from 'components/SocialDistancing'
import { Error } from 'components/Placeholders'
import {
  DownloadReport,
  EndDate,
  RunReportHeader,
  StartDate,
} from 'components/FormElements/common'
import { CreateReportType } from '../types'

// NOTE: this component does not yet support templates
const ReportBuilderPage: React.FC<{
  testId: string
  initialValues: AnyObject
  createReportMutation: DocumentNode
  mutationResponseKey: string
  onSubmit: (values: AnyObject, createReport: CreateReportType) => void
  formHeader?: string
  submitLabel?: string
  validationSchema?: AnyObject
  onCompleted?: (response: string | AnyObject) => void
  children: ReactNode | ((values: AnyObject) => ReactNode)
}> = ({
  testId,
  initialValues,
  createReportMutation,
  mutationResponseKey,
  onSubmit,
  formHeader = intlMessageForId('Reports.SectionHeaders.SelectFields'),
  submitLabel,
  validationSchema,
  onCompleted,
  children,
}) => {
  const [createReport, { error: mutationError }] = useMutation(
    createReportMutation,
    {
      onCompleted: (mutationData) => {
        const response = get(mutationData, mutationResponseKey)

        if (isSet(onCompleted)) {
          onCompleted(response)
          return
        }

        if (isString(response)) {
          openUrl(response)
        }
      },
    }
  )

  if (mutationError) return <Error />

  return (
    <div data-testid={testId}>
      <Formik
        initialValues={{
          ...initialValues,
          ...{
            [FormName.When]: {
              min: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
              max: dayjs().format('YYYY-MM-DD'),
            },
          },
        }}
        validationSchema={validationSchema}
        onSubmit={(formikValues) => onSubmit(formikValues, createReport)}
      >
        {({ values }) => (
          <Form>
            <Section first>
              <div style={{ flexGrow: 1 }}>
                <SectionHeader>{formHeader}</SectionHeader>
                <SocialDistancing spacing="48px">
                  {typeof children === 'function' ? children(values) : children}
                </SocialDistancing>
              </div>
              <div style={{ marginLeft: '48px', width: '30%' }}>
                <RunReportHeader />
                <SocialDistancing
                  spacing="32px"
                  style={{
                    paddingTop: '25px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <SocialDistancing spacing="8px">
                    <StartDate />
                    <EndDate />
                  </SocialDistancing>
                  <SocialDistancing spacing="8px">
                    <DownloadReport label={submitLabel} />
                  </SocialDistancing>
                </SocialDistancing>
              </div>
            </Section>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ReportBuilderPage
