import React, { FunctionComponent } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { DocumentNode, useMutation } from '@apollo/client'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { FormattedMessage } from 'react-intl'
import { find, get, values as _values, times, parseInt } from 'lodash'
import { filter, flow, map, property } from 'lodash/fp'
import { intlMessageForId } from 'localization'
import { AnyObject } from 'types'
import Color from 'types/color'
import { LayoutDirection, Path } from 'types/enums'
import { FormName } from 'types/forms'
import { isTemporaryId } from 'utils'
import { StyledErrorMessage } from 'components/FormElements/ErrorMessage'
import Page from 'components/Page'
import Section from 'components/Section'
import SocialDistancing from 'components/SocialDistancing'
import {
  ButtonLoadingIndicator,
  PrimaryButton,
  SecondaryButton,
} from 'components/Button'
import ExternalLink from 'components/ExternalLink'
import Callout from 'components/Callout'
import {
  FormInput,
  Textarea,
  FormSelect,
  FormCheckbox,
  VALID_EMAIL_ERROR_MESSAGE,
  REQUIRED_ERROR_MESSAGE,
  ToggledControlName,
  ToggledControlState,
  FormRadioButtons,
  getExceededMaxLengthMessage,
} from 'components/FormElements'
import { handleError } from 'components/FormElements/common'
import {
  LabsDivider as divider,
  LargeLabel,
  LabeledValue,
  AboutOrdering,
} from '../components'
import { LabCustomer } from '../hooks/useLabsCustomerLocationFacet'
import SamplesSection from './SamplesSection'
import { ApiErrorCode, Assay, FormType, FormValues } from './types'
import {
  SaveLabOrderTemplateType,
  useLabOrderTemplates,
} from './useLabOrderTemplates'
import { getCommoditiesFromAssays, getMessageForApiError } from './utils'
import { EnvirologixLocations, Location } from '../utils'

const ContactCallout: FunctionComponent = () => (
  <Callout>
    <SocialDistancing spacing="8px">
      <header>{intlMessageForId('ContactCallout.Header')}</header>
      <SocialDistancing spacing="4px">
        <a
          style={{ color: Color.White }}
          href="mailto:labservices@envirologix.com"
        >
          labservices@envirologix.com
        </a>
        <div>{`${intlMessageForId(
          'Common.Or'
        ).toLowerCase()} (866) 408-4597`}</div>
      </SocialDistancing>
    </SocialDistancing>
  </Callout>
)

const AboutOrderingSection: FunctionComponent = () => (
  <Section first>
    <div style={{ flexGrow: 1 }}>
      <AboutOrdering />
    </div>
    <div style={{ flexBasis: '30%' }}>
      <ContactCallout />
    </div>
  </Section>
)

const CustomerLocationSection: FunctionComponent<{
  labCustomers: Array<LabCustomer>
}> = ({ labCustomers }) => {
  const controlName = FormName.CustomerLocation
  return (
    <Section
      header={intlMessageForId('Labs.NewOrder.SectionHeaders.CustomerLocation')}
    >
      <FormSelect
        name={controlName}
        emptyState="Select a location"
        options={labCustomers.map((labCustomer: LabCustomer) => {
          const { qbenchCustomerId, customerName } = labCustomer
          return { label: customerName, value: qbenchCustomerId.toString() }
        })}
      />
    </Section>
  )
}

const EnvirologixLocationSection: FunctionComponent = () => (
  <Section
    header={intlMessageForId(
      'Labs.NewOrder.SectionHeaders.EnvirologixLocation'
    )}
  >
    <SocialDistancing spacing="8px">
      <div
        style={{ display: 'flex', fontSize: '16px', lineHeight: '22px' }}
        role="group"
        aria-labelledby="envirologix-location-group"
      >
        <FormRadioButtons
          name={FormName.EnvirologixLocation}
          labelStyle={{ alignItems: 'flex-start' }}
          options={map((entry: [string, Location]) => {
            const key = entry[0]
            const { name, address, city, state, zip, phone } = entry[1]

            return {
              label: (
                <SocialDistancing spacing="8px">
                  <div
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {city}, {state}
                  </div>
                  <div>
                    <div>{name}</div>
                    <div>{address}</div>
                    <div>
                      {city}, {state} {zip}
                    </div>
                  </div>
                  <div>
                    <div>{phone}</div>{' '}
                  </div>
                </SocialDistancing>
              ),
              value: key,
            }
          })(Object.entries(EnvirologixLocations))}
        />
      </div>
    </SocialDistancing>
  </Section>
)

const ContactInfoSection: FunctionComponent<{
  defaultEmails?: Array<string>
  shippingAddress?: string
  billingAddress?: string
}> = ({ defaultEmails = [], shippingAddress, billingAddress }) => {
  return (
    <Section style={{ columnGap: 24 }}>
      <div style={{ flexBasis: '33%' }}>
        <SocialDistancing spacing="16px">
          <LargeLabel>
            {intlMessageForId('Labs.FieldLabels.sendReportTo')}
          </LargeLabel>
          {times(3, (index: number) => {
            const str = FormName.Email1
            const base = str.substr(0, str.length - 1)
            const n = index + 1
            const name = `${base}${n}`
            return (
              <FormInput key={name} name={name} style={{ minWidth: '80%' }} />
            )
          })}
          {defaultEmails.length > 0 && (
            <div
              style={{ display: 'flex', flexDirection: 'column', rowGap: 8 }}
            >
              {map((email: string) => {
                return <p key={email}>{email}</p>
              })(defaultEmails.sort())}
            </div>
          )}
        </SocialDistancing>
      </div>
      <LabeledValue
        labelId="Labs.FieldLabels.yourInformation"
        value={shippingAddress}
        StyledLabel={LargeLabel}
        boldValueLine1
      />
      <div style={{ flexGrow: 1 }}>
        <SocialDistancing spacing="24px">
          <LabeledValue
            labelId="Labs.FieldLabels.billing"
            value={billingAddress}
            StyledLabel={LargeLabel}
            boldValueLine1
          />
          <FormInput
            name={FormName.PoNumber}
            label={intlMessageForId(
              'Labs.NewOrder.FieldLabels.PurchaseOrderNumber'
            )}
          />
        </SocialDistancing>
      </div>
    </Section>
  )
}

const SubmitSection: FunctionComponent<{
  SaveTemplate: SaveLabOrderTemplateType
  isSubmitting: boolean
  errorMessage?: string
}> = ({ SaveTemplate, isSubmitting, errorMessage }) => {
  const { values } = useFormikContext()
  const termsName = FormName.Terms

  return (
    <Section style={{ display: 'block' }}>
      <SocialDistancing spacing="24px">
        <FormInput
          name={FormName.Comments}
          label={intlMessageForId('Labs.NewOrder.FieldLabels.Comments')}
          as={Textarea}
          style={{ width: '100%', height: '175px' }}
          placeholder="Any additional information related to this order"
        />
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: 1 }}>
              <FormCheckbox
                id={termsName}
                checked={get(values, termsName, false)}
                name={termsName}
              >
                <div>
                  <FormattedMessage
                    id="Labs.NewOrder.Terms"
                    values={{
                      url: (
                        <ExternalLink href="https://www.envirologix.com/contact/lab-terms-conditions/">
                          {intlMessageForId('Labs.NewOrder.Terms.UrlText')}
                        </ExternalLink>
                      ),
                    }}
                  />
                </div>
              </FormCheckbox>
            </div>
            <SocialDistancing
              direction={LayoutDirection.Horizontal}
              spacing="16px"
            >
              <SaveTemplate formValue={values as FormValues} />
              <PrimaryButton
                disabled={isSubmitting}
                type="submit"
                style={{
                  position: 'relative',
                  flexBasis: '100%',
                }}
              >
                <div
                  style={{ visibility: isSubmitting ? 'hidden' : 'visible' }}
                >
                  {intlMessageForId('Labs.NewOrder.Buttons.Submit')}
                </div>
                {isSubmitting && <ButtonLoadingIndicator />}
              </PrimaryButton>
            </SocialDistancing>
          </div>
          {errorMessage && (
            <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
          )}
        </div>
      </SocialDistancing>
    </Section>
  )
}

const SAMPLE_COMMENTS_MAX_LENGTH = 280

const validationSchema = Yup.object().shape({
  [FormName.CustomerLocation]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  [FormName.EnvirologixLocation]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  [FormName.Email1]: Yup.string().email(VALID_EMAIL_ERROR_MESSAGE),
  [FormName.Email2]: Yup.string().email(VALID_EMAIL_ERROR_MESSAGE),
  [FormName.Email3]: Yup.string().email(VALID_EMAIL_ERROR_MESSAGE),
  [FormName.Terms]: Yup.bool().oneOf([true], REQUIRED_ERROR_MESSAGE),
  [FormName.Samples]: Yup.array()
    .of(
      Yup.object().shape({
        [FormName.SampleId]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
        [FormName.Commodity]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
        [FormName.SampleType]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
        [FormName.OvernightRush]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
        // transforms the object saved in Formik state to an array so its length can be validated
        // because there's no Yup object validation for min number of object keys
        // and we need to ensure at least one test is selected.
        [FormName.Tests]: Yup.array()
          .transform((_value, originalValue: AnyObject) => {
            return flow([
              _values,
              filter(property(ToggledControlName.Checked)),
            ])(originalValue)
          })
          .min(1, REQUIRED_ERROR_MESSAGE),
        [FormName.SampleComments]: Yup.string().max(
          SAMPLE_COMMENTS_MAX_LENGTH,
          getExceededMaxLengthMessage(SAMPLE_COMMENTS_MAX_LENGTH)
        ),
      })
    )
    .min(1, REQUIRED_ERROR_MESSAGE),
})

const LabOrderForm: FunctionComponent<{
  labAssays: Array<Assay>
  labCustomers: Array<LabCustomer>
  defaultSettings: {
    emailSettings: Array<string>
    sampleTypeSettings: Array<string>
  }
  initialValues: FormValues
  mutation: DocumentNode
  testId: string
  id?: number
}> = ({
  labAssays,
  labCustomers,
  defaultSettings,
  initialValues,
  mutation,
  testId,
  id,
}) => {
  const navigate = useNavigate()
  const {
    hasLabOrderTemplates,
    ChooseLabOrderTemplate,
    SaveLabOrderTemplate,
  } = useLabOrderTemplates()

  const [save, { data, error }] = useMutation(mutation, {
    onCompleted: (response) => {
      const code: ApiErrorCode = response?.updateLabOrder?.messages[0]?.code

      if (code) {
        handleError(
          navigate,
          `${Path.Labs_Orders}/${id}`,
          getMessageForApiError(code),
          false
        )
      }
    },
  })

  const formType: FormType = id ? 'edit' : 'new'

  const orderId =
    get(data, 'submitLabOrder.id') || get(data, 'updateLabOrder.result.id')

  if (orderId) {
    return (
      <div data-testid="Navigate">
        <Navigate to={`${Path.Labs_Orders}/${orderId}`} />
      </div>
    )
  }

  const handleSubmit = (values: FormValues, actions: AnyObject) => {
    const customerAccountId = values.CUSTOMER_LOCATION
    const { ENVIROLOGIX_LOCATION } = values
    const poNum = values.PO_NUMBER
    const reportContact = `${values.EMAIL1};${values.EMAIL2};${values.EMAIL3}`

    const samples = values.SAMPLES.map(
      ({
        ID: sampleId,
        SAMPLE_ID: description,
        COMMODITY: sampleCommodity,
        SAMPLE_TYPE: sampleType,
        OVERNIGHT_RUSH: rush,
        TESTS: tests,
        NO_GMO_SUM: noGmoSum,
        SAMPLE_COMMENTS: sampleComments,
      }) => {
        const testPayload = flow([
          _values,
          filter(property(ToggledControlName.Checked)),
          map((test: ToggledControlState) => ({
            assayId: parseInt(test[ToggledControlName.Value]),
          })),
        ])(tests)

        return {
          // don't send temp id of new samples
          id: isTemporaryId(sampleId) ? undefined : sampleId,
          description,
          sampleCommodity,
          sampleType,
          rush: rush === 'true',
          noGmoSum,
          tests: testPayload,
          sampleComments,
        }
      }
    )
    const specialInstructions = values.COMMENTS

    const payload = {
      ...(id ? { id } : {}),
      customerAccountId,
      location: ENVIROLOGIX_LOCATION,
      poNum,
      reportContact,
      samples,
      specialInstructions,
    }

    save({
      variables: payload,
    })
    actions.setSubmitting(true)
  }

  const cancelButton = () => (
    <SecondaryButton to={`${Path.Labs_Orders}/${id}`} as={Link}>
      Cancel
    </SecondaryButton>
  )

  return (
    <Page
      testId={testId}
      title={
        formType === 'edit'
          ? `${intlMessageForId('Labs.OrderDetails.Header')}: ${id}`
          : intlMessageForId('Labs.NewOrder.Header')
      }
      headerElement={formType === 'edit' ? cancelButton() : undefined}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={(values: FormValues, actions: AnyObject) => {
          handleSubmit(values, actions)
        }}
      >
        {(props) => {
          const customerLocation = find(labCustomers, (labCustomer) => {
            return (
              labCustomer.qbenchCustomerId.toString() ===
              props.values.CUSTOMER_LOCATION
            )
          })
          const shippingAddress =
            customerLocation &&
            `${customerLocation.address}\n${customerLocation.phone}`
          const billingAddress = customerLocation && customerLocation.billTo

          const { isSubmitting, values } = props

          const sampleValues = values.SAMPLES

          return (
            <Form>
              <AboutOrderingSection />
              {hasLabOrderTemplates && (
                <>
                  {divider}
                  <Section>
                    <ChooseLabOrderTemplate />
                  </Section>
                </>
              )}
              {divider}
              <CustomerLocationSection labCustomers={labCustomers} />
              {divider}
              <SamplesSection
                name={FormName.Samples}
                commodities={getCommoditiesFromAssays(labAssays)}
                values={sampleValues}
                assays={labAssays}
                defaultSampleTypes={get(
                  defaultSettings,
                  'sampleTypeSettings',
                  []
                )}
              />
              {divider}
              <EnvirologixLocationSection />
              {divider}
              <ContactInfoSection
                defaultEmails={get(defaultSettings, 'emailSettings', [])}
                shippingAddress={shippingAddress}
                billingAddress={billingAddress}
              />
              {divider}
              <SubmitSection
                SaveTemplate={SaveLabOrderTemplate}
                isSubmitting={isSubmitting}
                errorMessage={error?.message}
              />
            </Form>
          )
        }}
      </Formik>
    </Page>
  )
}

export default LabOrderForm
