import React, { FunctionComponent, ReactElement } from 'react'
import { DocumentNode, gql, useQuery } from '@apollo/client'
import { get } from 'utils'
import FacetsProvider, {
  appendAllOption,
  Facets,
  FacetValue,
} from 'components/charts/FacetsProvider'
import ChartLayoutManager from 'components/charts/ChartLayoutManager'
import useCustomDateFilter from 'components/charts/hooks/useCustomDateFilter'
import useFilterFactory from 'components/charts/hooks/useFilterFactory'
import { getQueryValue, getTakeover } from 'components/charts/utils'
import Scatter from 'components/charts/Scatter'
import { useThreshold } from 'components/charts/Threshold'
import { LabsOverview } from 'components/charts/Overview'
import { Concern, DateRange } from 'types/enums'
import { parseProps } from './utils'
import useLabsCustomerLocationFacet from '../hooks/useLabsCustomerLocationFacet'
import { AnalysisType, AnalysisTypeQuant } from '../utils'

type FacetsResponseData = {
  analysisType: FacetValue[]
  analyte: FacetValue[]
  commodity: FacetValue[]
  gmo: {
    analyte: FacetValue[]
  }
  mycotoxin: {
    analyte: FacetValue[]
  }
  allergen: {
    analyte: FacetValue[]
  }
}

export const LAB_TRANSLATED_FACETS_QUERY = gql`
  query {
    labTranslatedFacets {
      analyte {
        key
        label
      }
      gmo {
        analyte {
          key
          label
        }
      }
      mycotoxin {
        analyte {
          key
          label
        }
      }
      allergen {
        analyte {
          key
          label
        }
      }
      commodity {
        key
        label
      }
    }
  }
`

export const LAB_TESTS_QUERY: DocumentNode = gql`
  query(
    $analysisType: String
    $analyte: String
    $commodity: [String]
    $qbenchCustomerId: [ID]
    $sampleDateCreatedMin: Date
    $sampleDateCreatedMax: Date
  ) {
    labTests(
      analysisType: $analysisType
      analyte: $analyte
      commodity: $commodity
      qbenchCustomerId: $qbenchCustomerId
      sampleDateCreatedMin: $sampleDateCreatedMin
      sampleDateCreatedMax: $sampleDateCreatedMax
    ) {
      id
      dateCreated
      results
      numericResult
      sample {
        id
        description
        order {
          id
        }
      }
      units
    }
  }
`

const chartIds = {
  GMO: 'Charts.LabsDashboard',
  Mycotoxin: 'Charts.LabsMycotoxin',
}

const Chart: FunctionComponent<{
  facets: Facets
  analysisType: AnalysisTypeQuant
  concern: Concern
}> = ({ facets, analysisType, concern }) => {
  const {
    Component: CustomDateRange,
    startDate,
    endDate,
    startTime,
    endTime,
    key,
  } = useCustomDateFilter(chartIds[concern], DateRange.Year)

  const { Filters, selectedFacets } = useFilterFactory(
    chartIds[concern],
    facets
  )
  const additionalFilters = [<CustomDateRange key={key} />]

  const { loading, error, data } = useQuery(LAB_TESTS_QUERY, {
    variables: {
      analysisType,
      analyte: get(selectedFacets, 'analyte'),
      commodity: get(selectedFacets, 'commodity'),
      qbenchCustomerId: getQueryValue('location', selectedFacets.location),
      sampleDateCreatedMin: startDate,
      sampleDateCreatedMax: endDate,
    },
    fetchPolicy: 'no-cache',
  })

  const {
    hasResults,
    formattedData,
    units,
    formattedNumberOfSamples,
    formattedAverage,
  } = parseProps(data)

  const { Component: Threshold, value: threshold } = useThreshold(units)

  const chart: () => ReactElement = () => (
    <Scatter
      data={formattedData}
      yAxisUnits={units}
      threshold={threshold}
      startTime={startTime}
      endTime={endTime}
    />
  )

  const filters = () => <Filters additionalFilters={additionalFilters} />

  const overview = () => (
    <LabsOverview
      Threshold={Threshold}
      selectedAnalyte={get(selectedFacets, 'analyte')}
      average={formattedAverage}
      numberOfSamples={formattedNumberOfSamples}
    />
  )

  const takeover = () => getTakeover(true, loading, error, hasResults)

  return (
    <ChartLayoutManager
      chart={chart()}
      filters={filters()}
      overview={overview()}
      takeover={takeover()}
    />
  )
}

const TestResultsChart: FunctionComponent<{
  analysisType: AnalysisTypeQuant
  concern: Concern
}> = ({ analysisType, concern }) => {
  const locationFacet = useLabsCustomerLocationFacet()

  const getAnalyte = (
    gmo: { analyte: FacetValue[] },
    mycotoxin: { analyte: FacetValue[] }
  ) => {
    switch (analysisType) {
      case AnalysisType.GMO_PCR_QUANT:
        return gmo.analyte

      case AnalysisType.MYCOTOXIN_QUANT:
        return mycotoxin.analyte

      default:
        return undefined
    }
  }

  return (
    <FacetsProvider
      query={LAB_TRANSLATED_FACETS_QUERY}
      facetsKey="labTranslatedFacets"
      render={(facets) => {
        const { commodity, gmo, mycotoxin } = facets as FacetsResponseData
        const analyte = getAnalyte(gmo, mycotoxin)

        return (
          <Chart
            facets={appendAllOption(
              { commodity, analyte, ...locationFacet },
              {
                commodity: {
                  optional: false,
                },
                analyte: {
                  optional: false,
                },
                location: {
                  optional: true,
                },
              }
            )}
            analysisType={analysisType}
            concern={concern}
          />
        )
      }}
    />
  )
}

export default TestResultsChart
