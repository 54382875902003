import React from 'react'
import { intlMessageForId } from 'localization'
import { LayoutDirection, Path } from 'types/enums'
import Page from 'components/Page'
import SocialDistancing from 'components/SocialDistancing'
import CallToActionCard from 'components/CallToActionCard'
import { Error, Loading } from 'components/Placeholders'
import useCurrentUser from 'hooks/useCurrentUser'
import NotificationsSection from './NotificationsSection'

const Homepage: React.FunctionComponent = () => {
  const { loading, error, firstName, lastName } = useCurrentUser()

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Error />
  }

  const pageTitle = `${intlMessageForId(
    'Homepage.Greeting'
  )}, ${firstName} ${lastName}!`

  return (
    <Page title={pageTitle} testId="Homepage">
      <SocialDistancing
        spacing="32px"
        direction={LayoutDirection.Horizontal}
        style={{ display: 'flex' }}
      >
        <div style={{ width: '70%' }}>
          <SocialDistancing
            spacing="32px"
            direction={LayoutDirection.Horizontal}
          >
            <CallToActionCard
              header="Homepage.CallToActions.QuickScan.Title"
              body="Homepage.CallToActions.QuickScan.Description"
              callToAction={{
                to: Path.QuickScan,
                label: intlMessageForId(
                  'Homepage.CallToActions.QuickScan.Button'
                ),
              }}
            />
            <CallToActionCard
              header="Homepage.CallToActions.Labs.Title"
              body="Homepage.CallToActions.Labs.Description"
              callToAction={{
                to: Path.Labs,
                label: intlMessageForId('Homepage.CallToActions.Labs.Button'),
              }}
            />
          </SocialDistancing>
          <NotificationsSection />
        </div>
      </SocialDistancing>
    </Page>
  )
}

export default Homepage
