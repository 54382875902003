import React, { FunctionComponent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { gql, useMutation, useQuery } from '@apollo/client'
import { intlMessageForId } from 'localization'
import { ID } from 'types'
import { FormName } from 'types/forms'
import { get, isSet, openUrl } from 'utils'
import dayjs from 'dayjs'
import Section, { SectionHeader } from 'components/Section'
import SocialDistancing from 'components/SocialDistancing'
import { Loading, Error } from 'components/Placeholders'
import Group from 'components/FormElements/Group'
import {
  FormRadioButtons,
  ToggledInput,
  ToggledRange,
} from 'components/FormElements'
import { FormSelectOption } from 'components/MultiSelect'
import { EmptyToggledControl } from 'components/FormElements/ToggledControl'
import {
  DownloadReport,
  EndDate,
  handleCompleted,
  handleError,
  RunReportHeader,
  StartDate,
} from 'components/FormElements/common'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { Path } from 'types/enums'
import SaveAsTemplate from '../components/SaveAsTemplate'
import ChooseTemplate from '../components/ChooseTemplate'
import ReportsMultiSelect, {
  getSelectOptionsMap,
} from '../components/ReportsMultiSelect'
import { ExportFormat, TemplatesQuery } from '../types'
import { fieldLabel, groupTitle, INITIAL_VALUES_MAP } from '../utils'
import { PayloadType, toApiFormat } from './utils'

export const QUICKSCAN_FACETS_QUERY = gql`
  query GetQuickscanFacets {
    quickscanFacets {
      action
      analyte
      analyteError
      comment1
      comment2
      commodity
      location
      lotNumber
      operator
      resultText
      resultUnit
      supplier
      weightUnit
    }
  }
`

const LIST_TEMPLATES_QUERY = gql`
  query {
    quickscanReportTemplates {
      id
      title
      columns
      filters {
        action
        analyte
        analyteError
        cassetteName
        comment1
        comment2
        comment3
        commodity
        gmoSumMin
        gmoSumMax
        location
        bin
        lotNumber
        operator
        resultText
        resultNumericMin
        resultNumericMax
        resultUnit
        sampleId
        supplier
        weightUnit
        weightMin
        weightMax
      }
      mergeCombResults
    }
  }
`

export const CREATE_QUICKSCAN_REPORT_MUTATION = gql`
  mutation(
    $columns: [QuickscanReportColumn]!
    $filters: QuickscanReportFiltersInput!
    $mergeCombResults: Boolean
    $pdfReport: Boolean
  ) {
    createQuickscanReport(
      columns: $columns
      filters: $filters
      mergeCombResults: $mergeCombResults
      pdfReport: $pdfReport
    )
  }
`

const initialValues = {
  [FormName.Weight]: INITIAL_VALUES_MAP.range,
  [FormName.WeightUnit]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.Location]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.Supplier]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.Bin]: INITIAL_VALUES_MAP.input,
  [FormName.SampleId]: INITIAL_VALUES_MAP.input,
  [FormName.Operator]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.Commodity]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.LotNumber]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.CassetteName]: INITIAL_VALUES_MAP.input,
  [FormName.Action]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.AnalyteError]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.ResultNumeric]: INITIAL_VALUES_MAP.range,
  [FormName.ResultText]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.ResultUnit]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.Analyte]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.MergeCombResults]: INITIAL_VALUES_MAP.emptyControl,
  [FormName.GMOSum]: INITIAL_VALUES_MAP.range,
  [FormName.Comment1]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.Comment2]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.ExportFormat]: ExportFormat.csv,
}

const getCustomSelectOptionsMap: (
  facets: Record<string, Array<string>>
) => Record<string, FormSelectOption[]> = (facets) => {
  // manually renaming comment fields for the API because they don't follow the standard UPPER_CASE format
  const {
    COMMENT_1: COMMENT1,
    COMMENT_2: COMMENT2,
    ...rest
  } = getSelectOptionsMap(facets)

  return {
    ...rest,
    COMMENT1,
    COMMENT2,
  }
}

const TestResults: FunctionComponent<{
  publishPayload?: (payload: PayloadType) => void
}> = ({ publishPayload }) => {
  const navigate = useNavigate()

  const featureFlags = useFeatureFlags()
  const auditReport = featureFlags?.auditReport

  const { data, loading, error } = useQuery(QUICKSCAN_FACETS_QUERY)

  const templatesQuery = useQuery(LIST_TEMPLATES_QUERY) as TemplatesQuery

  const [createQuickscanReport, { error: mutationError }] = useMutation(
    CREATE_QUICKSCAN_REPORT_MUTATION,
    {
      onCompleted: (mutationData, clientOptions) => {
        const reportUrl = get(mutationData, 'createQuickscanReport')

        const {
          variables: { pdfReport },
        } = clientOptions as { variables: PayloadType }

        if (reportUrl) {
          openUrl(reportUrl)
        }

        if (pdfReport) {
          handleCompleted(
            navigate,
            Path.Reports_TestResults,
            `PDF ${intlMessageForId('Reports.Messages.ReportRequested')}`
          )
        }
      },
      onError: () => {
        handleError(navigate, Path.Reports_TestResults)
      },
    }
  )
  const [selectedTemplateId, setSelectedTemplateId] = useState<ID | undefined>(
    undefined
  )

  if (loading) return <Loading />
  if (error || mutationError) return <Error />

  const quickscanFacets = get(data, 'quickscanFacets', {})
  const selectOptionsMap = getCustomSelectOptionsMap(quickscanFacets)

  const showTemplatesSection =
    templatesQuery.loading ||
    get(templatesQuery, 'data.quickscanReportTemplates', []).length > 0

  return (
    <div data-testid="QuickscanReport">
      <Formik
        initialValues={{
          ...initialValues,
          ...{
            [FormName.When]: {
              min: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
              max: dayjs().format('YYYY-MM-DD'),
            },
          },
        }}
        onSubmit={(values) => {
          const payload = toApiFormat(values, true)

          if (isSet(publishPayload)) {
            publishPayload(payload)
          }

          createQuickscanReport({
            variables: payload,
          })
        }}
      >
        {() => (
          <Form>
            <Section first>
              <div style={{ flexGrow: 1 }}>
                <SectionHeader>
                  {intlMessageForId('Reports.SectionHeaders.SelectFields')}
                </SectionHeader>
                <SocialDistancing spacing="48px">
                  {showTemplatesSection && (
                    <Group title={groupTitle('ChooseTemplate')}>
                      <ChooseTemplate
                        selectedTemplateId={selectedTemplateId}
                        setSelectedTemplateId={setSelectedTemplateId}
                        templatesQuery={templatesQuery}
                      />
                    </Group>
                  )}
                  <Group title={groupTitle('SampleInformation')}>
                    <ReportsMultiSelect
                      formName={FormName.Location}
                      selectOptions={selectOptionsMap[FormName.Location]}
                      defaultChecked
                    />
                    <ReportsMultiSelect
                      formName={FormName.Supplier}
                      selectOptions={selectOptionsMap[FormName.Supplier]}
                      defaultChecked
                    />
                    <ToggledInput
                      name={FormName.SampleId}
                      label={fieldLabel(FormName.SampleId)}
                      defaultChecked
                    />
                    <ReportsMultiSelect
                      formName={FormName.Commodity}
                      selectOptions={selectOptionsMap[FormName.Commodity]}
                      defaultChecked
                    />
                    <ToggledInput
                      name={FormName.Bin}
                      label={fieldLabel(FormName.Bin)}
                    />
                    <ReportsMultiSelect
                      formName={FormName.Operator}
                      selectOptions={selectOptionsMap[FormName.Operator]}
                    />
                  </Group>
                  <Group title={groupTitle('Results')}>
                    <ReportsMultiSelect
                      formName={FormName.Analyte}
                      selectOptions={selectOptionsMap[FormName.Analyte]}
                      defaultChecked
                    />
                    <ToggledRange
                      name={FormName.ResultNumeric}
                      label={fieldLabel(FormName.ResultNumeric)}
                      defaultChecked
                      tooltip={intlMessageForId(
                        `Reports.Tooltips.${FormName.ResultNumeric}`
                      )}
                    />
                    <ReportsMultiSelect
                      formName={FormName.ResultText}
                      selectOptions={selectOptionsMap[FormName.ResultText]}
                      tooltip={intlMessageForId(
                        `Reports.Tooltips.${FormName.ResultText}`
                      )}
                    />
                    <ReportsMultiSelect
                      formName={FormName.ResultUnit}
                      selectOptions={selectOptionsMap[FormName.ResultUnit]}
                      defaultChecked
                    />
                    <ToggledRange
                      name={FormName.GMOSum}
                      label={fieldLabel(FormName.GMOSum)}
                      defaultChecked
                    />
                    <ReportsMultiSelect
                      formName={FormName.Action}
                      selectOptions={selectOptionsMap[FormName.Action]}
                      defaultChecked
                    />
                  </Group>
                  <Group title={groupTitle('AdditionalInformation')}>
                    <ToggledRange
                      name={FormName.Weight}
                      label={fieldLabel(FormName.Weight)}
                    />
                    <ReportsMultiSelect
                      formName={FormName.WeightUnit}
                      selectOptions={selectOptionsMap[FormName.WeightUnit]}
                    />
                    <ToggledInput
                      name={FormName.CassetteName}
                      label={fieldLabel(FormName.CassetteName)}
                    />
                    <ReportsMultiSelect
                      formName={FormName.LotNumber}
                      selectOptions={selectOptionsMap[FormName.LotNumber]}
                    />
                    <ReportsMultiSelect
                      formName={FormName.AnalyteError}
                      selectOptions={selectOptionsMap[FormName.AnalyteError]}
                    />
                    <ReportsMultiSelect
                      formName={FormName.Comment1}
                      selectOptions={selectOptionsMap[FormName.Comment1]}
                    />
                    <ReportsMultiSelect
                      formName={FormName.Comment2}
                      selectOptions={selectOptionsMap[FormName.Comment2]}
                    />
                    <EmptyToggledControl
                      name={FormName.MergeCombResults}
                      label={fieldLabel(FormName.MergeCombResults)}
                      defaultChecked
                      tooltip={intlMessageForId(
                        `Reports.Tooltips.${FormName.MergeCombResults}`
                      )}
                    />
                  </Group>
                </SocialDistancing>
              </div>
              <div style={{ marginLeft: '48px', width: '30%' }}>
                <RunReportHeader />
                <SocialDistancing
                  spacing="32px"
                  style={{
                    paddingTop: '25px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <SocialDistancing spacing="8px">
                    <StartDate />
                    <EndDate />
                  </SocialDistancing>
                  {auditReport && (
                    <FormRadioButtons
                      name={FormName.ExportFormat}
                      label={intlMessageForId(
                        'Reports.Form.SectionHeaders.ExportFormat'
                      )}
                      options={[
                        {
                          label: '.csv',
                          value: ExportFormat.csv,
                        },
                        {
                          label: '.pdf',
                          value: ExportFormat.pdf,
                        },
                      ]}
                    />
                  )}

                  <SocialDistancing spacing="8px">
                    <DownloadReport
                      label={
                        auditReport
                          ? intlMessageForId('Reports.CTAs.DownloadReport')
                          : undefined
                      }
                    />
                    <SaveAsTemplate
                      setSelectedTemplateId={setSelectedTemplateId}
                      refetchTemplates={templatesQuery.refetch}
                    />
                  </SocialDistancing>
                </SocialDistancing>
              </div>
            </Section>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default TestResults
