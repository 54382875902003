import React, { FC } from 'react'
import { ApolloError, gql, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { intlMessageForId } from 'localization'
import { AnyObject, typeToTitle } from 'types'
import { Path } from 'types/enums'
import { FormName } from 'types/forms'
import { isSet, joinErrorMessages, logError } from 'utils'
import { PrimaryButton, TextButton } from 'components/Button'
import { handleCompleted, handleError } from 'components/FormElements/common'
import FormInput from 'components/FormElements/FormInput'
import {
  REQUIRED_ERROR_MESSAGE,
  VALID_EMAIL_ERROR_MESSAGE,
} from 'components/FormElements'
import { SettingType, Setting, SettingDomain } from './types'

// TODO: add form submit test coverage

export const CREATE_SETTING_MUTATION = gql`
  mutation(
    $domain: SettingDomain!
    $email: String
    $sampleType: String
    $enabled: Boolean
  ) {
    createSetting(
      input: {
        domain: $domain
        email: $email
        sampleType: $sampleType
        enabled: $enabled
      }
    ) {
      result {
        domain
        enabled
        id
        email
        sampleType
      }
      successful
      messages {
        field
        message
        template
      }
    }
  }
`
interface FormValues {
  [FormName.Domain]: SettingDomain
  [FormName.Email]: string
  [FormName.SampleType]: string
  [FormName.Enabled]: boolean
}

const handleSubmit = (
  type: SettingType,
  values: FormValues,
  actions: AnyObject,
  createSetting: (payload: AnyObject) => void,
  // obj value can be any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  publishPayload?: (payload: Record<string, any>) => void
) => {
  const payload = {
    ...(type === 'EMAIL' && { email: values[FormName.Email] }),
    ...(type === 'SAMPLE_TYPE' && { sampleType: values[FormName.SampleType] }),
    domain: values[FormName.Domain],
    enabled: values[FormName.Enabled],
  }

  if (isSet(publishPayload)) {
    publishPayload(payload)
  }

  createSetting({
    variables: payload,
  })

  actions.setSubmitting(true)
  actions.resetForm()
}

const CreateSettingForm: FC<{
  domain: SettingDomain
  type: SettingType
  placeholder: string
  addSetting: (setting: Setting) => void
  publishPayload?: (payload: Record<string, Array<string> | AnyObject>) => void
}> = ({ domain, type, placeholder, addSetting, publishPayload }) => {
  const navigate = useNavigate()

  const [createSetting] = useMutation(CREATE_SETTING_MUTATION, {
    onCompleted: ({ createSetting: { result, messages, successful } }) => {
      if (successful) {
        addSetting(result)

        handleCompleted(
          navigate,
          Path.AdminSettings_Lab,
          `${typeToTitle(type)} added!`
        )
      } else {
        handleError(
          navigate,
          Path.AdminSettings_Lab,
          joinErrorMessages(messages)
        )
      }
    },
    onError: (e: ApolloError) => {
      handleError(navigate, Path.AdminSettings_Lab)
      logError(e.message, 'not-production')
    },
  })

  const initialValues: FormValues = {
    [FormName.Domain]: domain,
    [FormName.Email]: '',
    [FormName.SampleType]: '',
    [FormName.Enabled]: true,
  }

  const validationSchema = Yup.object().shape({
    ...(type === 'EMAIL' && {
      [FormName.Email]: Yup.string()
        .email(VALID_EMAIL_ERROR_MESSAGE)
        .required(REQUIRED_ERROR_MESSAGE),
    }),
    ...(type === 'SAMPLE_TYPE' && {
      [FormName.SampleType]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
    }),
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={(values: FormValues, actions: AnyObject) => {
        handleSubmit(type, values, actions, createSetting, publishPayload)
      }}
    >
      {({ resetForm }) => {
        return (
          <Form style={{ display: 'flex', gap: '24px' }}>
            <div style={{ flexGrow: 1 }}>
              {type === 'EMAIL' && (
                <FormInput
                  name={FormName.Email}
                  placeholder={placeholder}
                  style={{ width: '100%' }}
                />
              )}

              {type === 'SAMPLE_TYPE' && (
                <FormInput
                  name={FormName.SampleType}
                  placeholder={placeholder}
                  style={{ width: '100%' }}
                />
              )}
            </div>
            <PrimaryButton type="submit">
              {intlMessageForId('Buttons.Save')}
            </PrimaryButton>
            <TextButton onClick={resetForm}>
              {intlMessageForId('Buttons.Cancel')}
            </TextButton>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CreateSettingForm
