import React, { FunctionComponent, ReactElement } from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { intlMessageForId } from 'localization'
import { ID, StyleObject } from 'types'
import { LayoutDirection } from 'types/enums'
import Color from 'types/color'
import { EMPTY_STATE_STRING } from 'constants/index'
import { SectionDivider } from 'components/Divider'
import { Header, PRINT_ROW_GAP } from 'components/Page'
import SocialDistancing from 'components/SocialDistancing'
import { PrintButton } from 'components/Button'
import { DetailedCell } from 'components/Table'

export const LabsDivider = <SectionDivider />

const SmallLabel = styled.div`
  color: ${Color.Black60};
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`

const SmallValue = styled.div`
  color: ${Color.Black};
  font-size: 16px;
  line-height: 22px;
`

export const LargeLabel = styled.div`
  color: ${Color.Black};
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
`

export const Details = styled.div`
  color: ${Color.Black60};
  font-size: 14px;
  line-height: 21px;
`

// splits on new line, semi-colon, and comma
const splitRegex = /(?:\n|;|, )+/

export const LabeledValue: FunctionComponent<{
  labelId: string
  value?: string | null
  boldValueLine1?: boolean
  StyledLabel?: FunctionComponent
  StyledValue?: FunctionComponent
  style?: StyleObject
  split?: boolean
}> = ({
  labelId,
  value,
  boldValueLine1 = false,
  StyledLabel = SmallLabel,
  StyledValue = SmallValue,
  style = {},
  split = true,
}) => {
  const valueLines = split
    ? value?.split(splitRegex) || [EMPTY_STATE_STRING]
    : [value]
  const formattedValue = valueLines.map((str, index) => (
    <StyledValue
      key={str}
      style={
        boldValueLine1 && valueLines.length > 1 && index === 0
          ? { fontWeight: 'bold', paddingBottom: '4px' }
          : {}
      }
    >
      {str}
    </StyledValue>
  ))

  return (
    <div style={{ ...{ flexGrow: 1 }, ...style }}>
      <SocialDistancing spacing="8px">
        <StyledLabel>{intlMessageForId(labelId)}</StyledLabel>
        <SocialDistancing spacing="4px">{formattedValue}</SocialDistancing>
      </SocialDistancing>
    </div>
  )
}

const OrderStatus: FunctionComponent<{
  status: string
  details?: string
}> = ({ status, details }) => (
  <SocialDistancing spacing="4px">
    <SmallLabel>{intlMessageForId('Labs.FieldLabels.status')}</SmallLabel>
    <div style={{ fontWeight: 'bold' }}>{status}</div>
    {details && <Details>{details}</Details>}
  </SocialDistancing>
)

const Title = styled.div`
  flex-grow: 1;
`

const HeaderSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 48px;

  @media print {
    align-items: flex-start;
    margin-bottom: ${PRINT_ROW_GAP};
  }
`

export const HeaderSection: FunctionComponent<{
  pageTitle: string | ReactElement
  pageSubtitle?: string
  status?: string
  statusDetails?: string
  printButtonLabel?: string
  additionalElement?: ReactElement
}> = ({
  pageTitle,
  pageSubtitle,
  status,
  statusDetails,
  printButtonLabel,
  additionalElement,
}) => (
  <HeaderSectionWrapper>
    <Title>
      <Header>{pageTitle}</Header>
      {pageSubtitle && <Details>{pageSubtitle}</Details>}
    </Title>
    <SocialDistancing
      spacing="16px"
      direction={LayoutDirection.Horizontal}
      style={{ alignItems: 'center' }}
    >
      {status && <OrderStatus status={status} details={statusDetails} />}
      {printButtonLabel && <PrintButton>{printButtonLabel}</PrintButton>}
      {additionalElement && additionalElement}
    </SocialDistancing>
  </HeaderSectionWrapper>
)

const ListText = styled.li<{ textIndent?: string }>`
  color: ${Color.Black};
  font-size: 14px;
  line-height: 21px;
  text-indent: 1.5em;
  text-indent: ${(props) => props.textIndent || 0};
`

export const AboutOrdering: FunctionComponent = () => (
  <SocialDistancing spacing="8px">
    <ListText>
      <FormattedMessage
        id="Labs.AboutOrdering.Line1"
        values={{
          email: (
            <a href="mailto:labservices@envirologix.com">
              labservices@envirologix.com
            </a>
          ),
        }}
      />
    </ListText>
    <ListText>{intlMessageForId('Labs.AboutOrdering.Line2')}</ListText>
    <ListText textIndent="1.5em">
      {intlMessageForId('Labs.AboutOrdering.Line3')}
    </ListText>
    <ListText textIndent="1.5em">
      {intlMessageForId('Labs.AboutOrdering.Line4')}
    </ListText>
    <ListText textIndent="1.5em">
      {intlMessageForId('Labs.AboutOrdering.Line5')}
    </ListText>
    <ListText>{intlMessageForId('Labs.AboutOrdering.Line6')}</ListText>
    <ListText>{intlMessageForId('Labs.AboutOrdering.Line7')}</ListText>
  </SocialDistancing>
)

export const SampleIdTableCell: FunctionComponent<{
  customerSampleId?: ID
  qbenchSampleId?: ID
}> = ({ customerSampleId = EMPTY_STATE_STRING, qbenchSampleId }) => (
  <DetailedCell
    text={customerSampleId.toString()}
    detailText={qbenchSampleId?.toString()}
  />
)
