import {
  filter,
  flow,
  groupBy,
  keys,
  map,
  merge,
  property,
  reduce,
} from 'lodash/fp'
import { get, quickUuid } from 'utils'
import { AnyObject } from 'types'
import { Concern } from 'types/enums'
import {
  ToggledControlName,
  ToggledControlState,
} from 'components/FormElements'
import { FormName } from 'types/forms'
import {
  ApiErrorCode,
  Assay,
  GroupedAssayType,
  SampleFormValue,
  SampleTestState,
} from './types'
import { LabAssay } from '../LabOrderDetails'

export const groupAssays: (
  key: 'analyte' | 'commodity' | 'concern' | 'portalOrderForm'
) => (assays: Assay[]) => GroupedAssayType = (key) => {
  return (assays: Assay[]) => groupBy<Assay>(key)(assays)
}

export const getCommoditiesFromAssays: (assays: Assay[]) => string[] = (
  assays
) =>
  flow([
    filter((assay: LabAssay) => {
      return Boolean(assay.portalOrderForm)
    }),
    groupAssays('commodity'),
    keys,
  ])(assays)

export const getDefaultSettings: (
  data: AnyObject,
  type: 'emailSettings' | 'sampleTypeSettings'
) => Array<string> = (data, type) => {
  return [
    ...(type === 'emailSettings'
      ? map(property('email'))(filter(['enabled', true])(get(data, type, [])))
      : []),
    ...(type === 'sampleTypeSettings'
      ? map(property('sampleType'))(
          filter(['enabled', true])(get(data, type, []))
        )
      : []),
  ]
}

export const getMessageForApiError: (code: ApiErrorCode) => string = (code) => {
  const tail =
    'email labservices@envirologix.com or call 866-408-4597 for support.'
  const defaultErrorMessage = `Please ${tail}`

  const errorCodeMap = {
    '401': defaultErrorMessage,
    '403': defaultErrorMessage,
    '404': `An error occurred while updating this order. Please try again or ${tail}`,
    '422': `This order has been received and is no longer available for editing. ${defaultErrorMessage}`,
  }

  return errorCodeMap[code]
}

// TODO: add unit test coverage
export const visibleQuantitativeAssays: (
  commodity: string,
  labAssays: Array<LabAssay>
) => Array<LabAssay> = (commodity, labAssays) => {
  return flow([
    groupBy('commodity'),
    (byCommodity) => {
      return get(byCommodity, commodity)
    },
    filter((assay: LabAssay) => {
      // accept all mycotoxin assays but only GMO assays with portalOrderForm enabled
      return assay.concern === ('MYCOTOXIN' as Concern)
        ? true
        : Boolean(assay.portalOrderForm)
    }),
    groupBy('category'),
    (byCategory) => {
      const onlyQuantitative = [
        ...get(byCategory, 'GMO PCR Quant', []),
        ...get(byCategory, 'Mycotoxin - Analytical Quant', []),
      ]
      return onlyQuantitative
    },
  ])(labAssays)
}

const getInitialTestState: (
  commodity: string,
  labAssays: Array<LabAssay>
) => SampleTestState = (commodity, labAssays) => {
  return flow([
    map((assay: LabAssay) => {
      // set form defaults
      return {
        [assay.analyte]: {
          [ToggledControlName.Checked]:
            assay.concern === 'GMO' && assay.portalOrderForm === 'Standard',
          [ToggledControlName.Value]: assay.id,
        },
      }
    }),
    reduce(merge, {}),
  ])(visibleQuantitativeAssays(commodity, labAssays))
}

// 'assay' and 'test' are unfortunately used somewhat interchangeably but to be precise an assay belongs to a test
export const getDefaultTestMap: (
  labAssays: Array<LabAssay>
) => { [commodity: string]: SampleTestState } = (labAssays) =>
  flow([
    map((commodity: string) => {
      return {
        [commodity as string]: getInitialTestState(commodity, labAssays),
      }
    }),
    reduce(merge, {}),
  ])(getCommoditiesFromAssays(labAssays))

export const getSelectedTestStateFromAssays: (
  assays?: Array<LabAssay>
) => { [analyte: string]: ToggledControlState } = (assays = []) =>
  flow([
    map((assay: LabAssay) => {
      return {
        [assay.analyte]: {
          [ToggledControlName.Checked]: true,
          [ToggledControlName.Value]: assay.id,
        },
      }
    }),
    reduce(merge, {}),
  ])(assays)

export const getNewSample: () => SampleFormValue = () => ({
  [FormName.Id]: quickUuid(), // used only for component render key
  [FormName.SampleId]: '',
  [FormName.Commodity]: '',
  [FormName.SampleType]: '',
  [FormName.OvernightRush]: 'false',
  [FormName.NoGmoSum]: false,
  [FormName.Tests]: {},
  [FormName.SampleComments]: '',
})
