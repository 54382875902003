import { upperFirst } from 'lodash'
import { intlMessageForId } from 'localization'
import { BannerType } from 'components/Banner'

export const getDisplayName: (firstName: string, lastName: string) => string = (
  firstName,
  lastName
) => {
  return `${upperFirst(lastName)}, ${upperFirst(firstName)}`
}

export const getStatus: (hasPassword: boolean) => string = (hasPassword) => {
  return intlMessageForId(
    `Admin.StatusOptions.${hasPassword ? 'Active' : 'Pending'}`
  )
}

export const getRole: (orgAdmin: boolean) => string = (orgAdmin) => {
  return intlMessageForId(`Admin.RoleOptions.${orgAdmin ? 'Admin' : 'User'}`)
}

export enum UserStatus {
  Created = 'Created',
  Edited = 'Edited',
  Deleted = 'Deleted',
  Invited = 'Invited',
  InviteError = 'InviteError',
}

const messages = {
  [UserStatus.Created]: intlMessageForId('Admin.AddUser.Confirmations.Added'),
  [UserStatus.Edited]: intlMessageForId('Admin.EditUser.Confirmations.Edited'),
  [UserStatus.Deleted]: intlMessageForId(
    'Admin.EditUser.Confirmations.Deleted'
  ),
  [UserStatus.Invited]: intlMessageForId(
    'Admin.EditUser.Confirmations.Invited'
  ),
  [UserStatus.InviteError]: intlMessageForId(
    'Admin.EditUser.Confirmations.InviteError'
  ),
}

export const getUserBannerProps: (userStatus: UserStatus) => BannerType = (
  userStatus
) => {
  return {
    status: userStatus !== UserStatus.InviteError ? 'success' : 'error',
    message: messages[userStatus],
  }
}
