import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react'
import { compact, Dictionary, flow, includes, last, tail } from 'lodash'
import { map, merge, reduce } from 'lodash/fp'
import styled from '@emotion/styled'
import { intlMessageForId } from 'localization'
import { ensureArray, get, isSet } from 'utils'
import { EventAction, EventCategory, trackEvent } from 'utils/analytics'
import { AnyObject } from 'types'
import { SecondaryButton } from 'components/Button'
import Select from 'components/Select'
import {
  allOptionId,
  FacetName,
  Facets,
  FacetValue,
} from 'components/charts/FacetsProvider'
import MultiSelect, { getMultiSelectInput } from 'components/MultiSelect'
import { useSavedObject } from './useLocalStorage'

type FiltersComponentType = FunctionComponent<{
  additionalFilters?: ReactElement[]
}>

const MULTI_SELECT_FACET_NAMES: Array<FacetName> = [
  'commodity',
  'location',
  'supplier',
]

export const handleAllOption: (
  values: string[],
  allOption: string
) => string[] = (values, allOption) => {
  // remove all option if another option is selected
  if (values.length > 1 && values[0] === allOption) {
    return tail(values)
  }

  // return all option if it is selected
  if (last(values) === allOption) {
    return [allOption]
  }

  // no adjustment necessary for everything else
  return values
}

const isMultiSelectFacet: (facetName: string) => boolean = (facetName) =>
  includes(MULTI_SELECT_FACET_NAMES, facetName)

export type FormSelectOption = {
  label: string
  value: string
}

export const filterMargin = '16px 0 0 16px'

const StyledFilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;

  select,
  .CustomDateFilter {
    flex-grow: 1;
    margin: ${filterMargin};
  }
`

// TODO: update when implementing intl facet support
const DEFAULT_COMMODITY = 'Corn'

const getResetForFacet: (facet: FacetTuple) => Dictionary<string> = (facet) => {
  const facetName = facet[0] as string
  const facetValues = facet[1] as FacetValue[]
  const labels = map('label', facetValues)

  return {
    [facetName]:
      facetName === 'commodity' &&
      !includes(
        labels,
        intlMessageForId('Charts.Filters.AllOption.Commodity')
      ) &&
      includes(labels, DEFAULT_COMMODITY)
        ? DEFAULT_COMMODITY
        : get(facetValues, '[0].key'),
  }
}

const getDefaultForFacet: (
  facet: FacetTuple,
  savedFacets: AnyObject
) => Dictionary<string> = (facet, savedFacets) => {
  const facetName = facet[0] as string
  const savedFacet = savedFacets[facetName]

  if (isSet(savedFacet)) {
    return {
      [facetName]: savedFacet,
    }
  }

  return getResetForFacet(facet)
}

type GetFilterElements = (
  facets: Facets,
  selectedFacets: Dictionary<string | string[]>,
  handleChange:
    | ((e: ChangeEvent<HTMLSelectElement>) => void)
    | ((
        e: ChangeEvent<{ name?: string | undefined; value: unknown }>,
        setOpen: (open: boolean) => void
      ) => void)
) => ReactElement[]

type FacetTuple = [string, FacetValue[]]

const MultiSelectInput = getMultiSelectInput({
  fontSize: '16px',
  padding: '16px 38px 16px 20px',
})

const getFilterElements: GetFilterElements = (
  facets,
  selectedFacets,
  handleChange
) => {
  return flow([
    map((facet: FacetTuple) => {
      const facetName = facet[0] as string
      const facetValues = facet[1] as FacetValue[]

      if (!facetValues || facetValues.length === 0) {
        return null
      }

      return isMultiSelectFacet(facetName) ? (
        <MultiSelect
          name={facetName}
          key={facetName}
          value={(() => {
            const value = selectedFacets[facetName]
            return ensureArray(value)
          })()}
          onChange={
            handleChange as (
              e: ChangeEvent<{ name?: string | undefined; value: unknown }>,
              setOpen: (open: boolean) => void
            ) => void
          }
          selectOptions={map((facetValue: FacetValue) => {
            const { key, label } = facetValue
            return { label, value: key }
          })(facetValues)}
          Input={<MultiSelectInput />}
          containerStyles={{ margin: filterMargin }}
        />
      ) : (
        <Select
          data-testid={facetName}
          name={facetName}
          key={facetName}
          value={selectedFacets[facetName]}
          onChange={handleChange}
        >
          {map((facetValue: FacetValue) => {
            const { key, label } = facetValue
            return (
              <option key={key} value={key}>
                {label}
              </option>
            )
          })(facetValues)}
        </Select>
      )
    }),
    compact,
  ])(Object.entries(facets) as Array<FacetTuple>)
}

export const useFilterFactory: (
  chartId: string,
  facets: Facets
) => {
  Filters: FiltersComponentType
  selectedFacets: Dictionary<string | string[]>
} = (chartId, facets) => {
  const [savedFacets, setSavedFacets] = useSavedObject(`${chartId}-facets`)

  const defaultSelectedFacets: Dictionary<string> = flow([
    map((facet: FacetTuple) => getDefaultForFacet(facet, savedFacets)),
    reduce(merge, {}),
  ])(Object.entries(facets))

  const resetFacets: Dictionary<string> = flow([
    map((facet: FacetTuple) => getResetForFacet(facet)),
    reduce(merge, {}),
  ])(Object.entries(facets))

  const [selectedFacets, setSelectedFacets] = useState<
    Dictionary<string | string[]>
  >(defaultSelectedFacets)

  useEffect(() => {
    setSavedFacets(selectedFacets)
  }, [selectedFacets, setSavedFacets])

  const handleChange = (
    e: ChangeEvent<HTMLSelectElement>,
    setOpen?: (open: boolean) => void
  ) => {
    const { name, value } = e.target
    const facetName = name as FacetName

    trackEvent(
      EventCategory.Filter,
      EventAction.Select,
      `${facetName}__${value}`
    )

    if (!isMultiSelectFacet(facetName)) {
      setSelectedFacets({ ...selectedFacets, ...{ [facetName]: value } })
      return
    }

    const allOption = intlMessageForId(allOptionId(name as FacetName))
    const values = handleAllOption(ensureArray(value), allOption)

    setSelectedFacets({ ...selectedFacets, ...{ [facetName]: values } })

    if (setOpen && last(values) === allOption) {
      setOpen(false)
    }
  }

  const FilterWrapper: FunctionComponent<{
    children: ReactElement[]
    showResetButton?: boolean
  }> = ({ children, showResetButton = true }) => {
    return (
      <StyledFilterWrapper>
        {children}
        {showResetButton && (
          <SecondaryButton
            style={{ margin: filterMargin, fontWeight: 'normal', flexGrow: 1 }}
            onClick={() => {
              setSelectedFacets(resetFacets)
            }}
          >
            {intlMessageForId('Dashboard.Button.ResetFilters')}
          </SecondaryButton>
        )}
      </StyledFilterWrapper>
    )
  }

  const Filters: FiltersComponentType = ({ additionalFilters = [] }) => {
    const filterElements = [
      ...getFilterElements(facets, selectedFacets, handleChange),
      ...additionalFilters,
    ]

    return <FilterWrapper>{filterElements}</FilterWrapper>
  }

  return {
    Filters,
    selectedFacets,
  }
}

export default useFilterFactory
