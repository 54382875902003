import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { StyleObject } from 'types'
import { PRINT_ROW_GAP } from 'components/Page'

const SECTION_SPACING = '48px'

const StyledSection = styled.section<{ first: boolean }>`
  margin: ${SECTION_SPACING} 0;
  margin-top: ${(props) => (props.first ? 0 : SECTION_SPACING)};

  @media print {
    margin: ${PRINT_ROW_GAP} 0;
    margin-top: ${(props) => (props.first ? 0 : PRINT_ROW_GAP)};
  }
`

export const SectionHeader = styled.h2`
  margin-bottom: 32px;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
`

const Section: React.FunctionComponent<{
  header?: string
  first?: boolean
  style?: StyleObject
  children?: ReactNode
}> = ({ header, first = false, style = {}, children }) => (
  <StyledSection first={first}>
    {header && <SectionHeader>{header}</SectionHeader>}
    <div style={{ display: 'flex', ...style }}>{children}</div>
  </StyledSection>
)

export default Section
