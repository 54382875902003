import React from 'react'
import { intlMessageForId } from 'localization'
import Page from 'components/Page'
import Logout from 'components/Logout'
import Divider from 'components/Divider'
import ChangePassword from './ChangePassword'

const Account: React.FunctionComponent = () => (
  <Page
    testId="Account"
    title={intlMessageForId('Account.Title')}
    headerElement={<Logout />}
  >
    <Divider height="8px" />
    <ChangePassword />
  </Page>
)

export default Account
