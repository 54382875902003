import React, { FC, ReactElement } from 'react'
import { Navigate, Route } from 'react-router-dom'
import { flatten } from 'lodash'
import { map, property } from 'lodash/fp'
import { pairValues } from 'utils'
import Page from 'components/Page'
import { SegmentType, useSegmentedControl } from 'components/SegmentedControl'
import Divider from 'components/Divider'

export type SegmentedPageSegmentType = SegmentType & { component: ReactElement }

type SegmentedPageProps = {
  title: string
  segments: Array<SegmentedPageSegmentType>
  testId: string
}

const SegmentedPage: FC<SegmentedPageProps> = ({ title, segments, testId }) => {
  const { SegmentedControl, selectedSegment } = useSegmentedControl(segments)

  const componentMap = pairValues(segments, (segment) => {
    return { [segment.key]: segment.component }
  })

  return (
    <Page title={title} testId={testId}>
      <SegmentedControl />
      <Divider />
      <div style={{ paddingTop: '48px' }}>
        {componentMap[selectedSegment.key]}
      </div>
    </Page>
  )
}

const getSegmentedPagePaths: (segments: Array<SegmentType>) => Array<string> = (
  segments
) => {
  return map(property(['path']))(segments)
}

export const getSegmentedPageWithRoutes: (
  props: { rootPath: string } & SegmentedPageProps
) => Array<ReactElement> = (props) => {
  const { rootPath, segments, title, testId } = props
  const nestedPaths = getSegmentedPagePaths(segments)
  return flatten([
    <Route
      path={rootPath}
      key={`${rootPath}-redirect`}
      element={<Navigate to={nestedPaths[0]} />}
    />,
    nestedPaths.map((path) => {
      return (
        <Route
          key={path}
          path={path}
          element={
            <SegmentedPage segments={segments} title={title} testId={testId} />
          }
        />
      )
    }),
  ])
}
