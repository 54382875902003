import React, { FC, ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { find, remove, update } from 'lodash'
import { map } from 'lodash/fp'
import { gql, useMutation, useQuery } from '@apollo/client'
import { typeToTitle } from 'types'
import { isSet, joinErrorMessages } from 'utils'
import { TextButton } from 'components/Button'
import { handleCompleted, handleError } from 'components/FormElements/common'
import { Error, Loading } from 'components/Placeholders'
import SocialDistancing from 'components/SocialDistancing'
import Table, { getColumns, TableColumnType } from 'components/Table'
import CreateSettingForm from './CreateSettingForm'
import EnabledCell from './EnabledCell'
import { Setting, SettingType, SettingDomain } from './types'

const UPDATE_SETTING_MUTATION = gql`
  mutation(
    $id: ID!
    $domain: SettingDomain!
    $enabled: Boolean
    $email: String
    $sampleType: String
  ) {
    updateSetting(
      id: $id
      input: {
        domain: $domain
        enabled: $enabled
        email: $email
        sampleType: $sampleType
      }
    ) {
      successful
      messages {
        field
        message
      }
      result {
        id
        enabled
      }
    }
  }
`

const DELETE_SETTING_MUTATION = gql`
  mutation($id: ID!) {
    deleteSetting(id: $id)
  }
`
type SettingTableDatum = {
  id: string
  email: string
  sampleType: string
  enabled: ReactElement
  delete: ReactElement
}

const SettingTable: FC<{
  domain: SettingDomain
  type: SettingType
  placeholder: string
}> = ({ domain, type, placeholder }) => {
  const navigate = useNavigate()
  const [settings, setSettings] = useState<Array<Setting>>([])

  const addSetting: (setting: Setting) => void = (setting) =>
    setSettings([...settings, setting])

  const settingQuery = gql`
  query {
    settings(domain: ${domain}, type: ${[type]}) {
      id
      domain
      sampleType
      email
      enabled
    }
  }
`

  const tableColumns = (): Array<TableColumnType> => {
    const requiredColums = [
      {
        title: 'status',
        field: 'enabled',
      },
      {
        title: 'options',
        field: 'delete',
      },
    ]

    const emailColumn = [
      {
        title: typeToTitle(type),
        field: 'email',
      },
    ]

    const sampleTypeColumn = [
      {
        title: typeToTitle(type),
        field: 'sampleType',
      },
    ]

    return [
      ...(type === 'EMAIL' ? emailColumn : []),
      ...(type === 'SAMPLE_TYPE' ? sampleTypeColumn : []),
      ...requiredColums,
    ]
  }

  const { loading, error, data, refetch } = useQuery(settingQuery, {
    fetchPolicy: 'no-cache',
  })

  const response = data?.settings
  useEffect(() => {
    setSettings(response)
  }, [response])

  const [updateSetting, { data: updateData }] = useMutation(
    UPDATE_SETTING_MUTATION,
    {
      onCompleted: ({
        updateSetting: {
          successful,
          result: { id, enabled },
        },
      }) => {
        if (successful) {
          const setting = find(settings, (s: Setting) => {
            return s.id === id
          })

          if (isSet(setting)) {
            update(setting, 'enabled', () => enabled)
          }
        } else {
          handleError(
            navigate,
            window.location,
            joinErrorMessages(updateData?.messages)
          )
        }
      },
      onError: () => {
        handleError(navigate, window.location)
        refetch()
      },
    }
  )

  const [deleteSetting] = useMutation(DELETE_SETTING_MUTATION, {
    onCompleted: ({ deleteSetting: id }) => {
      remove(settings, (s: Setting) => {
        return s.id === id
      })

      handleCompleted(
        navigate,
        window.location,
        `${typeToTitle(type)} deleted!`
      )
    },
    onError: () => {
      handleError(navigate, window.location)
      refetch()
    },
  })

  const tableData: Array<SettingTableDatum> = map((setting: Setting) => {
    const { id, email, sampleType } = setting

    const deleteCell = (
      <TextButton
        type="button"
        onClick={() => {
          deleteSetting({
            variables: { id },
          })
        }}
      >
        Delete
      </TextButton>
    )

    const enabledCell = (
      <EnabledCell
        setting={setting}
        updateSetting={updateSetting}
        type={type}
      />
    )

    return {
      id,
      email,
      sampleType,
      enabled: enabledCell,
      delete: deleteCell,
    } as SettingTableDatum
  })(settings) as Array<SettingTableDatum>

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <SocialDistancing spacing="48px">
      <Table
        testId="SettingTable"
        columns={getColumns(tableColumns(), ['id'])}
        data={tableData}
      />
      <CreateSettingForm
        domain={domain}
        type={type}
        placeholder={placeholder}
        addSetting={addSetting}
      />
    </SocialDistancing>
  )
}

export default SettingTable
