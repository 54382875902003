import React, { FunctionComponent, ReactElement } from 'react'
import styled from '@emotion/styled'
import SocialDistancing from 'components/SocialDistancing'
import { StyleObject } from 'types'

const Content = styled.div`
  display: flex;
  height: 500px;
  border-radius: 8px;
`

const ChartLayoutManager: FunctionComponent<{
  chart: ReactElement
  filters: ReactElement
  overview?: ReactElement
  takeover?: ReactElement
  contentStyles?: StyleObject
  testId?: string
}> = ({ chart, filters, overview, takeover, contentStyles = {}, testId }) => (
  <section data-testid={testId}>
    <SocialDistancing spacing="24px">
      {filters}
      <Content style={contentStyles}>
        {takeover || (
          <>
            {chart}
            {overview}
          </>
        )}
      </Content>
    </SocialDistancing>
  </section>
)

export default ChartLayoutManager
