/** @jsxRuntime classic */
/** @jsx jsx */
import { FunctionComponent, ReactNode } from 'react'
import ReactGA from 'react-ga4'
import { Link, useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/react'
import { intlMessageForId } from 'localization'
import { DimensionedQuantity } from 'types'
import Color from 'types/color'
import { Path } from 'types/enums'
import { EventAction, EventCategory, trackEvent } from 'utils/analytics'
import useCurrentUser from 'hooks/useCurrentUser'
import useFeatureFlags from 'hooks/useFeatureFlags'
import SocialDistancing from 'components/SocialDistancing'
import Divider from 'components/Divider'
import NavLogo from 'assets/svg/nav-logo.svg'

const NavBarWidth: DimensionedQuantity = {
  value: 240,
  units: 'px',
}

const NavBarPadding: DimensionedQuantity = {
  value: 8,
  units: 'px',
}

const width = `${NavBarWidth.value}${NavBarWidth.units}`
const padding = `${NavBarPadding.value}${NavBarPadding.units}`
const heightCorrection = `${2 * NavBarPadding.value}${NavBarPadding.units}`

export const NavBarTotalWidth: DimensionedQuantity = {
  value: NavBarWidth.value + 2 * NavBarPadding.value,
  units: NavBarWidth.units,
}

const Wrapper = styled.div`
  background-color: ${Color.Gray};
  padding: ${padding};
  position: fixed;
  z-index: 99;
`

const Nav = styled.nav`
  box-sizing: border-box;
  background-color: ${Color.White};
  border-radius: 8px;
  height: calc(100vh - ${heightCorrection});
  padding: 0 16px;
  text-align: left;
  overflow: auto;

  width: ${width};
  display: flex;
  flex-flow: column nowrap;

  > header {
    padding-left: 16px;
  }
`

const Header = styled.header`
  padding: 50px 0;
`

const Section = styled.section`
  padding-bottom: 32px;
`

const DividerWrapper = styled.div`
  margin: 16px 0px;
`

const Footer = styled.footer`
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  padding-bottom: 32px;
`

const FooterContent = styled.div`
  display: flex;
  align-items: center;
`

const Avatar = styled.div`
  width: 32px;
  height: 32px;
  background: ${Color.Accent};
  color: ${Color.White};
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
`

const CurrentUser = styled.div`
  font-size: 16px;
`

const linkStyles = css`
  display: flex;
  flex-grow: 1;
  text-decoration: none;
  color: ${Color.Black};
  font-size: 16px;
  line-height: 19px;
  padding: 16px;

  :hover {
    color: ${Color.LightBlue};
  }

  &.selected {
    background-color: ${Color.SelectedBackground};
    border-radius: 4px;
    font-weight: bold;
    color: ${Color.SelectedText};
  }

  :active {
    color: ${Color.ActiveText};
  }
`

const NavBarLink: FunctionComponent<{
  to: string
  selected: boolean
  children: ReactNode
}> = ({ to, selected = false, children }) => (
  <Link
    to={to}
    className={selected ? 'selected' : 'normal'}
    css={linkStyles}
    onClick={() => trackEvent(EventCategory.NavItem, EventAction.Click, to)}
  >
    {children}
  </Link>
)

const NavBar: FunctionComponent = () => {
  const { pathname } = useLocation()
  const { id, firstName, lastName, organization, orgAdmin } = useCurrentUser()
  const featureFlags = useFeatureFlags()

  const standardLinks = [
    'QuickScan',
    'Labs',
    'Reports',
    featureFlags?.qualityReports || featureFlags?.operatorTraining
      ? 'Quality'
      : undefined,
    'DIVIDER',
    'HelpCenter',
  ].map((element?: string) => {
    if (element === undefined) {
      return null
    }

    if (element === 'DIVIDER') {
      return (
        <DividerWrapper key={element}>
          <Divider />
        </DividerWrapper>
      )
    }

    const path = Path[element as keyof typeof Path]

    if (element === 'Reports') {
      return (
        <NavBarLink
          key={element}
          to={Path.Menus_Reports}
          selected={
            pathname === Path.Menus_Reports ||
            pathname.startsWith(Path.Reports) ||
            pathname.startsWith(Path.Analytics)
          }
        >
          {intlMessageForId(`NavBar.Links.${element}`)}
        </NavBarLink>
      )
    }

    if (element === 'Quality') {
      return (
        <NavBarLink
          key={element}
          to={Path.Menus_QualityAndTraining}
          selected={
            pathname === Path.Menus_QualityAndTraining ||
            pathname.startsWith(path)
          }
        >
          {intlMessageForId(`NavBar.Links.${element}`)}
        </NavBarLink>
      )
    }

    return (
      <NavBarLink key={element} to={path} selected={pathname.startsWith(path)}>
        {intlMessageForId(`NavBar.Links.${element}`)}
      </NavBarLink>
    )
  })

  const name = firstName && lastName ? `${firstName} ${lastName}` : undefined

  if (organization) {
    ReactGA.set({ dimension1: organization.id.toString() })
    ReactGA.set({
      user_properties: {
        orgid: organization.id.toString(),
      },
    })
  }

  if (id) {
    ReactGA.set({ userId: id })
    ReactGA.set({
      user_properties: {
        UserID: id,
      },
    })
  }

  return (
    <Wrapper data-testid="NavBar">
      <Nav>
        <Header>
          <Link to={Path.Home}>
            <img src={NavLogo} alt="Envirologix" />
          </Link>
        </Header>
        <Section>
          <SocialDistancing spacing="8px">
            {standardLinks}
            {orgAdmin && (
              <NavBarLink
                to={Path.AdminSettings}
                selected={pathname.startsWith(Path.AdminSettings)}
              >
                {intlMessageForId('NavBar.Links.AdminSettings')}
              </NavBarLink>
            )}
          </SocialDistancing>
        </Section>
        <Footer>
          {name && (
            <NavBarLink to={Path.Account} selected={pathname === Path.Account}>
              <FooterContent>
                <Avatar>{name[0]}</Avatar>
                <div style={{ paddingLeft: '12px', paddingTop: '3px' }}>
                  <CurrentUser>{name}</CurrentUser>
                </div>
              </FooterContent>
            </NavBarLink>
          )}
        </Footer>
      </Nav>
    </Wrapper>
  )
}

export default NavBar
