import React, { FC, ReactNode } from 'react'
import { map } from 'lodash/fp'
import SocialDistancing from 'components/SocialDistancing'
import Table, {
  cellStyle,
  DetailedCell,
  TableColumnType,
} from 'components/Table'
import { intlMessageForId } from 'localization'
import { FormName } from 'types/forms'
import { isSet } from 'utils'
import { formatNumber } from 'utils/format'
import { AnalysisType } from './Form'
import {
  QuickscanBiReportAnalysisResult,
  QuickscanBiReportAnalysisType,
  QuickscanBiReportColumn,
} from './types'

type TableDatum = {
  name: string
  [FormName.TestAverage]: ReactNode
  [FormName.AcceptedTestAverage]: ReactNode
  [FormName.RejectionRate]: ReactNode
}

const detailText = (count: number) =>
  `${intlMessageForId(
    'Analytics.QuickScan.Details.AnalysesResults.Table.TestCount'
  )}: ${count}`

const getNameColumnTitle = (analysisType: QuickscanBiReportAnalysisType) => {
  const intl = (tail: string) => `Analytics.QuickScan.Form.Fields.${tail}`

  const analysisTypes = {
    [AnalysisType.Location]: intl(FormName.Location),
    [AnalysisType.Supplier]: intl(FormName.Supplier),
    [AnalysisType.Bin]: intl(FormName.Bin),
  }

  return intlMessageForId(analysisTypes[analysisType])
}

const getTitle = (formName: FormName, unit: string) => {
  return `${intlMessageForId(
    `Analytics.QuickScan.Form.Fields.${formName}`
  )} (${unit})`
}

const DetailsTable: FC<{
  analysisType: QuickscanBiReportAnalysisType
  analysesResults: Array<QuickscanBiReportAnalysisResult>
  unit: string
  threshold?: number
  columns: Array<QuickscanBiReportColumn>
}> = ({ analysisType, analysesResults, unit, threshold, columns }) => {
  const hideColumn: (field: FormName) => boolean = (field) => {
    return columns.indexOf(field as QuickscanBiReportColumn) === -1
  }

  const tableColumns = (): Array<TableColumnType> => {
    return [
      {
        title: getNameColumnTitle(analysisType),
        field: 'name',
      },
      {
        title: getTitle(FormName.TestAverage, unit),
        field: FormName.TestAverage,
        cellStyle,
        hidden: hideColumn(FormName.TestAverage),
      },
      {
        title: getTitle(FormName.AcceptedTestAverage, unit),
        field: FormName.AcceptedTestAverage,
        cellStyle,
        hidden: hideColumn(FormName.AcceptedTestAverage),
      },
      {
        title: getTitle(FormName.RejectionRate, '%'),
        field: FormName.RejectionRate,
        hidden: hideColumn(FormName.RejectionRate),
      },
    ]
  }

  const tableData: Array<TableDatum> = map(
    (result: QuickscanBiReportAnalysisResult) => {
      const {
        acceptedTestAverage,
        acceptedTestCount,
        name,
        rejectedTestCount,
        rejectionRate,
        testAverage,
        testCount,
      } = result

      return {
        name,
        [FormName.TestAverage]: (
          <DetailedCell
            text={formatNumber(testAverage, 1)}
            detailText={detailText(testCount)}
            highlight={isSet(threshold) && testAverage > threshold}
          />
        ),
        [FormName.AcceptedTestAverage]: (
          <DetailedCell
            text={formatNumber(acceptedTestAverage, 1)}
            detailText={detailText(acceptedTestCount)}
            highlight={isSet(threshold) && acceptedTestAverage > threshold}
          />
        ),
        [FormName.RejectionRate]: (
          <DetailedCell
            text={formatNumber(rejectionRate, 1)}
            detailText={detailText(rejectedTestCount)}
          />
        ),
      } as TableDatum
    }
  )(analysesResults) as Array<TableDatum>

  return (
    <SocialDistancing spacing="48px">
      <Table testId="DetailsTable" columns={tableColumns()} data={tableData} />
    </SocialDistancing>
  )
}

export default DetailsTable
