import React, { FunctionComponent, ReactElement } from 'react'
import { Field } from 'formik'
import styled from '@emotion/styled'
import FilterArrow from 'assets/svg/filter-arrow.svg'
import Search from 'assets/svg/search.svg'
import { StyleObject } from 'types'
import Color from 'types/color'
import { sharedStyles } from './common'
import LabeledFormElement from './LabeledFormElement'
import ErrorMessage, { StyledErrorMessage } from './ErrorMessage'

const styles = `
  ${sharedStyles}
  font-family: 'Kumbh Sans', sans-serif;
  background: ${Color.White};
  color: ${Color.Black};
  font-size: 16px;
  box-sizing: border-box;
  padding: 15px 20px;
  border: 1px solid rgba(22, 42, 56, 0.2);
  border-radius: 4px;
  outline: none;
`

export const Textarea = styled.textarea`
  ${styles}
`

export const Input = styled.input`
  ${styles}
`

export const SmallInput = styled(Input)`
  padding: 7px 12px;
  font-size: 14px;
`

export const AutoComplete = styled(Input)`
  :focus {
    background-image: url(${FilterArrow});
    background-repeat: no-repeat;
    background-position: right 20px top 50%, 0 0;
  }
`

export const SearchInput = styled(Input)`
  background-image: url(${Search});
  background-repeat: no-repeat;
  background-position: left 10px top 50%, 0 0;
  padding-left: 40px;
`

export type FormInputProps = {
  name: string
  label?: string
  placeholder?: string
  as?: unknown
  style?: StyleObject
  type?: string
  displayError?: boolean
  apiError?: string
  ariaLabel?: string
  list?: string
}

const error: (name: string, apiError?: string) => ReactElement = (
  name,
  apiError
) => {
  return apiError ? (
    <StyledErrorMessage>{apiError}</StyledErrorMessage>
  ) : (
    <ErrorMessage forName={name} />
  )
}

const FormInput: FunctionComponent<FormInputProps> = ({
  name,
  label,
  placeholder,
  as = Input,
  style = {},
  type = 'text',
  displayError = true,
  apiError,
  ariaLabel,
  list,
}) => (
  <LabeledFormElement label={label} name={name}>
    <>
      <Field
        name={name}
        as={as}
        style={{ width: '100%', ...style }}
        placeholder={placeholder}
        type={type}
        aria-label={ariaLabel}
        list={list}
        id={name.replaceAll('.', '-')}
      />
      {displayError && error(name, apiError)}
    </>
  </LabeledFormElement>
)

export default FormInput
