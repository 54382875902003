import React, { FC, useEffect } from 'react'
import { ApolloError, gql, useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { compact, times, toLower } from 'lodash'
import { map } from 'lodash/fp'
import { getIntlMessageForId, intlMessageForId } from 'localization'
import { AnyObject } from 'types'
import Color from 'types/color'
import { getNewPath, LayoutDirection, Path } from 'types/enums'
import { FormName } from 'types/forms'
import { get, isSet, joinErrorMessages, logError } from 'utils'
import useCurrentUser from 'hooks/useCurrentUser'
import { LinkButton, SecondaryButton, PrimaryButton } from 'components/Button'
import {
  FormInput,
  VALID_EMAIL_ERROR_MESSAGE,
  REQUIRED_ERROR_MESSAGE,
  FormRadioButtons,
  ToggledSelect,
  ToggledControlName,
  ErrorMessage,
  FormSelect,
} from 'components/FormElements'
import { handleCompleted, handleError } from 'components/FormElements/common'
import { FormSelectOption } from 'components/FormElements/FormSelect'
import Group from 'components/FormElements/Group'
import HighlightedFormSelect from 'components/FormElements/HighlightedFormSelect'
import Page from 'components/Page'
import { Loading, Error } from 'components/Placeholders'
import Section from 'components/Section'
import SocialDistancing from 'components/SocialDistancing'
import { FacetValue } from 'components/charts/FacetsProvider'
import useLabsCustomerLocationFacet from 'components/pages/labs/hooks/useLabsCustomerLocationFacet'
import { toggledSelectValidation } from 'utils/forms'
import { LargeLabel } from '../../labs/components'
import { DataSource, AnalyteType, AlertType, AlertTimeRange } from './types'

export const FACETS_QUERY = gql`
  query {
    quickscanTranslatedFacets {
      commodity {
        key
        label
      }
      gmo {
        analyte {
          key
          label
        }
      }
      mycotoxin {
        analyte {
          key
          label
        }
      }
      allergen {
        analyte {
          key
          label
        }
      }
      location {
        key
        label
      }
    }
    quickcheckTranslatedFacets {
      commodity {
        key
        label
      }
      gmo {
        analyte {
          key
          label
        }
      }
      mycotoxin {
        analyte {
          key
          label
        }
      }
      allergen {
        analyte {
          key
          label
        }
      }
      location {
        key
        label
      }
    }
    labTranslatedFacets {
      commodity {
        key
        label
      }
      gmo {
        analyte {
          key
          label
        }
      }
      mycotoxin {
        analyte {
          key
          label
        }
      }
      allergen {
        analyte {
          key
          label
        }
      }
    }
    labCustomers {
      qbenchCustomerId
      customerName
    }
  }
`

export const CREATE_ALERT_MUTATION = gql`
  mutation(
    $analyte: String
    $analyteType: AlertAnalyteType
    $commodities: [String]
    $emails: [String]!
    $enabled: Boolean!
    $locations: [String]
    $name: String!
    $source: AlertSource!
    $threshold: Float
    $timeRange: AlertTimeRange
    $alertType: AlertType!
  ) {
    createAlert(
      input: {
        analyte: $analyte
        analyteType: $analyteType
        commodities: $commodities
        emails: $emails
        enabled: $enabled
        locations: $locations
        name: $name
        source: $source
        threshold: $threshold
        timeRange: $timeRange
        alertType: $alertType
      }
    ) {
      successful
      messages {
        field
        message
        template
      }
    }
  }
`

interface FormValues {
  [FormName.DataSource]: DataSource
  [FormName.Locations]: { CHECKED: boolean; VALUE: Array<string> }
  [FormName.Commodities]: { CHECKED: boolean; VALUE: Array<string> }
  [FormName.AnalyteType]:
    | AnalyteType.Gmo
    | AnalyteType.Mycotoxin
    | AnalyteType.Allergen
    | AnalyteType.Rejections
  [FormName.Analyte]: string
  [FormName.AlertType]: AlertType
  [FormName.Threshold]: string
  [FormName.TimeRange]: AlertTimeRange | ''
  [FormName.Name]: string
  [FormName.Email1]: string
  [FormName.Email2]: string
  [FormName.Email3]: string
  [FormName.Email4]: string
  [FormName.Email5]: string
}

const initialValues: FormValues = {
  [FormName.DataSource]: DataSource.Quickscan,
  [FormName.Locations]: { CHECKED: false, VALUE: [] },
  [FormName.Commodities]: { CHECKED: false, VALUE: [] },
  [FormName.AnalyteType]: AnalyteType.Gmo,
  [FormName.Analyte]: '',
  [FormName.AlertType]: AlertType.SingleResult,
  [FormName.Threshold]: '',
  [FormName.TimeRange]: '',
  [FormName.Name]: '',
  [FormName.Email1]: '',
  [FormName.Email2]: '',
  [FormName.Email3]: '',
  [FormName.Email4]: '',
  [FormName.Email5]: '',
}

const AnalyteMap = {
  [DataSource.Quickscan]: [
    AnalyteType.Gmo,
    AnalyteType.Mycotoxin,
    AnalyteType.Allergen,
    AnalyteType.Rejections,
  ],
  [DataSource.Quickcheck]: [AnalyteType.Mycotoxin],
  [DataSource.Lab]: [AnalyteType.Gmo, AnalyteType.Mycotoxin],
}

const toSelectOptions: (
  facetValues: Array<FacetValue>
) => Array<FormSelectOption> = (facetValues) =>
  map(({ label }) => ({ label, value: label }))(facetValues)

const isAnalyteRequired: (
  dataSource: DataSource,
  analyteType: AnalyteType
) => boolean = (dataSource, analyteType) => {
  if (
    dataSource === DataSource.Quickscan &&
    analyteType === AnalyteType.Rejections
  ) {
    return false
  }

  return true
}

const isThresholdRequired: (
  dataSource: DataSource,
  analyteType: AnalyteType,
  alertType?: AlertType
) => boolean = (dataSource, analyteType, alertType) => {
  if (dataSource === DataSource.Quickcheck) {
    return false
  }

  if (
    dataSource === DataSource.Quickscan &&
    analyteType === AnalyteType.Rejections &&
    alertType === AlertType.SingleRejection
  ) {
    return false
  }

  return true
}

const isTimeRangeRequired: (alertType?: AlertType) => boolean = (alertType) => {
  return (
    alertType === AlertType.AverageResult ||
    alertType === AlertType.RejectionRate
  )
}

const validationSchema = Yup.lazy((values: FormValues) => {
  return Yup.object().shape({
    [FormName.DataSource]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
    [FormName.Locations]: toggledSelectValidation,
    [FormName.Commodities]: toggledSelectValidation,
    [FormName.AnalyteType]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
    [FormName.Analyte]: Yup.lazy(() => {
      return isAnalyteRequired(
        values[FormName.DataSource],
        values[FormName.AnalyteType]
      )
        ? Yup.string().required(REQUIRED_ERROR_MESSAGE)
        : Yup.string().notRequired()
    }),
    [FormName.Name]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
    [FormName.Email1]: Yup.string().email(VALID_EMAIL_ERROR_MESSAGE),
    [FormName.Email2]: Yup.string().email(VALID_EMAIL_ERROR_MESSAGE),
    [FormName.Email3]: Yup.string().email(VALID_EMAIL_ERROR_MESSAGE),
    [FormName.Email4]: Yup.string().email(VALID_EMAIL_ERROR_MESSAGE),
    [FormName.Email5]: Yup.string().email(VALID_EMAIL_ERROR_MESSAGE),
    [FormName.AlertType]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
    [FormName.Threshold]: Yup.lazy(() => {
      return isThresholdRequired(
        values[FormName.DataSource],
        values[FormName.AnalyteType],
        values[FormName.AlertType]
      )
        ? Yup.number().required(REQUIRED_ERROR_MESSAGE)
        : Yup.number().notRequired()
    }),
    [FormName.TimeRange]: Yup.lazy(() => {
      return isTimeRangeRequired(values[FormName.AlertType])
        ? Yup.string().required(REQUIRED_ERROR_MESSAGE)
        : Yup.string().notRequired()
    }),
  })
})

// ToggledSelect with ErrorMessage
// not a generic implementation because of the position of its error message
const AlertsToggledSelect: FC<{
  name: FormName
  label: string
  options: Array<FormSelectOption>
}> = ({ name, label, options }) => (
  <div>
    <ToggledSelect
      name={name}
      label={label}
      defaultChecked={false}
      initialValue={undefined}
      selectOptions={options}
      key={name}
      multiple
    />
    <div style={{ float: 'right' }}>
      <ErrorMessage forName={`${name}.${ToggledControlName.Value}`} />
    </div>
  </div>
)

const DataSourceSection: FC<{ locations: Array<FacetValue> }> = ({
  locations,
}) => {
  const { resetForm, values } = useFormikContext()

  const dataSource = (values as FormValues)[FormName.DataSource]

  const defaultAnalyteType =
    dataSource === DataSource.Quickcheck
      ? AnalyteType.Mycotoxin
      : AnalyteType.Gmo

  const email = (values as FormValues)[FormName.Email1]

  useEffect(() => {
    resetForm({
      values: {
        ...initialValues,
        [FormName.DataSource]: dataSource,
        [FormName.Commodities]: initialValues[FormName.Commodities],
        [FormName.AnalyteType]: defaultAnalyteType,
        [FormName.Email1]: email,
      },
    })

    // cannot include values here otherwise any time a form value changes the form will reset
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm, dataSource])

  const toIntl = getIntlMessageForId('Alerts.Form.Fields.Datasource.Options')

  const dataSourceOptions = map((option: { label: string; value: string }) => {
    const { label, value } = option
    return {
      label,
      value,
    }
  })([
    {
      label: toIntl('Quickscan'),
      value: DataSource.Quickscan,
    },
    {
      label: toIntl('Quickcheck'),
      value: DataSource.Quickcheck,
    },
    {
      label: toIntl('Lab'),
      value: DataSource.Lab,
    },
  ])

  const locationOptions = toSelectOptions(locations)

  return (
    <Group title={intlMessageForId('Alerts.Form.Fields.Datasource.Label')}>
      <FormRadioButtons
        name={FormName.DataSource}
        options={dataSourceOptions}
      />
      <AlertsToggledSelect
        name={FormName.Locations}
        label={intlMessageForId('Alerts.Form.Fields.Datasource.Location.Label')}
        options={locationOptions}
      />
    </Group>
  )
}

const CommoditySection: FC<{
  dataSource: DataSource
  commodities: Array<FacetValue>
}> = ({ dataSource, commodities }) => {
  const head = intlMessageForId('Alerts.Form.Fields.Commodity.Label')
  const tail = intlMessageForId(
    `Alerts.FieldLabels.Commodities.${dataSource}`
  ).toLowerCase()

  return (
    <Group
      title={intlMessageForId(`Alerts.FieldLabels.Commodities.${dataSource}`)}
    >
      <AlertsToggledSelect
        name={FormName.Commodities}
        label={`${head} ${tail}`}
        options={toSelectOptions(commodities)}
      />
    </Group>
  )
}

const AnalyteSection: FC<{
  analyteTypes: Array<AnalyteType>
  analytes: Array<FacetValue>
}> = ({ analyteTypes, analytes }) => {
  const { setFieldValue, values } = useFormikContext()

  const dataSource = (values as FormValues)[FormName.DataSource]
  const analyteType = (values as FormValues)[FormName.AnalyteType]

  const getDefaultAlertType = () => {
    if (
      dataSource === DataSource.Quickscan &&
      analyteType === AnalyteType.Rejections
    ) {
      return AlertType.SingleRejection
    }

    return initialValues[FormName.AlertType]
  }

  useEffect(() => {
    setFieldValue(FormName.Analyte, initialValues[FormName.Analyte], false)
    setFieldValue(FormName.AlertType, getDefaultAlertType(), false)

    // cannot include values here othereise any time a form value changes the form will reset
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, analyteType])

  const analyteTypeOptions = map((type: AnalyteType) => {
    return {
      label: intlMessageForId(`Alerts.Form.Fields.Analyte.Options.${type}`),
      value: type,
    }
  })(analyteTypes)

  const analyteOptions = map((analyte: FacetValue) => {
    return {
      label: analyte.label,
      value: analyte.label,
    }
  })(analytes)

  return (
    <Group title={intlMessageForId('Alerts.FieldLabels.Analyte')}>
      <FormRadioButtons
        name={FormName.AnalyteType}
        options={analyteTypeOptions}
      />
      {(values as FormValues)[FormName.AnalyteType] !==
      AnalyteType.Rejections ? (
        <HighlightedFormSelect
          emptyState={intlMessageForId('Common.Select.Help')}
          name={FormName.Analyte}
          label={intlMessageForId('Alerts.Form.Fields.Analyte.Label')}
          options={analyteOptions}
          style={{ fontSize: 14 }}
        />
      ) : null}
    </Group>
  )
}

const timeRangeOptions: () => Array<FormSelectOption> = () => {
  const toIntl = getIntlMessageForId('Alerts.Form.Fields.TimeRange.Options')

  return [
    {
      label: intlMessageForId('Form.Placeholders.SelectRange'),
      value: '',
      disabled: true,
    },
    {
      label: toIntl('Today'),
      value: AlertTimeRange.Today,
    },
    {
      label: toIntl('Past2Days'),
      value: AlertTimeRange.Past2Days,
    },
    {
      label: toIntl('Past7Days'),
      value: AlertTimeRange.Past7Days,
    },
    {
      label: toIntl('Past14Days'),
      value: AlertTimeRange.Past14Days,
    },
    {
      label: toIntl('Past30Days'),
      value: AlertTimeRange.Past30Days,
    },
  ]
}

const AlertSetting: FC<{
  alertOptions: Array<{ label: string; value: AlertType }>
  showThreshold?: boolean
  showTimeRange?: boolean
  thresholdPlaceholder?: string
}> = ({
  alertOptions,
  showThreshold = false,
  showTimeRange = false,
  thresholdPlaceholder = '',
}) => (
  <SocialDistancing spacing="16px">
    <FormRadioButtons name={FormName.AlertType} options={alertOptions} />
    <SocialDistancing direction={LayoutDirection.Horizontal} spacing="24px">
      {showThreshold && (
        <FormInput
          name={FormName.Threshold}
          type="number"
          label={intlMessageForId('Alerts.Form.Fields.Threshold')}
          placeholder={thresholdPlaceholder}
        />
      )}
      {showTimeRange && (
        <FormSelect
          label={intlMessageForId('Alerts.Form.Fields.TimeRange.Label')}
          name={FormName.TimeRange}
          options={timeRangeOptions()}
        />
      )}
    </SocialDistancing>
    {showTimeRange && (
      <em>
        <FormattedMessage
          id="Alerts.AverageResult.Disclaimer"
          values={{
            type: alertOptions[1].label.toLowerCase(),
          }}
        />
      </em>
    )}
  </SocialDistancing>
)

const AlertSettingSection: FC<{
  alertType?: AlertType
  dataSource?: DataSource
  analyteType?: AnalyteType
}> = ({ alertType, dataSource, analyteType }) => {
  const toIntl = getIntlMessageForId('Alerts.Form.Fields.AlertType.Options')
  return (
    <Group title={intlMessageForId('Alerts.Form.SectionHeaders.AlertSetting')}>
      {(dataSource === DataSource.Quickscan || dataSource === DataSource.Lab) &&
        analyteType !== AnalyteType.Rejections && (
          <AlertSetting
            alertOptions={[
              {
                label: toIntl('SingleResult'),
                value: AlertType.SingleResult,
              },
              {
                label: toIntl('AverageResult'),
                value: AlertType.AverageResult,
              },
            ]}
            showThreshold
            showTimeRange={alertType === AlertType.AverageResult}
            thresholdPlaceholder={intlMessageForId(
              'Form.Placeholders.NumericResult'
            )}
          />
        )}
      {dataSource === DataSource.Quickscan &&
        analyteType === AnalyteType.Rejections && (
          <AlertSetting
            alertOptions={[
              {
                label: toIntl('SingleRejection'),
                value: AlertType.SingleRejection,
              },
              {
                label: toIntl('RejectionRate'),
                value: AlertType.RejectionRate,
              },
            ]}
            showThreshold={alertType === AlertType.RejectionRate}
            showTimeRange={alertType === AlertType.RejectionRate}
            thresholdPlaceholder={intlMessageForId(
              'Form.Placeholders.PercentRejected'
            )}
          />
        )}
      {dataSource === DataSource.Quickcheck && (
        <AlertSetting
          alertOptions={[
            {
              label: toIntl('Quickcheck.All'),
              value: AlertType.SingleResult,
            },
            {
              label: toIntl('Quickcheck.Failed'),
              value: AlertType.SingleRejection,
            },
          ]}
        />
      )}
    </Group>
  )
}

const Name: FC = () => (
  <FormInput
    label={intlMessageForId('Alerts.Form.Fields.Nickname.Label')}
    name={FormName.Name}
    style={{ width: '100%' }}
    placeholder={intlMessageForId('Form.Placeholders.AlertNickname')}
  />
)

const Email: FC = () => (
  <div
    style={{
      width: '100%',
      backgroundColor: Color.DarkBlue,
      borderRadius: 4,
      padding: 24,
    }}
  >
    <SocialDistancing spacing="16px">
      <LargeLabel style={{ color: Color.White }}>
        {intlMessageForId('Labs.FieldLabels.sendReportTo')}
      </LargeLabel>
      {times(5, (index: number) => {
        const str = FormName.Email1
        const base = str.substr(0, str.length - 1)
        const n = index + 1
        const name = `${base}${n}`
        return <FormInput key={name} name={name} style={{ width: '100%' }} />
      })}
    </SocialDistancing>
  </div>
)

const Submit: FC = () => (
  <SocialDistancing spacing="16px" style={{ display: 'grid', width: '100%' }}>
    <PrimaryButton type="submit">
      {intlMessageForId('Buttons.Save')}
    </PrimaryButton>
    <LinkButton to={Path.Reports_Alerts} Component={SecondaryButton}>
      {intlMessageForId('Buttons.Cancel')}
    </LinkButton>
  </SocialDistancing>
)

const handleSubmit = (
  values: FormValues,
  actions: AnyObject,
  createAlert: (payload: AnyObject) => void,
  // obj value can be any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  publishPayload?: (payload: Record<string, any>) => void
) => {
  const locations = values[FormName.Locations]
  const isLocationsChecked = locations[ToggledControlName.Checked]
  const locationsSelected = locations[ToggledControlName.Value]

  const commodities = values[FormName.Commodities]
  const isCommoditiesChecked = commodities[ToggledControlName.Checked]
  const commoditiesSelected = commodities[ToggledControlName.Value]

  const source = values[FormName.DataSource]
  const analyteType = values[FormName.AnalyteType]
  const alertType = values[FormName.AlertType]

  const payload = {
    analyte: isAnalyteRequired(source, analyteType)
      ? values[FormName.Analyte]
      : undefined,
    analyteType,
    commodities:
      isCommoditiesChecked && commoditiesSelected.length > 0
        ? commoditiesSelected
        : undefined,
    emails: compact([
      values[FormName.Email1],
      values[FormName.Email2],
      values[FormName.Email3],
      values[FormName.Email4],
      values[FormName.Email5],
    ]),
    enabled: true, // enable new alert by default
    locations:
      isLocationsChecked && locationsSelected.length > 0
        ? locationsSelected
        : undefined,
    name: values[FormName.Name],
    source,
    alertType,
    threshold: isThresholdRequired(source, analyteType, alertType)
      ? values[FormName.Threshold]
      : undefined,
    timeRange: isTimeRangeRequired(alertType)
      ? values[FormName.TimeRange]
      : undefined,
  }

  if (isSet(publishPayload)) {
    publishPayload(payload)
  }

  createAlert({
    variables: payload,
  })

  actions.setSubmitting(true)
}

const CreateAlertForm: FC<{
  publishPayload?: (payload: Record<string, Array<string> | AnyObject>) => void
}> = ({ publishPayload }) => {
  const navigate = useNavigate()
  const facetsQuery = useQuery(FACETS_QUERY)
  const { location } = useLabsCustomerLocationFacet()
  const { email } = useCurrentUser()

  const errorPath = getNewPath(Path.Reports_Alerts)

  const [createAlert] = useMutation(CREATE_ALERT_MUTATION, {
    onCompleted: ({ createAlert: { messages, successful } }) => {
      if (successful) {
        handleCompleted(
          navigate,
          Path.Reports_Alerts,
          intlMessageForId('Alerts.AddAlert.Confirmations.Added')
        )
      } else {
        handleError(navigate, errorPath, joinErrorMessages(messages))
      }
    },
    onError: (e: ApolloError) => {
      handleError(navigate, errorPath)
      logError(e.message, 'not-production')
    },
  })

  if (facetsQuery.loading) {
    return <Loading />
  }

  if (facetsQuery.error) {
    return <Error error={facetsQuery.error} />
  }

  const facetsByDataSource = {
    [DataSource.Quickscan]: facetsQuery?.data?.quickscanTranslatedFacets,
    [DataSource.Quickcheck]: facetsQuery?.data?.quickcheckTranslatedFacets,
    [DataSource.Lab]: {
      ...facetsQuery?.data?.labTranslatedFacets,
      location,
    },
  }

  return (
    <Page
      testId="CreateAlert"
      title={intlMessageForId('Alerts.CreateAlert.Header')}
    >
      <Formik
        initialValues={{
          ...initialValues,
          [FormName.Email1]: email,
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={(values: FormValues, actions: AnyObject) => {
          handleSubmit(values, actions, createAlert, publishPayload)
        }}
      >
        {({ values: formValues }) => {
          const facetMap: {
            allergen: { analyte: Array<FacetValue> }
            commodity: Array<FacetValue>
            gmo: { analyte: Array<FacetValue> }
            location: Array<FacetValue>
            mycotoxin: { analyte: Array<FacetValue> }
          } = facetsByDataSource[formValues[FormName.DataSource]]

          const analytes = get(
            facetMap,
            toLower(formValues[FormName.AnalyteType])
          )?.analyte

          return (
            <Form style={{ display: 'flex' }}>
              <div className="left" style={{ flexGrow: 1 }}>
                <Section first style={{ display: 'block' }}>
                  <DataSourceSection locations={facetMap?.location} />
                </Section>
                <Section first style={{ display: 'block' }}>
                  <CommoditySection
                    dataSource={formValues[FormName.DataSource]}
                    commodities={facetMap?.commodity}
                  />
                </Section>
                <Section style={{ display: 'block' }}>
                  <AnalyteSection
                    analyteTypes={AnalyteMap[formValues.DATA_SOURCE]}
                    analytes={analytes}
                  />
                </Section>
                <Section>
                  <AlertSettingSection
                    alertType={formValues[FormName.AlertType]}
                    dataSource={formValues[FormName.DataSource]}
                    analyteType={formValues[FormName.AnalyteType]}
                  />
                </Section>
              </div>
              <div className="right" style={{ width: '25%', marginLeft: 48 }}>
                <Section first style={{ display: 'block' }}>
                  <Name />
                </Section>
                <Section>
                  <Email />
                </Section>
                <Section>
                  <Submit />
                </Section>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Page>
  )
}

export default CreateAlertForm
