import { createIntl, createIntlCache } from 'react-intl'
import { AnyObject, FormattedMessageId } from 'types'
import { getLocale, Locale } from './utils'
import en from './en.json'
import pt from './pt.json'
import de from './de.json'
import es from './es.json'
import fr from './fr.json'
import hu from './hu.json'
import it from './it.json'
import pl from './pl.json'
import ru from './ru.json'

export { Locale }

type TranslationMap = {
  [key: string]: string
}

type Messages = {
  [Locale.English]: TranslationMap
  [Locale.Portuguese]: TranslationMap
  [Locale.German]: TranslationMap
  [Locale.Spanish]: TranslationMap
  [Locale.French]: TranslationMap
  [Locale.Hungarian]: TranslationMap
  [Locale.Italian]: TranslationMap
  [Locale.Polish]: TranslationMap
  [Locale.Russian]: TranslationMap
}

export const locale = getLocale()
export const messagesByLocale: Messages = {
  en,
  pt: { ...en, ...pt },
  de: { ...en, ...de },
  es: { ...en, ...es },
  fr: { ...en, ...fr },
  hu: { ...en, ...hu },
  it: { ...en, ...it },
  pl: { ...en, ...pl },
  ru: { ...en, ...ru },
}

const cache = createIntlCache()

export const intl = createIntl(
  {
    locale,
    messages: messagesByLocale[locale],
  },
  cache
)

export const intlMessageForId: (
  id: FormattedMessageId,
  values?: AnyObject
) => string = (id, values = {}) => {
  return intl.formatMessage(
    {
      id,
    },
    values
  )
}

/**
 * convenience function to handle multiple translations with the same base lookup key
 * @param base string that's reused for multiple intl translations
 * @returns function that takes an intl key and returns a translated string
 */
export const getIntlMessageForId: (
  base: string
) => (appended: string) => string = (base) => (appended) =>
  intlMessageForId(`${base}.${appended}`)
