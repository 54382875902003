import React, { FunctionComponent, ReactElement } from 'react'
import { useFormikContext } from 'formik'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import styled from '@emotion/styled'
import { StyleObject } from 'types'
import SocialDistancing from 'components/SocialDistancing'
import ErrorMessage from './ErrorMessage'

export const MaterialCheckbox = withStyles({
  root: {
    color: 'rgba(22, 42, 56, 0.2)',
    '&$checked': {
      color: '#176C8C',
    },
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#176C8C',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox {...props} />)

export const Label = styled.label`
  display: flex;
  align-items: flex-end;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
`

// Multiple checkboxes with the same name attribute, but different
// value attributes will be considered a "checkbox group". Formik will automagically
// bind the checked values to a single array for your benefit. All the add and remove
// logic will be taken care of for you.
// https://formik.org/docs/examples/checkboxes

const FormCheckbox: FunctionComponent<{
  id: string
  checked: boolean
  name: string
  children: string | ReactElement
  value?: boolean | string | number // MUI Checkbox says, "The DOM API casts this to a string"
  displayError?: boolean
  style?: StyleObject
  labelStyle?: StyleObject
  hidden?: boolean
}> = ({
  id,
  checked,
  name,
  children,
  value = true,
  displayError = true,
  style = {},
  labelStyle = {},
  hidden = false,
}) => {
  const { handleChange } = useFormikContext()

  return (
    <SocialDistancing spacing="8px" style={style}>
      <Label htmlFor={id} style={labelStyle}>
        <MaterialCheckbox
          id={id}
          checked={checked}
          value={value}
          name={name}
          onChange={handleChange}
          style={{ marginRight: '8px', display: hidden ? 'none' : 'auto' }}
          disabled={hidden}
        />
        {children}
      </Label>
      {displayError && <ErrorMessage forName={name} />}
    </SocialDistancing>
  )
}

export default FormCheckbox
