import React, { FC } from 'react'
import { ApolloError } from '@apollo/client'
import { FormattedMessage } from 'react-intl'
import { logError } from 'utils'

export const Loading: FC<{ testId?: string }> = ({ testId = 'Loading' }) => (
  <div data-testid={testId}>
    <FormattedMessage id="Common.Loading" />
    ...
  </div>
)

export const Error: FC<{ testId?: string; error?: ApolloError }> = ({
  testId = 'Error',
  error,
}) => {
  if (error) {
    logError(error.message, 'not-production')
  }

  return (
    <div data-testid={testId}>
      <FormattedMessage id="Common.Error" />
      ...
    </div>
  )
}
