import React from 'react'
import { Form, Formik } from 'formik'
import { gql, useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import { intlMessageForId } from 'localization'
import { AnyObject } from 'types'
import { FormName } from 'types/forms'
import { get, isSet, openUrl } from 'utils'
import SocialDistancing from 'components/SocialDistancing'
import { Error } from 'components/Placeholders'
import { getMultiSelectInput } from 'components/MultiSelect'
import {
  DownloadReport,
  EndDate,
  RunReportHeader,
  StartDate,
} from 'components/FormElements/common'
import FormMultiSelect from 'components/FormElements/FormMultiSelect'
import useQuickScanLocationFacet from 'hooks/useQuickScanLocationFacet'

export const CREATE_QUALITY_TEST_REPORT_MUTATION = gql`
  mutation($startTime: DateTime!, $endTime: DateTime!, $locations: [String]) {
    createQualityTestReport(
      startTime: $startTime
      endTime: $endTime
      locations: $locations
    )
  }
`

const DATE_FORMAT = 'YYYY-MM-DD'

const MultiSelectInput = getMultiSelectInput({
  padding: '15px 20px',
  fontSize: 16,
})

const CalibrationReport: React.FC<{
  publishPayload?: (
    payload: Record<string, string | Array<string> | AnyObject>
  ) => void
}> = ({ publishPayload }) => {
  const { location: locations } = useQuickScanLocationFacet()

  const [createReport, { error }] = useMutation(
    CREATE_QUALITY_TEST_REPORT_MUTATION,
    {
      onCompleted: (mutationData) => {
        const reportUrl = get(mutationData, 'createQualityTestReport')

        if (reportUrl) {
          openUrl(reportUrl)
        }
      },
    }
  )

  if (error) return <Error />

  return (
    <div data-testid="QuickScanCalibrationReport">
      <Formik
        initialValues={{
          [FormName.When]: {
            min: dayjs().subtract(1, 'month').format(DATE_FORMAT),
            max: dayjs().format(DATE_FORMAT),
          },
        }}
        onSubmit={(formikValues: AnyObject) => {
          const { WHEN, LOCATION } = formikValues

          const payload = {
            startTime: dayjs(get(WHEN, 'min')).startOf('day').toISOString(),
            endTime: dayjs(get(WHEN, 'max')).endOf('day').toISOString(),
            locations: LOCATION,
          }

          createReport({
            variables: payload,
          })

          if (isSet(publishPayload)) {
            publishPayload(payload)
          }
        }}
      >
        {() => (
          <Form>
            <RunReportHeader />
            <SocialDistancing
              spacing="32px"
              style={{
                paddingTop: '25px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <SocialDistancing spacing="8px">
                {locations && (
                  <FormMultiSelect
                    label={intlMessageForId('Common.Locations')}
                    name={FormName.Location}
                    selectOptions={locations.map(
                      (location: { key: string; label: string }) => {
                        const { key: value, label } = location
                        return { label, value }
                      }
                    )}
                    Input={<MultiSelectInput />}
                  />
                )}
                <StartDate />
                <EndDate />
              </SocialDistancing>
              <SocialDistancing spacing="8px">
                <DownloadReport />
              </SocialDistancing>
            </SocialDistancing>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CalibrationReport
