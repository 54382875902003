import React, {
  ChangeEvent,
  ComponentType,
  FunctionComponent,
  ReactElement,
  useState,
} from 'react'
import {
  Select as MaterialSelect,
  MenuItem,
  FormControl,
  InputBase,
} from '@material-ui/core'
import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { flow, fromPairs, map, pick, values } from 'lodash/fp'
import { intlMessageForId } from 'localization'
import { StyleObject } from 'types'
import Color from 'types/color'
import FilterArrow from 'assets/svg/filter-arrow.svg'

export type FormSelectOption = {
  label: string
  value: string
}

const getSelectedLabels: (
  selectOptions: Array<FormSelectOption>,
  selectedValues: Array<string>
) => string[] = (selectOptions, selectedValues) =>
  flow([
    map((o: FormSelectOption) => {
      return [o.value, o.label]
    }),
    fromPairs,
    (obj) => pick(selectedValues, obj),
    values,
  ])(selectOptions)

export const getMultiSelectInput: (
  inputStyles?: StyleObject
) => ComponentType = (inputStyles = {}) =>
  withStyles((theme: Theme) =>
    createStyles({
      root: {
        'label + &': {
          marginTop: theme.spacing(3),
        },
      },
      input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: Color.White,
        border: `${Color.Gray} solid 1px`,
        fontSize: 14,
        padding: '11px 26px 7px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: "'Kumbh Sans', sans-serif",
        appearance: 'none',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 20px top 50%, 0 0',
        backgroundImage: `url(${FilterArrow})`,
        '&:focus': {
          borderRadius: 4,
          backgroundColor: Color.White,
          boxShadow: 'box-shadow: 0px 0px 0px 3px rgba(53, 176, 222, 0.3)',
        },
        ...inputStyles,
      },
    })
  )(InputBase)

const MultiSelectInput = getMultiSelectInput()

const MultiSelect: FunctionComponent<{
  name: string
  selectOptions: FormSelectOption[]
  onChange: (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    setOpen: (open: boolean) => void
  ) => void
  value: Array<string>
  Input?: ReactElement
  containerStyles?: StyleObject
}> = ({
  name,
  selectOptions,
  onChange,
  value,
  Input = <MultiSelectInput />,
  containerStyles = {},
}) => {
  const [open, setOpen] = useState<boolean>(false)

  // prevents `SyntaxError: '[id=a.b]' is not a valid selector`
  // our element names have periods from formik
  const id = name.replace('.', '-')
  return (
    <FormControl style={{ minWidth: 300, maxWidth: 450, ...containerStyles }}>
      <MaterialSelect
        name={name}
        id={id}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(e) => {
          onChange(e, setOpen)
        }}
        value={value}
        multiple
        input={Input}
        displayEmpty
        renderValue={(selectedValues: unknown) => {
          if (!Array.isArray(selectedValues)) {
            return null
          }

          const selectedLabels: string[] = getSelectedLabels(
            selectOptions,
            selectedValues
          )

          const element =
            selectedLabels.length === 0
              ? intlMessageForId('Common.MultiSelect.Help')
              : selectedLabels.join(', ')

          return (
            <div
              style={{
                paddingRight: '12px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {element}
            </div>
          )
        }}
        IconComponent={() => null}
      >
        [
        <MenuItem value="" disabled>
          {intlMessageForId('Common.MultiSelect.Help')}
        </MenuItem>
        {selectOptions.map((option: FormSelectOption) => (
          <MenuItem
            key={option.label}
            value={option.value}
            style={{ fontFamily: "'Kumbh Sans', sans-serif" }}
          >
            {option.label}
          </MenuItem>
        ))}
        ]
      </MaterialSelect>
    </FormControl>
  )
}

export default MultiSelect
