/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { StyleObject } from 'types'
import Color from 'types/color'
import PDFIcon from 'assets/svg/pdf.svg'
import PDFIconHover from 'assets/svg/pdf-hover.svg'

export const LabsPDF: React.FC<{ style?: StyleObject }> = ({ style = {} }) => (
  <div
    css={{
      width: 40,
      height: 22,
      transform: 'scale(1.5)',
      margin: '8px',
      backgroundImage: `url(${PDFIcon})`,
      backgroundPosition: 'center top',
      backgroundRepeat: 'no-repeat',
      ...style,

      '&:hover': {
        backgroundImage: `url(${PDFIconHover})`,
      },
    }}
  />
)

export type SVG = React.FC<{
  color?: Color | string
  scale?: number
  style?: StyleObject
}>

export const PDF: SVG = ({ color, scale, style }) => {
  return (
    <div style={{ transform: `scale(${scale})`, ...style }}>
      <svg
        width="16"
        height="16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M11.5 15.5a4 4 0 100-8 4 4 0 000 8z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5 9.5v4m0 0L13 12m-1.5 1.5L10 12m-4.5 1.5h-4a1 1 0 01-1-1v-11a1 1 0 011-1h7.086a1 1 0 01.707.293l1.914 1.914a1 1 0 01.293.707V5.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export const Play: SVG = ({ color = Color.Black, scale = 1, style = {} }) => {
  return (
    <div style={{ transform: `scale(${scale})`, ...style }}>
      <svg
        width="16"
        height="16"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        fill={color}
        id="Capa_1"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
      >
        <path
          className="st0"
          d="M256,9.6C119.9,9.6,9.6,119.9,9.6,256S119.9,502.4,256,502.4S502.4,392.1,502.4,256S392.1,9.6,256,9.6z   M256,477.5c-122.3,0-221.5-99.2-221.5-221.5S133.7,34.5,256,34.5S477.5,133.7,477.5,256S378.3,477.5,256,477.5z"
        />
        <polygon
          className="st0"
          points="365.5,256 212.8,408.7 212.8,256 212.8,103.3 "
        />
      </svg>
    </div>
  )
}
