import React, { FunctionComponent, ReactElement } from 'react'
import { DocumentNode, useQuery } from '@apollo/client'
import { capitalize, mapValues } from 'lodash'
import { intlMessageForId } from 'localization'
import { get } from 'utils'
import { AnyObject } from 'types'
import { Loading, Error } from 'components/Placeholders'

export type FacetName = 'commodity' | 'analyte' | 'location' | 'supplier'
export type FacetValue = { key: string; label: string }

export type Facets = Partial<{
  commodity: FacetValue[]
  analyte: FacetValue[]
  location: FacetValue[]
  supplier: FacetValue[]
}>

export type FacetsConfig = {
  [key: string]: {
    optional: boolean
  }
}

export const allOptionId: (facetName: FacetName) => string = (facetName) => {
  return `Charts.Filters.AllOption.${capitalize(facetName)}`
}

export const appendAllOption: (
  facets: Facets,
  config: FacetsConfig
) => Facets = (facets, config) =>
  (mapValues(facets, (values: FacetValue[] | null, key: FacetName) => {
    return config[key].optional
      ? [
          {
            key: intlMessageForId(allOptionId(key)),
            label: intlMessageForId(allOptionId(key)),
          },
          ...(values || []),
        ]
      : values
  }) as unknown) as Facets

const FacetsProvider: FunctionComponent<{
  query: DocumentNode
  facetsKey: string
  render: (facets: AnyObject) => ReactElement
}> = ({ query, facetsKey, render }) => {
  const { loading, error, data } = useQuery(query, {})

  if (loading) return <Loading testId="Loading" />
  if (error) return <Error />

  const facets = get(data, facetsKey, {})

  return render(facets)
}

export default FacetsProvider
