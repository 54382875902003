import React, {
  ButtonHTMLAttributes,
  ForwardRefExoticComponent,
  FunctionComponent,
  ReactElement,
  ReactNode,
  useRef,
} from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled from '@emotion/styled'
import { intlMessageForId } from 'localization'
import { get, isSet } from 'utils'
import {
  EventAction,
  EventCategory,
  EventLabel,
  trackEvent,
} from 'utils/analytics'
import { StyleObject } from 'types'
import Color from 'types/color'

type ButtonType = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> & {
  as?: ForwardRefExoticComponent<LinkProps>
  to?: string
  // onClick can take args of any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any[]) => void
  children: ReactNode
}

const StyledButton = styled.button`
  box-sizing: border-box;
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 16px;
  cursor: pointer;

  @media print {
    display: none;
  }
`

const BaseButton: FunctionComponent<ButtonType> = ({
  onClick,
  children,
  ...props
}) => {
  const ref = useRef(null)
  return (
    <StyledButton
      type="button"
      {...props}
      ref={ref}
      onClick={(e) => {
        const label =
          get(ref, 'current.innerText') ||
          get(ref, 'current.ariaLabel', EventLabel.Unknown)

        trackEvent(EventCategory.Button, EventAction.Click, label)

        // Executes the onClick action if set by the caller
        if (isSet(onClick)) {
          onClick(e)
        }
      }}
    >
      {children}
    </StyledButton>
  )
}

export const PrimaryButton = styled(BaseButton)`
  padding: 15px 20px;
  background-color: #38a538;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  border-radius: 4px;
  border: none;
  margin: 3px;

  :hover {
    background-color: #41be41;
  }

  :focus {
    outline: none;
    background-color: #41be41;
    border: 3px solid #ffffff;
    box-shadow: 0px 0px 0px 3px rgba(56, 165, 56, 0.3);
    margin: 0; // stops button from moving on focus
  }

  :active {
    background-color: #2f892f;
  }

  :disabled {
    background-color: #cbcacb;
    color: rgba(22, 42, 56, 0.6);
  }
`

export const SecondaryButton = styled(BaseButton)`
  padding: 15px 20px;
  background: #ffffff;
  border: 1px solid rgba(31, 148, 191, 0.5);
  color: rgba(31, 148, 191, 0.9);
  font-weight: bold;
  text-align: center;
  border-radius: 4px;
  margin: 3px;

  :hover {
    color: #1f94bf;
    border: 1px solid #1f94bf;
  }

  :focus {
    outline: none;
    color: #1f94bf;
    background: #ecf9fe;
    border: 3px solid #ffffff;
    box-shadow: 0px 0px 0px 3px rgba(53, 176, 222, 0.3);
  }

  :active {
    color: #176c8c;
    border: 1px solid #176c8c;
  }

  :disabled {
    color: rgba(22, 42, 56, 0.5);
    border: 1px solid #cbcacb;
  }
`

export const DeleteButton = styled(SecondaryButton)`
  :hover {
    color: ${Color.Error};
    border: 1px solid ${Color.Error};
  }
`

export const TextButton = styled(BaseButton)`
  color: ${Color.Black60};
  line-height: 22px;
  background-color: transparent;
  border: none;
  outline: none;

  :hover {
    opacity: 100%;
  }
`

export const ImgButton = styled(BaseButton)`
  background-color: transparent;
  border: none;
  outline: none;
`

const SmallButton = styled(BaseButton)`
  width: 100%;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 8px;
  border-radius: 2px;

  :focus {
    border: none;
  }
`

export const SmallPrimaryButton = SmallButton.withComponent(
  (props: ButtonType) => (
    <PrimaryButton {...props} style={{ paddingBottom: '9.5px' }} />
  )
)

export const SmallSecondaryButton = SmallButton.withComponent(SecondaryButton)

/*
  Link that looks like a Button
*/
export const LinkButton: FunctionComponent<{
  to: string
  Component?: FunctionComponent
  style?: StyleObject
  children: string | ReactElement
}> = ({ to, Component = PrimaryButton, children, style = {} }) => (
  <Component to={to} as={Link} style={style}>
    {children}
  </Component>
)

export const PrintButton: FunctionComponent<{ children?: ReactNode }> = ({
  children = intlMessageForId('Buttons.Print'),
}) => <PrimaryButton onClick={() => window.print()}>{children}</PrimaryButton>

export const ButtonLoadingIndicator: FunctionComponent = () => (
  <div
    style={{
      position: 'absolute',
      left: `calc(50% - 12px)`,
      top: `calc(50% - 12px)`,
    }}
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
    >
      <path
        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
        opacity=".25"
      />
      <path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z">
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="1.5s"
          values="0 12 12;360 12 12"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>
)
