import { compact, concat, flow } from 'lodash/fp'
import { intlMessageForId } from 'localization'
import { AnyObject } from 'types'
import { FormName } from 'types/forms'
import { mapObject } from 'utils'
import {
  ToggledControlName,
  ToggledControlState,
} from 'components/FormElements'
import { ToggledControlValue } from 'components/FormElements/ToggledControl'

export const NO_TEMPLATE_FIELDS = [FormName.When]

export const RANGE_FIELDS = [
  FormName.Weight,
  FormName.ResultNumeric,
  FormName.GMOSum,
]

export const INITIAL_VALUES_MAP = {
  range: {
    [ToggledControlName.Checked]: false,
    [ToggledControlName.Value]: { min: '', max: '' },
  },
  input: {
    [ToggledControlName.Checked]: false,
    [ToggledControlName.Value]: '',
  },
  select: {
    [ToggledControlName.Checked]: false,
    [ToggledControlName.Value]: '',
  },
  multiSelect: {
    [ToggledControlName.Checked]: false,
    [ToggledControlName.Value]: [],
  },
  emptyControl: {
    [ToggledControlName.Checked]: false,
  },
}

export const groupTitle: (title: string) => string = (title) =>
  intlMessageForId(`Reports.Form.SectionHeaders.${title}`)

export const fieldLabel: (field: string) => string = (field) =>
  intlMessageForId(`Reports.Form.Fields.${field}`)

export const getValue: (state: ToggledControlState) => ToggledControlValue = (
  state
) => {
  return state[ToggledControlName.Value]
}

export const getToggledValue: (
  state: ToggledControlState
) => ToggledControlValue | null = (state) => {
  return state[ToggledControlName.Checked]
    ? state[ToggledControlName.Value]
    : null
}

/**
 * returns the selected report builder fields for its api request
 * the order of columns here determines the order of columns in the csv
 * @param formValues form state
 * @returns array of selected fields
 */
export const getColumns: (formValues: AnyObject) => string[] = (formValues) =>
  flow([
    (arg) =>
      mapObject(arg, (key, value) => {
        return value[ToggledControlName.Checked] ? key : null
      }),
    compact,
    concat([FormName.When]), // always include the date range
  ])(formValues)
