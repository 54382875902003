import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Path } from 'types/enums'
import useFeatureFlags from 'hooks/useFeatureFlags'
import useCurrentUser from 'hooks/useCurrentUser'
import NotFound from 'components/NotFound'
import { Account, Homepage } from 'components/pages'
import Labs, {
  labsPaths,
  LabOrderDetails,
  SampleDetails,
  NewLabOrderContainer,
  EditLabOrderContainer,
} from 'components/pages/labs'
import Admin, { adminPaths } from 'components/pages/admin'
import Users from 'components/pages/admin/users/Users'
import AddUser from 'components/pages/admin/users/AddUser'
import EditUser from 'components/pages/admin/users/EditUser'
import Dashboard, { dashboardPaths } from 'components/pages/dashboard'
import HelpCenter, { helpCenterPaths } from 'components/pages/helpcenter'
import ReportsMenu from 'components/pages/ReportsMenu'
import { createReportSegments } from 'components/pages/reports'
import CreateAlert from 'components/pages/reports/Alerts/CreateAlertForm'
import { createQualitySegments } from 'components/pages/quality'
import AssignedTrainingDetails from 'components/pages/training/AssignedTrainingDetails'
import AssignTrainingForm from 'components/pages/training/AssignTrainingForm'
import { createAnalyticsQuickScanSegments } from 'components/pages/analytics/quickscan'
import AnalyticsQuickScanDetails from 'components/pages/analytics/quickscan/Details'
import QualityAndTrainingMenu from 'components/pages/QualityAndTrainingMenu'

import './App.css'

export const AppRoutes: React.FC = () => {
  const featureFlags = useFeatureFlags()
  const { isLabsCustomer } = useCurrentUser()

  if (!featureFlags) {
    return null
  }

  const { emailAlerts, operatorTraining, qualityReports } = featureFlags

  return (
    <Routes>
      <Route path={Path.Home} element={<Homepage />} />
      <Route
        path={Path.QuickScan}
        element={<Navigate to={dashboardPaths[0]} />}
      />
      {dashboardPaths.map((dashboardPath) => {
        return (
          <Route
            key={dashboardPath}
            path={dashboardPath}
            element={<Dashboard />}
          />
        )
      })}
      <Route
        path={`${Path.Labs_Orders}/:orderId/samples/:sampleId`}
        element={<SampleDetails />}
      />
      <Route
        path={`${Path.Labs_Orders}/new`}
        element={<NewLabOrderContainer />}
      />
      <Route
        path={`${Path.Labs_Orders}/:id/edit`}
        element={<EditLabOrderContainer />}
      />
      <Route path={`${Path.Labs_Orders}/:id`} element={<LabOrderDetails />} />
      <Route path={Path.Labs} element={<Navigate to={labsPaths[0]} />} />
      {labsPaths.map((labsPath) => {
        return <Route key={labsPath} path={labsPath} element={<Labs />} />
      })}
      <Route
        path={`${Path.AdminSettings_Users}/:id/edit`}
        element={<EditUser />}
      />
      <Route path={`${Path.AdminSettings_Users}/new`} element={<AddUser />} />
      <Route
        path={Path.AdminSettings}
        element={<Navigate to={adminPaths[0]} />}
      />
      {adminPaths.map((adminPath) => {
        return <Route key={adminPath} path={adminPath} element={<Admin />} />
      })}

      <Route path={Path.AdminSettings_Users} element={<Users />} />

      {createReportSegments({ emailAlerts }, isLabsCustomer)}
      {emailAlerts && (
        <Route path={`${Path.Reports_Alerts}/new`} element={<CreateAlert />} />
      )}
      <Route path={Path.Account} element={<Account />} />
      <Route
        path={Path.HelpCenter}
        element={<Navigate to={helpCenterPaths[0]} />}
      />

      {helpCenterPaths.map((helpCenterPath) => {
        return (
          <Route
            key={helpCenterPath}
            path={helpCenterPath}
            element={<HelpCenter />}
          />
        )
      })}
      {createQualitySegments(qualityReports, operatorTraining)}
      {operatorTraining && (
        <Route
          path={`${Path.Quality_AssignedTrainings}/:id`}
          element={<AssignedTrainingDetails />}
        />
      )}
      {operatorTraining && (
        <Route
          path={Path.Quality_AssignTrainingForm}
          element={<AssignTrainingForm />}
        />
      )}
      {createAnalyticsQuickScanSegments()}
      <Route
        path={`${Path.Analytics_QuickScan}/:id`}
        element={<AnalyticsQuickScanDetails />}
      />
      <Route
        path={Path.Menus_QualityAndTraining}
        element={<QualityAndTrainingMenu />}
      />
      <Route path={Path.Menus_Reports} element={<ReportsMenu />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
