import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { intlMessageForId } from 'localization'
import { Concern, Path } from 'types/enums'
import Color from 'types/color'
import SocialDistancing from 'components/SocialDistancing'
import LabResults from 'components/pages/labs/LabResults'

const SectionHeader = styled.header`
  color: ${Color.Black};
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
`

const RecentSampleResults: FunctionComponent<{
  concern?: Concern
}> = ({ concern }) => (
  <SocialDistancing spacing="32px">
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <SectionHeader>
        {intlMessageForId('Labs.Dashboard.SectionHeader.Text')}
      </SectionHeader>
      <Link
        to={Path.Labs_Results}
        style={{ paddingLeft: '16px', fontSize: '16px', lineHeight: '22px' }}
      >
        {intlMessageForId('Labs.Dashboard.SectionHeader.Link')}
      </Link>
    </div>
    <LabResults
      limit={5}
      paginate={false}
      showFilters={false}
      concern={concern}
    />
  </SocialDistancing>
)

export default RecentSampleResults
