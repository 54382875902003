import React from 'react'
import { ResponsivePie, PieDatum as NivoPieDatum } from '@nivo/pie'
import { map, flow, sum } from 'lodash/fp'
import Color from 'types/color'
import { isoDateToDateObject } from 'utils/time'
import { AnyObject, FormattedMessageId, ISODate } from 'types'
import { QuickscanRejectionDetail } from 'components/pages/dashboard/LoadAcceptance/utils'
import { formatNumber } from 'utils/format'
import Tooltip, { TooltipData } from 'components/charts/Tooltip'
import LabeledValue from 'components/charts/LabeledValue'
import { theme } from './utils'

type PieTooltip = {
  date: string
  rejectionDetails: Array<QuickscanRejectionDetail>
  data: TooltipData
}

type PieDatum = NivoPieDatum & {
  tooltip?: PieTooltip
}

export type PieData = Array<PieDatum>

const BACKGROUND_COLOR = Color.DarkBlue

export const PieOnly: React.FunctionComponent<{
  data: PieData
  props?: AnyObject
}> = ({ data, props = {} }) => (
  <ResponsivePie
    data={data}
    theme={{
      ...theme,
      ...{
        background: `${BACKGROUND_COLOR}`,
        labels: { text: { fontSize: '24px', fontWeight: 'bold' } },
        tooltip: {
          container: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        },
      },
    }}
    margin={{ top: 32, right: 0, bottom: 32, left: 0 }}
    colors={map('color')(data)}
    innerRadius={0.5}
    padAngle={2.0}
    cornerRadius={3}
    borderWidth={1}
    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
    enableRadialLabels={false}
    slicesLabelsSkipAngle={10}
    slicesLabelsTextColor={BACKGROUND_COLOR}
    animate
    motionStiffness={90}
    motionDamping={15}
    tooltip={(arg) => {
      const pieDatam = arg as PieDatum

      const {
        date: tooltipDate,
        data: tooltipData,
        rejectionDetails,
      } = pieDatam.tooltip as PieTooltip

      const date: string = isoDateToDateObject(
        tooltipDate as ISODate
      ).toLocaleDateString()

      return (
        <Tooltip
          date={date}
          data={tooltipData}
          rejectionDetails={rejectionDetails}
        />
      )
    }}
    {...props}
  />
)

const PieCenter: React.FunctionComponent<{
  labelId: FormattedMessageId
  value: string
  props?: AnyObject
}> = ({ labelId, value, props = {} }) => {
  const { valueSize } = props

  return (
    <div
      style={{
        zIndex: 1,
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        textAlign: 'center',
      }}
    >
      <LabeledValue
        labelId={labelId}
        value={value}
        invert
        valueSize={valueSize || 'Large'}
      />
    </div>
  )
}

export const PieWithCenter: React.FunctionComponent<{
  data: PieData
  pieProps?: AnyObject
  holeProps?: AnyObject
}> = ({ data, pieProps = {}, holeProps = {} }) => (
  <div
    style={{
      height: '100%',
      position: 'relative',
    }}
  >
    <PieCenter
      labelId="Charts.Pie.Center.Loads"
      value={flow([map('value'), sum], formatNumber)(data)}
      props={holeProps}
    />
    <PieOnly data={data} props={pieProps} />
  </div>
)

export default PieWithCenter
