import { Datum } from '@nivo/scatterplot'
import { BarDatum } from '@nivo/bar'
import { filter, groupBy, map, reject } from 'lodash'
import {
  findIndex as _findIndex,
  flatten as _flatten,
  flow as _flow,
  getOr as _getOr,
  groupBy as _groupBy,
  keys as _keys,
  map as _map,
  mapKeys as _mapKeys,
  mapValues as _mapValues,
  omit as _omit,
  pull as _pull,
  reduce as _reduce,
  sortBy as _sortBy,
  uniq as _uniq,
  values as _values,
} from 'lodash/fp'
import dayjs from 'dayjs'
import { AnyObject, ISODate, ISODateTime } from 'types'
import Color from 'types/color'
import { DateRange, LoadOutcome } from 'types/enums'
import { get, isSet, safeDivide } from 'utils'
import { formatNumber, formatResultText } from 'utils/format'
import {
  getDateArray,
  getDateRange,
  isoDateToDateObject,
  toIsoDate,
  toLocaleDate,
} from 'utils/time'
import { LoadAcceptanceSelectorOption } from 'components/charts/hooks/useLoadAcceptanceSelector'
import { PieData } from 'components/charts/Pie'
import {
  AcceptedAndDetected,
  AcceptedAndNotDetected,
  buildId,
  RejectedAndDetected,
  RejectedAndNotDetected,
  SeriesColors,
} from 'components/charts/DivergingBar/utils'

const getUnit: (average?: { value: number; unit: string }) => string = (
  average
) => {
  return get(average as AnyObject, 'unit', '')
}

type FormattedDataType = Array<
  Datum & {
    resultText?: string
    sampleId?: string
    location?: string
    supplier?: string
    accepted?: boolean
  }
>

type Serie = {
  id: string
  data: FormattedDataType
}

type QuickscanLoadConcentration = {
  analyte?: string
  weightedAverage?: {
    value: number
    unit: string
  }
  average?: {
    value: number
    unit: string
  }
  bushelsAccepted?: number
  measurements: Array<ConcentrationMeasurement>
  hasQualResults: boolean
  hasQuantResults: boolean
}

export type QuickscanLoadConcentrationQueryResponse = {
  quickscanLoadConcentration: QuickscanLoadConcentration
}

export type ResultQualIndexType = '0' | '1' | ''

export type ConcentrationMeasurement = {
  id: number
  recordedAt: ISODateTime
  resultNumber: number
  resultText: string
  unit: string | null
  analyte: string
  sampleId: string
  resultQualIndex: ResultQualIndexType
  location?: string
  supplier?: string
  accepted?: boolean
}

type ParsePropsFunc = (
  data: QuickscanLoadConcentrationQueryResponse,
  selectedLoadAcceptance: LoadAcceptanceSelectorOption,
  dateRange: DateRange,
  startTime: ISODateTime,
  endTime: ISODateTime,
  isQualitative: boolean
) => {
  hasResults: boolean
  hasQualResults: boolean
  hasQuantResults: boolean
  formattedData: {
    chartType: 'DivergingBar' | 'Scatterplot'
    chartData: Array<Serie> | Array<BarDatum>
    // for 'DivergingBar' only
    tooltipData?: DivergingBarTooltipDataType
    // for 'DivergingBar' only
    overviewData?: {
      percentNotDetected: string
      pieData: PieData
    }
  }
  yAxisUnits?: string
  formattedWeightedAverage?: string
  formattedAverage?: string
  formattedBushelsAccepted?: string
  formattedLoads?: string
}

const filterMeasurements: (
  measurements: ConcentrationMeasurement[],
  selectedLoadAcceptance: LoadAcceptanceSelectorOption
) => Array<ConcentrationMeasurement> = (
  measurements,
  selectedLoadAcceptance
) => {
  if (selectedLoadAcceptance === LoadAcceptanceSelectorOption.All) {
    return measurements
  }

  if (selectedLoadAcceptance === LoadAcceptanceSelectorOption.Accepted) {
    return filter(measurements, 'accepted')
  }

  if (selectedLoadAcceptance === LoadAcceptanceSelectorOption.Rejected) {
    return reject(measurements, 'accepted')
  }

  return []
}

const getScatterplotFormattedData: (
  measurments: Array<ConcentrationMeasurement>
) => FormattedDataType = (measurements) => {
  return map(measurements, (measurement: ConcentrationMeasurement) => {
    // `resultNumber` for charts and `resultText` for tooltip to support special-case results like '< LOD'
    const {
      recordedAt,
      resultNumber,
      resultText,
      sampleId,
      location,
      supplier,
      accepted,
    } = measurement

    return {
      x: recordedAt,
      y: resultNumber,
      resultText: formatResultText(resultText),
      sampleId,
      location,
      supplier,
      accepted,
    }
  })
}

type ConfigTypeKeys = 'day' | 'month'

type ConfigType = {
  interval: ConfigTypeKeys
  groupByFunc: (date: Date) => string
  dateFormatFunc: (isoDate: ISODate) => string
  tooltipDateFormatFunc: (isoDate: ISODate) => string
}

type DivergingBarDatum = { [key: string]: ISODate | string }

export const CONFIG: { [key in ConfigTypeKeys]: ConfigType } = {
  day: {
    interval: 'day' as const,
    groupByFunc: (date: Date): ISODate => {
      return toIsoDate(date)
    },
    dateFormatFunc: (isoDate: ISODate): string => {
      return dayjs(isoDate).date() === 1
        ? toLocaleDate(isoDate, {
            month: 'short',
            day: 'numeric',
          })
        : toLocaleDate(isoDate, {
            day: 'numeric',
          })
    },
    tooltipDateFormatFunc: (isoDate: ISODate): string => {
      return toLocaleDate(isoDate, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
    },
  },
  month: {
    interval: 'month' as const,
    groupByFunc: (date: Date): ISODate => {
      return toIsoDate(date, 'month')
    },
    dateFormatFunc: (isoDate: ISODate): string => {
      return toLocaleDate(isoDate, {
        month: 'short',
      })
    },
    tooltipDateFormatFunc: (isoDate: ISODate): string => {
      return toLocaleDate(isoDate, {
        month: 'short',
        year: 'numeric',
      })
    },
  },
}

const isDetected: (resultQualIndex: ResultQualIndexType) => boolean = (
  resultQualIndex
) => {
  return resultQualIndex === '1'
}

export const getChartKeys: (
  resultQualIndex: ResultQualIndexType
) => Array<{
  isAccepted: boolean
  isDetected: boolean
  label: string
}> = (resultQualIndex) => {
  const detected = isDetected(resultQualIndex)
  const isAcceptedOptions = [false, true]

  return isAcceptedOptions.map((isAcceptedOption: boolean) => ({
    isDetected: detected,
    isAccepted: isAcceptedOption,
    label: buildId(detected, isAcceptedOption),
  }))
}

const listByKey = (array: Array<ConcentrationMeasurement>, key: string) => {
  return _flow([_groupBy(key), _keys])(array)
}

type GroupMeasurementType = {
  [date: string]: {
    [result: string]: {
      true: Array<ConcentrationMeasurement>
      false: Array<ConcentrationMeasurement>
    }
  }
}

export const groupMeasurements: (
  measurments: Array<ConcentrationMeasurement>,
  config: ConfigType
) => GroupMeasurementType = (measurements, config) => {
  return _flow([
    // group measurements by date
    _groupBy((concentrationMeasurement: ConcentrationMeasurement) => {
      const date = isoDateToDateObject(concentrationMeasurement.recordedAt)
      return config.groupByFunc(date)
    }),
    _mapValues((dateGroup: Array<ConcentrationMeasurement>) => {
      return _flow([
        // group date-groups by result text (detected and not detected)
        _groupBy('resultQualIndex'),
        _mapValues(
          // group detected-groups by load acceptance (accepted and rejected)
          _groupBy('accepted')
        ),
      ])(dateGroup)
    }),
  ])(measurements)
}

export const getDivergingBarFormattedData: (
  groupedMeasurements: GroupMeasurementType,
  config: ConfigType,
  startTime: ISODateTime,
  endTime: ISODateTime
) => Array<BarDatum> = (groupedMeasurements, config, startTime, endTime) => {
  return _flow([
    _mapValues((dateGroup: Array<ConcentrationMeasurement>) => {
      return _flow([
        _mapValues(
          _flow([
            // sum load acceptance for each group
            _mapValues((value: Array<ConcentrationMeasurement>) => {
              return value.length
            }),
          ])
        ),
        (arg) => {
          // create data structure for chart
          return _map((entry: [string, { true: number; false: number }]) => {
            const resultQualIndex = entry[0] as ResultQualIndexType
            const [firstKey, secondKey] = getChartKeys(resultQualIndex)
            const obj = entry[1]

            return {
              [firstKey.label]:
                get(obj, String(firstKey.isAccepted), 0) *
                (firstKey.isDetected ? 1 : -1),
              [secondKey.label]:
                get(obj, String(secondKey.isAccepted), 0) *
                (secondKey.isDetected ? 1 : -1),
            }
          })(Object.entries(arg))
        },
        (arg) => {
          // ungroup by detected and not detected for each date group
          return _reduce(
            (sum, n) => {
              return { ...sum, ...n }
            },
            {},
            arg
          )
        },
        (arg) => {
          // sum the number of measurments in each date group
          const sum = _flow([
            _values,
            (v) => {
              return _reduce(
                (a, b) => {
                  return a + Math.abs(b)
                },
                0,
                v
              )
            },
          ])(arg)

          // convert to percentage
          const percentages = _mapValues((value: number) => {
            return formatNumber((value / sum) * 100)
          })(arg)

          return percentages
        },
      ])(dateGroup)
    }),
    (arg) => {
      // ungroup by date
      return _map((entry: [ISODate, { [key: string]: string }]) => {
        return { date: entry[0], ...entry[1] }
      })(Object.entries(arg))
    },
    (arg) => {
      // create obj of all chart keys with values set to zero
      // { [key]: 0 } for all available keys
      const baseObject: Array<DivergingBarDatum> = _flow([
        _map((datum: DivergingBarDatum) => {
          const k = Object.entries(datum).map((d) => {
            return d[0]
          })
          return _pull('date', k)
        }),
        _flatten,
        _uniq,
        _map((key: string) => {
          return { [key]: '0' }
        }),
        (x) => {
          return _reduce(
            (sum, n) => {
              return { ...sum, ...n }
            },
            {},
            x
          )
        },
      ])(arg)

      // make sure each chart datum has all of the keys
      // otherwise nivo throws a console error if a key is present in one data set but missing in others
      const withAllKeys = (_map((datum: DivergingBarDatum) => {
        return { ...baseObject, ...datum }
      })(arg) as unknown) as Array<DivergingBarDatum>

      // all dates from start to end of time interval
      const dateArray = getDateArray(startTime, endTime, 1, config.interval)

      // all dates in time interval with all result keys set to zero
      const withEmptyDatesFilled = (_map((date) => {
        return {
          date,
          ...baseObject,
        }
      })(dateArray) as unknown) as Array<DivergingBarDatum>

      // fill in dates without measurements so chart shows complete date range
      const filledData = _map((date: DivergingBarDatum) => {
        const index = _findIndex((r: DivergingBarDatum) => {
          // is there data for this date
          return r.date === date.date
        })(withAllKeys)

        return index === -1 ? date : withAllKeys[index]
      })(withEmptyDatesFilled)

      return _flow([
        // sort by date so chart is in chronological order
        _sortBy('date'),
        // format date strings so the axis is easier to read by adding formattedDate key
        _map((d: DivergingBarDatum) => {
          return {
            ...d,
            formattedDate: config.dateFormatFunc(d.date),
          }
        }),
        // remove date key (no longer needed)
        _map((d: DivergingBarDatum) => {
          return _omit('date')(d)
        }),
      ])(filledData)
    },
  ])(groupedMeasurements)
}

export type DivergingBarTooltipDataType = {
  [dateGroup: string]: {
    [chartSegmentTitle: string]: {
      formattedDate: string
      byLoad: string | number
      byLocation: string | number
      bySupplier: string | number
    }
  }
}

export const getDivergingBarTooltipData: (
  groupedMeasurements: GroupMeasurementType,
  config: ConfigType
) => DivergingBarTooltipDataType = (groupedMeasurements, config) => {
  return _flow([
    _mapValues((dateGroup: Array<ConcentrationMeasurement>) => {
      return _flow([
        (arg) => {
          // create data structure for chart
          return _map((entry: [string, { true: number; false: number }]) => {
            const resultQualIndex = entry[0] as ResultQualIndexType
            const [firstKey, secondKey] = getChartKeys(resultQualIndex)
            const obj = entry[1]

            return {
              [firstKey.label]: get(obj, String(firstKey.isAccepted), []),
              [secondKey.label]: get(obj, String(secondKey.isAccepted), []),
            }
          })(Object.entries(arg))
        },
        (arg) => {
          // ungroup by detected and not detected for each date group
          return _reduce(
            (sum, n) => {
              return { ...sum, ...n }
            },
            {},
            arg
          )
        },
        (arg) => {
          // return the number of elements in each group
          return _mapValues((value: Array<ConcentrationMeasurement>) => {
            const formattedDate = config.tooltipDateFormatFunc(
              // already grouped by date so we can get it from the first element
              value[0]?.recordedAt
            )

            const suppliers = listByKey(value, 'supplier')
            const locations = listByKey(value, 'location')
            const maxToRender = 2

            return {
              formattedDate,
              byLoad: value.length,
              bySupplier:
                suppliers.length > maxToRender
                  ? suppliers.length
                  : suppliers.join(', '),
              byLocation:
                locations.length > maxToRender
                  ? locations.length
                  : locations.join(', '),
            }
          })(arg)
        },
      ])(dateGroup)
    }),
    _mapKeys((key: string) => {
      return config.dateFormatFunc(key)
    }),
  ])(groupedMeasurements)
}

export const getPercentNotDetected: (
  measurements: Array<ConcentrationMeasurement>
) => string = (measurements) => {
  const byDetected = _groupBy((m: ConcentrationMeasurement) => {
    return isDetected(m.resultQualIndex)
  })(measurements)

  return safeDivide(
    get(byDetected, 'false.length', 0),
    get(measurements, 'length', 0),
    {
      asPercent: true,
      precision: 0,
    }
  )
}

export const parseProps: ParsePropsFunc = (
  data,
  selectedLoadAcceptance,
  dateRange,
  startTime,
  endTime,
  isQualitative
) => {
  const {
    measurements,
    bushelsAccepted,
    weightedAverage,
    average,
    hasQualResults,
    hasQuantResults,
  } = _getOr(
    {},
    'quickscanLoadConcentration',
    data
  ) as QuickscanLoadConcentration

  const hasResults = measurements && measurements.length > 0

  const loads =
    measurements &&
    filterMeasurements(measurements, selectedLoadAcceptance).length

  const yAxisUnits = getUnit(average)

  const formattedWeightedAverage =
    weightedAverage &&
    `${formatNumber(weightedAverage.value, 1)} ${getUnit(weightedAverage)}`

  const formattedAverage =
    average && `${formatNumber(average.value, 1)} ${getUnit(average)}`

  const formattedBushelsAccepted = isSet(bushelsAccepted)
    ? formatNumber(bushelsAccepted as number)
    : undefined

  const formattedLoads = formatNumber(loads)

  const getFormattedData = () => {
    if (isQualitative) {
      const standardDateRange = DateRange.Custom
        ? getDateRange(startTime, endTime)
        : dateRange

      const divergingBarInterval =
        standardDateRange === DateRange.Year ? 'month' : 'day'

      const config = CONFIG[divergingBarInterval]
      const groupedMeasurements = groupMeasurements(measurements, config)

      const byBoth = _groupBy((m: ConcentrationMeasurement) => {
        const detected = isDetected(m.resultQualIndex)
        const accepted = Boolean(m.accepted)
        return buildId(detected, accepted)
      })(measurements)

      return {
        chartType: 'DivergingBar' as const,
        chartData: getDivergingBarFormattedData(
          groupedMeasurements,
          config,
          startTime,
          endTime
        ),
        tooltipData: getDivergingBarTooltipData(groupedMeasurements, config),
        overviewData: {
          percentNotDetected: getPercentNotDetected(measurements),
          pieData: [
            {
              id: AcceptedAndDetected,
            },
            {
              id: AcceptedAndNotDetected,
            },
            {
              id: RejectedAndDetected,
            },
            {
              id: RejectedAndNotDetected,
            },
          ].map((element: { id: string }) => {
            const { id } = element
            return {
              id,
              value: get(byBoth, `${id}.length`, 0),
              color: SeriesColors[id],
            }
          }),
        },
      }
    }

    const grouped = groupBy(measurements, 'accepted')
    return {
      chartType: 'Scatterplot' as const,
      chartData: [
        {
          id: LoadOutcome.Rejected,
          data: getScatterplotFormattedData(grouped.false),
          color: Color.Rejected,
        },
        {
          id: LoadOutcome.Accepted,
          data: getScatterplotFormattedData(grouped.true),
          color: Color.Accepted,
        },
      ],
    }
  }

  return {
    hasResults,
    hasQuantResults,
    hasQualResults,
    formattedData: getFormattedData(),
    yAxisUnits,
    formattedWeightedAverage,
    formattedAverage,
    formattedBushelsAccepted,
    formattedLoads,
  }
}
