import React, { ReactElement } from 'react'
import { intlMessageForId } from 'localization'
import { Path } from 'types/enums'
import { getSegmentedPageWithRoutes } from 'components/SegmentedPage'
import AssignedTrainings from 'components/pages/training/AssignedTrainings'
import QuickScanCalibration from './QuickScanCalibration'

export const createQualitySegments: (
  isQualityReportsEnabled: boolean,
  isOperatorTrainingEnabled: boolean
) => Array<ReactElement> = (
  isQualityReportsEnabled,
  isOperatorTrainingEnabled
) => {
  const segments = []

  if (isQualityReportsEnabled) {
    segments.push({
      key: 'QuickScanCalibration',
      title: intlMessageForId('Quality.Segments.QuickScanCalibration'),
      path: Path.Quality_QuickScanCalibration,
      component: <QuickScanCalibration />,
    })
  }

  if (isOperatorTrainingEnabled) {
    segments.push({
      key: 'Assigned Trainings',
      title: intlMessageForId('Quality.Segments.AssignedTrainings'),
      path: Path.Quality_AssignedTrainings,
      component: <AssignedTrainings />,
    })
  }

  return getSegmentedPageWithRoutes({
    testId: 'Quality',
    title: intlMessageForId('Quality.Header'),
    rootPath: Path.Quality,
    segments,
  })
}
