import React, { ReactElement } from 'react'
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { LOGIN_URL } from 'constants/index'

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  credentials: 'include',
})

const logoutLink = onError(({ networkError }: ErrorResponse) => {
  if (
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401 &&
    LOGIN_URL
  ) {
    window.location.href = LOGIN_URL
  }
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: logoutLink.concat(httpLink),
})

const Provider: React.FunctionComponent<{
  children: ReactElement
}> = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
)

export default Provider
