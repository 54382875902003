import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { flow, map, merge, reduce } from 'lodash/fp'
import { ToggledControlName } from 'components/FormElements'
import { Loading, Error } from 'components/Placeholders'
import { FormName } from 'types/forms'
import LabOrderForm from '.'
import {
  LAB_ORDER_FORM_QUERY,
  LAB_ORDER_QUERY,
  UPDATE_LAB_ORDER_MUTATION,
} from './gql'
import {
  getDefaultSettings,
  getSelectedTestStateFromAssays,
  visibleQuantitativeAssays,
} from './utils'
import { LabAssay, Sample } from '../LabOrderDetails'

const EditLabOrderContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const { loading, error, data } = useQuery(LAB_ORDER_FORM_QUERY, {
    fetchPolicy: 'no-cache',
  })

  const {
    loading: labOrderLoading,
    error: labOrderError,
    data: labOrderData,
  } = useQuery(LAB_ORDER_QUERY, {
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  })

  if (loading || labOrderLoading) return <Loading />
  if (error || labOrderError) return <Error />

  const { labCustomers, labAssays } = data

  const defaultSettings = {
    emailSettings: getDefaultSettings(data, 'emailSettings'),
    sampleTypeSettings: getDefaultSettings(data, 'sampleTypeSettings'),
  }

  const {
    labOrder: {
      customer,
      location,
      reportContact,
      poNum: poNumber,
      specialInstructions,
      samples,
    },
  } = labOrderData

  const sortedSamples = [...samples].sort((a: Sample, b: Sample) => {
    return a.id.localeCompare(b.id)
  })

  const getEmailAtIndex: (index: number) => string = (index) => {
    const emails = reportContact?.split(';')
    return emails?.length ? emails[index] : ''
  }

  const formSamples = sortedSamples.map((sample: Sample) => {
    // sample commodity is required for form submission
    const commodity = sample.sampleCommodity as string

    const initialTestState = flow([
      map((assay: LabAssay) => {
        // default everything to unchecked and quantitative
        return {
          [assay.analyte]: {
            [ToggledControlName.Checked]: false,
            [ToggledControlName.Value]: assay.id,
          },
        }
      }),
      reduce(merge, {}),
      (defaults) => {
        const checked = getSelectedTestStateFromAssays(sample.assays)
        return { ...defaults, ...checked }
      },
    ])(visibleQuantitativeAssays(commodity, labAssays))

    return {
      [FormName.Id]: sample.id,
      [FormName.SampleId]: sample.description,
      [FormName.Commodity]: sample.sampleCommodity,
      [FormName.SampleType]: sample.sampleType,
      [FormName.OvernightRush]: String(sample.rush),
      [FormName.NoGmoSum]: sample.noGmoSum,
      [FormName.Tests]: initialTestState,
      [FormName.SampleComments]: sample.sampleComments || '',
    }
  })

  const initialValues = {
    [FormName.CustomerLocation]: String(customer?.qbenchCustomerId),
    [FormName.EnvirologixLocation]: location,
    [FormName.Email1]: getEmailAtIndex(0),
    [FormName.Email2]: getEmailAtIndex(1),
    [FormName.Email3]: getEmailAtIndex(2),
    [FormName.PoNumber]: poNumber || '',
    [FormName.Comments]: specialInstructions || '',
    [FormName.Terms]: false,
    [FormName.Samples]: formSamples || [],
  }

  return (
    <LabOrderForm
      id={Number(id)}
      labAssays={labAssays}
      labCustomers={labCustomers}
      defaultSettings={defaultSettings}
      initialValues={initialValues}
      mutation={UPDATE_LAB_ORDER_MUTATION}
      testId="EditLabOrderContainer"
    />
  )
}

export default EditLabOrderContainer
