import React, { FC, ReactNode } from 'react'
import { gql, useMutation } from '@apollo/client'
import { PrimaryButton } from 'components/Button'
import {
  ErrorMessage,
  ToggledInput,
  ToggledSelect,
} from 'components/FormElements'
import { FormSelectOption } from 'components/FormElements/FormSelect'
import { ToggledControlName } from 'components/FormElements/ToggledControl'
import { intlMessageForId } from 'localization'
import { ID } from 'types'
import { get, openUrl } from 'utils'

const AnalyticsComponent: FC<{
  children: ReactNode
  formName: string
}> = ({ children, formName }) => (
  <div>
    {children}
    <div style={{ float: 'right' }}>
      <ErrorMessage forName={`${formName}.${ToggledControlName.Value}`} />
    </div>
  </div>
)

export const AnalyticsSelect: FC<{
  formName: string
  label: string
  selectOptions: Array<FormSelectOption>
  defaultChecked?: boolean
  required?: boolean
  multiple?: boolean
}> = ({
  formName,
  label,
  selectOptions,
  defaultChecked,
  required = false,
  multiple = false,
}) => (
  <AnalyticsComponent formName={formName}>
    <ToggledSelect
      name={formName}
      label={label}
      selectOptions={selectOptions}
      defaultChecked={required || defaultChecked}
      required={required}
      emptyState={intlMessageForId('Common.Select.Help')}
      multiple={multiple}
    />
  </AnalyticsComponent>
)

export const AnalyticsInput: FC<{
  formName: string
  label: string
  tooltip?: string
}> = ({ formName, label, tooltip }) => (
  <AnalyticsComponent formName={formName}>
    <ToggledInput name={formName} label={label} tooltip={tooltip} />
  </AnalyticsComponent>
)

const DOWNLOAD_QUICKSCAN_BI_REPORT_MUTATION = gql`
  mutation($reportId: ID!) {
    downloadQuickscanBiReport(id: $reportId)
  }
`

export const DownloadReport: FC<{
  reportId: ID
}> = ({ reportId }) => {
  const [getReport] = useMutation(DOWNLOAD_QUICKSCAN_BI_REPORT_MUTATION, {
    variables: {
      reportId,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const url = get(data, 'downloadQuickscanBiReport')
      openUrl(url)
    },
    onError: () => {
      alert(intlMessageForId('ErrorMessages.404'))
    },
  })

  const handleClick = () => {
    getReport({
      variables: { reportId },
    })
  }

  return (
    <PrimaryButton onClick={handleClick}>{`${intlMessageForId(
      'Reports.CTAs.DownloadReport'
    )} (.csv)`}</PrimaryButton>
  )
}
