import React, { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { intlMessageForId } from 'localization'
import Color from 'types/color'
import FormInput, {
  AutoComplete,
  FormInputProps,
  Textarea,
} from 'components/FormElements/FormInput'
import FormSelect from 'components/FormElements/FormSelect'
import FormCheckbox from 'components/FormElements/FormCheckbox'
import ErrorMessage from 'components/FormElements/ErrorMessage'
import FormRadioButtons from 'components/FormElements/FormRadioButtons'
import {
  ToggledSelect,
  ToggledInput,
  ToggledRange,
  ToggledControlName,
  ToggledControlState,
} from 'components/FormElements/ToggledControl'

export const FieldLabel = styled.label`
  color: ${Color.Black60};
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`

export const REQUIRED_ERROR_MESSAGE = intlMessageForId(
  'ErrorMessages.RequiredField'
)
export const REQUIRED_NUMERIC_ERROR_MESSAGE = intlMessageForId(
  'ErrorMessages.RequiredNumericField'
)
export const REQUIRED_IF_SELECTED_ERROR_MESSAGE = intlMessageForId(
  'ErrorMessages.RequiredFieldIfSelected'
)
export const VALID_EMAIL_ERROR_MESSAGE = intlMessageForId(
  'ErrorMessages.InvalidEmail'
)

export const getExceededMaxLengthMessage: (maxLength: number) => string = (
  maxLength
) => intlMessageForId('ErrorMessages.ExceededMaxLength', { maxLength })

export {
  AutoComplete,
  FormInput,
  Textarea,
  FormSelect,
  FormCheckbox,
  ErrorMessage,
  FormRadioButtons,
  ToggledSelect,
  ToggledInput,
  ToggledRange,
  ToggledControlName,
}

export type { ToggledControlState }

export const PasswordInput: FunctionComponent<Omit<FormInputProps, 'type'>> = (
  props
) => <FormInput style={{ width: '100%' }} {...props} type="password" />
