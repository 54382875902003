import React, { FunctionComponent, ReactNode } from 'react'
import styled from '@emotion/styled'
import Color from 'types/color'
import SocialDistancing from 'components/SocialDistancing'
import Tooltip from './Tooltip'

const StyledHeader = styled.h3`
  color: ${Color.Black60};
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding-bottom: 16px;
`
const Group: FunctionComponent<{
  title?: string
  children?: ReactNode
  tooltip?: string
}> = ({ title, children, tooltip }) => (
  <div>
    {children && title && (
      <Tooltip copy={tooltip}>
        <StyledHeader>{title}</StyledHeader>
      </Tooltip>
    )}
    <SocialDistancing spacing="8px">{children}</SocialDistancing>
  </div>
)

export default Group
