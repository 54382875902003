import React from 'react'
import styled from '@emotion/styled'
import { StyleObject } from 'types'

const Container = styled.div`
  margin-right: 16px;
  margin-top: 4px;
`

const Indicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #c31f1b;
`

const ReadIndicator: React.FunctionComponent<{
  hide?: boolean
  style?: StyleObject
  indicatorStyle?: StyleObject
}> = ({ hide = false, style = {}, indicatorStyle = {} }) => (
  <Container
    style={{
      ...style,
      visibility: hide ? 'hidden' : 'visible',
    }}
  >
    <Indicator style={indicatorStyle} />
  </Container>
)

export default ReadIndicator
