import { join } from 'lodash'
import Color from 'types/color'
import { ACCEPTED, DETECTED, NOT_DETECTED, REJECTED } from './constants'

const SEPARATOR = ' - '

export const buildId: (isDetected: boolean, isAccepted: boolean) => string = (
  isDetected,
  isAccepted
) => {
  const head = isDetected ? DETECTED : NOT_DETECTED
  const tail = isAccepted ? ACCEPTED : REJECTED
  return join([head, tail], SEPARATOR)
}

export const AcceptedAndNotDetected = buildId(false, true)
export const AcceptedAndDetected = buildId(true, true)
export const RejectedAndNotDetected = buildId(false, false)
export const RejectedAndDetected = buildId(true, false)

export const SeriesColors: Record<string, string> = {
  [AcceptedAndNotDetected]: '#2471A3',
  [AcceptedAndDetected]: '#AE5C5A',
  [RejectedAndNotDetected]: '#7FB3D5',
  [RejectedAndDetected]: Color.Rejected,
}
