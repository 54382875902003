import React, { FC } from 'react'
import styled from '@emotion/styled'
import { capitalize } from 'lodash'
import { NavigateFunction } from 'react-router-dom'
import { intlMessageForId } from 'localization'
import { PrimaryButton } from 'components/Button'
import { SectionHeader } from 'components/Section'
import Color from 'types/color'
import { Path } from 'types/enums'
import { FormName } from 'types/forms'
import { FormInput } from '.'

const hoverBorder = '1px solid #1f94bf'

const focusBorder = `1px solid ${Color.Black}`

export const sharedStyles = `
  :hover {
    border: ${hoverBorder};
  }
  :focus {
    border: ${focusBorder};
  }
`

export const ContentHeader = styled.h3`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`

export const Subheader = styled.div`
  color: ${Color.Black};
  font-size: 0.625rem;
  font-weight: 700;
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
  opacity: 0.6;
`

export const RunReportHeader: FC = () => (
  <SectionHeader style={{ whiteSpace: 'nowrap' }}>
    {intlMessageForId('Reports.SectionHeaders.RunReport')}
  </SectionHeader>
)

const dateProps = {
  type: 'date',
  style: { width: '100%' },
}

export const StartDate: FC = () => (
  <FormInput
    {...dateProps}
    name={`${FormName.When}.min`}
    label={intlMessageForId('Common.StartDate')}
  />
)

export const EndDate: FC = () => (
  <FormInput
    {...dateProps}
    name={`${FormName.When}.max`}
    label={intlMessageForId('Common.EndDate')}
  />
)

export const DownloadReport: FC<{ label?: string }> = ({ label }) => (
  <PrimaryButton
    type="submit"
    style={{ marginLeft: 0, marginRight: 0, width: '100%' }}
  >
    {label || `${intlMessageForId('Reports.CTAs.DownloadReport')} (.csv)`}
  </PrimaryButton>
)

export const EmailInput: FC = () => (
  <FormInput
    name={FormName.Email}
    label={intlMessageForId('Admin.FieldLabels.Email')}
    placeholder={intlMessageForId('Admin.Placeholders.Email')}
  />
)

export const FirstNameInput: FC = () => (
  <FormInput
    name={FormName.FirstName}
    label={intlMessageForId('Admin.FieldLabels.FirstName')}
    placeholder={intlMessageForId('Admin.Placeholders.FirstName')}
  />
)

export const LastNameInput: FC = () => (
  <FormInput
    name={FormName.LastName}
    label={intlMessageForId('Admin.FieldLabels.LastName')}
    placeholder={intlMessageForId('Admin.Placeholders.LastName')}
  />
)

export const handleCompleted: (
  navigate: NavigateFunction,
  path: Path | Location,
  message: string
) => void = (navigate, path, message) =>
  navigate(path, {
    state: {
      status: 'success',
      message,
    },
    replace: true,
  })

export const handleError: (
  navigate: NavigateFunction,
  path: Path | Location | string,
  errorMessage?: string,
  formatErrorMessage?: boolean
) => void = (navigate, path, errorMessage, formatErrorMessage = true) => {
  const message =
    errorMessage && formatErrorMessage ? capitalize(errorMessage) : errorMessage

  navigate(path, {
    state: {
      status: 'error',
      message: errorMessage
        ? message
        : intlMessageForId('ErrorMessages.General'),
    },
    replace: true,
  })
}
