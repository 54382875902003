import dayjs from 'dayjs'
import { camelCase, filter, flow, fromPairs, includes, map } from 'lodash/fp'
import { AnyObject } from 'types'
import { FormName } from 'types/forms'
import { get, getFloat, isSet } from 'utils'
import { ToggledControlName } from 'components/FormElements'
import { ExportFormat, RangeValueType, Template } from '../types'
import {
  getColumns,
  getValue,
  NO_TEMPLATE_FIELDS,
  RANGE_FIELDS,
} from '../utils'

export type PayloadType = {
  columns: Array<string>
  filters: Record<string, string | Array<string> | undefined>
  mergeCombResults: boolean
  pdfReport: boolean
}

export const toApiFormat = (
  formikValues: AnyObject,
  includeWhen: boolean
): PayloadType => {
  const {
    ACTION,
    ANALYTE,
    ANALYTE_ERROR,
    CASSETTE_NAME,
    COMMENT1,
    COMMENT2,
    COMMODITY,
    GMO_SUM,
    LOCATION,
    BIN,
    LOT_NUMBER,
    OPERATOR,
    RESULT_NUMERIC,
    RESULT_TEXT,
    RESULT_UNIT,
    SAMPLE_ID,
    SUPPLIER,
    WEIGHT,
    WEIGHT_UNIT,
    WHEN,
    EXPORT_FORMAT,
  } = formikValues

  const columns = getColumns(formikValues).filter(
    (column) => column !== FormName.MergeCombResults
  )

  const filters: AnyObject = {
    ...{
      action: getValue(ACTION),
      analyte: getValue(ANALYTE),
      analyteError: getValue(ANALYTE_ERROR),
      cassetteName: getValue(CASSETTE_NAME),
      comment1: getValue(COMMENT1),
      comment2: getValue(COMMENT2),
      commodity: getValue(COMMODITY),
      gmoSumMax: getFloat(getValue(GMO_SUM) as RangeValueType, 'max'),
      gmoSumMin: getFloat(getValue(GMO_SUM) as RangeValueType, 'min'),
      location: getValue(LOCATION),
      bin: getValue(BIN),
      lotNumber: getValue(LOT_NUMBER),
      operator: getValue(OPERATOR),
      resultNumericMax: getFloat(
        getValue(RESULT_NUMERIC) as RangeValueType,
        'max'
      ),
      resultNumericMin: getFloat(
        getValue(RESULT_NUMERIC) as RangeValueType,
        'min'
      ),
      resultText: getValue(RESULT_TEXT),
      resultUnit: getValue(RESULT_UNIT),
      sampleId: getValue(SAMPLE_ID),
      supplier: getValue(SUPPLIER),
      weightMax: getFloat(getValue(WEIGHT) as RangeValueType, 'max'),
      weightMin: getFloat(getValue(WEIGHT) as RangeValueType, 'min'),
      weightUnit: getValue(WEIGHT_UNIT),
    },
    ...(includeWhen
      ? {
          whenMax: dayjs(get(WHEN, 'max')).endOf('day').toISOString(),
          whenMin: dayjs(get(WHEN, 'min')).startOf('day').toISOString(),
        }
      : {}),
  }

  const mergeCombResults = Object.entries(formikValues).filter(
    (entry) => entry[0] === FormName.MergeCombResults
  )[0][1][ToggledControlName.Checked]

  const pdfReport = EXPORT_FORMAT === ExportFormat.pdf

  return { columns, filters, mergeCombResults, pdfReport }
}

export const fromApiFormat = (template: Template): AnyObject => {
  const { columns, filters, mergeCombResults } = template

  const formikValues: AnyObject = flow([
    map((field: string) => {
      if (includes(field, NO_TEMPLATE_FIELDS)) {
        return null
      }
      const camelized: string = camelCase(field)
      // MERGE_COMB_RESULTS is associated with an EmptyToggledControl;
      // therefore, it's handled differently than the other report column fields
      if (field === FormName.MergeCombResults) {
        return [
          field,
          {
            [ToggledControlName.Checked]: mergeCombResults,
          },
        ]
      }
      return [
        field,
        {
          [ToggledControlName.Checked]: includes(field, columns),
          [ToggledControlName.Value]: includes(field, RANGE_FIELDS)
            ? {
                min: filters[`${camelized}Min`],
                max: filters[`${camelized}Max`],
              }
            : template.filters[camelized],
        },
      ]
    }),
    filter(isSet),
    fromPairs,
  ])(Object.values(FormName))

  return formikValues
}
