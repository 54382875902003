import React, { ChangeEvent, FunctionComponent, useState } from 'react'
import { intlMessageForId } from 'localization'
import {
  EventAction,
  EventCategory,
  EventLabel,
  trackEvent,
} from 'utils/analytics'
import Color from 'types/color'
import RadioButtons from 'components/RadioButtons'
import { LabeledElement } from '../LabeledValue'
import { useLocalStorage } from './useLocalStorage'

export enum LoadAcceptanceSelectorOption {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  All = 'All',
}

export const getLoadAcceptanceSelectorQueryValue = (
  loadAcceptanceSelector: LoadAcceptanceSelectorOption
): boolean | null => {
  // Setting accepted as 'null' returns both accepted and rejected (i.e., all) test results
  let accepted = null

  if (loadAcceptanceSelector === LoadAcceptanceSelectorOption.Accepted) {
    accepted = true
  } else if (loadAcceptanceSelector === LoadAcceptanceSelectorOption.Rejected) {
    accepted = false
  }

  return accepted
}

const useLoadAcceptanceSelector: (
  eventCategory: EventCategory,
  id: string
) => {
  LoadAcceptanceSelector: FunctionComponent
  selectedLoadAcceptance: LoadAcceptanceSelectorOption
} = (eventCategory, id) => {
  const [getSavedSelection, setSavedSelection] = useLocalStorage(
    `${id}-LoadAcceptanceSelector`
  )

  const [selectedLoadAcceptance, setSelectedLoadAcceptance] = useState<
    LoadAcceptanceSelectorOption
  >(
    (getSavedSelection() as LoadAcceptanceSelectorOption) ||
      LoadAcceptanceSelectorOption.Accepted
  )

  const handleChange: (e: ChangeEvent<HTMLInputElement>) => void = (e) => {
    const selection = e.target.value
    trackEvent(
      eventCategory,
      EventAction.Select,
      `${EventLabel.LoadAcceptance}_${selection}`
    )
    setSavedSelection(selection)
    setSelectedLoadAcceptance(selection as LoadAcceptanceSelectorOption)
  }

  const LoadAcceptanceSelector: FunctionComponent = () => {
    const options = [
      LoadAcceptanceSelectorOption.Accepted,
      LoadAcceptanceSelectorOption.Rejected,
      LoadAcceptanceSelectorOption.All,
    ].map((element: LoadAcceptanceSelectorOption) => {
      return {
        label: intlMessageForId(`Charts.Overview.DataView.Options.${element}`),
        value: LoadAcceptanceSelectorOption[element],
      }
    })

    return (
      <LabeledElement
        label={intlMessageForId('Charts.Overview.DataView.Header')}
      >
        <RadioButtons
          name={`LOAD-SELECTOR-${id}`}
          options={options}
          selectedValue={selectedLoadAcceptance as string}
          labelStyle={{
            color: Color.White60,
          }}
          groupLabelStyle={{
            color: Color.White60,
          }}
          handleChange={handleChange}
        />
      </LabeledElement>
    )
  }

  return {
    LoadAcceptanceSelector,
    selectedLoadAcceptance,
  }
}

export default useLoadAcceptanceSelector
