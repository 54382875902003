import React, { FunctionComponent, ReactNode } from 'react'
import styled from '@emotion/styled'
import Color from 'types/color'
import ContactCalloutBackground from 'assets/svg/elix-shape-bg.svg'

const Div = styled.div<{ backgroundImage: string }>`
  padding: 32px;
  border-radius: 8px;
  font-size: 14px;
  color: ${Color.White};
  background-color: #053451;
  background-repeat: no-repeat;
  background-position: right;
  background-image: ${({ backgroundImage }) => backgroundImage};

  header {
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
  }

  @media print {
    padding: 6px 0;
    color: ${Color.Black};
    background-image: none;
    background-color: transparent;
  }
`

const Callout: FunctionComponent<{ children: ReactNode }> = ({ children }) => (
  <Div backgroundImage={`url(${ContactCalloutBackground})`}>{children}</Div>
)

export default Callout
