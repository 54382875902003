import React, { FunctionComponent, ReactElement } from 'react'
import { useFormikContext } from 'formik'
import { get } from 'utils'
import { AnyObject, StyleObject } from 'types'
import MultiSelect, { FormSelectOption } from 'components/MultiSelect'
import LabeledFormElement from './LabeledFormElement'
import ErrorMessage from './ErrorMessage'

const FormMultiSelect: FunctionComponent<{
  name: string
  selectOptions: FormSelectOption[]
  label?: string
  style?: StyleObject
  Input?: ReactElement
  displayError?: boolean
}> = ({
  name,
  selectOptions,
  label,
  style = {},
  Input,
  displayError = false,
}) => {
  const { values, handleChange } = useFormikContext()

  return (
    <LabeledFormElement label={label} name={name}>
      <>
        <MultiSelect
          name={name}
          selectOptions={selectOptions}
          value={get(values as AnyObject, name, [])}
          onChange={handleChange}
          containerStyles={style}
          Input={Input}
        />
        {displayError && <ErrorMessage forName={name} />}
      </>
    </LabeledFormElement>
  )
}

export default FormMultiSelect
