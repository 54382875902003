import * as d3 from 'd3-time'
import dayjs, { OpUnitType } from 'dayjs'
import { includes } from 'lodash/fp'
import { DateRange } from 'types/enums'
import { ISODate, ISODateTime, ISODateTimeSansMilliseconds } from '../types'

export const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

/**
 * converts a javascript date object into an iso-formatted
 * local date string (YYYY-MM-DD or YYYY-MM) without a timestamp or timezone.
 * @param date javascript date object
 * @param precision day (YYYY-MM-DD) or month (YYYY-MM)
 * @returns formatted local date string
 */
export const toIsoDate: (
  date: Date,
  precision?: 'hour' | 'day' | 'month' | 'year'
) => ISODate = (date: Date, precision = 'day') => {
  const options = {
    hour: 'YYYY-MM-DDTHH',
    day: 'YYYY-MM-DD',
    month: 'YYYY-MM',
    year: 'YYYY',
  }

  return dayjs(date).format(options[precision])
}

export const toIsoDateTime: (
  isoDate: ISODate,
  time?: {
    hour: number
    minute: number
    second: number
  }
) => ISODateTime = (isoDate, time = { hour: 0, minute: 0, second: 0 }) => {
  const { hour, minute, second } = time
  return dayjs(isoDate)
    .hour(hour)
    .minute(minute)
    .second(second)
    .toDate()
    .toISOString()
}

export const isoDateToDateObject: (isoDate: ISODate) => Date = (
  isoDate: ISODate
) => {
  // Day.js parses and displays in local time by default
  return dayjs(isoDate).toDate()
}

// the api returns ISO date-time strings without milliseconds: '%Y-%m-%dT%H:%M:%SZ'
export const toIsoDateTimeSansMilliseconds: (
  dateTime: ISODateTime
) => ISODateTimeSansMilliseconds = (dateTime) => {
  return `${dateTime.substr(0, 19)}Z`
}

export const getDateRanges: (
  referenceDate: Date
) => (timeRange: DateRange) => { startDate: ISODate; endDate: ISODate } = (
  referenceDate: Date
) => {
  const today = toIsoDate(referenceDate)
  const yesterday = toIsoDate(d3.timeDay.offset(referenceDate, -1))

  const timeRanges: {
    [key: string]: { startDate: ISODate; endDate: ISODate }
  } = {
    [DateRange.Today]: {
      startDate: today,
      endDate: today,
    },
    [DateRange.Yesterday]: {
      startDate: yesterday,
      endDate: yesterday,
    },
    [DateRange.Week]: {
      startDate: toIsoDate(d3.timeWeek.offset(referenceDate, -1)),
      endDate: today,
    },
    [DateRange.Month]: {
      startDate: toIsoDate(d3.timeMonth.offset(referenceDate, -1)),
      endDate: today,
    },
    [DateRange.Year]: {
      startDate: toIsoDate(d3.timeYear.offset(referenceDate, -1)),
      endDate: today,
    },
  }

  return (timeRange: DateRange) => {
    return timeRanges[timeRange]
  }
}

export const toLocaleDate: (
  isoDate: ISODate,
  options?: { [key: string]: string }
) => string = (isoDate, options = {}) => {
  return isoDateToDateObject(isoDate).toLocaleDateString(undefined, options)
}

// returns the time difference in the given units.
export const dt: (
  start: ISODate | ISODateTime,
  end: ISODate | ISODateTime,
  unit?: OpUnitType
) => number = (start, end, unit = 'day') => {
  return dayjs(end).diff(dayjs(start), unit)
}

export const getDateRange: (start: ISODate, end: ISODate) => DateRange = (
  start,
  end
) => {
  const days = dt(start, end, 'day')

  if (days === 0) {
    return DateRange.Today
  }

  if (days <= 7) {
    return DateRange.Week
  }

  if (days <= 31) {
    return DateRange.Month
  }

  return DateRange.Year
}

export const isSingleDay: (start: ISODate, end: ISODate) => boolean = (
  start,
  end
) => {
  return dt(start, end) === 0
}

export const isValidDateRange: (start: ISODate, end: ISODate) => boolean = (
  start,
  end
) => {
  return dt(start, end) >= 0
}

export const getDateArray: (
  start: ISODate | ISODateTime,
  end: ISODate | ISODateTime,
  incrementValue?: number,
  incrementUnit?: 'hour' | 'day' | 'week' | 'month' | 'year'
) => Array<ISODate> = (
  start,
  end,
  incrementValue = 1,
  incrementUnit = 'day'
) => {
  const dates = []
  const roundToUnit = includes(incrementUnit, ['month', 'year'])
    ? incrementUnit
    : 'day'
  const roundedStart = dayjs(start).startOf(roundToUnit as OpUnitType)
  const roundedEnd = dayjs(end).endOf(roundToUnit as OpUnitType)

  let current = dayjs(roundedStart)

  while (current <= dayjs(roundedEnd)) {
    const date = current.toDate()
    // TODO: date formatting could be refactored out of this function so this function is only responsible for creating an array of Date objects and another function is responsible for formatting an array of dates appropriately
    const formattedDate = toIsoDate(
      date,
      incrementUnit === 'week' ? 'day' : incrementUnit
    )
    dates.push(formattedDate)
    current = current.add(incrementValue, incrementUnit)
  }

  return dates
}
