import React, { FunctionComponent, ReactElement } from 'react'
import styled from '@emotion/styled'
import { intlMessageForId } from 'localization'
import { FormattedMessageId } from 'types'
import Color from 'types/color'

export type ValueSize = 'Normal' | 'Large'

const Label = styled.div`
  color: ${Color.White60};
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: bold;
`

const getValuePropHeight = (size: ValueSize) => {
  return size === 'Normal' ? '24px' : '42px'
}

export const Value = styled.div<{ size: ValueSize }>`
  font-size: ${(props) => getValuePropHeight(props.size)};
  line-height: ${(props) => getValuePropHeight(props.size)};
  color: #ffffff;
  font-weight: bold;
`

export const LabeledElement: FunctionComponent<{
  label: string
  children: ReactElement
  invert?: boolean
}> = ({ label, children, invert = false }) => {
  const flexDirection = invert ? 'column-reverse' : 'column'

  return (
    <div
      style={{
        display: 'flex',
        flexDirection,
      }}
    >
      <Label>{label}</Label>
      <div style={{ paddingTop: '4px' }} />
      {children}
    </div>
  )
}

const LabeledValue: FunctionComponent<{
  labelId: FormattedMessageId
  value: string
  valueSize?: ValueSize
  invert?: boolean
}> = ({ labelId, value, valueSize = 'Normal', invert }) => (
  <LabeledElement label={intlMessageForId(labelId)} invert={invert}>
    <Value size={valueSize}>{value}</Value>
  </LabeledElement>
)

export default LabeledValue
