import React, { FunctionComponent } from 'react'
import { ErrorMessage as FormikErrorMessage } from 'formik'
import styled from '@emotion/styled'
import Color from 'types/color'

export const StyledErrorMessage = styled.p`
  color: ${Color.Error};
  font-size: 12px;
  line-height: 15px;
`

const ErrorMessage: FunctionComponent<{ forName: string }> = ({ forName }) => (
  <FormikErrorMessage component={StyledErrorMessage} name={forName} />
)

export default ErrorMessage
