import React, { ReactElement } from 'react'
import { compact } from 'lodash'
import { intlMessageForId } from 'localization'
import { Path } from 'types/enums'
import {
  SegmentedPageSegmentType,
  getSegmentedPageWithRoutes,
} from 'components/SegmentedPage'
import Alerts from './Alerts'
import TestResults from './TestResults'
import QuickCheck from './Quickcheck'
import Labs from './Labs'

const segments: Array<SegmentedPageSegmentType> = [
  {
    key: 'TestResults',
    title: intlMessageForId('Reports.Segments.QuickScanResults'),
    path: Path.Reports_TestResults,
    component: <TestResults />,
  },
  {
    key: 'QuickCheck',
    title: intlMessageForId('Reports.Segments.QuickCheck'),
    path: Path.Reports_Quickcheck,
    component: <QuickCheck />,
  },
]

const labsSegment = {
  key: 'Labs',
  title: intlMessageForId('Reports.Segments.Labs'),
  path: Path.Reports_Labs,
  component: <Labs />,
}

const alertSegment = {
  key: 'Alerts',
  title: intlMessageForId('Reports.Segments.Alerts'),
  path: Path.Reports_Alerts,
  component: <Alerts />,
}

export const createReportSegments = (
  featureFlags: {
    emailAlerts: boolean
  },
  isLabsCustomer: boolean
): Array<ReactElement> => {
  return getSegmentedPageWithRoutes({
    testId: 'Reports',
    title: intlMessageForId('Reports.Header'),
    rootPath: Path.Reports,
    segments: compact([
      ...segments,
      isLabsCustomer ? labsSegment : null,
      featureFlags.emailAlerts ? alertSegment : null,
    ]),
  })
}
