import { ReactElement } from 'react'

export enum DataSource {
  Quickscan = 'QUICKSCAN',
  Quickcheck = 'QUICKCHECK',
  Lab = 'LAB',
}

export enum AnalyteType {
  Gmo = 'GMO',
  Mycotoxin = 'MYCOTOXIN',
  Allergen = 'ALLERGEN',
  Rejections = 'REJECTION',
}

export enum AlertType {
  SingleResult = 'SINGLE_RESULT',
  AverageResult = 'AVERAGE_RESULT',
  SingleRejection = 'SINGLE_REJECTION',
  RejectionRate = 'REJECTION_RATE',
}

export enum AlertTimeRange {
  Today = 'TODAY',
  Past2Days = 'PAST_2_DAYS',
  Past7Days = 'PAST_7_DAYS',
  Past14Days = 'PAST_14_DAYS',
  Past30Days = 'PAST_30_DAYS',
}

export type Alert = {
  id: string
  analyte: string
  analyteType: AnalyteType
  commodities: [string]
  emails: [string]
  enabled: boolean
  locations: [string]
  name: string
  source: DataSource
  threshold: number
  timeRange: AlertTimeRange
  alertType: AlertType
}

export type AlertTableDatum = {
  id: string
  name: string
  source: DataSource
  analyte: string
  setting: ReactElement
  threshold: number
  enabled: ReactElement
  delete: ReactElement
}
