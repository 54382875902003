import { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { ID } from 'types'
import { get } from 'utils'

export type LocationFacetValue = { key: string; label: string }

export type LabCustomer = {
  address?: string
  billTo?: string
  billingEmail?: string
  comments?: string
  companyDiscount?: string
  contactName?: string
  contactNameBillTo?: string
  customerName: string
  epicor?: string
  id: ID
  lastUpdated?: string
  paymentTermDelays?: string
  phone?: string
  qba6?: string
  qbenchCustomerId: number
  reportingEmail?: string
}

const LAB_CUSTOMERS_QUERY = gql`
  query {
    labCustomers {
      qbenchCustomerId
      customerName
    }
  }
`

const useLabsCustomerLocationFacet: () => {
  location: Array<LocationFacetValue>
} = () => {
  const [locationFacetValues, setLocationFacetValues] = useState<
    Array<LocationFacetValue>
  >([])

  const { data } = useQuery(LAB_CUSTOMERS_QUERY)

  useEffect(() => {
    setLocationFacetValues(
      get(data, 'labCustomers', []).map((labCustomer: LabCustomer) => {
        const { qbenchCustomerId, customerName } = labCustomer
        return { key: qbenchCustomerId, label: customerName }
      })
    )
  }, [data])

  return { location: locationFacetValues }
}

export default useLabsCustomerLocationFacet
