import React, { Children, Fragment, FunctionComponent, ReactNode } from 'react'
import { StyleObject } from 'types'
import { LayoutDirection } from 'types/enums'

const SocialDistancing: FunctionComponent<{
  children: ReactNode
  spacing?: string
  direction?: LayoutDirection
  style?: StyleObject
  testId?: string
}> = ({
  children,
  spacing = '6px',
  direction = LayoutDirection.Vertical,
  style = {},
  testId,
}) => {
  const items: Array<ReactNode> = Children.toArray(children)

  return (
    <div
      data-testid={testId}
      style={{
        display: direction === LayoutDirection.Vertical ? 'block' : 'flex',
        ...style,
      }}
    >
      {items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          {index > 0 && (
            <div
              style={{
                [direction === LayoutDirection.Vertical
                  ? 'paddingTop'
                  : 'paddingLeft']: spacing,
              }}
            />
          )}
          {item}
        </Fragment>
      ))}
    </div>
  )
}

export default SocialDistancing
