import React from 'react'
import { useQuery } from '@apollo/client'
import { Loading, Error } from 'components/Placeholders'
import useCurrentUser from 'hooks/useCurrentUser'
import { FormName } from 'types/forms'
import LabOrderForm from '.'
import { ADD_LAB_ORDER_MUTATION, LAB_ORDER_FORM_QUERY } from './gql'
import { FormValues } from './types'
import { getDefaultSettings, getNewSample } from './utils'

const initialValues: FormValues = {
  [FormName.CustomerLocation]: '',
  [FormName.EnvirologixLocation]: '',
  [FormName.Email1]: '',
  [FormName.Email2]: '',
  [FormName.Email3]: '',
  [FormName.PoNumber]: '',
  [FormName.Comments]: '',
  [FormName.Terms]: false,
  [FormName.Samples]: [getNewSample()],
}

const NewLabOrderContainer: React.FC = () => {
  const { email } = useCurrentUser()
  const { loading, error, data } = useQuery(LAB_ORDER_FORM_QUERY)

  if (loading) return <Loading />
  if (error) return <Error />

  const { labCustomers, labAssays } = data

  const defaultSettings = {
    emailSettings: getDefaultSettings(data, 'emailSettings'),
    sampleTypeSettings: getDefaultSettings(data, 'sampleTypeSettings'),
  }

  return (
    <LabOrderForm
      labAssays={labAssays}
      labCustomers={labCustomers}
      defaultSettings={defaultSettings}
      initialValues={{ ...initialValues, [FormName.Email1]: email }}
      mutation={ADD_LAB_ORDER_MUTATION}
      testId="NewLabOrderContainer"
    />
  )
}

export default NewLabOrderContainer
