import { FC } from 'react'
import { gql } from '@apollo/client'
import { setIn } from 'formik'
import { filter, flow, map, property, values } from 'lodash/fp'
import {
  ToggledControlName,
  ToggledControlState,
} from 'components/FormElements'
import { FormName } from 'types/forms'
import { Assay, GetFieldName, SampleFormValue } from './types'
import { getSelectedTestStateFromAssays } from './utils'
import { LabAssay } from '../LabOrderDetails'
import {
  BuildCreatePayload,
  BuildUpdatePayload,
  OnTemplateChange,
  SetFormikValues,
  TemplateBase,
} from '../../../../hooks/useFormTemplates/types'
import useTemplates from '../../../../hooks/useFormTemplates'

type LabSampleTemplate = {
  assays?: Array<LabAssay>
  comment?: string
  commodity?: string
  description?: string
  noGmoSum?: boolean
  rush?: boolean
} & TemplateBase

export const LAB_SAMPLE_TEMPLATES_QUERY = gql`
  query {
    labSampleTemplates {
      assays {
        analyte
        id
      }
      comment
      commodity
      description
      id
      noGmoSum
      rush
      title
    }
  }
`

export const CREATE_LAB_SAMPLE_TEMPLATE_MUTATION = gql`
  mutation(
    $assayIds: [Int]
    $comment: String
    $commodity: String
    $description: String
    $noGmoSum: Boolean
    $rush: Boolean
    $title: String!
  ) {
    createLabSampleTemplate(
      assayIds: $assayIds
      comment: $comment
      commodity: $commodity
      description: $description
      noGmoSum: $noGmoSum
      rush: $rush
      title: $title
    ) {
      messages {
        code
        field
        message
      }
      result {
        id
      }
      successful
    }
  }
`

const UPDATE_LAB_SAMPLE_TEMPLATE_MUTATION = gql`
  mutation(
    $assayIds: [Int]
    $comment: String
    $commodity: String
    $description: String
    $id: ID!
    $noGmoSum: Boolean
    $rush: Boolean
    $title: String!
  ) {
    updateLabSampleTemplate(
      assayIds: $assayIds
      comment: $comment
      commodity: $commodity
      description: $description
      id: $id
      noGmoSum: $noGmoSum
      rush: $rush
      title: $title
    ) {
      messages {
        code
        field
        message
      }
      result {
        id
      }
      successful
    }
  }
`

const DELETE_LAB_SAMPLE_TEMPLATE_MUTATION = gql`
  mutation($id: id!) {
    deleteLabSampleTemplate(id: $id) {
      messages {
        code
        message
      }
      result {
        id
      }
      successful
    }
  }
`

const queryKey = 'labSampleTemplates'
const createMutationKey = 'createLabSampleTemplate'

const buildCreatePayload: BuildCreatePayload<SampleFormValue> = (
  title: string,
  formValue: SampleFormValue
) => ({
  assayIds: flow([
    values,
    filter(property(ToggledControlName.Checked)),
    map((test: ToggledControlState) => {
      return Number(test[ToggledControlName.Value])
    }),
  ])(formValue[FormName.Tests]),
  comment: formValue[FormName.SampleComments],
  commodity: formValue[FormName.Commodity],
  description: formValue[FormName.SampleType],
  noGmoSum: formValue[FormName.NoGmoSum],
  rush: formValue[FormName.OvernightRush] === 'true',
  title,
})

// currently we only support updating the title so not getting the form values
const buildUpdatePayload: BuildUpdatePayload<LabSampleTemplate> = (
  title,
  selectedTemplate
) => ({
  assayIds: selectedTemplate.assays?.map((assay: Assay) => Number(assay.id)),
  comment: selectedTemplate.comment,
  commodity: selectedTemplate.commodity,
  description: selectedTemplate.description,
  id: selectedTemplate.id,
  noGmoSum: selectedTemplate.noGmoSum,
  rush: selectedTemplate.rush,
  title,
})

export const useLabSampleTemplates: (
  getFieldName: GetFieldName
) => {
  hasSampleTemplates: boolean
  ChooseSampleTemplate: FC
  SaveSampleTemplate: FC<{ formValue: SampleFormValue }>
} = (getFieldName) => {
  const onTemplateChange: OnTemplateChange<
    LabSampleTemplate,
    SampleFormValue
  > = (
    chosenTemplate: LabSampleTemplate,
    formValues: SampleFormValue,
    setValues: SetFormikValues
  ) => {
    // clone form values
    let newValues = { ...formValues }

    // using formik's #setIn to set nested sample values (#setValues is not sufficient)
    newValues = setIn(
      newValues,
      getFieldName(FormName.Commodity),
      chosenTemplate.commodity || ''
    )
    newValues = setIn(
      newValues,
      getFieldName(FormName.SampleType),
      chosenTemplate.description || ''
    )
    newValues = setIn(
      newValues,
      getFieldName(FormName.OvernightRush),
      String(chosenTemplate.rush) || 'false'
    )
    newValues = setIn(
      newValues,
      getFieldName(FormName.SampleComments),
      chosenTemplate.comment || ''
    )
    newValues = setIn(
      newValues,
      getFieldName(FormName.NoGmoSum),
      chosenTemplate.noGmoSum || false
    )

    const checked = getSelectedTestStateFromAssays(chosenTemplate.assays)
    newValues = setIn(newValues, getFieldName(FormName.Tests), checked)

    setValues(newValues)
  }

  const {
    hasTemplates: hasSampleTemplates,
    ChooseTemplate: ChooseSampleTemplate,
    SaveTemplate: SaveSampleTemplate,
  } = useTemplates({
    query: LAB_SAMPLE_TEMPLATES_QUERY,
    createMutation: CREATE_LAB_SAMPLE_TEMPLATE_MUTATION,
    updateMutation: UPDATE_LAB_SAMPLE_TEMPLATE_MUTATION,
    deleteMutation: DELETE_LAB_SAMPLE_TEMPLATE_MUTATION,
    queryKey,
    createMutationKey,
    buildCreatePayload,
    buildUpdatePayload,
    onTemplateChange,
    testId: 'LabSampleTemplates',
  })

  return {
    hasSampleTemplates,
    ChooseSampleTemplate,
    SaveSampleTemplate,
  }
}
