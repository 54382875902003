import React, { FunctionComponent, useState } from 'react'
import { ApolloConsumer, gql, useMutation } from '@apollo/client'
import { Error } from 'components/Placeholders'
import { SecondaryButton } from 'components/Button'
import { intlMessageForId } from 'localization'
import { LOGIN_URL } from 'constants/index'

export type Client = {
  clearStore: () => void
}

export const clearSession: (client: Client) => void = (client) => {
  client.clearStore()
  window.localStorage.clear()
}

export const redirect: () => void = () => {
  if (LOGIN_URL) {
    window.location.href = LOGIN_URL
  }
}

const LOGOUT_MUTATION = gql`
  mutation {
    logout
  }
`

const Logout: FunctionComponent<{ client: Client }> = ({ client }) => {
  const [loggedOut, setLoggedOut] = useState<boolean>(false)

  const [logout, { error }] = useMutation(LOGOUT_MUTATION, {
    onCompleted: () => {
      clearSession(client)
      setLoggedOut(true)
    },
  })

  if (error) return <Error />

  if (loggedOut) {
    redirect()
    return null
  }

  return (
    <SecondaryButton onClick={() => logout()}>
      {intlMessageForId('NavBar.Logout')}
    </SecondaryButton>
  )
}

export default (() => (
  <ApolloConsumer>
    {(client) => {
      return <Logout client={client} />
    }}
  </ApolloConsumer>
)) as FunctionComponent
