import React, { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import Select from 'components/Select'
import Color from 'types/color'
import ErrorMessage from './ErrorMessage'
import { StyledContainer } from './ToggledControl'
import { Label } from './FormCheckbox'
import FormSelect, { FormSelectProps } from './FormSelect'
import FormMultiSelect from './FormMultiSelect'

const StyledSelect = styled(Select)`
  padding: 11px 146px 7px 12px;
`

const HighlightedFormSelect: FunctionComponent<FormSelectProps> = ({
  name,
  options,
  label,
  emptyState,
  component = StyledSelect,
  style = {},
  onChange,
  multiple = false,
  ariaLabel,
}) => {
  const fieldProps = onChange ? { onChange } : {}

  return (
    <div>
      <StyledContainer
        style={{
          display: 'block',
          backgroundColor: Color.SelectedBackground,
        }}
      >
        <div style={{ display: 'flex', flexGrow: 1 }}>
          <Label
            htmlFor={name}
            style={{
              ...style,
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              fontSize: 16,
            }}
          >
            {label}
          </Label>

          {multiple ? (
            <FormMultiSelect
              aria-label={ariaLabel}
              name={name}
              style={style}
              {...fieldProps}
              selectOptions={options}
              displayError={false}
            />
          ) : (
            <FormSelect
              aria-label={ariaLabel}
              name={name}
              component={component}
              style={style}
              {...fieldProps}
              multiple={multiple}
              options={options}
              emptyState={emptyState}
              displayError={false}
            />
          )}
        </div>
      </StyledContainer>
      <div style={{ float: 'right' }}>
        <ErrorMessage forName={name} />
      </div>
    </div>
  )
}

export default HighlightedFormSelect
