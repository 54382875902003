import React, { FC, useState } from 'react'
import styled from '@emotion/styled'
import { LoadingIndicator } from 'components/LoadingIndicator'
import { Play } from 'components/SVG'
import { StyleObject } from 'types'
import Color from 'types/color'
import { isSet } from 'utils'
import TileButton from '../TileButton'
import './styles.css'

const CloseButton = styled.div`
  color: white;
  opacity: 0.65;
  font-size: 24px;
  padding: 0px 6px 0px 0px;

  :hover {
    opacity: 1;
  }
`

const VideoModal: FC<{
  label: string
  src: string
  buttonStyle?: StyleObject
  onClick?: () => void
}> = ({ label, src, onClick }) => {
  const [modal, setModal] = useState(false)
  const [url, setUrl] = useState<string>()

  const toggleModal = () => {
    setModal(!modal)
  }

  return (
    <div>
      <TileButton
        label={label}
        Icon={Play}
        onClick={() => {
          if (isSet(onClick) && !modal) {
            onClick()
          }
          setUrl(`https://www.youtube.com/embed/${src}`)
          toggleModal()
        }}
      >
        {modal ? (
          <section className="modal__bg">
            <LoadingIndicator color={Color.White60} />
            <div className="modal__align">
              <div className="modal__content">
                <CloseButton className="modal__close" arial-label="close modal">
                  x
                </CloseButton>
                <div className="modal__video-align">
                  <iframe
                    className="modal__video-style"
                    width="900"
                    height="507"
                    frameBorder="0"
                    loading="lazy"
                    allowFullScreen
                    title={label}
                    src={url}
                  />
                </div>
              </div>
            </div>
          </section>
        ) : null}
      </TileButton>
    </div>
  )
}

export default VideoModal
