import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { FormattedMessageId } from 'types'
import Color from 'types/color'
import LoadingIndicator from 'components/LoadingIndicator'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  background-color: ${Color.Border};
  border-radius: 8px;
  color: ${Color.Black};
`

const Header = styled.header`
  font-size: 24px;
  line-height: 24px;
`
const Text = styled.p`
  font-size: 16px;
  line-height: 22px;
`

const Takeover: React.FunctionComponent<{
  headerId: FormattedMessageId
  bodyId?: FormattedMessageId
  children?: ReactElement
  testId?: string
}> = ({ headerId, bodyId, children, testId }) => (
  <Container data-testid={testId}>
    {children && <div style={{ paddingBottom: '24px' }}>{children}</div>}
    <Header>
      <FormattedMessage id={headerId} />
    </Header>
    {bodyId && (
      <Text>
        <FormattedMessage id={bodyId} />
      </Text>
    )}
  </Container>
)

export const LoadingTakeover: React.FunctionComponent = () => (
  <Takeover
    headerId="Charts.Takeover.Loading.Header"
    bodyId="Charts.Takeover.Loading.Body"
    testId="LoadingTakeover"
  >
    <div style={{ position: 'relative' }}>
      <LoadingIndicator />
    </div>
  </Takeover>
)

export const ErrorTakeover: React.FunctionComponent = () => (
  <Takeover
    headerId="Charts.Takeover.Error.Header"
    bodyId="Charts.Takeover.Error.Body"
    testId="ErrorTakeover"
  />
)

export const NoResultsTakeover: React.FunctionComponent = () => (
  <Takeover
    headerId="Charts.Takeover.NoResults.Header"
    bodyId="Charts.Takeover.NoResults.Body"
    testId="NoResultsTakeover"
  />
)

export const NoOrganizationDataTakeover: React.FunctionComponent = () => (
  <Takeover
    headerId="Charts.Takeover.NoOrganizationData.Header"
    bodyId="Charts.Takeover.NoOrganizationData.Body"
    testId="NoOrganizationDataTakeover"
  />
)

export default Takeover
