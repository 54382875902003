import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { intlMessageForId } from 'localization'
import { get } from 'utils'
import Color from 'types/color'
import ClearButton from 'assets/svg/clear.svg'
import { ImgButton } from 'components/Button'
import { LabeledElement } from 'components/charts/LabeledValue'

// TODO: apply font-family globally to styled components
const ThresholdInput = styled.input`
  width: 50%;
  height: 32px;
  padding: 7px 32px 7px 12px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  border: none;
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: ${Color.White};

  :focus {
    outline: none;
  }
`

const ThresholdClear = styled(ImgButton)`
  position: absolute;
  right: calc(50% - 32px - 20px);
  top: calc(50% - 6px - 20px);
  padding: 20px;
`

export const useThreshold: (
  units?: string
) => {
  Component: FunctionComponent
  value?: number
} = (units) => {
  // empty string fallback here because input elements should not switch from uncontrolled to controlled
  // https://fb.me/react-controlled-components
  const [threshold, setThreshold] = useState<number | string>('')
  const inputRef = useRef(null)

  const focus = () => {
    const current = get(inputRef, 'current')
    return current && current.focus()
  }

  useEffect(() => {
    focus()
  }, [threshold])

  const Threshold: FunctionComponent = () => {
    const label = intlMessageForId('Charts.Overview.Threshold.Label')

    return (
      <LabeledElement label={units ? `${label} ${units}` : label}>
        <div style={{ position: 'relative' }}>
          <ThresholdInput
            type="number"
            onChange={(e) => setThreshold(parseFloat(e.target.value) || '')}
            value={threshold && threshold.toString()}
            ref={inputRef}
            step={0.1}
          />
          {threshold && (
            <ThresholdClear onClick={() => setThreshold('')}>
              <img
                src={ClearButton}
                alt={intlMessageForId('Charts.Overview.Threshold.AltText')}
              />
            </ThresholdClear>
          )}
        </div>
      </LabeledElement>
    )
  }

  return {
    Component: Threshold,
    value: typeof threshold === 'number' ? threshold : undefined,
  }
}
