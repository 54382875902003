import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/pt'
import { includes } from 'lodash'
import { Locale, locale } from 'localization'
import { ISODate } from 'types'
import { logError } from 'utils'

const SUPPORTED_LOCALES = [Locale.Portuguese, Locale.English]
const isSupported = includes(SUPPORTED_LOCALES, locale)

if (!isSupported) {
  logError(
    `ERROR: missing import for locale. Add 'import dayjs/locale/${locale}'.`,
    'not-production'
  )
}

dayjs.locale(isSupported ? locale : Locale.English)
dayjs.extend(relativeTime)

export const toRelativeTime: (isoDate: ISODate) => string = (isoDate) =>
  dayjs().to(dayjs(isoDate))
