import React, { FunctionComponent } from 'react'
import { gql, useMutation } from '@apollo/client'
import { intlMessageForId } from 'localization'
import { get, openUrl } from 'utils'
import { PrimaryButton } from 'components/Button'
import SocialDistancing from 'components/SocialDistancing'
import { GrayContainer } from 'components/GrayContainer'
import { Loading, Error } from 'components/Placeholders'
import useCurrentUser from 'hooks/useCurrentUser'

export const DOWNLOAD_CONNECT_CARD_MUTATION = gql`
  mutation {
    downloadConnectCard
  }
`

const QuickScan: FunctionComponent = () => {
  const { loading, error, organization } = useCurrentUser()
  const [downloadConnectCard] = useMutation(DOWNLOAD_CONNECT_CARD_MUTATION, {
    onCompleted: (mutationData) => {
      const connectCardUrl = get(mutationData, 'downloadConnectCard')

      if (connectCardUrl) {
        openUrl(connectCardUrl)
      }
    },
  })

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Error />
  }

  const { shorthandName, quickscanAccessCode } = organization

  return (
    <div data-testid="QuickScanHelpSettings">
      <SocialDistancing spacing="16px">
        <h2>{intlMessageForId('Admin.Organization.ActivationCode')}</h2>
        <GrayContainer
          style={{
            display: 'inline-block',
            marginTop: '16px',
            fontFamily: 'Verdana, "Andale Mono"',
          }}
        >
          <SocialDistancing spacing="16px">
            <div>
              {intlMessageForId('Admin.Organization.Name')}
              {shorthandName}
            </div>
            <div>
              {intlMessageForId('Admin.Organization.AccessCode')}
              {quickscanAccessCode}
            </div>
          </SocialDistancing>
        </GrayContainer>
        <PrimaryButton onClick={downloadConnectCard}>
          {`${intlMessageForId('Admin.THCC.Download')} (.pdf)`}
        </PrimaryButton>
      </SocialDistancing>
    </div>
  )
}

export default QuickScan
