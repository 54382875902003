import React, { FC } from 'react'
import { gql, useMutation } from '@apollo/client'
import { get } from 'lodash/fp'
import { ID, StyleObject } from 'types'
import { openUrl } from 'utils'
import { EventLabel } from 'utils/analytics'
import { ImgButton } from 'components/Button'
import { LabsPDF } from 'components/SVG'

const LAB_SAMPLE_REPORT_MUTATION = gql`
  mutation($id: ID) {
    labSampleReport(id: $id)
  }
`

const DownloadSampleReport: FC<{ sampleId: ID; style?: StyleObject }> = ({
  sampleId,
  style = {},
}) => {
  const [getLabSampleReport] = useMutation(LAB_SAMPLE_REPORT_MUTATION, {
    variables: {
      id: sampleId,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const pdfUrl = get('labSampleReport', data)

      if (pdfUrl) {
        openUrl(pdfUrl)
      }
    },
  })

  return (
    <ImgButton
      onClick={(e) => {
        e.stopPropagation()
        getLabSampleReport()
      }}
      aria-label={EventLabel.LabResultPdf}
    >
      <LabsPDF style={style} />
    </ImgButton>
  )
}

export default DownloadSampleReport
