import React, { FC } from 'react'
import { lowerCase, startCase } from 'lodash'
import { intlMessageForId } from 'localization'
import { isSet } from 'utils'
import { DetailedCell } from 'components/Table'

export const toIntl: (parent: string, key: string) => string = (
  parent,
  key
) => {
  return intlMessageForId(
    `Alerts.Form.Fields.${parent}.Options.${startCase(
      lowerCase(key)
    ).replaceAll(' ', '')}`
  )
}

const AlertSettingCell: FC<{ alertType: string; timeRange: string }> = ({
  alertType,
  timeRange,
}) => (
  <DetailedCell
    text={toIntl('AlertType', alertType)}
    detailText={isSet(timeRange) ? toIntl('TimeRange', timeRange) : undefined}
  />
)

export default AlertSettingCell
