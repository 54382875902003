import React, { FC, ReactNode } from 'react'
import MaterialTooltip from '@material-ui/core/Tooltip'
import styled from '@emotion/styled'
import { ReactComponent as HelpCircle } from 'assets/svg/help-circle.svg'
import Color from 'types/color'

const TooltipContainer = styled.div`
  display: flex;
  align-items: baseline;
`

const TooltipElement: FC<{ copy: ReactNode; color?: string }> = ({
  copy,
  color = Color.Black60,
}) => (
  <MaterialTooltip
    arrow
    title={
      <div
        style={{
          fontSize: 14,
          fontWeight: 300,
          padding: 8,
          lineHeight: '125%',
        }}
      >
        {copy}
      </div>
    }
    style={{ marginLeft: 4 }}
    PopperProps={{
      style: { marginTop: -8 },
    }}
  >
    <HelpCircle fill={color} style={{ transform: 'scale(0.8)' }} />
  </MaterialTooltip>
)

const Tooltip: FC<{
  children: ReactNode
  copy?: ReactNode
  color?: string
}> = ({ children: label, copy, color = Color.Black60 }) => (
  <TooltipContainer>
    {label}
    {copy && <TooltipElement copy={copy} color={color} />}
  </TooltipContainer>
)

export default Tooltip
