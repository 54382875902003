import React, { CSSProperties } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { intlMessageForId } from 'localization'
import Color from 'types/color'
import { TextButton } from './Button'

export type BannerType = {
  status: 'success' | 'error'
  message: string
}

const Container = styled.div`
  color: ${Color.White};
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 2px rgba(22, 42, 56, 0.3);
`
const dismissButtonStyle: CSSProperties = {
  color: Color.White,
  paddingLeft: '16px',
  fontWeight: 'bold',
}

const Banner: React.FunctionComponent<
  BannerType & { marginLeft?: string | number }
> = ({ status, message, marginLeft = 0 }) => {
  const navigate = useNavigate()

  return (
    <Container
      style={{
        background: status === 'success' ? Color.Success : Color.Error,
        marginLeft,
      }}
    >
      {message}
      <TextButton
        style={dismissButtonStyle}
        onClick={() => {
          navigate(window.location, {
            state: {},
            replace: true,
          })
        }}
      >
        {intlMessageForId('Admin.Users.Confirmation.Dismiss')}
      </TextButton>
    </Container>
  )
}

export default Banner
