import React from 'react'
import { Bar, BarDatum, BarTooltipDatum, LabelFormatter } from '@nivo/bar'
import { LegendProps } from '@nivo/legends'
import { flow, keys, map, omit, pull } from 'lodash/fp'
import { getIntlMessageForId } from 'localization'
import Color from 'types/color'
import { DivergingBarTooltipDataType } from 'components/pages/dashboard/ConcernConcFactory/utils'
import SocialDistancing from 'components/SocialDistancing'
import { TooltipContainer } from '../Tooltip'
import { commonLegendProps, margin, theme } from '../utils'
import { NoResultsTakeover } from '../Takeover'
import { SeriesColors } from './utils'
import { DETECTED, NOT_DETECTED } from './constants'

const getColors: (chartKeys: Array<string>) => Array<string> = (chartKeys) =>
  chartKeys.map((key) => SeriesColors[key])

// TODO: look into importing from nivo when we update to the latest version
type TickFormatter = (value: number | string | Date) => string | number

const markerProps = {
  axis: 'y',
  value: 0,
  lineStyle: { strokeOpacity: 0 },
  textStyle: { fill: Color.Black },
  legendOrientation: 'vertical',
  legendOffsetY: 40,
  legendOffsetX: -50,
} as const

// constant DivergingBar props only
const props = {
  width: 1050,
  height: 500,
  margin: { ...margin, left: 65 },
  padding: 0.4,
  theme,
  labelSkipWidth: 16,
  labelSkipHeight: 16,
  minValue: -100,
  maxValue: 100,
  enableGridX: false,
  enableGridY: true,
  labelTextColor: 'inherit:darker(1.2)',
  axisTop: {
    tickSize: 0,
    tickPadding: 12,
  },
  axisRight: null,
  axisLeft: {
    tickSize: 0,
    tickPadding: 12,
    format: ((value: number) => `${value}%`) as TickFormatter,
  },
  markers: [
    {
      ...markerProps,
      legend: DETECTED,
      legendPosition: 'top-left',
    },
    {
      ...markerProps,
      legend: NOT_DETECTED,
      legendPosition: 'bottom-left',
    },
  ],
  legends: [
    {
      ...commonLegendProps,
      itemWidth: 160,
      dataFrom: 'keys',
    },
  ] as Array<LegendProps & { dataFrom: 'keys' | 'indexes' }>,
}

const getTooltip = (tooltipData: DivergingBarTooltipDataType) => ({
  id,
  indexValue,
}: BarTooltipDatum) => {
  const tooltipToIntl = getIntlMessageForId('Charts.DivergingBar.Tooltip')

  const date = indexValue
  const resultType = id
  const base = tooltipData[date][resultType]
  const loads = base.byLoad
  const locations = base.byLocation
  const suppliers = base.bySupplier
  const { formattedDate } = base

  return (
    <TooltipContainer
      // TODO: opacity isn't working here - not sure why
      style={{
        boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.1)',
        padding: 8,
        color: Color.Black,
      }}
    >
      <SocialDistancing spacing="8px">
        <div>
          {tooltipToIntl('Date')}: {formattedDate}
        </div>
        <div>
          {tooltipToIntl('Result')}: {id}
        </div>
        <div>
          {tooltipToIntl('Loads')}: {loads}
        </div>
        <div>
          {tooltipToIntl('Locations')}: {locations}
        </div>
        <div>
          {tooltipToIntl('Suppliers')}: {suppliers}
        </div>
      </SocialDistancing>
    </TooltipContainer>
  )
}

export const hasResults: (data: Array<BarDatum>, indexBy: string) => boolean = (
  data,
  indexBy
) =>
  map((d: BarDatum) => {
    // remove date
    return omit([indexBy], d)
  })(data).filter((obj) => {
    // remove empty objects
    return keys(obj).length > 0
  }).length > 0

const DivergingBar: React.FC<{
  data: Array<BarDatum>
  indexBy: string
  tooltipData?: DivergingBarTooltipDataType
}> = ({ data, indexBy, tooltipData }) => {
  const chartKeys = flow([keys, pull(indexBy)])(data[0])
  const colors = getColors(chartKeys)

  return hasResults(data, indexBy) ? (
    <Bar
      {...props}
      data={data}
      colors={colors}
      indexBy={indexBy}
      keys={chartKeys}
      labelFormat={((v: string) => `${v}%`) as LabelFormatter}
      tooltip={tooltipData && getTooltip(tooltipData)}
    />
  ) : (
    // allows the user to change radio-button filters in the overview even when there's no chart data.
    <NoResultsTakeover />
  )
}

export default DivergingBar
