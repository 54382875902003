export enum FormName {
  Acceptable = 'ACCEPTABLE',
  AcceptedTestAverage = 'ACCEPTED_TEST_AVERAGE',
  Action = 'ACTION',
  AlertType = 'ALERT_TYPE',
  Analyses = 'ANALYSES',
  AnalysisType = 'ANALYSIS_TYPE',
  Analyte = 'ANALYTE',
  AnalyteError = 'ANALYTE_ERROR',
  AnalyteType = 'ANALYTE_TYPE',
  Bin = 'BIN',
  CassetteName = 'CASSETTE_NAME',
  Comment1 = 'COMMENT1',
  Comment2 = 'COMMENT2',
  Comments = 'COMMENTS',
  Commodities = 'COMMODITIES',
  Commodity = 'COMMODITY',
  CreatedAt = 'CREATED_AT',
  CurrentPassword = 'CURRENT_PASSWORD',
  CustomerLocation = 'CUSTOMER_LOCATION',
  DataSource = 'DATA_SOURCE',
  Domain = 'DOMAIN',
  Email = 'EMAIL',
  Email1 = 'EMAIL1',
  Email2 = 'EMAIL2',
  Email3 = 'EMAIL3',
  Email4 = 'EMAIL4',
  Email5 = 'EMAIL5',
  Enabled = 'ENABLED',
  EnvirologixLocation = 'ENVIROLOGIX_LOCATION',
  ExportFormat = 'EXPORT_FORMAT',
  FirstName = 'FIRST_NAME',
  GMOSum = 'GMO_SUM',
  Id = 'ID',
  LabsAccessType = 'LABS_ACCESS_TYPE',
  LabsLocations = 'LABS_LOCATIONS',
  Language = 'LANGUAGE',
  LastName = 'LAST_NAME',
  Location = 'LOCATION',
  Locations = 'LOCATION',
  LotNumber = 'LOT_NUMBER',
  MergeCombResults = 'MERGE_COMB_RESULTS',
  Name = 'NAME',
  NoGmoSum = 'NO_GMO_SUM',
  Operator = 'OPERATOR',
  OrderId = 'ORDER_ID',
  OrgAdmin = 'ORG_ADMIN',
  OvernightRush = 'OVERNIGHT_RUSH',
  Password = 'PASSWORD',
  PasswordConfirmation = 'PASSWORD_CONFIRMATION',
  PoNumber = 'PO_NUMBER',
  QuickscanAccessType = 'QUICKSCAN_ACCESS_TYPE',
  QuickscanLocations = 'QUICKSCAN_LOCATIONS',
  RejectionRate = 'REJECTION_RATE',
  ResultNumeric = 'RESULT_NUMERIC',
  ResultText = 'RESULT_TEXT',
  ResultUnit = 'RESULT_UNIT',
  SampleComments = 'SAMPLE_COMMENTS',
  SampleId = 'SAMPLE_ID',
  Samples = 'SAMPLES',
  SampleType = 'SAMPLE_TYPE',
  Status = 'STATUS',
  Supplier = 'SUPPLIER',
  Terms = 'TERMS',
  TestAverage = 'TEST_AVERAGE',
  Tests = 'TESTS',
  Threshold = 'THRESHOLD',
  TimeRange = 'TIME_RANGE',
  Weight = 'WEIGHT',
  WeightUnit = 'WEIGHT_UNIT',
  When = 'WHEN',
}
