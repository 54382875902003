import React, { FunctionComponent } from 'react'
import { map } from 'lodash/fp'
import { intlMessageForId } from 'localization'
import CallToActionCard from 'components/CallToActionCard'
import Page from 'components/Page'

export type MenuPageElementType = {
  header: string
  body: string
  cta: {
    path: string
    label: string
  }
  key?: string
}

export type MenuElementType = { key: string; path: string }

export const toConfig: (
  getIntl: (key1: string, key2: string) => string
) => (element: MenuElementType) => MenuPageElementType = (getIntl) => (
  element
) => {
  const { key, path } = element
  return {
    key,
    header: getIntl(key, 'Title'),
    body: getIntl(key, 'Description'),
    cta: {
      path,
      label: intlMessageForId(getIntl(key, 'Button')),
    },
  }
}

export const MenuPage: FunctionComponent<{
  title: string
  testId: string
  config: Array<MenuPageElementType>
}> = ({ title, testId, config }) => (
  <Page title={title} testId={testId}>
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '24px' }}>
      {map((element: MenuPageElementType) => {
        const { header, body, cta, key } = element
        return (
          <CallToActionCard
            key={key || header}
            header={header}
            body={body}
            callToAction={{
              to: cta.path,
              label: cta.label,
            }}
          />
        )
      })(config)}
    </div>
  </Page>
)

export default MenuPage
