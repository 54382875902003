import { partialRight } from 'lodash'
import { map, flow, sum, isEmpty, getOr } from 'lodash/fp'
import { Serie, Datum } from '@nivo/line'
import { LoadOutcome } from 'types/enums'
import { formatNumber, toPercent } from 'utils/format'
import Color from 'types/color'
import { PieData } from 'components/charts/Pie'
import { ISODate } from 'types'

export type QuickscanLoadAcceptanceQueryResponse = {
  quickscanLoadAcceptance: {
    dailySummaries: Array<QuickscanLoadAcceptanceDay>
  }
}

export type QuickscanRejectionDetail = {
  rationale: string
  occurrences: number
}

export type QuickscanLoadAcceptanceDay = {
  day: ISODate
  total: number
  accepted: number
  rejected: number
  rejectionDetails: Array<QuickscanRejectionDetail>
  bushelsAccepted?: number
}

// we are rendering the same tooltip regardless of what pie section the user hovers over
const pieTooltip = (datum: QuickscanLoadAcceptanceDay) => ({
  date: datum.day,
  rejectionDetails: datum.rejectionDetails,
  data: [
    {
      loadOutcome: LoadOutcome.Accepted,
      quantity: datum.accepted,
      color: Color.Accepted,
    },
    {
      loadOutcome: LoadOutcome.Rejected,
      quantity: datum.rejected,
      color: Color.Rejected,
    },
  ],
})

export const parseProps: (
  data: QuickscanLoadAcceptanceQueryResponse
) => {
  hasResults: boolean
  pieData: Array<{
    id: string
    value: number
    // TODO: remove `any` below by updating nivo's PieDatum type as it currently only accepts keys with
    // a value of string | number NOT objects per nivo/packages/pie/index.d.ts
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }>
  lineData: Array<Serie>
  totalBushels: string
  acceptRate: string
  overviewPieData: PieData
} = (data) => {
  const dailySummaries = getOr(
    [],
    'quickscanLoadAcceptance.dailySummaries',
    data
  )

  const pieData = isEmpty(dailySummaries)
    ? []
    : [
        {
          id: LoadOutcome.Rejected,
          label: LoadOutcome.Rejected,
          value: dailySummaries[0].rejected,
          color: Color.Rejected,
          tooltip: pieTooltip(dailySummaries[0]),
        },
        {
          id: LoadOutcome.Accepted,
          label: LoadOutcome.Accepted,
          value: dailySummaries[0].accepted,
          color: Color.Accepted,
          tooltip: pieTooltip(dailySummaries[0]),
        },
      ]

  const accepted: Array<Datum & { bushels?: number }> = map(
    (element: QuickscanLoadAcceptanceDay) => ({
      x: element.day,
      y: element.accepted,
      bushels: element.bushelsAccepted,
    })
  )(dailySummaries)

  const rejected: Array<
    Datum & { rejectionDetails: Array<QuickscanRejectionDetail> }
  > = map((element: QuickscanLoadAcceptanceDay) => ({
    x: element.day,
    y: element.rejected,
    rejectionDetails: element.rejectionDetails,
  }))(dailySummaries)

  const lineData: Array<Serie> = [
    {
      id: LoadOutcome.Rejected,
      color: Color.Rejected,
      data: rejected,
    },
    {
      id: LoadOutcome.Accepted,
      color: Color.Accepted,
      data: accepted,
    },
  ]

  const totalBushels = flow(
    map('bushelsAccepted'),
    sum,
    partialRight(formatNumber, 2)
  )(dailySummaries)
  const totalLoads = flow(map('total'), sum)(dailySummaries)
  const totalAccepted = flow(map('accepted'), sum)(dailySummaries)
  const totalRejected = flow(map('rejected'), sum)(dailySummaries)
  const acceptRate = toPercent(totalAccepted / totalLoads, 2)

  const overviewPieData: PieData = [
    {
      id: LoadOutcome.Rejected,
      value: totalRejected,
      color: Color.Rejected,
    },
    {
      id: LoadOutcome.Accepted,
      value: totalAccepted,
      color: Color.Accepted,
    },
  ]

  const hasResults = totalLoads > 0

  return {
    hasResults,
    pieData,
    lineData,
    totalBushels,
    acceptRate,
    overviewPieData,
  }
}
