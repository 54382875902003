import React, { Fragment, FunctionComponent, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { map } from 'lodash/fp'
import ReactMarkdown from 'react-markdown'
import { intlMessageForId } from 'localization'
import { get, isSet } from 'utils'
import { toRelativeTime } from 'utils/relativeTime'
import { ID, ISODateTime, Markdown } from 'types'
import SocialDistancing from 'components/SocialDistancing'
import Divider from 'components/Divider'
import ReadIndicator from 'components/ReadIndicator'
import Section from 'components/Section'
import ExternalLink from 'components/ExternalLink'

type NotificationType = {
  id: ID
  deliverAt: ISODateTime
  title: string
  description: Markdown
  ctaTitle?: string
  ctaUrl?: string
  readAt?: ISODateTime
}

// api returns only notifications that are past their delivery date
const NOTIFICATIONS_QUERY = gql`
  query {
    notifications(limit: 10) {
      id
      deliverAt
      title
      description
      ctaTitle
      ctaUrl
      readAt
    }
  }
`

const READ_NOTIFICATION_MUTATION = gql`
  mutation($id: ID) {
    readNotification(id: $id) {
      id
      readAt
    }
  }
`

const Container = styled.div`
  display: flex;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
`

const FormattedDate = styled.div`
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  white-space: nowrap;
`

const Notification: FunctionComponent<{ notification: NotificationType }> = ({
  notification,
}) => {
  const {
    id,
    title,
    description,
    ctaTitle,
    ctaUrl,
    deliverAt,
    readAt,
  } = notification

  const formattedDate = toRelativeTime(deliverAt)

  const [hideIndicator, setHideIndicator] = useState<boolean>(isSet(readAt))

  const [readNotification] = useMutation(READ_NOTIFICATION_MUTATION, {
    onCompleted: () => setHideIndicator(true),
  })

  return (
    <Fragment key={id}>
      <Container
        style={{ opacity: hideIndicator ? '80%' : '100%' }}
        onClick={
          hideIndicator
            ? undefined
            : () => readNotification({ variables: { id } })
        }
      >
        <ReadIndicator hide={hideIndicator} />
        <div style={{ flexGrow: 1 }}>
          <SocialDistancing spacing="8px">
            <h3 style={{ fontWeight: 'bold' }}>{title}</h3>
            <ReactMarkdown
              skipHtml
              renderers={{
                link: ({ href, children }) => (
                  <ExternalLink href={href}>{children}</ExternalLink>
                ),
              }}
            >
              {description}
            </ReactMarkdown>
            {ctaTitle && ctaUrl && <a href={ctaUrl}>{ctaTitle}</a>}
          </SocialDistancing>
        </div>
        <FormattedDate>{formattedDate}</FormattedDate>
      </Container>
      <Divider style={{ margin: '24px 0' }} />
    </Fragment>
  )
}

const NotificationsSection: FunctionComponent = () => {
  const { data } = useQuery(NOTIFICATIONS_QUERY, { fetchPolicy: 'no-cache' })

  const notifications = get(data, 'notifications', []) as NotificationType[]
  const hasNotifications: boolean = notifications.length > 0

  const elements = map((notification: NotificationType) => {
    return <Notification notification={notification} key={notification.id} />
  })(notifications)

  return hasNotifications ? (
    <Section
      header={intlMessageForId('Homepage.SectionHeaders.Notifications')}
      style={{ display: 'block' }}
    >
      {elements}
    </Section>
  ) : null
}

export default NotificationsSection
