enum Color {
  White = '#FFFFFF',
  White60 = 'rgba(255, 255, 255, 0.6)',
  LightBlue = '#1F94BF',
  DarkBlue = '#053451',
  Gray = '#E5E5E5',
  Black = '#162A38',
  Black60 = 'rgba(22, 42, 56, 0.6)',
  Border = 'rgba(22, 42, 56, 0.1)',
  SelectedBackground = '#ECF9FE',
  UnselectedBackground = 'rgba(22, 42, 56, 0.05)',
  SelectedText = '#176c8c',
  ActiveText = '#176c8c',
  Accent = '#A50069',
  Accepted = '#85CB84',
  Rejected = '#E18F8D',
  Error = '#C31F1B',
  Success = '#38a538',
  ScatterPointSelected = '#2D9CDB',
  ScatterPointDefault = '#A392B3',
  Disabled = 'rgba(0, 0, 0, 0.26)',
  Highlight = 'rgba(225, 143, 141, 0.5)',
  Placeholder = 'rgba(22, 42, 56, 0.60)',
}

export default Color
