import React, { FunctionComponent } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { capitalize, groupBy, map, slice, upperCase } from 'lodash'
import { isSet } from 'utils'
import { AnyObject, FormattedMessageId } from 'types'
import { intlMessageForId } from 'localization'
import { EMPTY_STATE_STRING } from 'constants/index'
import Page from 'components/Page'
import Section from 'components/Section'
import SocialDistancing from 'components/SocialDistancing'
import { Loading, Error } from 'components/Placeholders'
import Table, { TableColumnType } from 'components/Table'
import {
  LabsDivider as divider,
  HeaderSection,
  LabeledValue,
  LargeLabel,
} from './components'
import DownloadSampleReport from './components/DownloadSampleReport'

enum LabSampleStatus {
  'In-Review' = 'IN-REVIEW',
  Approved = 'APPROVED',
}

export const SAMPLE_DETAILS_QUERY = gql`
  query($sampleId: ID) {
    labSample(id: $sampleId) {
      id
      description
      status
      approvedDate
      sampleCommodity
      sampleType
      rush
      order {
        id
        customer {
          customerName
        }
        receivedAt
      }
      tests {
        assay {
          category
          title
          units
          lod
        }
        results
        commentsToCustomer
      }
      sampleComments
      hasReport
    }
  }
`

const InfoSection: FunctionComponent<AnyObject> = (props) => {
  const info = Object.keys(props).map((key) => {
    return {
      id: `Labs.FieldLabels.${key}`,
      value: props[key],
    }
  })

  const middle = Math.floor(info.length / 2)

  const row1 = slice(info, 0, middle)
  const row2 = slice(info, middle, info.length)

  return (
    <Section>
      <div style={{ width: '100%' }}>
        {[row1, row2].map((row, index) => {
          return (
            <div
              style={{ display: 'flex', paddingTop: index > 0 ? '32px' : 0 }}
              key={row[0].id}
            >
              {row.map(
                (content: { id: FormattedMessageId; value?: string }) => {
                  const { id, value } = content
                  return (
                    <LabeledValue
                      key={id}
                      labelId={id}
                      value={value}
                      style={{ flexBasis: `${100 / row.length}%` }}
                    />
                  )
                }
              )}
            </div>
          )
        })}
      </div>
    </Section>
  )
}

type LabTest = Partial<{
  assay: Partial<{
    category: string
    title: string
    units: string
    lod: string
  }>
  results: string
  commentsToCustomer: string
}>

type TableDatum = Partial<{
  assay: string
  result: string
  unit: string
  lod: string
  comments: string
}>

const tableColumns: TableColumnType[] = [
  'assay',
  'result',
  'unit',
  'lod',
  'comments',
].map((field) => {
  return {
    title: intlMessageForId(`Labs.FieldLabels.${field}`),
    field,
    emptyValue: EMPTY_STATE_STRING,
  }
})

const tableData: (tests: LabTest[]) => TableDatum[] = (tests) => {
  return map(tests, (test: LabTest) => {
    const { assay, results: result, commentsToCustomer: comments } = test

    return {
      assay: assay?.title,
      result,
      unit: assay?.units,
      lod: assay?.lod,
      comments,
    }
  })
}

const TableSection: FunctionComponent<{ groupedTests: AnyObject }> = ({
  groupedTests,
}) => {
  return (
    <Section>
      <div style={{ width: '100%' }}>
        <SocialDistancing spacing="48px">
          {Object.keys(groupedTests).map((groupName: string) => {
            return (
              <SocialDistancing spacing="32px" key={groupName}>
                <LargeLabel>{groupName}</LargeLabel>
                <Table
                  columns={tableColumns}
                  data={tableData(groupedTests[groupName])}
                />
              </SocialDistancing>
            )
          })}
        </SocialDistancing>
      </div>
    </Section>
  )
}

const SampleCommentsSection: FunctionComponent<{ sampleComments: string }> = ({
  sampleComments,
}) => (
  <Section>
    <SocialDistancing spacing="32px">
      <LargeLabel>Sample Comments</LargeLabel>
      <div>{sampleComments || EMPTY_STATE_STRING}</div>
    </SocialDistancing>
  </Section>
)

const SampleDetails: React.FunctionComponent = () => {
  const { sampleId } = useParams<{ sampleId: string }>()
  const { loading, error, data } = useQuery(SAMPLE_DETAILS_QUERY, {
    variables: {
      sampleId,
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const {
    description,
    status,
    approvedDate,
    sampleCommodity: commodity,
    sampleType,
    rush,
    order,
    tests,
    sampleComments,
    hasReport,
  } = data.labSample

  const formattedSampleType = capitalize(sampleType)
  const formattedOvernightRush = intlMessageForId(
    `Common.${rush ? 'Yes' : 'No'}`
  )
  const orderId = order.id
  const location = order.customer?.customerName
  const received = order.receivedAt
  const groupedTests = groupBy(tests, 'assay.category')

  return (
    <Page showNav={false} testId="SampleDetails">
      <HeaderSection
        pageTitle={description}
        pageSubtitle={sampleId}
        status={
          isSet(status)
            ? intlMessageForId(`Types.LabSampleStatus.${upperCase(status)}`)
            : undefined
        }
        statusDetails={
          upperCase(status) === LabSampleStatus.Approved ? approvedDate : null
        }
        additionalElement={
          approvedDate &&
          hasReport &&
          sampleId && (
            <DownloadSampleReport
              sampleId={sampleId}
              style={{ backgroundPosition: 'center' }}
            />
          )
        }
      />
      {divider}
      <InfoSection
        commodity={commodity}
        formattedSampleType={formattedSampleType}
        formattedOvernightRush={formattedOvernightRush}
        orderId={orderId}
        location={location}
        received={received}
      />
      {divider}
      <TableSection groupedTests={groupedTests} />
      {divider}
      <SampleCommentsSection sampleComments={sampleComments} />
    </Page>
  )
}

export default SampleDetails
