import React, { ReactNode } from 'react'
import { StyleObject } from 'types'
import Color from 'types/color'

export const GrayContainer: React.FC<{
  style: StyleObject
  children: ReactNode
}> = ({ style = {}, children }) => (
  <div
    style={{
      padding: 32,
      borderRadius: 8,
      ...style,
      backgroundColor: Color.UnselectedBackground,
    }}
  >
    {children}
  </div>
)
