import React, { ReactElement } from 'react'
import { ApolloError } from '@apollo/client'
import { Theme } from '@nivo/core/index'
import { Serie } from '@nivo/line'
import { LegendProps } from '@nivo/legends'
import { SymbolProps } from '@nivo/legends/dist/types/svg/symbols/types'
import { map, flow, reverse } from 'lodash/fp'
import { intlMessageForId } from 'localization'
import { ensureArray } from 'utils'
import { allOptionId, FacetName } from 'components/charts/FacetsProvider'
import {
  LoadingTakeover,
  ErrorTakeover,
  NoResultsTakeover,
  NoOrganizationDataTakeover,
} from './Takeover'

export const legendOffset = -40
export const enableGridX = false
export const margin = { top: 10, right: 55, bottom: 65, left: 55 }
export const theme: Theme = {
  fontFamily: 'Kumbh Sans, sans-serif',
}

export const getTakeover: (
  hasOrganizationData: boolean,
  loading: boolean,
  error?: ApolloError,
  hasResults?: boolean,
  loadingAndErrorOnly?: boolean
) => ReactElement | undefined = (
  hasOrganizationData,
  loading,
  error,
  hasResults,
  loadingAndErrorOnly = false
) => {
  const noResults = !hasResults
  if (!hasOrganizationData) return <NoOrganizationDataTakeover />
  if (loading) return <LoadingTakeover />
  if (error) return <ErrorTakeover />
  if (!loadingAndErrorOnly && noResults) return <NoResultsTakeover />
  return undefined
}

export const getQueryValue: (
  facetName: FacetName,
  selectedValue: string | string[]
) => string | string[] | null = (facetName, selectedValue) => {
  if (facetName === 'analyte') {
    return selectedValue
  }

  const allOption = intlMessageForId(allOptionId(facetName))
  const isAllSelected = ensureArray(selectedValue)[0] === allOption

  return isAllSelected ? null : selectedValue
}

type SymbolShape =
  | 'circle'
  | 'diamond'
  | 'square'
  | 'triangle'
  | React.FC<SymbolProps>

export const commonLegendProps: LegendProps = {
  anchor: 'bottom-left',
  direction: 'row',
  justify: false,
  translateX: legendOffset,
  translateY: 60,
  itemWidth: 120,
  itemHeight: 15,
  itemsSpacing: 4,
  symbolSize: 10,
  itemDirection: 'left-to-right',
  itemTextColor: '#777',
  symbolShape: 'square',
}

export const getLegend: (
  data: Array<Serie>,
  symbolShape?: SymbolShape
) => LegendProps[] = (data: Array<Serie>, symbolShape = 'square') => {
  const multipleSeries = data.length > 1

  return multipleSeries
    ? [
        {
          ...commonLegendProps,
          symbolShape,
          data: flow([
            map((item) => {
              const { id, color } = item as { id: string; color: string }
              return {
                id,
                color,
                label: intlMessageForId(`Types.LoadAcceptanceStatus.${id}`),
              }
            }),
            reverse,
          ])(data),
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]
    : []
}
