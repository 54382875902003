import React, { FC } from 'react'
import { StyleObject } from 'types'
import useCurrentUser from 'hooks/useCurrentUser'
import SocialDistancing from 'components/SocialDistancing'
import NotYetCustomer from 'components/pages/labs/NotYetCustomer'
import SettingTable from 'components/Settings/SettingsTable'
import Divider from 'components/Divider'

const headerContainerStyle: StyleObject = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: 8,
}

const Lab: FC = () => {
  const { isLabsCustomer } = useCurrentUser()

  return isLabsCustomer ? (
    <SocialDistancing spacing="36px">
      <div style={headerContainerStyle}>
        <h2>Lab Notifications</h2>
        <p>
          These email contacts will automatically receive lab order and report
          notifications
        </p>
      </div>
      <SettingTable
        domain="LAB"
        type="EMAIL"
        placeholder="Enter an email address"
      />
      <Divider height="2px" />
      <div style={headerContainerStyle}>
        <h2>Sample Types</h2>
        <p>
          These sample types will appear as drop-down menu options for new lab
          orders (text entry will still be possible for users)
        </p>
      </div>
      <SettingTable
        domain="LAB"
        type="SAMPLE_TYPE"
        placeholder="Enter a sample type"
      />
    </SocialDistancing>
  ) : (
    <NotYetCustomer />
  )
}

export default Lab
