import React, { FunctionComponent, ReactElement } from 'react'
import { StyleObject } from 'types'
import SocialDistancing from 'components/SocialDistancing'
import { FieldLabel } from './index'
import Tooltip from './Tooltip'

const LabeledFormElement: FunctionComponent<{
  name: string
  children: ReactElement
  label?: string
  style?: StyleObject
  tooltip?: string
}> = ({ name, children, label, style = {}, tooltip }) => (
  <SocialDistancing spacing="8px">
    {label && (
      <Tooltip copy={tooltip}>
        <FieldLabel htmlFor={name} style={style}>
          {label}
        </FieldLabel>
      </Tooltip>
    )}
    {children}
  </SocialDistancing>
)

export default LabeledFormElement
