import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './index.css'

// Sets both Universal and GA4 tracker IDs
const isProduction = window.location.host === 'portal.envirologix.com'

if (isProduction) {
  ReactGA.initialize([
    {
      trackingId: 'G-3DCGB09NBM',
    },
    {
      trackingId: 'UA-184247496-1',
    },
  ])
} else {
  ReactGA.initialize([
    {
      trackingId: 'G-S867H10XRB',
    },
    {
      trackingId: 'UA-184247496-2',
    },
  ])
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
