import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import { capitalize, find, remove, update } from 'lodash'
import { map } from 'lodash/fp'
import { intlMessageForId } from 'localization'
import { compare, get, isSet, joinErrorMessages } from 'utils'
import { AnyObject } from 'types'
import { getNewPath, Path } from 'types/enums'
import { LinkButton } from 'components/Button'
import { handleCompleted, handleError } from 'components/FormElements/common'
import { Loading, Error } from 'components/Placeholders'
import Table, {
  DeleteCell,
  getColumns,
  TableColumnType,
} from 'components/Table'
import { Alert, AlertTableDatum, AlertTimeRange } from './types'
import AlertSettingCell, { toIntl } from './components/AlertSettingCell'
import EnabledCell from './components/EnabledCell'

const EMPTY_STATE_STRING = intlMessageForId('Common.Any')

// querying for all fields because they are required for the update call
export const ALERTS_QUERY = gql`
  query {
    alerts {
      id
      alertType
      analyte
      analyteType
      commodities
      emails
      enabled
      locations
      name
      source
      threshold
      timeRange
    }
  }
`

const UPDATE_ALERT_MUTATION = gql`
  mutation(
    $id: ID!
    $alertType: AlertType!
    $analyte: String
    $analyteType: AlertAnalyteType
    $commodities: [String]
    $emails: [String]!
    $enabled: Boolean
    $locations: [String]
    $name: String!
    $source: AlertSource!
    $threshold: Float
    $timeRange: AlertTimeRange
  ) {
    updateAlert(
      id: $id
      input: {
        alertType: $alertType
        analyte: $analyte
        analyteType: $analyteType
        commodities: $commodities
        emails: $emails
        enabled: $enabled
        locations: $locations
        name: $name
        source: $source
        threshold: $threshold
        timeRange: $timeRange
      }
    ) {
      successful
      messages {
        field
        message
      }
      result {
        id
        enabled
      }
    }
  }
`

const DELETE_ALERT_MUTATION = gql`
  mutation($id: ID!) {
    deleteAlert(id: $id)
  }
`

const tableColumns: Array<TableColumnType> = [
  ['name'],
  ['source'],
  ['locations'],
  ['commodities'],
  ['analyte'],
  ['threshold'],
  [
    'enabled',
    true,
    (a: AnyObject, b: AnyObject) => {
      const key = 'enabled.props.alert.enabled'
      return compare(get(a, key), get(b, key), 'descending')
    },
  ],
  // fighting with TS here
  // should be [string, boolean | undefined, ((a: AnyObject, b: AnyObject) => number) | undefined]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
].map((element: Array<any>) => {
  const [field, sorting = true, customSort] = element

  return {
    title: intlMessageForId(`Alerts.FieldLabels.${capitalize(field)}`),
    field,
    emptyValue: EMPTY_STATE_STRING,
    sorting,
    customSort,
  }
})

const Alerts: FC = () => {
  const navigate = useNavigate()
  const [alerts, setAlerts] = useState([])

  const { loading, error, data, refetch } = useQuery(ALERTS_QUERY, {
    fetchPolicy: 'no-cache',
  })

  const [updateAlert, { data: updateData }] = useMutation(
    UPDATE_ALERT_MUTATION,
    {
      onCompleted: ({
        updateAlert: {
          successful,
          result: { id, enabled },
        },
      }) => {
        if (successful) {
          const alert = find(alerts, (a: Alert) => {
            return a.id === id
          })

          if (isSet(alert)) {
            update(alert, 'enabled', () => enabled)
          }
        } else {
          handleError(
            navigate,
            window.location,
            joinErrorMessages(updateData?.messages)
          )
        }
      },
      onError: () => {
        handleError(navigate, window.location)
        refetch()
      },
    }
  )

  const [deleteAlert] = useMutation(DELETE_ALERT_MUTATION, {
    onCompleted: ({ deleteAlert: id }) => {
      remove(alerts, (a: Alert) => {
        return a.id === id
      })

      handleCompleted(
        navigate,
        window.location,
        intlMessageForId('Alerts.AddAlert.Confirmations.Deleted')
      )
    },
    onError: () => {
      handleError(navigate, window.location)
      refetch()
    },
  })

  const response = data?.alerts
  useEffect(() => {
    setAlerts(response)
  }, [response])

  if (loading) return <Loading />
  if (error) return <Error />

  const tableData: Array<AlertTableDatum> = map((alert: Alert) => {
    const {
      alertType,
      analyte,
      commodities,
      id,
      locations,
      name,
      source,
      threshold,
      timeRange,
    } = alert

    const enabledCell = <EnabledCell alert={alert} updateAlert={updateAlert} />

    const settingCell = (
      <AlertSettingCell alertType={alertType} timeRange={timeRange} />
    )

    return {
      id,
      name,
      source,
      locations: isSet(locations) ? locations.join(', ') : EMPTY_STATE_STRING,
      commodities: isSet(commodities)
        ? commodities.join(', ')
        : EMPTY_STATE_STRING,
      analyte: isSet(analyte) ? analyte : EMPTY_STATE_STRING,
      setting: settingCell,
      threshold,
      enabled: enabledCell,
      delete: (
        <DeleteCell
          confirmationMessage={`Are you sure you want to delete the '${name}' alert?`}
          onClick={() =>
            deleteAlert({
              variables: { id },
            })
          }
        />
      ),
    } as AlertTableDatum
  })(alerts) as Array<AlertTableDatum>

  const settingColumn = {
    title: intlMessageForId(`Alerts.FieldLabels.${capitalize('setting')}`),
    field: 'setting',
    emptyValue: EMPTY_STATE_STRING,
    customFilterAndSearch: (value: string, rowData: AnyObject) => {
      const props = get(rowData, 'setting.props', {})
      const { alertType, timeRange } = props

      const text = toIntl('AlertType', alertType)
      const detailText = isSet(timeRange)
        ? toIntl('TimeRange', timeRange)
        : undefined

      const searchableText = detailText ? `${text} ${detailText}` : text

      return searchableText.toLowerCase().indexOf(value.toLowerCase()) !== -1
    },
    customSort: (
      { setting: { props: a } }: AnyObject,
      { setting: { props: b } }: AnyObject
    ) => {
      // padding single digits with a zero for lexicographic comparison
      // returns (2, 7, 14, 30) as otherwise would return 14, 2, 30, 7
      // because javascript compares character by character
      // AlertTimeRange.Today is treated as '01' so it comes first
      const aNumber =
        a.timeRange !== AlertTimeRange.Today
          ? parseInt(a.timeRange?.match(/\d/g)?.join(''), 10)
              .toString()
              .padStart(2, '0')
          : '01'
      const bNumber =
        b.timeRange !== AlertTimeRange.Today
          ? parseInt(b.timeRange?.match(/\d/g)?.join(''), 10)
              .toString()
              .padStart(2, '0')
          : '01'

      const aText = `${toIntl('AlertType', a.alertType)} ${aNumber}`
      const bText = `${toIntl('AlertType', b.alertType)} ${bNumber}`

      return aText.localeCompare(bText)
    },
  }

  return (
    <div>
      <Table
        testId="Alerts"
        columns={getColumns([...tableColumns, settingColumn], ['id'], {
          includeDelete: true,
        })}
        data={tableData}
        search
        sorting
        CallToAction={
          <LinkButton
            to={getNewPath(Path.Reports_Alerts)}
            style={{ float: 'right' }}
          >
            {intlMessageForId('Alerts.Buttons.AddAlert')}
          </LinkButton>
        }
      />
    </div>
  )
}

export default Alerts
