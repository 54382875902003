import { filter, flow, map, mean, property } from 'lodash/fp'
import { ID } from 'types'
import { Path } from 'types/enums'
import { get, isSet } from 'utils'
import { formatNumber, formatResultText } from 'utils/format'
import { isoDateToDateObject, toIsoDateTimeSansMilliseconds } from 'utils/time'

type ResponseData = {
  labTests: Test[]
}

type Test = {
  id: ID
  dateCreated: string
  results: string
  numericResult: number | null
  sample: {
    id: ID
    description: string
    order: {
      id: ID
    }
  }
  units: string
}

type ChartData = Array<{
  x: string
  y: number
  sampleId: ID
  orderId: ID
  formattedDate: string
  url?: string
}>

type FormattedData = Array<{ id: string; data: ChartData }>

type ParseData = {
  hasResults: boolean
  formattedData: FormattedData
  units: string
  formattedNumberOfSamples: string
  formattedAverage?: string
}

export const parseProps: (data: ResponseData) => ParseData = (data) => {
  const labTests: Test[] = get(data, 'labTests', [])

  const chartData = flow([
    filter((test: Test) => {
      return isSet(test.numericResult)
    }),
    map((test: Test) => {
      const { dateCreated, numericResult, results, sample, units } = test
      const date = isoDateToDateObject(dateCreated)

      return {
        x: toIsoDateTimeSansMilliseconds(date.toISOString()),
        y: numericResult,
        resultText: formatResultText(results),
        sampleId: sample.description, // customerSampleId NOT qbenchSampleId
        orderId: sample.order.id,
        formattedDate: date.toLocaleDateString(),
        units,
        url: `${Path.Labs_Orders}/${sample.order.id}/samples/${sample.id}`,
      }
    }),
  ])(labTests)

  const formattedData = [
    {
      id: 'labTests',
      data: chartData,
    },
  ]

  const units = get(chartData, '[0].units', '')
  const hasResults = chartData.length > 0
  const numberOfSamples = chartData.length
  const average = flow([map(property('y')), mean])(chartData) || undefined

  const formattedNumberOfSamples = numberOfSamples.toString()
  const formattedAverage = average && `${formatNumber(average, 1)} ${units}`

  return {
    hasResults,
    formattedData,
    units,
    formattedNumberOfSamples,
    formattedAverage,
  }
}
