import React, { ReactText } from 'react'
import { ResponsiveLine, Serie, Point } from '@nivo/line'
import { endsWith, filter } from 'lodash'
import { map, flow, sum, property } from 'lodash/fp'
import { intlMessageForId } from 'localization'
import { ISODate } from 'types'
import { DateRange, LoadOutcome } from 'types/enums'
import { isoDateToDateObject } from 'utils/time'
import { QuickscanRejectionDetail } from 'components/pages/dashboard/LoadAcceptance/utils'
import { theme, margin, enableGridX, legendOffset, getLegend } from '../utils'
import Tooltip, { TooltipData } from '../Tooltip'

const dateFormatOptions: { [key: string]: string } = {
  month: '2-digit',
  day: '2-digit',
}

const formattedDate: (isoDate: ISODate, locale?: string) => string = (
  isoDate,
  locale = window.navigator.language
) => {
  return new Intl.DateTimeFormat(locale, dateFormatOptions).format(
    isoDateToDateObject(isoDate)
  )
}

const Line: React.FunctionComponent<{
  data: Array<Serie>
  dateRange: DateRange
}> = ({ data, dateRange }) => (
  <ResponsiveLine
    data={data}
    theme={theme}
    margin={margin}
    enableGridX={enableGridX}
    curve="monotoneX"
    enablePoints={false}
    enableSlices="x"
    lineWidth={3}
    colors={map('color')(data)}
    yScale={{
      type: 'linear',
      stacked: false,
    }}
    axisLeft={{
      legend: intlMessageForId(
        'Charts.LoadAcceptance.AxisLabels.Yaxis'
      ).toUpperCase(),
      legendPosition: 'middle',
      legendOffset,
      tickSize: 0,
    }}
    axisBottom={{
      format(value: string | number | Date) {
        const isoDate = value as string

        if (dateRange === DateRange.Year) {
          return endsWith(isoDate, '01') ? formattedDate(isoDate) : ''
        }

        if (dateRange === DateRange.Month) {
          return isoDateToDateObject(isoDate).getDay() === 1
            ? formattedDate(isoDate)
            : ''
        }

        return formattedDate(isoDate)
      },
      tickSize: 0,
    }}
    legends={getLegend(data)}
    sliceTooltip={({ slice }) => {
      const { points } = slice

      const acceptedData = filter(points, { serieId: LoadOutcome.Accepted })[0]
        .data as {
        x: string | number | Date
        xFormatted: ReactText
        y: string | number | Date
        yFormatted: ReactText
        yStacked?: number | undefined
      } & { bushels: number }

      const date: string = isoDateToDateObject(
        acceptedData.xFormatted as ISODate
      ).toLocaleDateString()

      const { bushels } = acceptedData

      const totalTests = flow([map(property(['data', 'y'])), sum])(points)

      const acceptedTests = acceptedData.y as number

      const acceptRate: number | undefined =
        totalTests > 0 ? acceptedTests / totalTests : undefined

      const { rejectionDetails } = filter(points, {
        serieId: LoadOutcome.Rejected,
      })[0].data as {
        x: string | number | Date
        xFormatted: ReactText
        y: string | number | Date
        yFormatted: ReactText
        yStacked?: number | undefined
      } & { rejectionDetails: Array<QuickscanRejectionDetail> }

      const tooltipData: TooltipData = map((point: Point) => ({
        loadOutcome: point.serieId.toString().toLowerCase(),
        quantity: point.data.y as number,
        color: point.serieColor,
      }))(points)

      return (
        <Tooltip
          date={date}
          bushels={bushels}
          totalTests={totalTests}
          acceptRate={acceptRate}
          data={tooltipData}
          rejectionDetails={rejectionDetails}
        />
      )
    }}
  />
)

export default Line
