import dayjs from 'dayjs'
import { EMPTY_STATE_STRING } from 'constants/index'

type NumberFormatter = (
  number: number,
  precision?: number,
  options?: Intl.NumberFormatOptions
) => string

const setDecimalPrecision: (precision: number) => Intl.NumberFormatOptions = (
  precision: number
) => {
  return {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  }
}

export const formatNumber: NumberFormatter = (
  number,
  precision = 0,
  options = {}
) => {
  return new Intl.NumberFormat(undefined, {
    ...setDecimalPrecision(precision),
    ...options,
  }).format(number)
}

export const toPercent: NumberFormatter = (
  number,
  precision = 0,
  options = {}
) => {
  return number === Infinity
    ? EMPTY_STATE_STRING
    : `${new Intl.NumberFormat(undefined, {
        ...setDecimalPrecision(precision),
        ...options,
      }).format(number * 100)}%`
}

/**
 * Formats resultText containing numeric values to have a fixed number of decimal places (one by default)
 * @param resultText string containing text (e.g., "<LOD") or numeric value (e.g., "1.2")
 * @param precision number of decimal places
 * @returns string containing text (e.g., "<LOD") or numeric value with fixed number of decimal places (e.g., "1.20")
 */
export const formatResultText: (
  resultText: string,
  precision?: number
) => string = (resultText, precision = 1) => {
  return Number.isNaN(Number(resultText))
    ? resultText
    : formatNumber(parseFloat(resultText), precision)
}

export const formatDate: (date: string) => string = (date) => {
  return dayjs(date).toDate().toLocaleDateString()
}
