import React from 'react'
import styled from '@emotion/styled'
import Color from 'types/color'
import FilterArrow from 'assets/svg/filter-arrow.svg'
import { AnyObject } from 'types'

const StyledSelect = styled.select`
  font-family: 'Kumbh Sans', sans-serif;
  border: ${Color.Gray} solid 1px;
  border-radius: 4px;
  color: ${Color.Black};
  font-size: 16px;
  padding: 16px 38px 16px 20px;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 20px top 50%, 0 0;

  :focus {
    outline: none;
  }
`

const Select: React.FunctionComponent<AnyObject> = ({
  multiple,
  style,
  ...props
}) => {
  const baseStyles = multiple ? {} : { backgroundImage: `url(${FilterArrow})` }
  return (
    <StyledSelect
      multiple={multiple}
      {...props}
      style={{ ...baseStyles, ...style }}
    />
  )
}

export default Select
