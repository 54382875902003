import React, { ReactChild } from 'react'
import { FormattedMessage } from 'react-intl'
import { capitalize } from 'lodash'
import SocialDistancing from 'components/SocialDistancing'
import { formatNumber, toPercent } from 'utils/format'
import { QuickscanRejectionDetail } from 'components/pages/dashboard/LoadAcceptance/utils'
import { isSet } from 'utils'
import { StyleObject } from 'types'

type TooltipDatum = {
  loadOutcome: string
  quantity: number
  color: string
}

export type TooltipData = Array<TooltipDatum>

export const TooltipContainer: React.FunctionComponent<{
  children: ReactChild | ReactChild[]
  style?: StyleObject
}> = ({ children, style = {} }) => (
  <div
    style={{
      background: 'rgba(255, 255, 255, 0.85)',
      boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.1)',
      borderRadius: 8,
      padding: 16,
      textAlign: 'left',
      fontSize: 12,
      lineHeight: '12px',
      ...style,
    }}
  >
    {children}
  </div>
)

const Tooltip: React.FunctionComponent<{
  date: string
  bushels?: number
  totalTests?: number
  acceptRate?: number
  rejectionDetails: Array<QuickscanRejectionDetail>
  data: TooltipData
}> = ({ date, bushels, totalTests, acceptRate, rejectionDetails, data }) => (
  <TooltipContainer>
    <div style={{ paddingBottom: 16 }}>
      <SocialDistancing spacing="8px">
        <div>
          <FormattedMessage id="Common.Date" />
          {': '} {date}
        </div>
        {isSet(bushels) && (
          <div>
            <FormattedMessage id="Charts.Tooltip.BushelsAccepted" />
            {': '}
            {formatNumber(bushels as number, 2)}
          </div>
        )}
        {isSet(totalTests) && (
          <div>
            <FormattedMessage id="Charts.Tooltip.TotalTests" />
            {': '} {totalTests}
          </div>
        )}
        {isSet(acceptRate) && (
          <div>
            <FormattedMessage id="Charts.Tooltip.AcceptRate" />
            {': '} {toPercent(acceptRate as number, 2)}
          </div>
        )}
      </SocialDistancing>
    </div>
    <SocialDistancing spacing="8px">
      {data.map((datum: TooltipDatum) => {
        const label = capitalize(datum.loadOutcome)
        const value = datum.quantity

        return (
          <div key={label} style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                backgroundColor: `${datum.color}`,
                width: 8,
                height: 8,
              }}
            />
            <div style={{ paddingLeft: 5 }}>
              {value} {label}
            </div>
          </div>
        )
      })}
      {rejectionDetails.map((detail) => (
        <div
          key={detail.rationale}
          style={{ paddingLeft: '13px', color: 'rgba(22, 42, 56, 0.6)' }}
        >
          {detail.occurrences} {detail.rationale}
        </div>
      ))}
    </SocialDistancing>
  </TooltipContainer>
)

export default Tooltip
