import React, { FunctionComponent } from 'react'
import { gql, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { capitalize, map } from 'lodash/fp'
import { useParams } from 'react-router-dom'
import { PrintButton } from 'components/Button'
import { SectionDivider } from 'components/Divider'
import Page, { PRINT_ROW_GAP } from 'components/Page'
import { Loading, Error } from 'components/Placeholders'
import { SectionHeader } from 'components/Section'
import { intlMessageForId } from 'localization'
import Color from 'types/color'
import { isSet } from 'utils'
import { formatDate as format } from 'utils/format'
import { DownloadReport } from './components'
import DetailsTable from './DetailsTable'
import { QuickscanBiReportOutput, QuickscanBiReportResult } from './types'
import { LabeledValue } from '../../labs/components'

export const QUICKSCAN_BI_REPORT_QUERY = gql`
  query($id: ID!) {
    quickscanBiReport(id: $id) {
      analysisType
      bins
      columns
      locations
      operators
      recordedAt
      results {
        analyte
        commodity
        analysesResults {
          acceptedTestAverage
          acceptedTestCount
          name
          rejectedTestCount
          rejectionRate
          testAverage
          testCount
        }
        threshold
        unit
      }
      status
      suppliers
      whenMax
      whenMin
    }
  }
`

const AnalyticsDivider = <SectionDivider />

const ResultTitle = styled.div`
  color: ${Color.Black};
  font-size: 20px;
  font-weight: 700;
`

const ResultSubtitle = styled.div`
  color: ${Color.Black};
  font-size: 14px;
  font-weight: 400;
`

const LargeContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 48px;

  @media print {
    row-gap: ${PRINT_ROW_GAP};
  }
`

const SmallContainer = styled(LargeContainer)`
  row-gap: 24px;
`

const AnalyticsQuickScanDetails: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>()
  const { loading, error, data } = useQuery(QUICKSCAN_BI_REPORT_QUERY, {
    variables: {
      id,
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const {
    analysisType,
    bins,
    columns,
    locations,
    operators,
    results,
    status,
    suppliers,
    whenMin,
    whenMax,
  }: QuickscanBiReportOutput = data.quickscanBiReport

  const filterSectionTitle = `${intlMessageForId(
    'Analytics.QuickScan.Details.Sections.Filter.Title'
  )} ${intlMessageForId(
    `Analytics.QuickScan.Form.Fields.AnalysisType.Options.${capitalize(
      analysisType
    )}`
  )}`

  return (
    <Page
      testId="AnalyticsQuickScanDetails"
      showNav={false}
      title={intlMessageForId('Analytics.QuickScan.Details.Title')}
      headerElement={
        <div>
          {status === 'COMPLETED' && id && <DownloadReport reportId={id} />}
          <PrintButton />
        </div>
      }
    >
      <SmallContainer>
        {AnalyticsDivider}
        <SmallContainer>
          <div style={{ display: 'flex', columnGap: 12 }}>
            <div style={{ flexGrow: 1 }}>
              <SectionHeader>{filterSectionTitle}</SectionHeader>
            </div>
            <div style={{ display: 'flex', gap: 48, paddingRight: 48 }}>
              <LabeledValue
                labelId="Common.StartDate"
                value={format(whenMin)}
              />
              <LabeledValue labelId="Common.EndDate" value={format(whenMax)} />
            </div>
          </div>
          {map(({ filter, values }) => {
            return isSet(values) ? (
              <LabeledValue
                labelId={`Analytics.QuickScan.Details.Sections.Filter.Labels.${filter}Filter`}
                value={values.join(', ')}
                split={false}
                key={filter}
              />
            ) : null
          })([
            { filter: 'Location', values: locations },
            { filter: 'Supplier', values: suppliers },
            { filter: 'Operator', values: operators },
            { filter: 'Bin', values: bins },
          ])}
        </SmallContainer>
        {AnalyticsDivider}
        {status === 'PENDING' && (
          <div style={{ textAlign: 'center' }}>
            {intlMessageForId(
              'Analytics.QuickScan.Details.AnalysesResults.Status.Pending'
            )}
          </div>
        )}
        {status === 'COMPLETED' &&
          map((result: QuickscanBiReportResult) => {
            const {
              analysesResults,
              analyte,
              commodity,
              threshold,
              unit,
            } = result

            return (
              <LargeContainer
                key={`${analyte}-${commodity}-${threshold}-${unit}`}
              >
                <header>
                  <ResultTitle>
                    {analyte} - {commodity}
                  </ResultTitle>
                  {threshold && (
                    <ResultSubtitle>
                      {intlMessageForId(
                        'Analytics.QuickScan.Form.Fields.THRESHOLD'
                      )}
                      : {threshold} {unit}
                    </ResultSubtitle>
                  )}
                </header>
                <DetailsTable
                  analysisType={analysisType}
                  analysesResults={analysesResults}
                  unit={unit}
                  threshold={threshold}
                  columns={columns}
                />
                {AnalyticsDivider}
              </LargeContainer>
            )
          })(results)}
      </SmallContainer>
    </Page>
  )
}

export default AnalyticsQuickScanDetails
