import { MutableRefObject } from 'react'
import { get } from 'utils'

// only applicable to form element refs
export const deconstructFormElementRef: (
  ref: MutableRefObject<unknown>
) => { value: string; valid: boolean; message: string } = (ref) => ({
  value: get(ref, 'current.value'),
  valid: get(ref, 'current.validity.valid'),
  message: get(ref, 'current.validationMessage'),
})
