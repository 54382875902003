import React, { FunctionComponent, ReactElement } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { pick } from 'lodash'
import { get, map } from 'lodash/fp'
import { intlMessageForId } from 'localization'
import { isSet } from 'utils'
import {
  EventAction,
  EventCategory,
  EventLabel,
  trackEvent,
} from 'utils/analytics'
import { ID, ISODateTime } from 'types'
import { Path } from 'types/enums'
import { EMPTY_STATE_STRING } from 'constants/index'
import Table, {
  DetailedCell,
  PAGINATED_ROWS_PER_PAGE,
  Pagination,
  usePageQueryParam,
  getOffset,
  TableColumnType,
} from 'components/Table'
import { Loading, Error } from 'components/Placeholders'
import SocialDistancing from 'components/SocialDistancing'

export enum LabOrderStatus {
  Created = 'CREATED',
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
}

type LabOrder = Partial<{
  id: ID
  customer: {
    customerName: string
  }
  numSamples: number
  createdAt: ISODateTime
  receivedAt: ISODateTime
  completedAt: ISODateTime
  state: LabOrderStatus
}>

type TableDatum = Omit<LabOrder, 'id' | 'customer' | 'state'> &
  Partial<{
    orderId: ID
    location: string
    status: ReactElement
  }>

const tableColumns: Array<TableColumnType> = [
  'orderId',
  'location',
  'numSamples',
  'createdAt',
  'receivedAt',
  'completedAt',
  'status',
].map((field) => {
  return {
    title: intlMessageForId(`Labs.FieldLabels.${field}`),
    field,
    emptyValue: EMPTY_STATE_STRING,
  }
})

export const LAB_ORDERS_QUERY = gql`
  query($limit: Int, $offset: Int) {
    labOrders(limit: $limit, offset: $offset) {
      id
      customer {
        customerName
      }
      numSamples
      createdAt
      receivedAt
      completedAt
      state
    }
  }
`

const LabOrders: FunctionComponent<{ limit?: number }> = ({
  limit = PAGINATED_ROWS_PER_PAGE,
}) => {
  const page = usePageQueryParam()
  const navigate = useNavigate()
  const { loading, error, data, refetch } = useQuery(LAB_ORDERS_QUERY, {
    variables: {
      limit,
      offset: getOffset(page, limit),
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const labOrders = get('labOrders', data)

  // #pick is a workaround for an incompatibility issue between immutable new Apollo cache and
  // requirement of material-table for data to be mutable -- c.eldridge:15dec2020
  // https://github.com/mbrn/material-table/issues/1979
  const tableData: Array<TableDatum> = map((order: LabOrder) => {
    const picked = pick(order, [
      'numSamples',
      'createdAt',
      'receivedAt',
      'completedAt',
    ])

    const status = (
      <DetailedCell
        text={intlMessageForId(`Types.LabOrderStatus.${order.state}`)}
        detailText={
          order.state === LabOrderStatus.Pending ? 'Est: yyyy-mm-dd' : undefined
        }
      />
    )

    return {
      ...picked,
      status,
      orderId: order.id,
      location: order.customer?.customerName,
    } as TableDatum
  })(labOrders) as Array<TableDatum>

  return (
    <SocialDistancing spacing="48px">
      <Table
        testId="LabOrders"
        columns={tableColumns}
        data={tableData}
        onRowClick={(_e: MouseEvent, rowData: { orderId?: string }) => {
          trackEvent(
            EventCategory.ListItem,
            EventAction.Click,
            EventLabel.LabOrder
          )
          navigate(`${Path.Labs_Orders}/${rowData.orderId}`)
        }}
      />
      <Pagination
        limit={limit}
        fetched={isSet(labOrders) ? labOrders.length : 0}
        refetch={refetch}
        page={page}
        path={Path.Labs_Orders}
      />
    </SocialDistancing>
  )
}

export default LabOrders
