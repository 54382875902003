export enum Locale {
  English = 'en',
  Portuguese = 'pt',
  German = 'de',
  Spanish = 'es',
  French = 'fr',
  Hungarian = 'hu',
  Italian = 'it',
  Polish = 'pl',
  Russian = 'ru',
}

export const getLocale = (): Locale => {
  const preferredLanguage = window.navigator.language.split('-')[0]

  const isSupportedLocale = (locale: string): locale is Locale => {
    return Object.values(Locale).includes(locale as Locale)
  }

  return isSupportedLocale(preferredLanguage)
    ? preferredLanguage
    : Locale.English
}
