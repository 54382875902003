import React, { FunctionComponent } from 'react'
import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { snakeCase, toUpper } from 'lodash'
import { intlMessageForId } from 'localization'
import { get, getFloat, isSet, traverseObject } from 'utils'
import { AnyObject, ID } from 'types'
import { FormName } from 'types/forms'
import { Loading, Error } from 'components/Placeholders'
import Group from 'components/FormElements/Group'
import {
  ToggledInput,
  ToggledRange,
  ToggledSelect,
} from 'components/FormElements'
import {
  INITIAL_VALUES_MAP,
  groupTitle,
  fieldLabel,
  getValue,
  getColumns,
} from '../utils'
import { CreateReportType, RangeValueType } from '../types'
import ReportsMultiSelect, {
  getSelectOptionsMap,
} from '../components/ReportsMultiSelect'
import ReportBuilderPage from '../components/ReportBuilderPage'

export const LAB_TRANSLATED_FACETS_QUERY = gql`
  query {
    labTranslatedFacets {
      analysisType {
        key
        label
      }
      analyte {
        key
        label
      }
      commodity {
        key
        label
      }
      orderId
      resultText
      resultUnit
      sampleType
    }
    labCustomers {
      qbenchCustomerId
      customerName
    }
  }
`

export const CREATE_LAB_REPORT_MUTATION = gql`
  mutation($columns: [LabReportColumn]!, $filters: LabReportFiltersInput!) {
    createLabReport(columns: $columns, filters: $filters)
  }
`

const initialValues = {
  [FormName.OrderId]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.Location]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.SampleId]: INITIAL_VALUES_MAP.input,
  [FormName.Commodity]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.SampleType]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.AnalysisType]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.Analyte]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.ResultNumeric]: INITIAL_VALUES_MAP.range,
  [FormName.ResultText]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.ResultUnit]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.GMOSum]: INITIAL_VALUES_MAP.range,
}

const onSubmit = (
  publishPayload?: (payload: Record<string, Array<string> | AnyObject>) => void
) => (formikValues: AnyObject, createReport: CreateReportType) => {
  const columns = getColumns(formikValues)

  const {
    ANALYSIS_TYPE,
    ANALYTE,
    COMMODITY,
    GMO_SUM,
    LOCATION,
    ORDER_ID,
    RESULT_NUMERIC,
    RESULT_TEXT,
    RESULT_UNIT,
    SAMPLE_ID,
    SAMPLE_TYPE,
    WHEN,
  } = formikValues

  const filters: AnyObject = {
    analysisType: getValue(ANALYSIS_TYPE),
    analyte: getValue(ANALYTE),
    commodity: getValue(COMMODITY),
    gmoSumMax: getFloat(getValue(GMO_SUM) as RangeValueType, 'max'),
    gmoSumMin: getFloat(getValue(GMO_SUM) as RangeValueType, 'min'),
    location: getValue(LOCATION),
    orderId: getValue(ORDER_ID),
    resultNumericMax: getFloat(
      getValue(RESULT_NUMERIC) as RangeValueType,
      'max'
    ),
    resultNumericMin: getFloat(
      getValue(RESULT_NUMERIC) as RangeValueType,
      'min'
    ),
    resultText: getValue(RESULT_TEXT),
    resultUnit: getValue(RESULT_UNIT),
    sampleId: getValue(SAMPLE_ID),
    sampleType: getValue(SAMPLE_TYPE),
    whenMax: dayjs(get(WHEN, 'max')).endOf('day').toISOString(),
    whenMin: dayjs(get(WHEN, 'min')).startOf('day').toISOString(),
  }

  const payload = {
    columns,
    filters,
  }

  if (isSet(publishPayload)) {
    publishPayload(payload)
  }

  createReport({
    variables: payload,
  })
}

const Labs: FunctionComponent<{
  publishPayload?: (payload: Record<string, string[] | AnyObject>) => void
}> = ({ publishPayload }) => {
  const { data, loading, error } = useQuery(LAB_TRANSLATED_FACETS_QUERY)

  if (loading) return <Loading />
  if (error) return <Error />

  // `labTranslatedFacets` returns a mix of translated facets and facets that cannot be translated
  const { analysisType, analyte, commodity, ...notTranslated } = get(
    data,
    'labTranslatedFacets',
    {}
  )

  const translatedOptions = traverseObject(
    { analysisType, analyte, commodity },
    (key) => toUpper(snakeCase(key)),
    (key, value) => {
      return value.map((v: { label: string; key: string }) => {
        return { label: v.label, value: v.key }
      })
    },
    '__typename'
  )

  const selectOptionsMap = {
    ...translatedOptions,
    ...getSelectOptionsMap(notTranslated),
  }

  return (
    <ReportBuilderPage
      testId="LabReport"
      initialValues={initialValues}
      createReportMutation={CREATE_LAB_REPORT_MUTATION}
      mutationResponseKey="createLabReport"
      onSubmit={onSubmit(publishPayload)}
    >
      <Group title={groupTitle('SampleInformation')}>
        <ReportsMultiSelect
          formName={FormName.OrderId}
          selectOptions={selectOptionsMap[FormName.OrderId]}
          defaultChecked
        />
        <ReportsMultiSelect
          formName={FormName.Location}
          selectOptions={data?.labCustomers.map(
            (labCustomer: { qbenchCustomerId: ID; customerName: string }) => {
              const { qbenchCustomerId, customerName } = labCustomer
              return { label: customerName, value: qbenchCustomerId.toString() }
            }
          )}
          defaultChecked
        />
        <ToggledInput
          name={FormName.SampleId}
          label={fieldLabel(FormName.SampleId)}
          defaultChecked
        />
        {
          // NOTE: overriding the default commodity label here until a global label is determined
          // TODO: use `ReportsMultiSelect` component again after
        }
        <ToggledSelect
          name={FormName.Commodity}
          label="Commodity"
          selectOptions={selectOptionsMap[FormName.Commodity]}
          defaultChecked
          multiple
        />
        <ReportsMultiSelect
          formName={FormName.SampleType}
          selectOptions={selectOptionsMap[FormName.SampleType]}
          defaultChecked
        />
      </Group>
      <Group title={groupTitle('Results')}>
        <ReportsMultiSelect
          formName={FormName.AnalysisType}
          selectOptions={selectOptionsMap[FormName.AnalysisType]}
          defaultChecked
        />
        <ReportsMultiSelect
          formName={FormName.Analyte}
          selectOptions={selectOptionsMap[FormName.Analyte]}
          defaultChecked
        />
        <ToggledRange
          name={FormName.ResultNumeric}
          label={fieldLabel(FormName.ResultNumeric)}
          defaultChecked
          tooltip={intlMessageForId(
            `Reports.Tooltips.${FormName.ResultNumeric}`
          )}
        />
        <ReportsMultiSelect
          formName={FormName.ResultText}
          selectOptions={selectOptionsMap[FormName.ResultText]}
          tooltip={intlMessageForId(`Reports.Tooltips.${FormName.ResultText}`)}
        />
        <ReportsMultiSelect
          formName={FormName.ResultUnit}
          selectOptions={selectOptionsMap[FormName.ResultUnit]}
          defaultChecked
        />
        <ToggledRange
          name={FormName.GMOSum}
          label={fieldLabel(FormName.GMOSum)}
          defaultChecked
        />
      </Group>
    </ReportBuilderPage>
  )
}

export default Labs
