/* eslint-disable @typescript-eslint/no-explicit-any */
import { get as _get, isNaN, isNull } from 'lodash'
import { flow, map, merge, omit, reduce } from 'lodash/fp'
import { AnyObject } from 'types'
import { EMPTY_STATE_STRING } from 'constants/index'
import { formatNumber, toPercent } from './format'

export const isSet = <T>(value: T | undefined | null): value is T => {
  if (Array.isArray(value)) {
    return value.length > 0
  }

  if (typeof value === 'string') {
    return value !== ''
  }

  return value !== undefined && value !== null
}

/**
 * Like lodash#get except defaultValue is returned if the resolved value is undefined OR null.
 * @param object the object to query.
 * @param path the path of the property to get.
 * @param defaultValue the value returned for undefined or null resolved values
 * @returns resolved value or default value
 */
export const get: (
  object: AnyObject,
  path: string | Array<string>,
  defaultValue?: any
) => any = (object, path, defaultValue) => {
  const lodashResult = _get(object, path, defaultValue)
  return isNull(lodashResult) ? defaultValue : lodashResult
}

// Formik needs an empty string as an input default but the api needs a float
// fallback of `undefined` instead of `null` to cull network payload
export const getFloat: (
  object: AnyObject,
  path: string,
  defaultValue?: any
) => number | undefined = (object, path, defaultValue) => {
  return parseFloat(get(object, path, defaultValue)) || undefined
}

/**
 * parseInt but with a fallback so a number is always returned.
 * @param s string to parse.
 * @param n number fallback
 * @returns an integer parsed from the given string or the fallback if parseInt returns NaN.
 */
export const parseIntOr: (s: string, n: number) => number = (s, n) => {
  const result = parseInt(s, 10)
  return isNaN(result) ? n : result
}

export const openUrl: (url: string) => void = (url) => {
  if (url) {
    window.open(url, '_blank', 'noopener noreferrer')
  }
}

// TODO: replace console errors with an application monitoring servce -- c.eldridge:26apr2021
// for example: https://sentry.io/for/react/
export const logError: (
  message: string,
  restrictions?: 'none' | 'not-production'
) => void = (message, restrictions = 'none') => {
  const shouldLog =
    restrictions === 'none' ||
    (restrictions === 'not-production' && process.env.NODE_ENV !== 'production')

  if (shouldLog) {
    // eslint-disable-next-line no-console
    console.error(message)
  }
}

export const ensureArray: (arg: any) => Array<any> = (arg) => {
  return Array.isArray(arg) ? arg : [arg]
}

/**
 * Like lodash#mapValues except access to both key and value during iteration (instead of just value).
 * @param obj the object to iterate over.
 * @param func the function called on each key/value pair of the object.
 * @returns the object with the same keys but updated values.
 */
export const mapValues: (
  obj: AnyObject,
  func: (key: string, value: any) => any
) => AnyObject = (obj, func) => {
  return map((e: [string, any]) => {
    const key = e[0]
    const value = e[1]
    return { [key]: func(key, value) }
  })(Object.entries(obj)).reduce(merge, {})
}

/**
 * Iterates through each key/value pair of an object and inserts the func result into an array.
 * @param obj the object to iterate over.
 * @param func the function called on each key/value pair of the object.
 * @returns an array created from the func arg.
 */
export const mapObject: (
  obj: AnyObject,
  func: (key: string, value: any) => any
) => Array<any> = (obj, func) => {
  return map((e: [string, any]) => {
    const key = e[0]
    const value = e[1]
    return func(key, value)
  })(Object.entries(obj))
}

/**
 * Iterates through each key/value pair of an object after omitting prop keys and returns the resulting object based on key and value functions.
 * @param obj the object to iterate over.
 * @param keyFunc the function called on each object key.
 * @param valueFunc the function called on each object value.
 * @param omitted keys to omit from the original object.
 * @returns a new object.
 */
export const traverseObject: (
  obj: AnyObject,
  keyFunc: (key: string) => string,
  valueFunc: (key: string, value: any) => any,
  omitted?: string | string[]
) => AnyObject = (obj, keyFunc, valueFunc, omitted = []) => {
  return flow([
    map((e: [string, any]) => {
      const key = e[0]
      const value = e[1]
      return { [keyFunc(key)]: valueFunc(key, value) }
    }),
    reduce(merge, {}),
  ])(Object.entries(omit(omitted, obj)))
}

/**
 * Iterates through a collection of objects and pairs values based on the mapping function.
 * @param collection the collection of objects to integrate over.
 * @param mapFunc mapping function used to pair values.
 * @returns an object of paired values.
 */
export const pairValues: (
  collection: Array<Record<string, any>>,
  mapFunc: (element: Record<string, any>) => Record<string, any>
) => Record<string, string> = (collection, mapFunc) => {
  return flow([map(mapFunc), reduce(merge, {})])(collection)
}

/**
 * regular division for UI elements (by always returning a formatted string)
 * except returns a fallback string if the denominator argument is zero.
 * @param numerator top number.
 * @param denominator bottom number.
 * @param options.precision number of decimal places.
 * @param options.asPercent boolean for percent formatting.
 * @param options.fallback returned string if the denominator argument is zero.
 * @returns formatted number string or fallback string.
 */
export const safeDivide: (
  numerator: number,
  denominator: number,
  options?: {
    precision?: number
    asPercent?: boolean
    fallback?: string
  }
) => string = (numerator, denominator, options = {}) => {
  const defaultOptions = {
    precision: 1,
    asPercent: false,
    fallback: EMPTY_STATE_STRING,
  }

  const opts = { ...defaultOptions, ...options }
  const { precision, asPercent, fallback } = opts

  if (denominator === 0) {
    return fallback
  }

  const result = numerator / denominator

  if (asPercent) {
    return toPercent(result, precision)
  }

  return formatNumber(result, precision)
}

type ValidationMessage = {
  field: string
  message: string
}

export const joinErrorMessages: (
  messages: Array<ValidationMessage>
) => string = (messages) => {
  return messages
    .map((m: ValidationMessage) => {
      return m.message
    })
    .join(', ')
}

type ComparableType = string | number | boolean | Date

export const compare: (
  a: ComparableType,
  b: ComparableType,
  direction?: 'ascending' | 'descending'
) => number = (a, b, direction = 'ascending') => {
  if (a === b) {
    return 0
  }
  const value = direction === 'ascending' ? -1 : 1
  return a < b ? value : value * -1
}

/**
 * Inserts an element at a specific index of an array.
 * @param array the original array.
 * @param index index to insert the new element.
 * @param element the element to be inserted
 * @returns the new array.
 */
export const insertAtIndex: (
  array: Array<any>,
  index: number,
  element: any
) => Array<any> = (array, index, element) => {
  // using a copy because splice mutates the original array
  const copy = Array(...array)
  copy.splice(index, 0, element)
  return copy
}

/**
 * Quick UUID generator
 * @returns Returns a random string that can be used as a unique identifier for a component key
 */
const QUICK_UUID_HEAD = 'temp-id'
export const quickUuid: () => string = () => {
  const random = Math.random().toString().substring(2)
  return `${QUICK_UUID_HEAD}-${random}`
}

export const isTemporaryId: (id: string | number) => boolean = (id) => {
  return String(id).startsWith(QUICK_UUID_HEAD)
}
