import React, { FunctionComponent, ReactElement, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { get, map } from 'lodash/fp'
import { intlMessageForId } from 'localization'
import { isSet, openUrl } from 'utils'
import {
  EventAction,
  EventCategory,
  EventLabel,
  trackEvent,
} from 'utils/analytics'
import { ID, ISODate } from 'types'
import { Concern, LayoutDirection, Path } from 'types/enums'
import { EMPTY_STATE_STRING } from 'constants/index'
import Table, {
  getColumns,
  MultilineCell,
  PAGINATED_ROWS_PER_PAGE,
  Pagination,
  usePageQueryParam,
  getOffset,
  TableColumnType,
} from 'components/Table'
import { Loading, Error } from 'components/Placeholders'
import SocialDistancing from 'components/SocialDistancing'
import FacetsProvider, {
  appendAllOption,
  FacetValue,
  Facets,
} from 'components/charts/FacetsProvider'
import { getQueryValue } from 'components/charts/utils'
import useCustomDateFilter from 'components/charts/hooks/useCustomDateFilter'
import useFilterFactory from 'components/charts/hooks/useFilterFactory'
import { SampleIdTableCell } from './components'
import DownloadSampleReport from './components/DownloadSampleReport'
import useLabsCustomerLocationFacet from './hooks/useLabsCustomerLocationFacet'

export const LAB_TRANSLATED_FACETS_QUERY = gql`
  query {
    labTranslatedFacets {
      commodity {
        key
        label
      }
    }
  }
`

const getTitleId: (identifier: string) => string = (identifier) =>
  `labResultsTable-${identifier}`

type TableDatum = Partial<{
  sampleId: ReactElement
  orderId: ID
  commodity: string
  analysisType: ReactElement
  result: ReactElement
  unit: ReactElement
  reportDate: ReactElement
}>

const tableColumns: Array<TableColumnType> = [
  'sampleId',
  'orderId',
  'commodity',
  'analysisType',
  'result',
  'unit',
  'reportDate',
].map((field) => {
  return {
    title: intlMessageForId(`Labs.FieldLabels.${field}`),
    field,
    emptyValue: EMPTY_STATE_STRING,
  }
})

export const LAB_SAMPLES_QUERY = gql`
  query(
    $limit: Int
    $offset: Int
    $commodity: String
    $concern: String
    $qbenchCustomerId: ID
    $dateCreatedMin: Date
    $dateCreatedMax: Date
  ) {
    labSamples(
      limit: $limit
      offset: $offset
      commodity: $commodity
      concern: $concern
      qbenchCustomerId: $qbenchCustomerId
      dateCreatedMin: $dateCreatedMin
      dateCreatedMax: $dateCreatedMax
    ) {
      id
      order {
        id
      }
      sampleCommodity
      results {
        category
        value
        unit
      }
      approvedDate
      hasReport
      description
    }
  }
`

type LabSampleResult = {
  category: string
  value: string
  unit: string
}

type LabSample = {
  id: ID
  order: {
    id: ID
  }
  sampleCommodity: string
  results: Array<LabSampleResult>
  approvedDate: ISODate
  hasReport: boolean
  description: string
}

const ReportDateCell: FunctionComponent<{
  reportDate: string
  hasReport: boolean
  sampleId: ID
}> = ({ reportDate, hasReport, sampleId }) => (
  <SocialDistancing spacing="20px" direction={LayoutDirection.Horizontal}>
    {reportDate}
    {reportDate && hasReport && <DownloadSampleReport sampleId={sampleId} />}
  </SocialDistancing>
)

type LabResultsType = {
  limit?: number
  paginate?: boolean
  showFilters?: boolean
  concern?: Concern
}

const LabResults: FunctionComponent<
  { titleId: string; facets?: Facets; concern?: Concern } & LabResultsType
> = ({
  titleId,
  facets = {},
  limit = PAGINATED_ROWS_PER_PAGE,
  paginate = true,
  showFilters = true,
  concern,
}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const {
    Component: CustomDateRange,
    startDate,
    endDate,
    key: dateFilterKey,
  } = useCustomDateFilter(titleId)

  const { Filters, selectedFacets } = useFilterFactory(titleId, facets)
  const additionalFilters = [<CustomDateRange key={dateFilterKey} />]

  // resets paginated page to 1 on filter change
  useEffect(() => {
    if (paginate) {
      navigate(location, { state: { search: '?page=1' } })
    }
    // including 'history' and 'location' in the dependency array caueses a 'Maximum update depth exceeded' error
    // and are NOT needed anyway.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacets, startDate, endDate])

  const page = usePageQueryParam()

  const filterPayload = showFilters
    ? {
        commodity: getQueryValue('commodity', selectedFacets.commodity),
        qbenchCustomerId: getQueryValue('location', selectedFacets.location),
        dateCreatedMin: startDate,
        dateCreatedMax: endDate,
      }
    : {}

  const { loading, error, data, refetch } = useQuery(LAB_SAMPLES_QUERY, {
    variables: {
      limit,
      offset: getOffset(page, limit),
      concern,
      ...filterPayload,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const labSamples = get('labSamples', data)

  const tableData: Array<TableDatum> = map((labSample: LabSample) => {
    const {
      id,
      order,
      sampleCommodity: commodity,
      results,
      approvedDate: reportDate,
      hasReport,
      description,
    } = labSample

    const sampleId = (
      <SampleIdTableCell customerSampleId={description} qbenchSampleId={id} />
    )

    const getLineItems = (key: string) => {
      return map((result: LabSampleResult) => {
        return { key: result.category, value: get(key, result) }
      })(results)
    }

    return {
      id,
      sampleId,
      orderId: order?.id,
      commodity,
      analysisType: <MultilineCell lineItems={getLineItems('category')} />,
      result: <MultilineCell lineItems={getLineItems('value')} />,
      unit: <MultilineCell lineItems={getLineItems('unit')} />,
      reportDate: reportDate ? (
        <ReportDateCell
          reportDate={reportDate}
          hasReport={hasReport}
          sampleId={id} // qbenchSampleId NOT customerSampleId
        />
      ) : null,
    }
  })(labSamples) as Array<TableDatum>

  return (
    <SocialDistancing spacing="48px">
      {showFilters && <Filters additionalFilters={additionalFilters} />}
      <Table
        testId="LabResults"
        columns={getColumns(tableColumns, ['id'])}
        data={tableData}
        onRowClick={(
          _e: MouseEvent,
          rowData: { orderId?: string; id?: ID }
        ) => {
          const { orderId, id: sampleId } = rowData
          trackEvent(
            EventCategory.ListItem,
            EventAction.Click,
            EventLabel.LabResult
          )
          openUrl(`${Path.Labs_Orders}/${orderId}/samples/${sampleId}`)
        }}
      />
      {paginate && (
        <Pagination
          limit={limit}
          fetched={isSet(labSamples) ? labSamples.length : 0}
          refetch={refetch}
          page={page}
          path={Path.Labs_Results}
        />
      )}
    </SocialDistancing>
  )
}

type FacetsResponseData = {
  commodity: FacetValue[]
}

export default ((props) => {
  const { showFilters } = props
  const locationFacet = useLabsCustomerLocationFacet()

  return showFilters ? (
    <FacetsProvider
      query={LAB_TRANSLATED_FACETS_QUERY}
      facetsKey="labTranslatedFacets"
      render={(facets) => {
        const { commodity } = facets as FacetsResponseData

        return (
          <LabResults
            titleId={getTitleId('showFilters')}
            {...props}
            facets={appendAllOption(
              { commodity, ...locationFacet },
              {
                commodity: {
                  optional: true,
                },
                location: {
                  optional: true,
                },
              }
            )}
          />
        )
      }}
    />
  ) : (
    <LabResults titleId={getTitleId('hideFilters')} {...props} />
  )
}) as FunctionComponent<LabResultsType>
