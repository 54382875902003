import * as Yup from 'yup'
import {
  ToggledControlName,
  REQUIRED_IF_SELECTED_ERROR_MESSAGE,
  REQUIRED_NUMERIC_ERROR_MESSAGE,
  REQUIRED_ERROR_MESSAGE,
} from 'components/FormElements'
import {
  ToggledSelectType,
  ToggledNumericInputType,
} from 'components/FormElements/ToggledControl'

export const requiredSelectValidation = Yup.lazy((value: ToggledSelectType) => {
  const multi = Array.isArray(value[ToggledControlName.Value])

  return Yup.object().shape({
    [ToggledControlName.Value]: multi
      ? Yup.array().min(1, REQUIRED_ERROR_MESSAGE)
      : Yup.string().required(REQUIRED_ERROR_MESSAGE),
  })
})

export const toggledSelectValidation = Yup.lazy((value: ToggledSelectType) => {
  const multi = Array.isArray(value[ToggledControlName.Value])

  const uncheckedValidation = multi
    ? Yup.array().notRequired()
    : Yup.string().notRequired()

  const checkedValidation = multi
    ? Yup.array().min(1, REQUIRED_IF_SELECTED_ERROR_MESSAGE)
    : Yup.string().required(REQUIRED_IF_SELECTED_ERROR_MESSAGE)

  return Yup.object().shape({
    [ToggledControlName.Value]:
      value[ToggledControlName.Checked] === false
        ? uncheckedValidation
        : checkedValidation,
  })
})

export const toggledNumericInputValidation = Yup.lazy(
  (value: ToggledNumericInputType) => {
    return Yup.object().shape({
      [ToggledControlName.Value]:
        value[ToggledControlName.Checked] === false
          ? Yup.number().notRequired()
          : Yup.number()
              .required(REQUIRED_IF_SELECTED_ERROR_MESSAGE)
              .typeError(REQUIRED_NUMERIC_ERROR_MESSAGE),
    })
  }
)
