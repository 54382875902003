import useCurrentUser, { FeatureFlags } from 'hooks/useCurrentUser'

// broken out into its own hook to facilitate mocking
const useFeatureFlags: () => undefined | FeatureFlags = () => {
  const { loading, error, featureFlags } = useCurrentUser()

  return loading || error ? undefined : featureFlags
}

export default useFeatureFlags
