import React, { ChangeEvent, FC } from 'react'
import { useFormikContext } from 'formik'
import { map } from 'lodash'
import { TextButton } from 'components/Button'
import Divider from 'components/Divider'
import {
  AutoComplete,
  FormInput,
  FormSelect,
  FormRadioButtons,
  Textarea,
} from 'components/FormElements'
import { ContentHeader } from 'components/FormElements/common'
import SocialDistancing from 'components/SocialDistancing'
import { LayoutDirection } from 'types/enums'
import { FormName } from 'types/forms'
import { get, isSet } from 'utils'
import { SampleFormValue, GetFieldName, Assay } from './types'
import { useLabSampleTemplates } from './useLabSampleTemplates'
import { getDefaultTestMap, groupAssays } from './utils'
import LabAssays from './LabAssays'

const SampleSection: FC<{
  name: string
  value: SampleFormValue
  sampleIndex: number
  assays: Array<Assay>
  commodities: Array<string>
  multipleSamples: boolean
  remove: <T>(index: number) => T | undefined
  defaultSampleTypes?: Array<string>
}> = ({
  name,
  value,
  sampleIndex,
  assays,
  commodities,
  multipleSamples,
  remove,
  defaultSampleTypes,
}) => {
  const assaysByCommodity = groupAssays('commodity')(assays)
  const assaysForCommodity = assaysByCommodity[value.COMMODITY]
  const assaysByConcern = groupAssays('concern')(assaysForCommodity)

  const getFieldName: GetFieldName = (formName) =>
    `${name}.${sampleIndex}.${formName}`

  const { handleChange, setFieldValue } = useFormikContext()

  const {
    hasSampleTemplates,
    ChooseSampleTemplate,
    SaveSampleTemplate,
  } = useLabSampleTemplates(getFieldName)

  return (
    <div>
      {sampleIndex > 0 && <Divider style={{ margin: '48px 0' }} />}
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ minWidth: 300, paddingRight: '32px' }}>
          <ContentHeader style={{ marginBottom: 12 }}>
            Sample Details
          </ContentHeader>
          <SocialDistancing spacing="24px">
            {hasSampleTemplates && <ChooseSampleTemplate />}
            <FormInput
              name={getFieldName(FormName.SampleId)}
              label="SAMPLE ID"
              placeholder="Enter a Sample ID"
              style={{ width: '100%' }}
            />
            <FormSelect
              name={getFieldName(FormName.Commodity)}
              emptyState="Select a commodity"
              options={commodities.map((commodity) => ({
                label: commodity,
                value: commodity,
              }))}
              label="Commodity"
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                // resets selected assays on commodity change
                setFieldValue(
                  getFieldName(FormName.Tests),
                  get(getDefaultTestMap(assays), e.target.value, {}),
                  true
                )
                handleChange(e)
              }}
              style={{ width: '100%' }}
            />
            <FormInput
              as={AutoComplete}
              name={getFieldName(FormName.SampleType)}
              label="SAMPLE TYPE"
              placeholder="Select or enter a sample type"
              style={{ width: '100%' }}
              list={FormName.SampleType}
            />
            <datalist id={FormName.SampleType}>
              {map(defaultSampleTypes, (sampleType: string) => {
                return <option key={sampleType}>{sampleType}</option>
              })}
            </datalist>
            <FormRadioButtons
              name={getFieldName(FormName.OvernightRush)}
              label="RUSH SERVICE?"
              options={[
                {
                  label: 'No',
                  value: 'false',
                },
                {
                  label: 'Yes',
                  value: 'true',
                },
              ]}
            />
            <FormInput
              name={getFieldName(FormName.SampleComments)}
              label="SAMPLE COMMENTS"
              as={Textarea}
              style={{ width: '100%' }}
              placeholder="Ex. Lot ID or Bin #"
            />
            {multipleSamples && (
              <TextButton onClick={() => remove(sampleIndex)}>
                Delete
              </TextButton>
            )}
            <SaveSampleTemplate formValue={value} />
          </SocialDistancing>
        </div>
        <SocialDistancing direction={LayoutDirection.Horizontal} spacing="48px">
          {(() => {
            const { MYCOTOXIN: mycotoxinAssays } = assaysByConcern

            if (!isSet(mycotoxinAssays)) {
              return null
            }

            const assaysByAnalyte = groupAssays('analyte')(mycotoxinAssays)

            return (
              <LabAssays
                concern="mycotoxin"
                header="MYCOTOXIN LC-MS"
                subheader="4-DAY STANDARD  |  2-DAY RUSH"
                sections={[
                  {
                    title: 'Standard Panel',
                    groupedAssays: assaysByAnalyte,
                  },
                ]}
                formValue={value}
                getFieldName={getFieldName}
              />
            )
          })()}
          {(() => {
            const { GMO: gmoAssays } = assaysByConcern

            if (!isSet(gmoAssays)) {
              return null
            }

            const assaysBySection = groupAssays('portalOrderForm')(gmoAssays)

            const standardAssaysByAnalyte = groupAssays('analyte')(
              assaysBySection.Standard
            )

            const sections = [
              {
                title: 'Standard Panel',
                groupedAssays: standardAssaysByAnalyte,
              },
            ]

            if (isSet(standardAssaysByAnalyte)) {
              const additionalAssaysByAnalyte = groupAssays('analyte')(
                assaysBySection['Add-on']
              )

              sections.push({
                title: 'Additional Assays',
                groupedAssays: additionalAssaysByAnalyte,
              })
            }

            return (
              <LabAssays
                concern="gmo"
                header="GMO PCR"
                subheader="3-DAY STANDARD  |  OVERNIGHT RUSH"
                sections={sections}
                formValue={value}
                getFieldName={getFieldName}
              />
            )
          })()}
        </SocialDistancing>
      </div>
    </div>
  )
}

export default SampleSection
