import React from 'react'
import styled from '@emotion/styled'
import SocialDistancing from 'components/SocialDistancing'
import ExternalLink from 'components/ExternalLink'
import ExampleLabsDashboard from 'assets/png/example-labs-dashboard.png'

const Div = styled.div`
  display: inline-block;

  header {
    font-weight: bold;
    font-size: 24px;
  }

  p {
    width: 0;
    min-width: 100%;
    font-size: 16px;
    line-height: 22px;
  }

  img {
    margin: 24px 0;
    box-shadow: 0px 4px 24px rgba(22, 42, 56, 0.3);
    border-radius: 8px;
  }
`

const NotYetCustomer: React.FunctionComponent = () => (
  <Div>
    <SocialDistancing spacing="36px">
      <header>
        EnviroLogix now offers GMO lab testing with TotalTest Lab Services
      </header>
      <p>
        Located in St. Charles, MO and Portland, ME, the TotalTest Labs are{' '}
        <b>
          <ExternalLink href="https://www.nongmoproject.org/product-verification/testing-labs/">
            Non-GMO Project
          </ExternalLink>{' '}
          Approved
        </b>{' '}
        and{' '}
        <b>
          ISO 1025 Accredited by the{' '}
          <ExternalLink href="https://www.a2la.org/">
            American Association for Laboratory Accreditation (A2LA)
          </ExternalLink>
        </b>
        . Purchasing your decision-point LFD and PCR lab testing from a single
        vendor can play a key part in coordinating operational efficiencies, and
        the EnviroLogix data portal allows you to view and manage your data from
        both sides of the GMO testing equation. If you’d like to learn more
        about TotalTest Lab Services, visit:{' '}
        <b>
          <ExternalLink href="https://www.envirologix.com/lab-services/">
            envirologix.com/lab-services
          </ExternalLink>
        </b>
        .
      </p>
      <img src={ExampleLabsDashboard} alt="example labs dashboard" />
    </SocialDistancing>
  </Div>
)

export default NotYetCustomer
