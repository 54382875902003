import { useLocation } from 'react-router-dom'
import { get } from 'utils'
import { BannerType } from 'components/Banner'

const useBanner: () => undefined | BannerType = () => {
  const routerLocation = useLocation()
  const status = get(routerLocation, 'state.status')
  const message = get(routerLocation, 'state.message')

  return status ? { status, message } : undefined
}

export default useBanner
