import React, { FunctionComponent } from 'react'
import { map, property } from 'lodash/fp'
import { intlMessageForId } from 'localization'
import { Path } from 'types/enums'
import { SegmentType, useSegmentedControl } from 'components/SegmentedControl'
import Page from 'components/Page'
import Divider from 'components/Divider'
import Users from './users/Users'
import Lab from './lab'

enum SegmentKey {
  UsersSegment = 'USERS_SEGMENT',
  LabSegment = 'LAB_SEGMENT',
}

const segments: Array<SegmentType> = [
  {
    key: SegmentKey.UsersSegment,
    title: intlMessageForId('Admin.Segments.User'),
    path: Path.AdminSettings_Users,
  },
  {
    key: SegmentKey.LabSegment,
    title: intlMessageForId('Admin.Segments.Lab'),
    path: Path.AdminSettings_Lab,
  },
]

const cases = {
  [SegmentKey.UsersSegment]: <Users />,
  [SegmentKey.LabSegment]: <Lab />,
}

export const adminPaths: string[] = map(property(['path']))(segments)

const Admin: FunctionComponent = () => {
  const { SegmentedControl, selectedSegment } = useSegmentedControl(segments)

  return (
    <Page testId="Admin" title={intlMessageForId('Admin.Header')}>
      <SegmentedControl />
      <Divider />
      <div style={{ paddingTop: '48px' }}>
        {cases[selectedSegment.key as SegmentKey]}
      </div>
    </Page>
  )
}

export default Admin
