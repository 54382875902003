import React, { FunctionComponent } from 'react'
import { useFormikContext } from 'formik'
import { AnyObject, StyleObject } from 'types'
import Color from 'types/color'
import { get } from 'utils'
import RadioButtons, {
  disabledStyle,
  RadioButtonOption,
} from 'components/RadioButtons'
import SocialDistancing from 'components/SocialDistancing'
import ErrorMessage from './ErrorMessage'

export const FormRadioButtons: FunctionComponent<{
  name: string
  options: RadioButtonOption[]
  label?: string
  displayError?: boolean
  labelStyle?: StyleObject
  tooltip?: string
}> = ({
  name,
  options,
  label,
  displayError = true,
  labelStyle = {},
  tooltip,
}) => {
  const { values, handleChange } = useFormikContext()

  return (
    <SocialDistancing
      spacing="8px"
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <RadioButtons
        name={name}
        options={options}
        radioButtonStyle={{
          rootStyles: {
            color: Color.SelectedText,
            '&$checked': {
              color: '#176C8C',
              '&:hover': {
                backgroundColor: Color.Border,
              },
              '&.Mui-disabled': disabledStyle,
            },
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#176C8C',
            },
          },
        }}
        label={label}
        labelStyle={labelStyle}
        tooltip={tooltip}
        handleChange={handleChange}
        selectedValue={get(values as AnyObject, name)}
      />
      {displayError && <ErrorMessage forName={name} />}
    </SocialDistancing>
  )
}

export default FormRadioButtons
