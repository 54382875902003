import React, { FunctionComponent, ReactElement } from 'react'
import { useQuery, gql } from '@apollo/client'
import ChartLayoutManager from 'components/charts/ChartLayoutManager'
import Line from 'components/charts/Line'
import Pie from 'components/charts/Pie'
import { getDateRange, isSingleDay, timeZone } from 'utils/time'
import { DateRange } from 'types/enums'
import Color from 'types/color'
import useCurrentUser from 'hooks/useCurrentUser'
import { LoadAcceptanceOverview as Overview } from 'components/charts/Overview'
import { Facets } from 'components/charts/FacetsProvider'
import { getQueryValue, getTakeover } from 'components/charts/utils'
import { useFilterFactory } from 'components/charts/hooks/useFilterFactory'
import useCustomDateFilter from 'components/charts/hooks/useCustomDateFilter'
import { parseProps } from './utils'

export const QUICKSCAN_LOAD_ACCEPTANCE_QUERY = gql`
  query(
    $startDate: Date!
    $endDate: Date!
    $timeZone: String!
    $commodity: [String]
    $location: [String]
    $supplier: [String]
  ) {
    quickscanLoadAcceptance {
      dailySummaries(
        startDate: $startDate
        endDate: $endDate
        timeZone: $timeZone
        commodity: $commodity
        location: $location
        supplier: $supplier
      ) {
        day
        total
        accepted
        rejected
        rejectionDetails {
          occurrences
          rationale
        }
        bushelsAccepted
      }
    }
  }
`

const titleId = 'Charts.LoadAcceptance'

const LoadAcceptance: FunctionComponent<{
  testId?: string
  facets: Facets
}> = ({ testId, facets }) => {
  const {
    Component: CustomDateRange,
    dateRange,
    startDate,
    endDate,
    key,
  } = useCustomDateFilter(titleId)

  const { Filters, selectedFacets } = useFilterFactory(titleId, facets)
  const additionalFilters = [<CustomDateRange key={key} />]

  const { loading, error, data } = useQuery(QUICKSCAN_LOAD_ACCEPTANCE_QUERY, {
    variables: {
      startDate,
      endDate,
      timeZone,
      commodity: getQueryValue('commodity', selectedFacets.commodity),
      location: getQueryValue('location', selectedFacets.location),
      supplier: getQueryValue('supplier', selectedFacets.supplier),
    },
    fetchPolicy: 'no-cache',
  })

  const {
    hasResults,
    pieData,
    lineData,
    totalBushels,
    acceptRate,
    overviewPieData,
  } = parseProps(data)

  const renderPieChart: boolean = isSingleDay(startDate, endDate)
  const renderLineChart = !renderPieChart

  const standardDateRange =
    dateRange === DateRange.Custom
      ? getDateRange(startDate, endDate)
      : dateRange

  const chart: () => ReactElement = () => {
    return renderPieChart ? (
      <div
        style={{
          height: '100%',
          width: '350px',
          paddingLeft: '30%',
          paddingRight: '16px',
          backgroundColor: Color.DarkBlue,
        }}
      >
        <Pie data={pieData} />
      </div>
    ) : (
      <Line data={lineData} dateRange={standardDateRange} />
    )
  }

  const filters = () => <Filters additionalFilters={additionalFilters} />

  const overview = () => (
    <Overview
      acceptanceRate={acceptRate}
      acceptedBushels={totalBushels}
      chart={
        renderLineChart ? (
          <Pie
            data={overviewPieData}
            pieProps={{ isInteractive: false, enableSlicesLabels: false }}
            holeProps={{ valueSize: 'Normal' }}
          />
        ) : undefined
      }
      style={renderPieChart ? { flexGrow: 1, borderRadius: 0 } : {}}
    />
  )

  const { isQuickscanCustomer } = useCurrentUser()
  const takeover = () =>
    getTakeover(isQuickscanCustomer, loading, error, hasResults, false)

  return (
    <ChartLayoutManager
      chart={chart()}
      filters={filters()}
      overview={overview()}
      contentStyles={renderPieChart ? { overflow: 'hidden' } : {}}
      takeover={takeover()}
      testId={testId}
    />
  )
}

export default LoadAcceptance
