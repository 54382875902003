import React, { FunctionComponent } from 'react'
import { FieldArray } from 'formik'
import { SecondaryButton } from 'components/Button'
import Divider from 'components/Divider'
import Section from 'components/Section'
import { FormName } from 'types/forms'
import { get } from 'utils'
import { SampleFormValue, Assay } from './types'
import SampleSection from './SampleSection'
import { getNewSample } from './utils'

const SamplesSection: FunctionComponent<{
  name: string
  commodities: string[]
  assays: Array<Assay>
  values: Array<SampleFormValue>
  defaultSampleTypes?: Array<string>
}> = ({ name, commodities, values, assays, defaultSampleTypes }) => {
  const multipleSamples: boolean = values.length > 1

  return (
    <Section header="Samples" style={{ flexDirection: 'column' }}>
      <FieldArray name={name}>
        {({ push, remove }) => (
          <div>
            {values.length > 0 &&
              values.map((value, sampleIndex) => {
                const key = get(value, FormName.Id)
                return (
                  <SampleSection
                    key={key}
                    name={name}
                    value={value}
                    sampleIndex={sampleIndex}
                    assays={assays}
                    commodities={commodities}
                    multipleSamples={multipleSamples}
                    remove={remove}
                    defaultSampleTypes={defaultSampleTypes}
                  />
                )
              })}
            <Divider style={{ margin: '48px 0' }} />
            <SecondaryButton onClick={() => push(getNewSample())}>
              + Add Sample
            </SecondaryButton>
          </div>
        )}
      </FieldArray>
    </Section>
  )
}

export default SamplesSection
