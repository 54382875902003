/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Color from 'types/color'

const WIDTH = '24px'
const HEIGHT = '25px'

const styles = css`
animation: loading 3.0s infinite linear;

@keyframes loading {
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
`

/**
 * Loading indicator that will automatically center in its parent container with `position: relative`
 * @param color
 * @returns centered loading indicator svg
 */
export const LoadingIndicator: React.FC<{
  color?: string
}> = ({ color = Color.Black }) => (
  <div
    style={{
      position: 'absolute',
      top: `calc(50% - calc(${HEIGHT}/2))`, // half its container minus half the indicator's height
      left: `calc(50% - calc(${WIDTH}/2))`, // half its container minus half the indicator's width
    }}
    css={styles}
  >
    <svg
      width={WIDTH}
      height={HEIGHT}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.747v3.75m0 18.75v-3.75M4.045 5.042l2.652 2.652m13.258 13.258l-2.652-2.65M.75 12.997H4.5m18.75 0H19.5M4.045 20.952l2.652-2.65m13.258-13.26l-2.652 2.652"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
)

export default LoadingIndicator
