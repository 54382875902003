import React, { FunctionComponent } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useFormikContext } from 'formik'
import { intlMessageForId } from 'localization'
import { AnyObject, ID } from 'types'
import { Error } from 'components/Placeholders'
import { SecondaryButton } from 'components/Button'
import { toApiFormat } from '../TestResults/utils'

const CREATE_TEMPLATE_MUTATION = gql`
  mutation(
    $title: string!
    $columns: [QuickscanReportColumn]!
    $filters: QuickscanReportFiltersTemplateInput!
    $mergeCombResults: Boolean
  ) {
    createQuickscanReportTemplate(
      title: $title
      columns: $columns
      filters: $filters
      mergeCombResults: $mergeCombResults
    ) {
      result {
        id
      }
      successful
    }
  }
`

const SaveAsTemplate: FunctionComponent<{
  refetchTemplates: () => void
  setSelectedTemplateId: (templateId: ID) => void
}> = ({ refetchTemplates, setSelectedTemplateId }) => {
  const { values } = useFormikContext()

  const [createQuickscanReportTemplate, { error: mutationError }] = useMutation(
    CREATE_TEMPLATE_MUTATION,
    {
      onCompleted: (mutationData) => {
        if (mutationData.createQuickscanReportTemplate.successful) {
          refetchTemplates()
          setSelectedTemplateId(
            mutationData.createQuickscanReportTemplate.result.id
          )
        }
      },
    }
  )

  const promptForName = () => {
    // using alert by design
    // eslint-disable-next-line no-alert
    const title = window.prompt(
      intlMessageForId('Reports.Dialogs.NameTemplatePrompt'),
      intlMessageForId('Reports.Dialogs.NameTemplateDefault')
    )
    if (title) {
      const apiFormat = toApiFormat(values as AnyObject, false)
      createQuickscanReportTemplate({
        variables: { ...apiFormat, ...{ title } },
      })
    }
  }

  if (mutationError) return <Error />

  return (
    <SecondaryButton
      style={{ marginLeft: 0, marginRight: 0, width: '100%' }}
      onClick={promptForName}
    >
      {`${intlMessageForId('Reports.CTAs.SaveAsTemplate')}`}
    </SecondaryButton>
  )
}

export default SaveAsTemplate
