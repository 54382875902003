import React, { FC } from 'react'
import { map } from 'lodash/fp'
import MenuPage, { MenuElementType, toConfig } from 'components/MenuPage'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { intlMessageForId } from 'localization'
import { Path } from 'types/enums'

const getIntl: (key1: string, key2: string) => string = (key1, key2) => {
  return `Reports.Menu.CallToActions.${key1}.${key2}`
}

const ReportsMenu: FC = () => {
  const config: Array<MenuElementType> = [
    { key: 'Download', path: Path.Reports_TestResults },
  ]

  const featureFlags = useFeatureFlags()
  const alerts = featureFlags?.emailAlerts
  const biReport = featureFlags?.biReport

  if (biReport) {
    config.push({
      key: 'Analytics',
      path: Path.Analytics_QuickScanForm,
    })
  }

  if (alerts) {
    config.push({
      key: 'Alerts',
      path: Path.Reports_Alerts,
    })
  }

  return (
    <MenuPage
      title={intlMessageForId('Reports.Menu.Title')}
      testId="ReportsMenu"
      config={map(toConfig(getIntl))(config)}
    />
  )
}

export default ReportsMenu
