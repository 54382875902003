import React, { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { Formik, Form } from 'formik'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Navigate, useNavigate, useParams, useLocation } from 'react-router-dom'
import { intlMessageForId } from 'localization'
import { get } from 'utils'
import { ID } from 'types'
import { FormName } from 'types/forms'
import { LayoutDirection, Path } from 'types/enums'
import useCurrentUser from 'hooks/useCurrentUser'
import Page from 'components/Page'
import { Error, Loading } from 'components/Placeholders'
import SocialDistancing from 'components/SocialDistancing'
import Section from 'components/Section'
import { DeleteButton, SecondaryButton } from 'components/Button'
import { FieldLabel } from 'components/FormElements/index'
import { LocationAccessType } from 'components/FormElements/LocationFilter'
import {
  FormValues,
  validationSchema,
  HeaderElement,
  Profile,
  Location,
} from './AddUser'
import {
  getDisplayName,
  getStatus,
  getUserBannerProps,
  UserStatus,
} from './utils'

export const USER_QUERY = gql`
  query($id: ID) {
    user(id: $id) {
      email
      firstName
      lastName
      orgAdmin
      language
      hasPassword
      locations
      qbenchCustomerIds
    }
  }
`

const UPDATE_USER_MUTATION = gql`
  mutation(
    $id: ID!
    $email: String!
    $firstName: String!
    $lastName: String!
    $orgAdmin: Boolean!
    $locations: [String]
    $qbenchCustomerIds: [Int]
  ) {
    updateUser(
      id: $id
      input: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        orgAdmin: $orgAdmin
        locations: $locations
        qbenchCustomerIds: $qbenchCustomerIds
      }
    ) {
      successful
      messages {
        message
      }
    }
  }
`

const FieldValue = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`

const ManageAccount: FunctionComponent<{ userId: ID }> = ({ userId }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const DELETE_USER_MUTATION = gql`
    mutation($id: ID) {
      deleteUser(id: $id)
    }
  `

  const [deleteUser] = useMutation(DELETE_USER_MUTATION, {
    variables: {
      id: userId,
    },
    onCompleted: () => {
      navigate(Path.AdminSettings_Users, {
        state: getUserBannerProps(UserStatus.Deleted),
      })
    },
  })

  const INVITE_USER_MUTATION = gql`
    mutation($id: ID) {
      inviteUser(id: $id)
    }
  `
  const [inviteUser] = useMutation(INVITE_USER_MUTATION, {
    variables: {
      id: userId,
    },
    onCompleted: () => {
      navigate(Path.AdminSettings_Users, {
        state: getUserBannerProps(UserStatus.Invited),
      })
    },
    onError: () => {
      navigate(location.pathname, {
        replace: true,
        state: getUserBannerProps(UserStatus.InviteError),
      })
    },
  })

  return (
    <Section
      header={intlMessageForId('Admin.EditUser.SectionHeaders.ManageAccount')}
    >
      <SocialDistancing spacing="16px" direction={LayoutDirection.Horizontal}>
        <SecondaryButton
          onClick={() => {
            inviteUser()
          }}
        >
          {intlMessageForId('Admin.EditUser.ManageAccount.ReInviteUser.Button')}
        </SecondaryButton>
        <DeleteButton
          onClick={(e) => {
            e.preventDefault()

            if (
              // using alert by design
              // eslint-disable-next-line no-alert
              window.confirm(
                intlMessageForId(
                  'Admin.EditUser.ManageAccount.DeleteUser.ConfirmationMessage'
                )
              )
            ) {
              deleteUser()
            }
          }}
        >
          {intlMessageForId('Admin.EditUser.ManageAccount.DeleteUser.Button')}
        </DeleteButton>
      </SocialDistancing>
    </Section>
  )
}

const EditUser: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>()

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(USER_QUERY, {
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  })

  const {
    loading: queryCurrentUserLoading,
    error: queryCurrentUserError,
    id: currentUserId,
  } = useCurrentUser()

  const [
    updateUser,
    { data: mutationData, error: mutationError },
  ] = useMutation(UPDATE_USER_MUTATION, {
    fetchPolicy: 'no-cache',
  })

  if (queryLoading || queryCurrentUserLoading) return <Loading />
  if (queryError || mutationError || queryCurrentUserError) return <Error />

  if (get(mutationData, 'updateUser.successful') === true) {
    return (
      <Navigate
        state={getUserBannerProps(UserStatus.Edited)}
        to={Path.AdminSettings_Users}
      />
    )
  }

  const {
    email,
    firstName,
    lastName,
    orgAdmin,
    language,
    hasPassword,
    locations,
    qbenchCustomerIds,
  } = get(queryData, 'user')

  const initialValues: FormValues = {
    [FormName.Email]: email,
    [FormName.FirstName]: firstName,
    [FormName.LastName]: lastName,
    [FormName.OrgAdmin]: orgAdmin.toString(),
    [FormName.Language]: language,
    [FormName.QuickscanAccessType]:
      locations === null
        ? LocationAccessType.All
        : LocationAccessType.Restricted,
    [FormName.QuickscanLocations]: locations || [],
    [FormName.LabsAccessType]:
      qbenchCustomerIds === null
        ? LocationAccessType.All
        : LocationAccessType.Restricted,
    [FormName.LabsLocations]: qbenchCustomerIds
      ? qbenchCustomerIds.map(String)
      : [],
  }

  const errorMessage = get(mutationData, 'updateUser.messages[0].message')

  const status = getStatus(hasPassword)

  /**
   * Does not allow an admin to change own role
   * @returns true, if admin is allowed to change the selected user's role; false, otherwise.
   */
  const allowDemotingRole = (): boolean => {
    return id !== currentUserId
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={({
        EMAIL,
        FIRST_NAME,
        LAST_NAME,
        ORG_ADMIN,
        QUICKSCAN_ACCESS_TYPE,
        QUICKSCAN_LOCATIONS,
        LABS_ACCESS_TYPE,
        LABS_LOCATIONS,
      }) => {
        updateUser({
          variables: {
            id,
            email: EMAIL,
            firstName: FIRST_NAME,
            lastName: LAST_NAME,
            orgAdmin: ORG_ADMIN === 'true',
            locations:
              QUICKSCAN_ACCESS_TYPE === LocationAccessType.Restricted
                ? QUICKSCAN_LOCATIONS
                : null,
            qbenchCustomerIds:
              LABS_ACCESS_TYPE === LocationAccessType.Restricted
                ? LABS_LOCATIONS.map(Number)
                : null,
          },
        })
      }}
    >
      {({ values }) => {
        const title = getDisplayName(values.FIRST_NAME, values.LAST_NAME)
        const headerElement = (
          <SocialDistancing
            spacing="32px"
            direction={LayoutDirection.Horizontal}
          >
            <SocialDistancing spacing="8px" style={{ paddingTop: '5px' }}>
              <FieldLabel>
                {intlMessageForId('Admin.FieldLabels.Status')}
              </FieldLabel>
              <FieldValue>{status}</FieldValue>
            </SocialDistancing>
            <HeaderElement />
          </SocialDistancing>
        )

        return (
          <Form>
            <Page testId="EditUser" title={title} headerElement={headerElement}>
              <Profile
                renderRoleOptions={allowDemotingRole()}
                errorMessage={errorMessage}
              />
              <Location />
              <ManageAccount userId={id as ID} />
            </Page>
          </Form>
        )
      }}
    </Formik>
  )
}

export default EditUser
