import React, { ChangeEvent, FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { Field, useFormikContext } from 'formik'
import Select from 'components/Select'
import { AnyObject, StyleObject } from 'types'
import Color from 'types/color'
import { get } from 'utils'
import { sharedStyles } from './common'
import LabeledFormElement from './LabeledFormElement'
import ErrorMessage from './ErrorMessage'

export type FormSelectOption = {
  label: string
  value: string
  disabled?: boolean
}

export type FormSelectProps = {
  name: string
  options: FormSelectOption[]
  label?: string
  emptyState?: string
  displayError?: boolean
  component?: FunctionComponent
  style?: StyleObject
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
  multiple?: boolean
  ariaLabel?: string
  details?: string
}

const PLACEHOLDER_VALUE = ''

const StyledDetails = styled.div`
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(22, 42, 56, 0.5);
`

const FormSelect: FunctionComponent<FormSelectProps> = ({
  name,
  options,
  label,
  emptyState,
  displayError = true,
  component = Select,
  style = {},
  onChange,
  multiple = false,
  ariaLabel,
  details,
}) => {
  const { values } = useFormikContext()

  const fieldProps = onChange ? { onChange } : {}

  const isPlaceholder = get(values as AnyObject, name) === PLACEHOLDER_VALUE

  const StyledField = styled(Field)`
    ${sharedStyles}
  `

  return (
    <LabeledFormElement label={label} name={name}>
      <>
        <StyledField
          aria-label={ariaLabel}
          name={name}
          id={name.replaceAll('.', '-')}
          as={component}
          style={{
            width: '100%',
            color: isPlaceholder ? Color.Placeholder : 'inherit',
            ...style,
          }}
          {...fieldProps}
          multiple={multiple}
        >
          {emptyState && (
            <option disabled value={PLACEHOLDER_VALUE}>
              {emptyState}
            </option>
          )}
          {options.map((option: FormSelectOption) => {
            const { label: optionLabel, value: optionValue, disabled } = option
            return (
              <option
                key={optionLabel}
                value={optionValue}
                data-testid={optionValue}
                disabled={disabled}
              >
                {optionLabel}
              </option>
            )
          })}
        </StyledField>
        {displayError && <ErrorMessage forName={name} />}
        {details && <StyledDetails>{details}</StyledDetails>}
      </>
    </LabeledFormElement>
  )
}

export default FormSelect
