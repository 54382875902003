import React, { ChangeEvent, Dispatch, ReactElement } from 'react'
import { DocumentNode, useMutation } from '@apollo/client'
import { useFormikContext } from 'formik'
import { find } from 'lodash/fp'
import { TextButton } from 'components/Button'
import Group from 'components/FormElements/Group'
import Select from 'components/Select'
import SocialDistancing from 'components/SocialDistancing'
import { intlMessageForId } from 'localization'
import { ID } from 'types'
import { LayoutDirection } from 'types/enums'
import { isSet } from 'utils'
import { BuildUpdatePayload, OnTemplateChange, TemplateBase } from './types'

type Props<Template, FormValueType> = {
  updateMutation: DocumentNode
  deleteMutation: DocumentNode
  templates: Array<Template>
  refetch: () => void
  selectedTemplate: Template | undefined
  setSelectedTemplate: Dispatch<React.SetStateAction<Template | undefined>>
  buildUpdatePayload: BuildUpdatePayload<Template>
  onTemplateChange: OnTemplateChange<Template, FormValueType>
  testId: string
}

// using a function here to support typescript generics
function ChooseTemplate<Template extends TemplateBase, FormValueType>(
  props: Props<Template, FormValueType>
): ReactElement<Props<Template, FormValueType>> {
  const {
    updateMutation,
    deleteMutation,
    templates,
    refetch,
    selectedTemplate,
    setSelectedTemplate,
    buildUpdatePayload,
    onTemplateChange,
    testId,
  } = props

  const { values, setValues } = useFormikContext()

  const [updateTemplateMutation] = useMutation(updateMutation, {
    onCompleted: () => {
      refetch()
    },
  })

  const [deleteTemplateMutation] = useMutation(deleteMutation, {
    onCompleted: () => {
      setSelectedTemplate(undefined)
      refetch()
    },
  })

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const findTemplate: (id: ID) => Template = (id) => {
      // will always return a template because of the select element
      return find({ id }, templates) as Template
    }

    const chosenTemplate = findTemplate(e.target.value)
    setSelectedTemplate(chosenTemplate)

    if (chosenTemplate) {
      onTemplateChange(chosenTemplate, values as FormValueType, setValues)
    }
  }

  const editTitle = () => {
    if (!isSet(selectedTemplate)) {
      return
    }

    // using alert by design
    // eslint-disable-next-line no-alert
    const title = window.prompt(
      intlMessageForId('Reports.Dialogs.NameTemplatePrompt'),
      selectedTemplate.title
    )
    if (title) {
      updateTemplateMutation({
        variables: buildUpdatePayload(title, selectedTemplate),
      })
    }
  }

  const deleteTemplate = () => {
    if (!isSet(selectedTemplate)) {
      return
    }

    const confirmation = window.confirm(
      intlMessageForId('Reports.Dialogs.DeleteTemplatePrompt')
    )
    if (confirmation) {
      deleteTemplateMutation({
        variables: { id: selectedTemplate.id },
      })
    }
  }

  return (
    <Group title="choose a template">
      <SocialDistancing spacing="16px" direction={LayoutDirection.Horizontal}>
        <Select
          data-testid={testId}
          onChange={handleChange}
          value={selectedTemplate?.id}
        >
          <option value="">
            {intlMessageForId('Reports.Form.Templates.None')}
          </option>
          {templates.map((template: Template) => (
            <option key={template.id} value={template.id}>
              {template.title}
            </option>
          ))}
        </Select>

        {selectedTemplate && (
          <SocialDistancing
            spacing="8px"
            direction={LayoutDirection.Horizontal}
          >
            <TextButton onClick={editTitle} style={{ color: '#10A5DD' }}>
              {intlMessageForId('Reports.CTAs.EditTemplateTitle')}
            </TextButton>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'rgba(22, 42, 56, 0.5)',
              }}
            >
              •
            </div>
            <TextButton onClick={deleteTemplate}>
              {intlMessageForId('Reports.CTAs.DeleteTemplate')}
            </TextButton>
          </SocialDistancing>
        )}
      </SocialDistancing>
    </Group>
  )
}

export default ChooseTemplate
