import React, { FunctionComponent, ReactChild, useEffect } from 'react'
import { useFormikContext } from 'formik'
import styled from '@emotion/styled'
import { lowerCase } from 'lodash'
import { intlMessageForId } from 'localization'
import { get } from 'utils'
import { AnyObject } from 'types'
import Color from 'types/color'
import { LayoutDirection } from 'types/enums'
import SocialDistancing from 'components/SocialDistancing'
import Select from 'components/Select'
import FormSelect, { FormSelectOption } from './FormSelect'
import FormMultiSelect from './FormMultiSelect'
import FormInput, { SmallInput } from './FormInput'
import FormCheckbox from './FormCheckbox'
import Tooltip from './Tooltip'

// keys used to store component state in Formik
export enum ToggledControlName {
  Checked = 'CHECKED',
  Value = 'VALUE',
}

// any value is allowed
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ToggledControlValue = any

type ToggledControlProps = {
  name: string
  label: string
  detail?: string
  initialValue?: ToggledControlValue
  defaultChecked?: boolean
  required?: boolean
  tooltip?: string
}

export type ToggledControlState = {
  [ToggledControlName.Checked]: boolean
  [ToggledControlName.Value]: ToggledControlValue
}

export type ToggledSelectType = {
  [ToggledControlName.Checked]: boolean
  [ToggledControlName.Value]: string | Array<string>
}

export type ToggledNumericInputType = {
  [ToggledControlName.Checked]: boolean
  [ToggledControlName.Value]: number
}

export const StyledContainer = styled.div`
  box-sizing: border-box;
  height: 71px;
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
`

const StyledLabel = styled.label`
  cursor: pointer;
  flex-grow: 1;
  font-size: 16px;
  line-height: 19px;
`
/**
 * Displays a checkbox with a child component (e.g., FormInput, FormSelect).
 * The child component can be activated or deactivated based on the checked status of the
 * checkbox. In case, there is no child component, the checked status is used to enable
 * or disable a particular feature (e.g., merging comb cassette analyte results)
 */
const ToggledControl: FunctionComponent<
  ToggledControlProps & {
    children?: (childFieldName: string) => ReactChild | ReactChild[]
  }
> = ({
  name,
  label,
  detail,
  children,
  initialValue,
  defaultChecked = false,
  required = false,
  tooltip,
}) => {
  const { values, setFieldValue } = useFormikContext()

  const checkedName = `${name}.${ToggledControlName.Checked}`
  const valueName = `${name}.${ToggledControlName.Value}`
  const selected = get(values as AnyObject, checkedName, false)

  // sets initial Formik state
  useEffect(() => {
    // setting `shouldValidate` to false significantly improves render time
    setFieldValue(checkedName, defaultChecked, false)
    setFieldValue(valueName, initialValue, false)

    // React stores references to objects and runs the effect when the reference changes
    // this is an issue because `initialValue` can be an object which then will cause an infinite render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultChecked, name, setFieldValue])

  return (
    <StyledContainer
      style={{
        backgroundColor: selected
          ? Color.SelectedBackground
          : Color.UnselectedBackground,
      }}
    >
      <StyledLabel
        htmlFor={checkedName}
        aria-label={`${selected ? 'remove' : 'add'} ${label}`.toLowerCase()}
      >
        <FormCheckbox
          id={checkedName}
          checked={required || selected}
          name={checkedName}
          displayError={false}
          labelStyle={{
            marginRight: '12px',
            cursor: required ? 'auto' : 'pointer',
            alignItems: 'center',
          }}
          hidden={required}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Tooltip
              copy={tooltip}
              color={selected ? Color.SelectedText : Color.Black60}
            >
              {label}
            </Tooltip>
            {detail && <div>{detail}</div>}
          </div>
        </FormCheckbox>
      </StyledLabel>
      <SocialDistancing
        spacing="8px"
        direction={LayoutDirection.Horizontal}
        style={{ visibility: selected ? 'visible' : 'hidden' }}
      >
        {children && children(valueName)}
      </SocialDistancing>
    </StyledContainer>
  )
}

export const ToggledSelect: FunctionComponent<
  ToggledControlProps & {
    selectOptions: FormSelectOption[]
    multiple?: boolean
    component?: FunctionComponent
    initialValue?: string | string[]
    emptyState?: string
  }
> = ({
  name,
  label,
  detail,
  selectOptions,
  defaultChecked,
  required,
  multiple = false,
  initialValue = multiple ? [] : '',
  component = Select,
  tooltip,
  emptyState,
}) => {
  return (
    <ToggledControl
      name={name}
      label={label}
      detail={detail}
      initialValue={initialValue}
      defaultChecked={defaultChecked}
      required={required}
      tooltip={tooltip}
    >
      {(childName: string) => {
        return multiple ? (
          <FormMultiSelect name={childName} selectOptions={selectOptions} />
        ) : (
          <FormSelect
            ariaLabel={`${label} options`.toLowerCase()}
            name={childName}
            options={selectOptions}
            component={component}
            displayError={false}
            style={{ fontSize: 14 }}
            emptyState={emptyState}
          />
        )
      }}
    </ToggledControl>
  )
}

export const ToggledInput: FunctionComponent<
  ToggledControlProps & {
    placeholder?: string
    initialValue?: string
  }
> = ({
  name,
  label,
  placeholder,
  defaultChecked,
  initialValue = '',
  tooltip,
}) => {
  return (
    <ToggledControl
      name={name}
      label={label}
      initialValue={initialValue}
      defaultChecked={defaultChecked}
      tooltip={tooltip}
    >
      {(childName: string) => {
        return (
          <FormInput
            ariaLabel={`${label.replace(' ', '-')} input`}
            name={childName}
            placeholder={placeholder}
            as={SmallInput}
            displayError={false}
          />
        )
      }}
    </ToggledControl>
  )
}

export const ToggledRange: FunctionComponent<
  ToggledControlProps & {
    // string included to support date ranges
    initialValue?: {
      min?: number | string
      max?: number | string
    }
    type?: string
  }
> = ({
  name,
  label,
  defaultChecked,
  initialValue = { min: '', max: '' },
  type = 'number',
  tooltip,
}) => {
  return (
    <ToggledControl
      name={name}
      label={label}
      initialValue={initialValue}
      defaultChecked={defaultChecked}
      tooltip={tooltip}
    >
      {(childName: string) => {
        return [
          <FormInput
            key={`${childName}.min`}
            name={`${childName}.min`}
            placeholder={lowerCase(intlMessageForId('Common.Min'))}
            type={type}
            as={SmallInput}
            displayError={false}
            ariaLabel={`${label}-min`}
          />,
          <FormInput
            key={`${childName}.max`}
            name={`${childName}.max`}
            placeholder={lowerCase(intlMessageForId('Common.Max'))}
            type={type}
            as={SmallInput}
            displayError={false}
            ariaLabel={`${label}-max`}
          />,
        ]
      }}
    </ToggledControl>
  )
}

export const EmptyToggledControl: FunctionComponent<ToggledControlProps> = ({
  name,
  label,
  defaultChecked,
  tooltip,
}) => {
  return (
    <ToggledControl
      name={name}
      label={label}
      defaultChecked={defaultChecked}
      tooltip={tooltip}
    />
  )
}
