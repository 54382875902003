import React from 'react'
import { getIntlMessageForId } from 'localization'
import Color from 'types/color'
import SocialDistancing from 'components/SocialDistancing'
import { GrayContainer } from 'components/GrayContainer'
import ExternalLink from 'components/ExternalLink'

const ContactTechSupport: React.FC = () => {
  const toIntl = getIntlMessageForId('HelpCenter.TotalHubSupport.TechSupport')
  const email = 'techsupport@envirologix.com'

  return (
    <GrayContainer style={{ padding: 16 }}>
      <h3 style={{ paddingBottom: 12 }}>{toIntl('Header')}</h3>
      <SocialDistancing spacing="4px">
        <p>{toIntl('Phone')}: 866.408.4597</p>
        <div style={{ display: 'flex' }}>
          <p>{toIntl('Email')}:</p>
          <ExternalLink
            href={`mailto:${email}`}
            style={{ color: Color.Black, paddingLeft: 4 }}
          >
            <p>{email}</p>
          </ExternalLink>
        </div>
      </SocialDistancing>
    </GrayContainer>
  )
}

export default ContactTechSupport
