import React, { ReactNode } from 'react'
import { StyleObject } from 'types'

const ExternalLink: React.FunctionComponent<{
  href: string
  onClick?: () => void
  style?: StyleObject
  children: ReactNode
}> = ({ href, onClick, style = {}, children }) => (
  <a
    href={href}
    onClick={onClick}
    style={style}
    rel="nofollow noopener noreferrer"
    target="_blank"
  >
    {children}
  </a>
)

export default ExternalLink
