import { gql } from '@apollo/client'

export const LAB_ORDER_FORM_QUERY = gql`
  query {
    labCustomers {
      qbenchCustomerId
      customerName
      address
      phone
      billTo
    }
    labAssays {
      commodity
      portalOrderForm
      analyte
      category
      id
      concern
      type
    }
    emailSettings: settings(domain: LAB, type: [EMAIL]) {
      email
      enabled
    }
    sampleTypeSettings: settings(domain: LAB, type: [SAMPLE_TYPE]) {
      sampleType
      enabled
    }
  }
`

export const LAB_ORDER_QUERY = gql`
  query($id: ID!) {
    labOrder(id: $id) {
      customer {
        id
        qbenchCustomerId
        customerName
        address
        billTo
        phone
      }
      state
      createdAt
      receivedAt
      completedAt
      reportContact
      numSamples
      samples {
        id
        assays {
          id
          analyte
        }
        sampleType
        rush
        status
        sampleCommodity
        description
        sampleComments
        noGmoSum
      }
      location
      poNum
      specialInstructions
    }
  }
`

export const UPDATE_LAB_ORDER_MUTATION = gql`
  mutation(
    $id: ID!
    $customerAccountId: ID!
    $location: LabOrderLocation!
    $poNum: String
    $reportContact: String
    $specialInstructions: String
    $samples: [LabSampleInput]!
  ) {
    updateLabOrder(
      id: $id
      input: {
        customerAccountId: $customerAccountId
        location: $location
        poNum: $poNum
        reportContact: $reportContact
        samples: $samples
        specialInstructions: $specialInstructions
      }
    ) {
      messages {
        field
        message
        code
        options {
          key
          value
        }
        template
      }
      result {
        id
      }
      successful
    }
  }
`

export const ADD_LAB_ORDER_MUTATION = gql`
  mutation(
    $customerAccountId: ID!
    $location: LabOrderLocation!
    $poNum: String
    $reportContact: String
    $specialInstructions: String
    $samples: [LabSampleInput]!
  ) {
    submitLabOrder(
      input: {
        customerAccountId: $customerAccountId
        location: $location
        poNum: $poNum
        reportContact: $reportContact
        samples: $samples
        specialInstructions: $specialInstructions
      }
    ) {
      id
    }
  }
`
