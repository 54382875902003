import React, { FunctionComponent } from 'react'
import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { FieldArray } from 'formik'
import { compact, flow, map } from 'lodash/fp'
import * as Yup from 'yup'
import { SecondaryButton, TextButton } from 'components/Button'
import { FormRadioButtons } from 'components/FormElements'
import Group from 'components/FormElements/Group'
import {
  EmptyToggledControl,
  ToggledControlName,
  ToggledControlState,
} from 'components/FormElements/ToggledControl'
import { Loading, Error } from 'components/Placeholders'
import SocialDistancing from 'components/SocialDistancing'
import { intlMessageForId } from 'localization'
import { AnyObject } from 'types'
import { FormName } from 'types/forms'
import { getDetailsPath, Path } from 'types/enums'
import { get, isSet, openUrl } from 'utils'
import {
  requiredSelectValidation,
  toggledNumericInputValidation,
  toggledSelectValidation,
} from 'utils/forms'
import { AnalyticsInput, AnalyticsSelect } from './components'
import { CreateReportType } from '../../reports/types'
import { INITIAL_VALUES_MAP, getToggledValue } from '../../reports/utils'
import ReportBuilderPage from '../../reports/components/ReportBuilderPage'
import { getSelectOptionsMap } from '../../reports/components/ReportsMultiSelect'

export enum AnalysisType {
  Bin = 'BIN',
  Location = 'LOCATION',
  Supplier = 'SUPPLIER',
}

type Analysis = {
  [FormName.Analyte]: ToggledControlState
  [FormName.Commodity]: ToggledControlState
  [FormName.Threshold]: ToggledControlState
}

const NewAnalysis = {
  [FormName.Analyte]: INITIAL_VALUES_MAP.select,
  [FormName.Commodity]: INITIAL_VALUES_MAP.select,
  [FormName.Threshold]: INITIAL_VALUES_MAP.input,
}

const getIntl: (tail: string) => string = (tail) => {
  return `Analytics.QuickScan.Form.${tail}`
}

export const QUICKSCAN_FACETS_QUERY = gql`
  query GetQuickscanFacets {
    quickscanFacets {
      analyte
      bin
      commodity
      location
      operator
      supplier
    }
  }
`

export const CREATE_QUICKSCAN_BI_REPORT_MUTATION = gql`
  mutation(
    $analyses: [QuickscanBiReportAnalysesInput]!
    $analysisType: QuickscanBiReportAnalysisType!
    $columns: [QuickscanBiReportColumn]!
    $filters: QuickscanBiReportFiltersInput!
  ) {
    createQuickscanBiReport(
      analyses: $analyses
      analysisType: $analysisType
      columns: $columns
      filters: $filters
    ) {
      id
    }
  }
`

const initialValues = {
  [FormName.AnalysisType]: AnalysisType.Location,
  [FormName.Analyses]: [NewAnalysis],
  [FormName.Location]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.Supplier]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.Bin]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.Operator]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.TestAverage]: INITIAL_VALUES_MAP.emptyControl,
  [FormName.AcceptedTestAverage]: INITIAL_VALUES_MAP.emptyControl,
  [FormName.RejectionRate]: INITIAL_VALUES_MAP.emptyControl,
}

const validationSchema = Yup.object().shape({
  [FormName.Analyses]: Yup.array().of(
    Yup.object().shape({
      [FormName.Analyte]: requiredSelectValidation,
      [FormName.Commodity]: requiredSelectValidation,
      [FormName.Threshold]: toggledNumericInputValidation,
    })
  ),
  [FormName.Location]: toggledSelectValidation,
  [FormName.Supplier]: toggledSelectValidation,
  [FormName.Bin]: toggledSelectValidation,
  [FormName.Operator]: toggledSelectValidation,
})

const onSubmit = (
  publishPayload?: (payload: Record<string, Array<string> | AnyObject>) => void
) => (formikValues: AnyObject, createReport: CreateReportType) => {
  const filters: AnyObject = {
    bins: getToggledValue(formikValues[FormName.Bin]),
    locations: getToggledValue(formikValues[FormName.Location]),
    operators: getToggledValue(formikValues[FormName.Operator]),
    suppliers: getToggledValue(formikValues[FormName.Supplier]),
    whenMax: dayjs(get(formikValues[FormName.When], 'max'))
      .endOf('day')
      .toISOString(),
    whenMin: dayjs(get(formikValues[FormName.When], 'min'))
      .startOf('day')
      .toISOString(),
  }

  const analysisType = formikValues[FormName.AnalysisType]

  const analyses = formikValues[FormName.Analyses].map((analysis: Analysis) => {
    const threshold = getToggledValue(analysis[FormName.Threshold])
    return {
      analyte: getToggledValue(analysis[FormName.Analyte]),
      commodity: getToggledValue(analysis[FormName.Commodity]),
      threshold: parseFloat(threshold) || null,
    }
  })

  const columns = flow([
    map((element: FormName) => {
      const checked = formikValues[element][ToggledControlName.Checked]
      return checked ? element : null
    }),
    compact,
  ])([
    FormName.AcceptedTestAverage,
    FormName.RejectionRate,
    FormName.TestAverage,
  ])

  const payload = {
    analyses,
    analysisType,
    columns,
    filters,
  }

  if (isSet(publishPayload)) {
    publishPayload(payload)
  }

  createReport({
    variables: payload,
  })
}

const getFieldName: (fieldName: string, index: number) => string = (
  fieldName,
  index
) => `${FormName.Analyses}.${index}.${fieldName}`

const AnalyticsQuickScanForm: FunctionComponent<{
  publishPayload?: (payload: Record<string, string[] | AnyObject>) => void
}> = ({ publishPayload }) => {
  const { data, loading, error } = useQuery(QUICKSCAN_FACETS_QUERY)

  if (loading) return <Loading />
  if (error) return <Error />

  const facets = get(data, 'quickscanFacets', {})

  const selectOptionsMap = getSelectOptionsMap(facets)

  return (
    <ReportBuilderPage
      testId="AnalyticsQuickScanForm"
      initialValues={initialValues}
      validationSchema={validationSchema}
      createReportMutation={CREATE_QUICKSCAN_BI_REPORT_MUTATION}
      mutationResponseKey="createQuickscanBiReport"
      onSubmit={onSubmit(publishPayload)}
      formHeader={intlMessageForId(getIntl('FormHeader'))}
      submitLabel={intlMessageForId(getIntl('CTAs.RunReport'))}
      onCompleted={(r) => {
        const response = r as AnyObject
        const { id } = response

        if (id) {
          openUrl(getDetailsPath(Path.Analytics_QuickScan, id))
        }
      }}
    >
      {(values: AnyObject) => (
        <SocialDistancing spacing="24px">
          <Group>
            <FormRadioButtons
              name={FormName.AnalysisType}
              options={[
                {
                  label: intlMessageForId(
                    getIntl('Fields.AnalysisType.Options.Location')
                  ),
                  value: AnalysisType.Location,
                },
                {
                  label: intlMessageForId(
                    getIntl('Fields.AnalysisType.Options.Supplier')
                  ),
                  value: AnalysisType.Supplier,
                },
                {
                  label: intlMessageForId(
                    getIntl('Fields.AnalysisType.Options.Bin')
                  ),
                  value: AnalysisType.Bin,
                },
              ]}
              label={intlMessageForId(
                getIntl('SectionHeaders.Labels.AnalysisType')
              )}
              displayError={false}
              tooltip={intlMessageForId(
                'Analytics.QuickScan.Form.Fields.Tooltips.ANALYSIS_TYPE'
              )}
            />
          </Group>
          <Group
            title={intlMessageForId(getIntl('SectionHeaders.Labels.Analysis'))}
            tooltip={intlMessageForId(
              getIntl('SectionHeaders.Tooltips.Analysis')
            )}
          >
            <FieldArray name={FormName.Analyses}>
              {({ push, remove }) => {
                const analyses = values[FormName.Analyses]
                return (
                  <SocialDistancing spacing="16px">
                    {analyses.map((value: string, index: number) => {
                      const multipleSamples: boolean = analyses.length > 1

                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Group key={index}>
                          <AnalyticsSelect
                            formName={getFieldName(FormName.Analyte, index)}
                            label={intlMessageForId(
                              getIntl(`Fields.${FormName.Analyte}`)
                            )}
                            selectOptions={selectOptionsMap[FormName.Analyte]}
                            required
                          />
                          <AnalyticsSelect
                            formName={getFieldName(FormName.Commodity, index)}
                            label={intlMessageForId(
                              getIntl(`Fields.${FormName.Commodity}`)
                            )}
                            selectOptions={selectOptionsMap[FormName.Commodity]}
                            required
                          />
                          <AnalyticsInput
                            formName={getFieldName(FormName.Threshold, index)}
                            label={intlMessageForId(
                              getIntl(`Fields.${FormName.Threshold}`)
                            )}
                            tooltip={intlMessageForId(
                              'Analytics.QuickScan.Form.Fields.Tooltips.THRESHOLD'
                            )}
                          />
                          {multipleSamples && (
                            <TextButton onClick={() => remove(index)}>
                              Delete
                            </TextButton>
                          )}
                        </Group>
                      )
                    })}
                    <SecondaryButton onClick={() => push(NewAnalysis)}>
                      {intlMessageForId(getIntl('CTAs.AddAnalysis'))}
                    </SecondaryButton>
                  </SocialDistancing>
                )
              }}
            </FieldArray>
          </Group>
          <Group
            title={intlMessageForId(getIntl('SectionHeaders.Labels.Filters'))}
          >
            <AnalyticsSelect
              formName={FormName.Location}
              label={intlMessageForId(getIntl(`Fields.${FormName.Location}`))}
              selectOptions={selectOptionsMap[FormName.Location]}
              multiple
            />
            <AnalyticsSelect
              formName={FormName.Supplier}
              label={intlMessageForId(getIntl(`Fields.${FormName.Supplier}`))}
              selectOptions={selectOptionsMap[FormName.Supplier]}
              multiple
            />
            <AnalyticsSelect
              formName={FormName.Bin}
              label={intlMessageForId(getIntl(`Fields.${FormName.Bin}`))}
              selectOptions={selectOptionsMap[FormName.Bin]}
              multiple
            />
            <AnalyticsSelect
              formName={FormName.Operator}
              label={intlMessageForId(getIntl(`Fields.${FormName.Operator}`))}
              selectOptions={selectOptionsMap[FormName.Operator]}
              multiple
            />
          </Group>
          <Group
            title={intlMessageForId(
              getIntl('SectionHeaders.Labels.MeasurementOptions')
            )}
            tooltip={intlMessageForId(
              getIntl('SectionHeaders.Tooltips.MeasurementOptions'),
              {
                b: (chunks: string) => <b>{chunks}</b>,
              }
            )}
          >
            <EmptyToggledControl
              name={FormName.TestAverage}
              label={intlMessageForId(getIntl('Fields.TEST_AVERAGE'))}
              defaultChecked
            />
            <EmptyToggledControl
              name={FormName.AcceptedTestAverage}
              label={intlMessageForId(getIntl('Fields.ACCEPTED_TEST_AVERAGE'))}
              defaultChecked
            />
            <EmptyToggledControl
              name={FormName.RejectionRate}
              label={intlMessageForId(getIntl('Fields.REJECTION_RATE'))}
              defaultChecked
            />
          </Group>
        </SocialDistancing>
      )}
    </ReportBuilderPage>
  )
}

export default AnalyticsQuickScanForm
