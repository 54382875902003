import React, { ChangeEvent, FunctionComponent, useState } from 'react'
import { intlMessageForId } from 'localization'
import {
  EventAction,
  EventCategory,
  EventLabel,
  trackEvent,
} from 'utils/analytics'
import Color from 'types/color'
import RadioButtons from 'components/RadioButtons'
import { LabeledElement } from '../LabeledValue'
import { useSavedValue } from './useLocalStorage'

export enum ResultTypeSelectorOption {
  Quantitative = '0',
  Qualitative = '1',
}

const useResultTypeSelector: (
  eventCategory: EventCategory,
  id: string
) => {
  ResultTypeSelector: FunctionComponent
  selectedResultType: ResultTypeSelectorOption
} = (eventCategory, id) => {
  const [getSavedSelection, setSavedSelection] = useSavedValue<
    ResultTypeSelectorOption
  >(`${id}-ResultTypeSelector`, ResultTypeSelectorOption.Quantitative)

  const [selectedResultType, setSelectedResultType] = useState<
    ResultTypeSelectorOption
  >(getSavedSelection)

  const handleChange: (e: ChangeEvent<HTMLInputElement>) => void = (e) => {
    const selection = e.target.value as ResultTypeSelectorOption
    trackEvent(
      eventCategory,
      EventAction.Select,
      `${EventLabel.ResultType}_${selection}`
    )
    setSavedSelection(selection)
    setSelectedResultType(selection as ResultTypeSelectorOption)
  }

  const ResultTypeSelector: FunctionComponent = () => {
    const base = 'Charts.Overview.ResultType.Options'
    const options = [
      {
        label: intlMessageForId(`${base}.Quantitative`),
        value: ResultTypeSelectorOption.Quantitative,
      },
      {
        label: intlMessageForId(`${base}.Qualitative`),
        value: ResultTypeSelectorOption.Qualitative,
      },
    ]

    return (
      <LabeledElement
        label={intlMessageForId('Charts.Overview.ResultType.Header')}
      >
        <RadioButtons
          name={`ResultTypeSelector-${id}`}
          options={options}
          selectedValue={selectedResultType}
          labelStyle={{
            color: Color.White60,
          }}
          groupLabelStyle={{
            color: Color.White60,
          }}
          handleChange={handleChange}
        />
      </LabeledElement>
    )
  }

  return {
    ResultTypeSelector,
    selectedResultType,
  }
}

export default useResultTypeSelector
