/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApolloError,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
} from '@apollo/client'
import { AnyObject } from 'types'

export type Template = {
  id: number
  title: string
  columns: string[]
  filters: AnyObject
  mergeCombResults: boolean
}

export type TemplatesQuery = {
  data: AnyObject
  loading: boolean
  error: ApolloError
  refetch: () => void
}

export enum ExportFormat {
  csv = 'csv',
  pdf = 'pdf',
}

export type RangeValueType = { min: number | string; max: number | string }

export type CreateReportType = (
  options?: MutationFunctionOptions<any, OperationVariables> | undefined
) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>
