import dayjs from 'dayjs'
import { intlMessageForId } from 'localization'
import { capitalize, groupBy, orderBy, sortBy } from 'lodash/fp'
import { ISODate } from 'types'
import { get } from 'utils'
import {
  OperatorTraining,
  OperatorTrainingCourse,
  OperatorTrainingFrequency,
  OperatorTrainingStatus,
} from './types'

export const formatStatus: (status: OperatorTrainingStatus) => string = (
  status
) => capitalize(intlMessageForId(`Types.Training.Status.${status}`))

export const formatFrequency: (
  frequency: OperatorTrainingFrequency
) => string = (frequency) =>
  capitalize(
    intlMessageForId(`Training.Form.FrequencySelect.Options.${frequency}`)
  )

export const highlight: (
  status: OperatorTrainingStatus,
  dueDate?: ISODate
) => boolean = (status, dueDate) =>
  status !== 'COMPLETE' && dayjs() > dayjs(dueDate)

const groupByStatus: <T>(
  collection: Array<T>
) => {
  pending: Array<T>
  incomplete: Array<T>
  complete: Array<T>
} = (collection) => {
  const grouped = groupBy('status')(collection)

  const PENDING = get(grouped, 'PENDING')
  const COMPLETE = get(grouped, 'COMPLETE', [])
  const IN_PROGRESS = get(grouped, 'IN_PROGRESS', [])
  const SENT = get(grouped, 'SENT', [])

  const incomplete = [...IN_PROGRESS, ...SENT]
  return { pending: PENDING, incomplete, complete: COMPLETE }
}

type SortableType = OperatorTraining | OperatorTrainingCourse

const sort: <T extends SortableType>(
  collection: Array<T>,
  sortByKey: string
) => Array<T> = (collection, sortByKey) => {
  const grouped = groupByStatus(collection)
  const { pending, incomplete, complete } = grouped

  return [
    ...sortBy<SortableType>(sortByKey)(pending),
    ...sortBy<SortableType>(sortByKey)(incomplete),
    ...orderBy<SortableType>(sortByKey, 'desc')(complete),
    // TODO: improve type interface to avoid casting to Array<any>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ] as Array<any>
}

export const sortedOperatorTrainings: (
  operatorTrainings: Array<OperatorTraining>
) => Array<OperatorTraining> = (operatorTrainings) =>
  sort<OperatorTraining>(operatorTrainings, 'dueAt')

export const sortedOperatorTrainingCourses: (
  operatorTrainingCourses: Array<OperatorTrainingCourse>
) => Array<OperatorTrainingCourse> = (operatorTrainingCourses) =>
  sort<OperatorTrainingCourse>(operatorTrainingCourses, 'statusAt')
