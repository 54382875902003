import React, { FC } from 'react'
import { intlMessageForId } from 'localization'
import { AnyObject } from 'types'
import { LayoutDirection } from 'types/enums'
import { MaterialCheckbox } from 'components/FormElements/FormCheckbox'
import SocialDistancing from 'components/SocialDistancing'
import { Alert } from '../types'

type UpdateAlertType = (arg: { variables: AnyObject }) => void

const EnabledCell: FC<{
  alert: Alert
  updateAlert: UpdateAlertType
}> = ({ alert, updateAlert }) => {
  const { id, enabled } = alert

  return (
    <SocialDistancing
      direction={LayoutDirection.Horizontal}
      spacing="4px"
      style={{ alignItems: 'center' }}
    >
      <MaterialCheckbox
        id={id}
        checked={enabled}
        onChange={() => {
          updateAlert({
            variables: {
              ...alert,
              enabled: !enabled,
            },
          })
        }}
      />
      <label htmlFor={alert.id} style={{ cursor: 'pointer' }}>
        {intlMessageForId('Alerts.Status.ControlLabel')}
      </label>
    </SocialDistancing>
  )
}

export default EnabledCell
