import React from 'react'
import styled from '@emotion/styled'
import { intlMessageForId } from 'localization'
import { FormattedMessageId } from 'types'
import Color from 'types/color'
import { LinkButton } from 'components/Button'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 0;
  padding: 32px;
  border-radius: 8px;
  background-color: ${Color.DarkBlue};
  min-width: 250px;
`

const Header = styled.h2`
  margin-bottom: 12px;
  color: ${Color.White};
`

const Body = styled.p`
  flex-grow: 1;
  margin-bottom: 30px;
  line-height: 22px;
  color: ${Color.White};
`

const CallToActionCard: React.FunctionComponent<{
  header: FormattedMessageId
  body: FormattedMessageId
  callToAction: { to: string; label: string }
}> = ({ header, body, callToAction }) => (
  <Container>
    <Header>{intlMessageForId(header)}</Header>
    <Body>{intlMessageForId(body)}</Body>
    <LinkButton
      to={callToAction.to}
      style={{ width: '100%', marginLeft: 0, marginRight: 0 }}
    >
      {callToAction.label}
    </LinkButton>
  </Container>
)

export default CallToActionCard
