import React, { FC, useState } from 'react'
import { DocumentNode, useQuery } from '@apollo/client'
import { get } from 'utils'
import {
  BuildCreatePayload,
  BuildUpdatePayload,
  OnTemplateChange,
  TemplateBase,
} from './types'
import ChooseTemplate from './ChooseTemplate'
import SaveTemplate from './SaveTemplate'

function useTemplate<T extends TemplateBase, FormValueType>(config: {
  query: DocumentNode
  createMutation: DocumentNode
  updateMutation: DocumentNode
  deleteMutation: DocumentNode
  queryKey: string
  createMutationKey: string
  buildCreatePayload: BuildCreatePayload<FormValueType>
  buildUpdatePayload: BuildUpdatePayload<T>
  onTemplateChange: OnTemplateChange<T, FormValueType>
  testId: string
}): {
  hasTemplates: boolean
  ChooseTemplate: FC
  SaveTemplate: FC<{ formValue: FormValueType }>
} {
  const [selectedTemplate, setSelectedTemplate] = useState<T | undefined>(
    undefined
  )

  const {
    query,
    createMutation,
    updateMutation,
    deleteMutation,
    queryKey,
    createMutationKey,
    buildCreatePayload,
    buildUpdatePayload,
    onTemplateChange,
    testId,
  } = config

  const { data, refetch } = useQuery(query)

  const templates = get(data, queryKey, [])

  return {
    hasTemplates: templates.length > 0,
    ChooseTemplate: () => (
      <ChooseTemplate
        updateMutation={updateMutation}
        deleteMutation={deleteMutation}
        templates={templates}
        refetch={refetch}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
        buildUpdatePayload={buildUpdatePayload}
        onTemplateChange={onTemplateChange}
        testId={testId}
      />
    ),
    SaveTemplate(props: { formValue: FormValueType }) {
      const { formValue } = props

      return (
        <SaveTemplate
          createMutation={createMutation}
          formValue={formValue}
          refetch={refetch}
          setSelectedTemplate={setSelectedTemplate}
          createMutationKey={createMutationKey}
          buildCreatePayload={buildCreatePayload}
        />
      )
    },
  }
}

export default useTemplate
