import React, { FunctionComponent, ReactChild, ReactElement } from 'react'
import { isNull } from 'lodash'
import styled from '@emotion/styled'
import { intlMessageForId } from 'localization'
import { isSet } from 'utils'
import { StyleObject } from 'types'
import Color from 'types/color'
import SocialDistancing from 'components/SocialDistancing'
import Divider from 'components/Divider'
import LabeledValue, {
  LabeledElement,
  Value,
} from 'components/charts/LabeledValue'
import Pie, { PieData } from 'components/charts/Pie'

const UnweightedDataDisclaimer = styled.p`
  color: ${Color.White60};
  font-size: 14px;
  line-height: 21px;
`

const Container: React.FunctionComponent<{
  children: ReactChild | Array<ReactChild | undefined>
  style?: StyleObject
}> = ({ children, style = {} }) => (
  <div
    style={{
      ...{
        width: '33%',
        display: 'flex',
        alignItems: 'center',
        padding: '32px',
        backgroundColor: Color.DarkBlue,
        borderRadius: '8px',
      },
      ...style,
    }}
  >
    <div style={{ width: '100%' }}>{children}</div>
  </div>
)

export const LoadAcceptanceOverview: React.FunctionComponent<{
  acceptanceRate: string
  acceptedBushels: string
  chart?: ReactElement
  style?: StyleObject
}> = ({ acceptanceRate, acceptedBushels, chart, style = {} }) => (
  <Container style={style}>
    <SocialDistancing spacing="16px">
      <LabeledValue
        labelId="Charts.Overview.AcceptanceRate"
        value={acceptanceRate}
        valueSize="Large"
      />
      <LabeledValue
        labelId="Charts.Overview.BushelsAccepted"
        value={acceptedBushels}
      />
      {chart && <div style={{ height: '250px' }}>{chart}</div>}
      <UnweightedDataDisclaimer>
        {intlMessageForId('Charts.Overview.Disclaimers.LoadAcceptance')}
      </UnweightedDataDisclaimer>
    </SocialDistancing>
  </Container>
)

export const ConcentrationOverview: React.FunctionComponent<{
  LoadAcceptanceSelector: FunctionComponent
  AverageTypeSelector: FunctionComponent
  Threshold: FunctionComponent
  ResultTypeSelector?: FunctionComponent
  weightedAverage?: string
  average?: string
  acceptedBushels?: string
  loads?: string
  style?: StyleObject
}> = ({
  LoadAcceptanceSelector,
  AverageTypeSelector,
  ResultTypeSelector,
  Threshold,
  weightedAverage,
  acceptedBushels,
  loads,
  style = {},
}) => {
  return (
    <Container style={style}>
      <SocialDistancing spacing="16px">
        <LoadAcceptanceSelector />
        {isSet(ResultTypeSelector) && <ResultTypeSelector />}
        <AverageTypeSelector />
        {isNull(weightedAverage) && (
          <UnweightedDataDisclaimer>
            {intlMessageForId('Charts.Overview.Disclaimers.Concentration')}
          </UnweightedDataDisclaimer>
        )}
        <Threshold />
        {acceptedBushels && (
          <LabeledValue
            labelId="Charts.Overview.BushelsAccepted"
            value={acceptedBushels}
          />
        )}
        {loads && (
          <LabeledValue labelId="Charts.Overview.Loads" value={loads} />
        )}
      </SocialDistancing>
    </Container>
  )
}

export const QualitativeChartsOverview: React.FC<{
  LoadAcceptanceSelector: FunctionComponent
  ResultTypeSelector?: FunctionComponent
  percentNotDetected?: string
  acceptedBushels?: string
  pieData?: PieData
  style?: StyleObject
}> = ({
  LoadAcceptanceSelector,
  ResultTypeSelector,
  percentNotDetected,
  acceptedBushels,
  pieData,
  style = {},
}) => (
  <Container style={style}>
    <SocialDistancing spacing="16px">
      <LoadAcceptanceSelector />
      {isSet(ResultTypeSelector) && <ResultTypeSelector />}
      <Divider color={Color.White} style={{ margin: '8px -8px' }} />
      {isSet(percentNotDetected) && (
        <LabeledValue
          labelId="Charts.Overview.PercentNotDetected"
          value={percentNotDetected}
          valueSize="Large"
        />
      )}

      {isSet(acceptedBushels) && (
        <LabeledValue
          labelId="Charts.Overview.BushelsAccepted"
          value={acceptedBushels}
        />
      )}
    </SocialDistancing>
    {pieData && (
      <div style={{ height: '200px' }}>
        <Pie
          data={pieData}
          pieProps={{
            margin: { top: 16, right: 0, bottom: 16, left: 0 },
            isInteractive: false,
            enableSlicesLabels: false,
          }}
          holeProps={{ valueSize: 'Normal' }}
        />
      </div>
    )}
    <UnweightedDataDisclaimer>
      {intlMessageForId('Charts.Overview.Disclaimers.LoadAcceptance')}
    </UnweightedDataDisclaimer>
  </Container>
)

export const LabsOverview: FunctionComponent<{
  Threshold: FunctionComponent
  selectedAnalyte?: string
  numberOfSamples?: string
  average?: string
  style?: StyleObject
}> = ({ Threshold, selectedAnalyte, numberOfSamples, average, style = {} }) => (
  <Container style={style}>
    <SocialDistancing spacing="16px">
      {average && (
        <LabeledElement
          label={`${intlMessageForId(
            'Charts.Overview.Average'
          )} ${selectedAnalyte}`}
        >
          <Value size="Large">{average}</Value>
        </LabeledElement>
      )}
      <Threshold />
      {numberOfSamples && (
        <LabeledValue
          labelId="Charts.Overview.NumberOfSamples"
          value={numberOfSamples}
        />
      )}
    </SocialDistancing>
  </Container>
)
