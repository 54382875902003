import { capitalize } from 'lodash'

export type ISODateTime = string
export type ISODateTimeSansMilliseconds = string
export type ISODate = string
export type DimensionedQuantity = { value: number; units: string }
export type FormattedMessageId = string
export type Markdown = string
export type StyleObject = { [key: string]: string | number }
export type ID = number | string

/**
 * AnyObject supports a key whose value is `any` object.
 * This allows values of unknown object shape.
 */
export type AnyObject = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export const typeToTitle: (type: string) => string = (type) =>
  capitalize(type.replace('_', ' '))
