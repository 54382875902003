import React from 'react'
import { LayoutDirection } from 'types/enums'
import SocialDistancing from 'components/SocialDistancing'
import CalibrationTable from './components/CalibrationTable'
import CalibrationReport from './components/CalibrationReport'

const QuickScanCalibration: React.FC = () => (
  <SocialDistancing
    testId="QuickscanCalibration"
    direction={LayoutDirection.Horizontal}
    spacing="48px"
  >
    <div style={{ flexGrow: 1 }}>
      <CalibrationTable />
    </div>
    <CalibrationReport />
  </SocialDistancing>
)

export default QuickScanCalibration
