import { ApolloError, gql, useQuery } from '@apollo/client'
import { Locale } from 'localization'
import { ID } from 'types'
import { get, isSet } from 'utils'

export type FeatureFlags = {
  auditReport: boolean
  biReport: boolean
  emailAlerts: boolean
  operatorTraining: boolean
  qualityReports: boolean
}

type Organization = {
  id: ID
  shorthandName: string
  quickscanAccessCode: string
  featureFlags: FeatureFlags
}

export type User = {
  id: ID
  email: string
  firstName: string
  lastName: string
  orgAdmin: boolean
  language: Locale
  hasPassword: boolean
  organization: Organization
}

export const CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    currentUser {
      id
      email
      firstName
      lastName
      orgAdmin
      organization {
        id
        shorthandName
        quickscanAccessCode
        featureFlags {
          auditReport
          biReport
          emailAlerts
          operatorTraining
          qualityReports
        }
      }
    }
    labCustomers {
      id
    }
  }
`

const useCurrentUser: () => {
  loading: boolean
  error: ApolloError | undefined
  isQuickscanCustomer: boolean
  isLabsCustomer: boolean
  featureFlags: FeatureFlags
} & User = () => {
  const { loading, error, data } = useQuery(CURRENT_USER_QUERY)
  const currentUser = get(data, 'currentUser') as User

  const labCustomers = get(data, 'labCustomers', [])
  const isLabsCustomer = labCustomers.length > 0

  const featureFlags = currentUser?.organization?.featureFlags

  return {
    loading,
    error,
    ...currentUser,
    isQuickscanCustomer: isSet(
      get(currentUser, 'organization.quickscanAccessCode')
    ),
    isLabsCustomer,
    featureFlags,
  }
}

export default useCurrentUser
