import React, { FunctionComponent } from 'react'
import { ApolloConsumer, gql, useMutation } from '@apollo/client'
import { Formik, Form } from 'formik'
import { groupBy, join, map, property } from 'lodash'
import * as Yup from 'yup'
import { intlMessageForId } from 'localization'
import { FormName } from 'types/forms'
import { get } from 'utils'
import Section from 'components/Section'
import SocialDistancing from 'components/SocialDistancing'
import { PasswordInput, REQUIRED_ERROR_MESSAGE } from 'components/FormElements'
import { PrimaryButton } from 'components/Button'
import { Error } from 'components/Placeholders'
import { clearSession, redirect, Client } from 'components/Logout'

const CHANGE_PASSWORD_MUTATION = gql`
  mutation(
    $currentPassword: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    changePassword(
      currentPassword: $currentPassword
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      successful
      messages {
        message
        field
      }
    }
  }
`

interface FormValues {
  [FormName.CurrentPassword]: string
  [FormName.Password]: string
  [FormName.PasswordConfirmation]: string
}

const initialValues: FormValues = {
  [FormName.CurrentPassword]: '',
  [FormName.Password]: '',
  [FormName.PasswordConfirmation]: '',
}

const validationSchema = Yup.object().shape({
  [FormName.CurrentPassword]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  [FormName.Password]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  [FormName.PasswordConfirmation]: Yup.string().required(
    REQUIRED_ERROR_MESSAGE
  ),
})

const ChangePassword: FunctionComponent<{ client: Client }> = ({ client }) => {
  const [changePassword, { data, error }] = useMutation(
    CHANGE_PASSWORD_MUTATION
  )

  if (error) return <Error />

  const didChangePassword = get(data, 'changePassword.successful')

  if (didChangePassword) {
    clearSession(client)
    redirect()
    return null
  }

  const groupedValidationMessages = groupBy(
    get(data, 'changePassword.messages'),
    'field'
  )

  const getApiError: (field: string) => string = (field) => {
    return join(
      map(groupedValidationMessages[field], property(['message'])),
      ', '
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={(values: FormValues) => {
        const {
          CURRENT_PASSWORD: currentPassword,
          PASSWORD: password,
          PASSWORD_CONFIRMATION: passwordConfirmation,
        } = values

        changePassword({
          variables: {
            currentPassword,
            password,
            passwordConfirmation,
          },
        })
      }}
    >
      {() => (
        <Form>
          <Section
            header={intlMessageForId('Account.SectionHeaders.ChangePassword')}
          >
            <SocialDistancing spacing="24px" style={{ width: '375px' }}>
              <PasswordInput
                name={FormName.CurrentPassword}
                label={intlMessageForId(
                  'Account.Sections.ChangePassword.FieldLabels.CurrentPassword'
                )}
                apiError={getApiError('currentPassword')}
              />
              <PasswordInput
                name={FormName.Password}
                label={intlMessageForId(
                  'Account.Sections.ChangePassword.FieldLabels.Password'
                )}
                apiError={getApiError('password')}
              />
              <PasswordInput
                name={FormName.PasswordConfirmation}
                label={intlMessageForId(
                  'Account.Sections.ChangePassword.FieldLabels.PasswordConfirmation'
                )}
                apiError={getApiError('passwordConfirmation')}
              />
              <PrimaryButton type="submit" style={{ width: '100%' }}>
                {intlMessageForId('Common.Save')}
              </PrimaryButton>
            </SocialDistancing>
          </Section>
        </Form>
      )}
    </Formik>
  )
}

export default (() => (
  <ApolloConsumer>
    {(client) => {
      return <ChangePassword client={client} />
    }}
  </ApolloConsumer>
)) as FunctionComponent
