import React, { FC } from 'react'
import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { capitalize, map } from 'lodash/fp'
import { intlMessageForId } from 'localization'
import { Loading, Error } from 'components/Placeholders'
import Table, { getColumns, TableColumnType } from 'components/Table'
import { ID } from 'types'
import { getDetailsPath, Path } from 'types/enums'
import { FormName } from 'types/forms'
import { compare, insertAtIndex, openUrl } from 'utils'
import { QuickscanBiReport, QuickscanBiReportAnalysis } from './types'

const EMPTY_STATE_STRING = intlMessageForId('Common.Any')

export const QUICKSCAN_BI_REPORTS_QUERY = gql`
  query {
    quickscanBiReports {
      id
      analyses {
        analyte
        commodity
      }
      analysisType
      recordedAt
      status
    }
  }
`

const tableColumns: Array<TableColumnType> = [
  FormName.AnalysisType,
  FormName.Analyses,
  FormName.Status,
].map((field) => {
  return {
    title: intlMessageForId(`Analytics.QuickScan.Table.Fields.${field}`),
    field,
    emptyValue: EMPTY_STATE_STRING,
  }
})

const createdAtColumn = {
  title: intlMessageForId(
    `Analytics.QuickScan.Table.Fields.${FormName.CreatedAt}`
  ),
  field: FormName.CreatedAt,
  emptyValue: EMPTY_STATE_STRING,
  customSort: (
    a: { [FormName.CreatedAt]: string },
    b: { [FormName.CreatedAt]: string }
  ) => {
    const aDate = dayjs(a[FormName.CreatedAt]).toDate()
    const bDate = dayjs(b[FormName.CreatedAt]).toDate()

    return compare(aDate, bDate, 'ascending')
  },
}

const AnalyticsQuickScanReports: FC = () => {
  const { loading, error, data } = useQuery(QUICKSCAN_BI_REPORTS_QUERY, {
    fetchPolicy: 'no-cache',
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const reports = data?.quickscanBiReports

  const tableData = map((report: QuickscanBiReport) => {
    const { id, analyses, analysisType, recordedAt, status } = report

    const analysesCell = map((analysis: QuickscanBiReportAnalysis) => {
      const { analyte, commodity } = analysis
      return `${analyte} in ${capitalize(commodity)}`
    })(analyses).join(', ')

    return {
      id,
      [FormName.AnalysisType]: capitalize(analysisType),
      [FormName.Analyses]: analysesCell,
      [FormName.CreatedAt]: dayjs(recordedAt).toDate().toLocaleDateString(),
      [FormName.Status]: capitalize(status),
    }
  })(reports)

  return (
    <Table
      testId="AnalyticsQuickScanReports"
      columns={getColumns(insertAtIndex(tableColumns, 2, createdAtColumn), [
        'id',
      ])}
      data={tableData}
      onRowClick={(_e: MouseEvent, rowData: { id?: ID }) => {
        const { id } = rowData
        if (id) {
          openUrl(getDetailsPath(Path.Analytics_QuickScan, id))
        }
      }}
      search
      sorting
    />
  )
}

export default AnalyticsQuickScanReports
