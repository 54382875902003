import React, { FunctionComponent } from 'react'
import { gql, useQuery } from '@apollo/client'
import { map, upperFirst } from 'lodash/fp'
import { useNavigate } from 'react-router-dom'
import { ID } from 'types'
import { LayoutDirection, Path } from 'types/enums'
import { intlMessageForId } from 'localization'
import { EMPTY_STATE_STRING } from 'constants/index'
import { User } from 'hooks/useCurrentUser'
import Table, { getColumns, TableColumnType } from 'components/Table'
import { Loading, Error } from 'components/Placeholders'
import { LinkButton } from 'components/Button'
import SocialDistancing from 'components/SocialDistancing'
import { getDisplayName, getRole, getStatus } from './utils'

type TableDatum = {
  id: ID
  email: string
  name: string
  role: string
  status: string
}

const tableColumns: Array<TableColumnType> = [
  'email',
  'name',
  'role',
  'status',
].map((field) => {
  return {
    title: intlMessageForId(`Admin.FieldLabels.${upperFirst(field)}`),
    field,
    emptyValue: EMPTY_STATE_STRING,
  }
})

export const USERS_QUERY = gql`
  query {
    users {
      id
      email
      firstName
      lastName
      orgAdmin
      hasPassword
    }
  }
`

const Users: FunctionComponent<{
  limit?: number
}> = () => {
  const navigate = useNavigate()
  const { loading, error, data } = useQuery(USERS_QUERY, {
    fetchPolicy: 'no-cache',
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const tableData: Array<TableDatum> = map((user: User) => {
    const { id, email, firstName, lastName, orgAdmin, hasPassword } = user

    const name = getDisplayName(firstName, lastName)
    const role = getRole(orgAdmin)
    const status = getStatus(hasPassword)

    return {
      id,
      email,
      name,
      role,
      status,
    }
  })(data.users) as Array<TableDatum>

  return (
    <div>
      <SocialDistancing
        direction={LayoutDirection.Horizontal}
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h2>{intlMessageForId('Admin.Organization.Users')}</h2>
        <LinkButton to={`${Path.AdminSettings_Users}/new`}>
          {intlMessageForId('Admin.Users.Buttons.AddUser')}
        </LinkButton>
      </SocialDistancing>
      <Table
        testId="OrganizationUsers"
        columns={getColumns(tableColumns, ['id'])}
        data={tableData}
        onRowClick={(_e: MouseEvent, rowData: { id?: ID }) => {
          const { id } = rowData
          navigate(`${Path.AdminSettings_Users}/${id}/edit`)
        }}
      />
    </div>
  )
}

export default Users
