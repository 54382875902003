import React, { FC, ReactElement } from 'react'
import { gql, useQuery } from '@apollo/client'
import { map } from 'lodash/fp'
import { useParams } from 'react-router-dom'
import { SectionDivider } from 'components/Divider'
import Page from 'components/Page'
import { Loading, Error } from 'components/Placeholders'
import Table, {
  cellStyle,
  DetailedCell,
  DetailedHeader,
  TableColumnType,
} from 'components/Table'
import { EMPTY_STATE_STRING } from 'constants/index'
import { intlMessageForId } from 'localization'
import { formatDate } from 'utils/format'
import { OperatorTrainingCourse, OperatorTrainingCoursesOutput } from './types'
import {
  formatFrequency,
  formatStatus,
  highlight,
  sortedOperatorTrainingCourses,
} from './utils'

type TableDatum = {
  course: string
  status: ReactElement
  quizScore: number
  frequency: ReactElement
}

export const OPERATOR_TRAINING_COURSES_QUERY = gql`
  query($id: ID!) {
    operatorTrainingCourses(id: $id) {
      courses {
        frequency
        id
        name
        nextDueAt
        quizScore
        status
        statusAt
      }
      firstName
      lastName
    }
  }
`

const tableColumns: Array<TableColumnType> = [
  {
    field: 'course',
    title: intlMessageForId('Training.FieldLabels.COURSE'),
  },
  {
    field: 'status',
    title: (
      <DetailedHeader
        text={intlMessageForId('Training.FieldLabels.STATUS')}
        detail={intlMessageForId('Training.FieldLabels.STATUS_AT')}
      />
    ),
    cellStyle,
  },
  {
    field: 'quizScore',
    title: intlMessageForId('Training.FieldLabels.QUIZ_SCORE'),
    emptyValue: EMPTY_STATE_STRING,
  },
  {
    field: 'frequency',
    title: (
      <DetailedHeader
        text={intlMessageForId('Training.FieldLabels.FREQUENCY')}
        detail={intlMessageForId('Training.FieldLabels.NEXT_DUE_DATE')}
      />
    ),
  },
]

const AssignedTrainingDetails: FC = () => {
  const { id } = useParams<{ id: string }>()
  const { loading, error, data } = useQuery(OPERATOR_TRAINING_COURSES_QUERY, {
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const {
    courses,
    firstName,
    lastName,
  } = data?.operatorTrainingCourses as OperatorTrainingCoursesOutput

  const sortedCourses = sortedOperatorTrainingCourses(courses)

  const tableData: Array<TableDatum> = map(
    (operatorTrainingCourse: OperatorTrainingCourse) => {
      const {
        frequency,
        name,
        nextDueAt,
        quizScore,
        status,
        statusAt,
      } = operatorTrainingCourse

      return {
        course: name,
        status: (
          <DetailedCell
            text={formatStatus(status)}
            detailText={statusAt ? formatDate(statusAt) : EMPTY_STATE_STRING}
            highlight={highlight(status, statusAt)}
          />
        ),
        quizScore,
        frequency: (
          <DetailedCell
            text={formatFrequency(frequency)}
            detailText={nextDueAt ? formatDate(nextDueAt) : EMPTY_STATE_STRING}
          />
        ),
      }
    }
  )(sortedCourses)

  return (
    <Page
      testId="AssignedTrainingDetails"
      title={`${intlMessageForId(
        'Training.AssignedTrainingDetails.Header'
      )}: ${lastName}, ${firstName}`}
    >
      <>
        <SectionDivider style={{ marginBottom: 42 }} />
        <Table columns={tableColumns} data={tableData} />
      </>
    </Page>
  )
}

export default AssignedTrainingDetails
