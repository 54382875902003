import React, { ChangeEvent, FunctionComponent } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useFormikContext } from 'formik'
import { find, merge, omit } from 'lodash/fp'
import { intlMessageForId } from 'localization'
import { get, isSet } from 'utils'
import { ID } from 'types'
import { LayoutDirection } from 'types/enums'
import SocialDistancing from 'components/SocialDistancing'
import { Error, Loading } from 'components/Placeholders'
import { TextButton } from 'components/Button'
import Select from 'components/Select'
import { fromApiFormat } from '../TestResults/utils'
import { Template, TemplatesQuery } from '../types'

const UPDATE_TEMPLATE_MUTATION = gql`
  mutation(
    $id: id!
    $title: string!
    $columns: [QuickscanReportColumn]!
    $filters: QuickscanReportFiltersTemplateInput!
    $mergeCombResults: Boolean
  ) {
    updateQuickscanReportTemplate(
      id: $id
      title: $title
      columns: $columns
      filters: $filters
      mergeCombResults: $mergeCombResults
    ) {
      successful
    }
  }
`

const DELETE_TEMPLATE_MUTATION = gql`
  mutation($id: id!) {
    deleteQuickscanReportTemplate(id: $id) {
      messages {
        code
        message
      }
      result {
        id
      }
      successful
    }
  }
`

const ChooseTemplate: FunctionComponent<{
  selectedTemplateId: ID | undefined
  setSelectedTemplateId: (templateId: ID | undefined) => void
  templatesQuery: TemplatesQuery
}> = ({ selectedTemplateId, setSelectedTemplateId, templatesQuery }) => {
  const { data, loading, error, refetch } = templatesQuery

  const [updateQuickscanReportTemplate, { error: updateError }] = useMutation(
    UPDATE_TEMPLATE_MUTATION,
    {
      onCompleted: () => {
        refetch()
      },
    }
  )

  const [deleteQuickscanReportTemplate, { error: deleteError }] = useMutation(
    DELETE_TEMPLATE_MUTATION,
    {
      onCompleted: () => {
        setSelectedTemplateId(undefined)
        templatesQuery.refetch()
      },
    }
  )

  const templates = get(data, 'quickscanReportTemplates', [])

  const { values, setValues } = useFormikContext()

  if (loading) return <Loading />
  if (error || updateError || deleteError) return <Error />

  const findTemplate = (id: ID): Template => {
    return find({ id }, templates)
  }

  const changeTemplate = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedTemplateId(e.target.value)
    const chosenTemplate = findTemplate(e.target.value)
    if (chosenTemplate) {
      setValues(merge(values, fromApiFormat(chosenTemplate)))
    }
  }

  const editTitle = () => {
    if (!isSet(selectedTemplateId)) {
      return
    }

    const selectedTemplate = findTemplate(selectedTemplateId)

    // using alert by design
    // eslint-disable-next-line no-alert
    const title = window.prompt(
      intlMessageForId('Reports.Dialogs.NameTemplatePrompt'),
      selectedTemplate.title
    )
    if (title) {
      updateQuickscanReportTemplate({
        variables: {
          id: selectedTemplateId,
          title,
          columns: selectedTemplate.columns,
          filters: omit('__typename', selectedTemplate.filters),
          mergeCombResults: selectedTemplate.mergeCombResults,
        },
      })
    }
  }

  const deleteTemplate = () => {
    if (!isSet(selectedTemplateId)) {
      return
    }

    const confirmation = window.confirm(
      intlMessageForId('Reports.Dialogs.DeleteTemplatePrompt')
    )
    if (confirmation) {
      deleteQuickscanReportTemplate({
        variables: { id: selectedTemplateId },
      })
    }
  }

  return (
    <SocialDistancing spacing="16px" direction={LayoutDirection.Horizontal}>
      <Select onChange={changeTemplate} value={selectedTemplateId || ''}>
        <option value="">
          {intlMessageForId('Reports.Form.Templates.None')}
        </option>
        {templates.map((template: Template) => (
          <option key={template.id} value={template.id}>
            {template.title}
          </option>
        ))}
      </Select>

      {selectedTemplateId && (
        <SocialDistancing spacing="8px" direction={LayoutDirection.Horizontal}>
          <TextButton onClick={editTitle} style={{ color: '#10A5DD' }}>
            {intlMessageForId('Reports.CTAs.EditTemplateTitle')}
          </TextButton>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color: 'rgba(22, 42, 56, 0.5)',
            }}
          >
            •
          </div>
          <TextButton onClick={deleteTemplate}>
            {intlMessageForId('Reports.CTAs.DeleteTemplate')}
          </TextButton>
        </SocialDistancing>
      )}
    </SocialDistancing>
  )
}

export default ChooseTemplate
