import React, { FunctionComponent } from 'react'
import { map, property } from 'lodash/fp'
import { intlMessageForId } from 'localization'
import { Concern, Path } from 'types/enums'
import useCurrentUser from 'hooks/useCurrentUser'
import Page from 'components/Page'
import Divider from 'components/Divider'
import { SegmentType, useSegmentedControl } from 'components/SegmentedControl'
import { LinkButton } from 'components/Button'
import { Loading } from 'components/Placeholders'
import LabOrders from './LabOrders'
import LabResults from './LabResults'
import LabsConcernDashboard from './LabsConcernDashboard'
import LabOrderDetails from './LabOrderDetails'
import SampleDetails from './SampleDetails'
import NewLabOrderContainer from './LabOrderForm/NewLabOrderContainer'
import EditLabOrderContainer from './LabOrderForm/EditLabOrderContainer'
import NotYetCustomer from './NotYetCustomer'
import { AnalysisType } from './utils'

export {
  LabOrderDetails,
  SampleDetails,
  NewLabOrderContainer,
  EditLabOrderContainer,
}

enum SegmentKey {
  Gmo = 'GMO',
  Mycotoxin = 'Mycotoxin',
  Results = 'RESULTS',
  Orders = 'ORDERS',
}

const segments: Array<SegmentType> = [
  {
    key: SegmentKey.Gmo,
    title: intlMessageForId('Labs.Links.Gmo'),
    path: Path.Labs_Gmo,
  },
  {
    key: SegmentKey.Mycotoxin,
    title: intlMessageForId('Labs.Links.Mycotoxin'),
    path: Path.Labs_Mycotoxin,
  },
  {
    key: SegmentKey.Results,
    title: intlMessageForId('Labs.Links.Results'),
    path: Path.Labs_Results,
  },
  {
    key: SegmentKey.Orders,
    title: intlMessageForId('Labs.Links.Orders'),
    path: Path.Labs_Orders,
  },
]

const cases = {
  [SegmentKey.Orders]: <LabOrders />,
  [SegmentKey.Results]: <LabResults showFilters />,
  [SegmentKey.Gmo]: (
    <LabsConcernDashboard
      testId="LabsGMODashboard"
      key={Concern.GMO}
      analysisType={AnalysisType.GMO_PCR_QUANT}
      concern={Concern.GMO}
    />
  ),
  [SegmentKey.Mycotoxin]: (
    <LabsConcernDashboard
      testId="LabsMycotoxinDashboard"
      key={Concern.Mycotoxin}
      analysisType={AnalysisType.MYCOTOXIN_QUANT}
      concern={Concern.Mycotoxin}
    />
  ),
}

export const labsPaths: string[] = map(property(['path']))(segments)

const Labs: FunctionComponent = () => {
  const { loading, isLabsCustomer } = useCurrentUser()
  const { SegmentedControl, selectedSegment } = useSegmentedControl(segments)

  if (loading) return <Loading />

  const HeaderElement = (
    <LinkButton to={`${Path.Labs_Orders}/new`}>
      {intlMessageForId('Labs.NewOrder.CTA')}
    </LinkButton>
  )

  return (
    <Page
      title={intlMessageForId('Labs.Header')}
      testId="Labs"
      headerElement={isLabsCustomer ? HeaderElement : undefined}
    >
      {isLabsCustomer ? (
        <>
          <SegmentedControl />
          <Divider />
          <div style={{ paddingTop: '48px' }}>
            {cases[selectedSegment.key as SegmentKey]}
          </div>
        </>
      ) : (
        <NotYetCustomer />
      )}
    </Page>
  )
}

export default Labs
