import React, { FC } from 'react'
import { map, property } from 'lodash/fp'
import { intlMessageForId } from 'localization'
import { Path } from 'types/enums'
import Page from 'components/Page'
import { SegmentType, useSegmentedControl } from 'components/SegmentedControl'
import Divider from 'components/Divider'
import TotalHubSupport from './TotalHubSupport'
import QuickScan from './QuickScan'

enum SegmentKey {
  TotalHub = 'TOTAL_HUB',
  QuickScanSegment = 'QUICKSCAN_SEGMENT',
}

const segments: Array<SegmentType> = [
  {
    key: SegmentKey.TotalHub,
    title: intlMessageForId('HelpCenter.Links.TotalHubSupport'),
    path: Path.HelpCenter_Guides,
  },
  {
    key: SegmentKey.QuickScanSegment,
    title: intlMessageForId('Admin.Segments.QuickScan'),
    path: Path.HelpCenter_QuickScan,
  },
]

const cases = {
  [SegmentKey.TotalHub]: <TotalHubSupport />,
  [SegmentKey.QuickScanSegment]: <QuickScan />,
}

export const helpCenterPaths: string[] = map(property(['path']))(segments)

const HelpCenter: FC = () => {
  const { SegmentedControl, selectedSegment } = useSegmentedControl(segments)

  return (
    <Page title={intlMessageForId('HelpCenter.Header')} testId="HelpCenter">
      <SegmentedControl />
      <Divider />
      <div style={{ paddingTop: '48px' }}>
        {cases[selectedSegment.key as SegmentKey]}
      </div>
    </Page>
  )
}

export default HelpCenter
