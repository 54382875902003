import React from 'react'
import { StyleObject } from 'types'
import Color from 'types/color'

const Divider: React.FunctionComponent<{
  height?: string
  color?: string
  style?: StyleObject
}> = ({ height = '1px', color = Color.Border, style = {} }) => (
  <div style={{ height, backgroundColor: color, ...style }} />
)

export const SectionDivider: React.FunctionComponent<{
  style?: StyleObject
}> = ({ style }) => <Divider height="8px" style={style} />

export default Divider
