import React from 'react'
import styled from '@emotion/styled'
import { capitalize, find, orderBy } from 'lodash'
import { get, mapObject } from 'utils'
import { FormName } from 'types/forms'
import Color from 'types/color'
import {
  ErrorMessage,
  FormCheckbox,
  ToggledControlName,
  ToggledSelect,
} from 'components/FormElements'
import { ContentHeader, Subheader } from 'components/FormElements/common'
import { FormSelectOption } from 'components/MultiSelect'
import SocialDistancing from 'components/SocialDistancing'
import Group from 'components/FormElements/Group'
import { Assay, SampleFormValue } from './types'

const Container = styled.div`
  padding-left: 32px;
  border-left: ${Color.Border} solid 1px;
`

const getLabel: (
  analyte: string
) => { label: string; detail: string | undefined } = (analyte) => {
  const index = analyte.indexOf('(')

  if (index === -1) {
    return { label: analyte, detail: undefined }
  }

  const label = analyte.slice(0, index).replace(' ', '')
  const detail = analyte.slice(index)
  return { label, detail }
}

const LabAssays: React.FC<{
  concern: 'gmo' | 'mycotoxin'
  header: string
  subheader: string
  sections: Array<{
    title: string
    groupedAssays: Record<string, Array<Assay>>
  }>
  formValue: SampleFormValue
  getFieldName: (formName: FormName) => string
}> = ({ concern, header, subheader, sections, formValue, getFieldName }) => (
  <Container>
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 24 }}>
      <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: 16 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 2,
            flexGrow: 1,
          }}
        >
          <ContentHeader>{header}</ContentHeader>
          <Subheader>{subheader}</Subheader>
        </div>
        {concern === 'gmo' && (
          <FormCheckbox
            id={getFieldName(FormName.NoGmoSum)}
            checked={get(formValue, FormName.NoGmoSum, false)}
            name={getFieldName(FormName.NoGmoSum)}
            labelStyle={{
              fontSize: '14px',
              lineHeight: '21px',
            }}
          >
            DO NOT include GMO sum score
          </FormCheckbox>
        )}
      </div>
      <SocialDistancing spacing="32px">
        {sections.map(({ title, groupedAssays }) => {
          const fieldNamePrefix = getFieldName(FormName.Tests)
          return (
            groupedAssays && (
              <Group title={title} key={title}>
                {mapObject(groupedAssays, (analyte, assaysForAnalyte) => {
                  const quantAssayId = find(assaysForAnalyte, {
                    type: 'QUANTITATIVE',
                  })?.id

                  const selectOptions = orderBy(
                    assaysForAnalyte,
                    'category',
                    'desc'
                  ).map((assay: Assay) => {
                    return {
                      label: capitalize(assay.type),
                      value: assay.id,
                    }
                  }) as FormSelectOption[]

                  const fieldName = `${fieldNamePrefix}.${analyte}`

                  const { label, detail } = getLabel(analyte)

                  const checked = get(
                    formValue,
                    `${FormName.Tests}.${analyte}.${ToggledControlName.Checked}`
                  )

                  const value = get(
                    formValue,
                    `${FormName.Tests}.${analyte}.${ToggledControlName.Value}`
                  )

                  return (
                    <ToggledSelect
                      name={fieldName}
                      label={label}
                      detail={detail}
                      initialValue={checked ? value : quantAssayId}
                      selectOptions={selectOptions}
                      defaultChecked={checked}
                      key={`${fieldName}-${formValue.COMMODITY}`}
                    />
                  )
                })}
              </Group>
            )
          )
        })}
        <ErrorMessage forName={getFieldName(FormName.Tests)} />
      </SocialDistancing>
    </div>
  </Container>
)

export default LabAssays
