import React, { ReactElement } from 'react'
import {
  SegmentedPageSegmentType,
  getSegmentedPageWithRoutes,
} from 'components/SegmentedPage'
import { intlMessageForId } from 'localization'
import { Path } from 'types/enums'
import AnalyticsQuickScanForm from './Form'
import AnalyticsQuickScanReports from './Reports'

const segments: Array<SegmentedPageSegmentType> = [
  {
    key: 'AnalyticsQuickScanForm',
    title: intlMessageForId('Analytics.Segments.Form'),
    path: Path.Analytics_QuickScanForm,
    component: <AnalyticsQuickScanForm />,
  },
  {
    key: 'AnalyticsQuickScanReports',
    title: intlMessageForId('Analytics.Segments.Reports'),
    path: Path.Analytics_QuickScan,
    component: <AnalyticsQuickScanReports />,
  },
]

export const createAnalyticsQuickScanSegments = (): Array<ReactElement> => {
  return getSegmentedPageWithRoutes({
    testId: 'AnalyticsQuickScan',
    title: intlMessageForId('Analytics.Title'),
    rootPath: Path.Analytics,
    segments,
  })
}
