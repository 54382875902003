import React, { FC, ReactElement, useEffect, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { remove } from 'lodash'
import { map, upperCase } from 'lodash/fp'
import { useNavigate } from 'react-router-dom'
import { LinkButton } from 'components/Button'
import { handleCompleted, handleError } from 'components/FormElements/common'
import { Loading, Error } from 'components/Placeholders'
import Table, {
  cellStyle,
  DeleteCell,
  DetailedCell,
  DetailedHeader,
  getColumns,
  TableColumnType,
} from 'components/Table'
import { EMPTY_STATE_STRING } from 'constants/index'
import useCurrentUser from 'hooks/useCurrentUser'
import { intlMessageForId } from 'localization'
import { AnyObject } from 'types'
import { getDetailsPath, getNewPath, Path } from 'types/enums'
import { get, isSet } from 'utils'
import { formatDate } from 'utils/format'
import { OperatorTraining } from './types'
import {
  formatFrequency,
  formatStatus,
  highlight,
  sortedOperatorTrainings,
} from './utils'

type TableDatum = {
  id: number
  name: string
  email: string
  totalCourses: number
  status: ReactElement
  frequency: ReactElement
}

export const OPERATOR_TRAININGS_QUERY = gql`
  query {
    operatorTrainings {
      dueAt
      email
      firstName
      frequency
      id
      lastName
      nextDueAt
      status
      totalCourses
    }
  }
`

const getFilter: (
  field: string
) => (value: string, rowData: AnyObject) => boolean = (field) => {
  return (value: string, rowData: AnyObject) => {
    const { detailText, text } = get(rowData, `${field}.props`, {})
    const searchableText = detailText ? `${text} ${detailText}` : text
    return searchableText.toLowerCase().indexOf(value.toLowerCase()) > -1
  }
}
const DELETE_OPERATOR_TRAINING_MUTATION = gql`
  mutation($id: ID!) {
    deleteOperatorTraining(id: $id)
  }
`

const columns: Array<TableColumnType> = ['name', 'email', 'totalCourses'].map(
  (field) => {
    return {
      field,
      title: intlMessageForId(
        `Training.FieldLabels.${upperCase(field).replaceAll(' ', '_')}`
      ),
    }
  }
)

const customColumns: Array<TableColumnType> = [
  {
    field: 'status',
    title: (
      <DetailedHeader
        text={intlMessageForId('Training.FieldLabels.STATUS')}
        detail={intlMessageForId('Training.FieldLabels.DUE_DATE')}
      />
    ),
    cellStyle,
    customFilterAndSearch: getFilter('status'),
  },
  {
    field: 'frequency',
    title: (
      <DetailedHeader
        text={intlMessageForId('Training.FieldLabels.FREQUENCY')}
        detail={intlMessageForId('Training.FieldLabels.NEXT_DUE_DATE')}
      />
    ),
    customFilterAndSearch: getFilter('frequency'),
  },
]

const tableColumns = [...columns, ...customColumns]

const AssignedTrainings: FC = () => {
  const navigate = useNavigate()
  const { email: userEmail } = useCurrentUser()
  const [trainings, setTrainings] = useState<Array<OperatorTraining>>([])

  const { loading, error, data, refetch } = useQuery(OPERATOR_TRAININGS_QUERY, {
    fetchPolicy: 'no-cache',
  })

  const [deleteOperatorTraining] = useMutation(
    DELETE_OPERATOR_TRAINING_MUTATION,
    {
      onCompleted: ({ deleteOperatorTraining: id }) => {
        remove(trainings, (training: OperatorTraining) => {
          return training.id === id
        })

        handleCompleted(
          navigate,
          window.location,
          intlMessageForId('Training.Confirmations.Deleted')
        )
      },
      onError: () => {
        handleError(navigate, window.location)
        refetch()
      },
    }
  )

  const response = data?.operatorTrainings
  useEffect(() => {
    setTrainings(response)
  }, [response])

  if (loading) return <Loading />
  if (error) return <Error />

  const operatorTrainings = sortedOperatorTrainings(data.operatorTrainings)

  const tableData: Array<TableDatum> = map((training: OperatorTraining) => {
    const {
      dueAt,
      email,
      firstName,
      frequency,
      id,
      lastName,
      nextDueAt,
      status,
      totalCourses,
    } = training

    return {
      id,
      name: `${lastName}, ${firstName}`,
      email,
      totalCourses,
      status: (
        <DetailedCell
          text={formatStatus(status)}
          detailText={dueAt ? formatDate(dueAt) : EMPTY_STATE_STRING}
          highlight={highlight(status, dueAt)}
        />
      ),
      frequency: (
        <DetailedCell
          text={formatFrequency(frequency)}
          detailText={nextDueAt ? formatDate(nextDueAt) : EMPTY_STATE_STRING}
        />
      ),
      delete:
        email !== userEmail ? (
          <DeleteCell
            confirmationMessage="Are you sure you want to delete the training?" // TODO: intl
            onClick={() =>
              deleteOperatorTraining({
                variables: { id },
              })
            }
          />
        ) : null,
    }
  })(operatorTrainings)

  return (
    <Table
      testId="AssignedTrainings"
      columns={getColumns(tableColumns, ['id'], { includeDelete: true })}
      data={tableData}
      onRowClick={(_e: MouseEvent, rowData: { id?: number }) => {
        const { id } = rowData
        if (isSet(id)) {
          navigate(getDetailsPath(Path.Quality_AssignedTrainings, id))
        }
      }}
      CallToAction={
        <LinkButton
          to={getNewPath(Path.Quality_AssignedTrainings)}
          style={{ float: 'right' }}
        >
          {intlMessageForId('Training.Buttons.AssignTraining')}
        </LinkButton>
      }
      search
    />
  )
}

export default AssignedTrainings
