import ReactGA from 'react-ga4'

export enum EventCategory {
  Filter = 'Filter',
  Button = 'Button',
  NavItem = 'Nav Item',
  TotalHubSupport = 'TotalHub Support',
  ListItem = 'List Item',
  Segment = 'Segment',
  Overview = 'Overview',
}

export enum EventAction {
  Select = 'Select',
  Click = 'Click',
}

export enum EventLabel {
  AverageType = 'AverageType',
  LoadAcceptance = 'LoadAcceptance',
  LabOrder = 'Lab Order',
  LabResult = 'Lab Result',
  LabResultPdf = 'Lab Result PDF',
  ResultType = 'ResultType',
  Unknown = 'Unknown',
}

type TrackingFunc<T> = (
  category: EventCategory,
  action: string,
  label: string,
  value?: number
) => T

// Helper function for Google Analytics event tracker
const buildTrackingEvent: TrackingFunc<{
  category: string
  action: string
  label: string
  value: number
}> = (category, action, label, value = 1) => ({
  category,
  action,
  label,
  value,
})

export const trackEvent: TrackingFunc<void> = (
  category,
  action,
  label,
  value = 1
) => {
  ReactGA.event(buildTrackingEvent(category, action, label, value))
}
