import { FC } from 'react'
import { gql } from '@apollo/client'
import { FormName } from 'types/forms'
import { get } from 'utils'
import useTemplates from 'hooks/useFormTemplates'
import {
  BuildCreatePayload,
  BuildUpdatePayload,
  OnTemplateChange,
  SetFormikValues,
  TemplateBase,
} from 'hooks/useFormTemplates/types'
import { FormValues, LabOrderLocation } from './types'

export const LAB_ORDER_TEMPLATES_QUERY = gql`
  query {
    labOrderTemplates {
      customerAccountId
      id
      location
      poNum
      sendReportTo
      specialInstructions
      title
    }
  }
`

type LabOrderTemplate = {
  customerAccountId?: string
  location?: LabOrderLocation
  poNum?: string
  sendReportTo?: Array<string>
  specialInstructions?: string
} & TemplateBase

export const CREATE_LAB_ORDER_TEMPLATE_MUTATION = gql`
  mutation(
    $customerAccountId: String
    $location: LabOrderLocation
    $poNum: String
    $sendReportTo: [String]
    $specialInstructions: String
    $title: String!
  ) {
    createLabOrderTemplate(
      customerAccountId: $customerAccountId
      location: $location
      poNum: $poNum
      sendReportTo: $sendReportTo
      specialInstructions: $specialInstructions
      title: $title
    ) {
      messages {
        code
        field
        message
      }
      result {
        id
      }
      successful
    }
  }
`

const UPDATE_LAB_ORDER_TEMPLATE_MUTATION = gql`
  mutation(
    $customerAccountId: String
    $id: ID!
    $location: LabOrderLocation
    $poNum: String
    $sendReportTo: [String]
    $specialInstructions: String
    $title: String!
  ) {
    updateLabOrderTemplate(
      customerAccountId: $customerAccountId
      id: $id
      location: $location
      poNum: $poNum
      sendReportTo: $sendReportTo
      specialInstructions: $specialInstructions
      title: $title
    ) {
      messages {
        code
        field
        message
      }
      result {
        id
      }
      successful
    }
  }
`

const DELETE_LAB_ORDER_TEMPLATE_MUTATION = gql`
  mutation($id: id!) {
    deleteLabOrderTemplate(id: $id) {
      messages {
        code
        message
      }
      result {
        id
      }
      successful
    }
  }
`

const queryKey = 'labOrderTemplates'
const createMutationKey = 'createLabOrderTemplate'

const buildCreatePayload: BuildCreatePayload<FormValues> = (
  title: string,
  formValue: FormValues
) => ({
  customerAccountId: formValue[FormName.CustomerLocation],
  location: formValue[FormName.EnvirologixLocation] || null,
  poNum: formValue[FormName.PoNumber],
  sendReportTo: [
    formValue[FormName.Email1],
    formValue[FormName.Email2],
    formValue[FormName.Email3],
  ],
  specialInstructions: formValue[FormName.Comments],
  title,
})

// currently we only support updating the title so not getting the form values
const buildUpdatePayload: BuildUpdatePayload<LabOrderTemplate> = (
  title,
  selectedTemplate
) => ({
  customerAccountId: selectedTemplate.customerAccountId,
  id: selectedTemplate.id,
  location: selectedTemplate.location,
  poNum: selectedTemplate.poNum,
  sendReportTo: selectedTemplate.sendReportTo,
  specialInstructions: selectedTemplate.specialInstructions,
  title,
})

export type SaveLabOrderTemplateType = FC<{ formValue: FormValues }>

export const useLabOrderTemplates: () => {
  hasLabOrderTemplates: boolean
  ChooseLabOrderTemplate: FC
  SaveLabOrderTemplate: SaveLabOrderTemplateType
} = () => {
  const onTemplateChange: OnTemplateChange<LabOrderTemplate, FormValues> = (
    chosenTemplate: LabOrderTemplate,
    formValues: FormValues,
    setValues: SetFormikValues
  ) => {
    const newValues = {
      // clone form values
      ...formValues,
      [FormName.CustomerLocation]: chosenTemplate.customerAccountId || '',
      [FormName.EnvirologixLocation]: chosenTemplate.location || '',
      [FormName.Email1]: get(chosenTemplate, 'sendReportTo[0]', ''),
      [FormName.Email2]: get(chosenTemplate, 'sendReportTo[1]', ''),
      [FormName.Email3]: get(chosenTemplate, 'sendReportTo[2]', ''),
      [FormName.PoNumber]: chosenTemplate.poNum || '',
      [FormName.Comments]: chosenTemplate.specialInstructions || '',
    }

    setValues(newValues)
  }

  const {
    hasTemplates: hasLabOrderTemplates,
    ChooseTemplate: ChooseLabOrderTemplate,
    SaveTemplate: SaveLabOrderTemplate,
  } = useTemplates<LabOrderTemplate, FormValues>({
    query: LAB_ORDER_TEMPLATES_QUERY,
    createMutation: CREATE_LAB_ORDER_TEMPLATE_MUTATION,
    updateMutation: UPDATE_LAB_ORDER_TEMPLATE_MUTATION,
    deleteMutation: DELETE_LAB_ORDER_TEMPLATE_MUTATION,
    queryKey,
    createMutationKey,
    buildCreatePayload,
    buildUpdatePayload,
    onTemplateChange,
    testId: 'LabOrderTemplates',
  })

  return {
    hasLabOrderTemplates,
    ChooseLabOrderTemplate,
    SaveLabOrderTemplate,
  }
}
