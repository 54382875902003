enum EnvirologixLocation {
  SaintCharles = 'SAINT_CHARLES',
  Portland = 'PORTLAND',
}

export type Location = {
  name: string
  address: string
  city: string
  state: string
  zip: string
  phone: string
}

export const EnvirologixLocations: { [key: string]: Location } = {
  [EnvirologixLocation.SaintCharles]: {
    name: 'EnviroLogix Laboratory Services',
    address: '3 Research Park Drive',
    city: 'St. Charles',
    state: 'MO',
    zip: '63304',
    phone: '1-207-797-0300',
  },
  [EnvirologixLocation.Portland]: {
    name: 'EnviroLogix Laboratory Services',
    address: '500 Riverside Industrial Parkway',
    city: 'Portland',
    state: 'ME',
    zip: '04103',
    phone: '1-207-797-0300',
  },
}

/**
 * Corresponds to assay's 'category' in QBench
 */
export enum AnalysisType {
  GMO_PCR_QUANT = 'GMO PCR Quant',
  GMO_PCR_QUAL = 'GMO PCR Qual',
  MYCOTOXIN_QUANT = 'Mycotoxin - Analytical Quant',
}

export type AnalysisTypeQuant = Omit<AnalysisType, 'GMO_PCR_QUAL'>
